import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { academicInstitutionsService } from 'services';
import { UrlEnum } from 'shared';

const Header = props => {
    return (
        <th colSpan={3}>
            <div className="d-flex justify-content-center">
                <h5 className="mb-0 text-strong">{props.children}</h5>
            </div>
        </th>
    );
};

const SubHeader = () => {
    return (
        <thead>
            <tr>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Head Count
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    FTE
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Degrees
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Head Count
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    FTE
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Degrees
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Head Count
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    FTE
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Degrees
                </th>
            </tr>
        </thead>
    );
};

const Cell = props => {
    return <td className="text-center text-strong">{props.children}</td>;
};

export const AcademicAffairSystemView = ({ academicSystemsData }) => {
    

    const makeShortDate = date => {
        return new Date(date).toLocaleDateString('en-US');
    };

    
    console.log(academicSystemsData)

    const {
        systemName,
        systemID,
        institutions,
    }=academicSystemsData;
    
    return (
        <React.Fragment>
            <div className="page-container">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_INSTITUTIONS}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back to search
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>System Name</label>
                                        <div>{systemName}</div>
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>System Id</label>
                                        <div>{systemID}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                                    <div className="col-md mb-3">
                                        <label>Approved Institutions</label>
                                        <div className="d-flex">
                                            <ul className="mr-3 p-0 mb-0 no-bullets med-type">
                                                {institutions?.map(loc => (
                                                    <li key={loc.institutionId}>
                                                        {loc.institutionName}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                </div>
            </div>
        </React.Fragment>
    );
};
