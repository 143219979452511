import React, { useState, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { capitalProjectTypeService } from 'services';
import { faDollarSign, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
    Form,
    Label,
    Input,
    Row,
    Col,
    Button,
    Table,
    Card,
    CardTitle,
    InputGroup,
    UncontrolledTooltip,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { fundingSource, RadioButtons, FormEnum } from 'shared';
import { ValidationErrorBox } from './validation-error-box';
import { InfoTooltip } from 'components/blocks';

export const CostSizeAndFunding = ({ capitalProject, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 2,
            sizeGSF: capitalProject.sizeGSF ?? 0,
            sizeASF: capitalProject.sizeASF ?? 0,
            netChangeGSF: capitalProject.netChangeGSF ?? 0,
            netChangeASF: capitalProject.netChangeASF ?? 0,
            estAnnualChangeInOpsCost: capitalProject.estAnnualChangeInOpsCost ?? 0,
            estAnnualRRInvestment: capitalProject.estAnnualRRInvestment ?? 0,
            estAnnualDebtPayment: capitalProject.estAnnualDebtPayment ?? 0,
            isFundsSecured: capitalProject.isFundsSecured,
            fundingNarrative: capitalProject.fundingNarrative
                ? capitalProject.fundingNarrative
                : '',
            justificationOfCost: capitalProject.justificationOfCost
                ? capitalProject.justificationOfCost
                : '',
            fundingList: capitalProject.fundingList ? capitalProject.fundingList : [],
            totalProjectCost:
                capitalProject.sizeGSF +
                capitalProject.sizeASF +
                capitalProject.netChangeGSF +
                capitalProject.netChangeASF,
        }
    );
    const [fundingTypes] = useState(
        capitalProjectTypeService.getFundingType(capitalProject.biennium)
    );
    const handleNumberChange = evt => {
        const name = evt.target.name;
        const newValue = +evt.target.value ?? 0;
        capitalProject[name] = newValue;
        userInput.totalProjectCost =
            capitalProject.sizeGSF +
            capitalProject.sizeASF +
            capitalProject.netChangeGSF +
            capitalProject.netChangeASF;
        setUserInput({ [name]: newValue });
    };
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };
    const handleRadioButtonChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value === 'true' ? true : false;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };
    const handleFundingTypeChange = (selectedOption, details) => {
        const indexOfFundingSource = details.name;
        userInput.fundingList.forEach((fundingSource, index) => {
            if (index === indexOfFundingSource) {
                fundingSource.fundingTypeId = selectedOption;
            }
        });
        const newFundingSource = [...userInput.fundingList];
        capitalProject.fundingList = newFundingSource;
        return setUserInput({ fundingList: newFundingSource });
    };
    const addFundingSource = () => {
        userInput.fundingList.push(fundingSource());
        const newFundingDesc = [...userInput.fundingList];
        setUserInput({ fundingList: newFundingDesc });
    };
    const loadFundingTypes = () => {
        return Promise.resolve(fundingTypes).then(fundingTypes => {
            userInput.fundingList.forEach(fundingSource => {
                fundingTypes.forEach(fundingType => {
                    if (+fundingType.value === fundingSource.fundingTypeId) {
                        fundingSource.fundingTypeId = fundingType;
                        const newFundingSource = [...userInput.fundingList];
                        setUserInput({ fundingList: newFundingSource });
                    }
                });
            });
            return fundingTypes;
        });
    };

    const sanitizeCapitalProject = () => {
        userInput.fundingList.forEach(fundingSource => {
            const changeFundingTypeId = +fundingSource.fundingTypeId.value;
            fundingSource.fundingTypeId = changeFundingTypeId;
        });
        const sanitizedFundingList = [...userInput.fundingList];
        setUserInput({ fundingList: sanitizedFundingList });
        const sanitizedUserInput = userInput;
        return sanitizedUserInput;
    };
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Cost, Size, & Funding
            </h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.COSTSIZEFUNDING}
            />
            <Card className="no-shadow border p-3 mb-3">
                <CardTitle>
                    {' '}
                    <h4 className="mb-3 text-primary">Project Size</h4>
                </CardTitle>
                <Table
                    responsive
                    className="table table-striped table-bordered table-hover align-middle table-sm"
                >
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <div className="d-flex">
                                    GSF{' '}
                                    <InfoTooltip
                                        id="gsf-tooltip"
                                        text="Gross Square Feet (GSF)- Sum of all area within the exterior envelope of the structure."
                                    />
                                </div>
                            </th>
                            <th>
                                <div className="d-flex">
                                    ASF{' '}
                                    <InfoTooltip
                                        placement="left"
                                        id="asf-tooltip"
                                        text="Assignable Square Feet (ASF)- Amount of space that can be used by people or programs within the interior walls of a structure. For Information on assignable space use categories, see Space Detail Tab"
                                    />
                                </div>
                            </th>
                            <th className="min-tablet-l no-sort text-right">ASF/GSF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Project Size</th>
                            <td>
                                <Input
                                    type="text"
                                    onChange={handleNumberChange}
                                    name="sizeGSF"
                                    value={userInput.sizeGSF ?? 0}
                                    required
                                />
                            </td>
                            <td>
                                <Input
                                    type="text"
                                    onChange={handleNumberChange}
                                    name="sizeASF"
                                    value={userInput.sizeASF ?? 0}
                                    required
                                />
                            </td>
                            <td>
                                {userInput.sizeGSF === 0
                                    ? 0
                                    : +(userInput.sizeASF / userInput.sizeGSF).toFixed(2)}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Net Change in Overall Campus Space</th>
                            <td>
                                <Input
                                    type="text"
                                    onChange={handleNumberChange}
                                    name="netChangeGSF"
                                    value={userInput.netChangeGSF ?? 0}
                                    required
                                />
                            </td>
                            <td>
                                <Input
                                    type="text"
                                    onChange={handleNumberChange}
                                    name="netChangeASF"
                                    value={userInput.netChangeASF}
                                    required
                                />
                            </td>
                            <td>
                                {' '}
                                {userInput.netChangeGSF === 0
                                    ? 0
                                    : +(
                                          userInput.netChangeASF / userInput.netChangeGSF
                                      ).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <CardTitle>
                    <h4 className="mb-3 text-primary">Project Cost Summary</h4>
                </CardTitle>
                <Row>
                    <div className="col-md-auto mb-3 mb-md-0">
                        <label>
                            Total Project Cost <sup className="text-danger">*</sup>{' '}
                            <InfoTooltip
                                blue
                                id="total-project-cost"
                                text="Projects should include all costs associated with the project (structure, A&E, infrastructure, consulting, FF&E, etc.)"
                            />
                        </label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                            </div>
                            <Input
                                type="text"
                                onChange={handleNumberChange}
                                name="netChangeASF"
                                value={userInput.totalProjectCost}
                                readOnly
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md mb-3 mb-md-0">
                        <label>Cost Per GSF/ASF</label>
                        <ul className="no-bullets m-0 p-0">
                            <li>
                                $10 <label className="m-0">GSF</label>
                            </li>
                            <li>
                                $25 <label className="m-0">ASF</label>
                            </li>
                        </ul>
                    </div>
                </Row>
            </Card>
            <Card className=" no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">
                    Funding Source(s) for Project{' '}
                    <InfoTooltip
                        blue
                        id="funding-source"
                        text="Be consistent in the naming of funds to be used for projects. If bonding, note Bonding Authority Year (1965, 1929, 1927, etc.)"
                    />
                </h4>

                {userInput.fundingList.map((fundingSource, index) => {
                    return (
                        <Card key={index} className=" p-2 no-shadow border">
                            <Row className="align-items-center mb-2">
                                <Col className="md mb-3 mb-md-0">
                                    <Label>Funding Amount</Label>
                                    <InputGroup>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <FontAwesomeIcon icon={faDollarSign} />
                                            </span>
                                        </div>
                                        <Input
                                            id={index + 'amount'}
                                            type="number"
                                            value={
                                                +userInput.fundingList[index]
                                                    .fundingAmount
                                            }
                                            onChange={e => {
                                                userInput.fundingList[
                                                    index
                                                ].fundingAmount = +e.target.value;
                                                const newFundingSource = [
                                                    ...userInput.fundingList,
                                                ];
                                                setUserInput({
                                                    fundingList: newFundingSource,
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col className="md mb-3 mb-md-0">
                                    <Label>Funding Type</Label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        name={index}
                                        onChange={handleFundingTypeChange}
                                        value={userInput.fundingList[index].fundingTypeId}
                                        loadOptions={loadFundingTypes}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </Col>
                                <Col className="md mb-3 mb-md-0">
                                    <Label>Funding Description</Label>
                                    <InputGroup>
                                        <Input
                                            id={index + 'desc'}
                                            type="text"
                                            value={
                                                userInput.fundingList[index]
                                                    .fundingDescription
                                            }
                                            onChange={e => {
                                                userInput.fundingList[
                                                    index
                                                ].fundingDescription = e.target.value;
                                                const newFundingDesc = [
                                                    ...userInput.fundingList,
                                                ];
                                                setUserInput({
                                                    fundingList: newFundingDesc,
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card>
                    );
                })}
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <Button
                        className="btn btn-sm btn-outline-primary"
                        onClick={addFundingSource}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        Add Another
                    </Button>
                    <label className="m-0">
                        Total Funding: $
                        {userInput.fundingList.reduce(function(acc, fundingSource) {
                            return acc + +fundingSource.fundingAmount;
                        }, 0)}
                    </label>
                </div>
            </Card>

            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Annual Cost</h4>
                <Row className="row align-items-end mb-3">
                    <Col className="md mb-3 mb-md-0">
                        <Label>
                            Est. annual change in cost of building operations based on the
                            project
                        </Label>
                        <InputGroup>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                            </div>
                            <Input
                                type="text"
                                onChange={handleNumberChange}
                                name="estAnnualChangeInOpsCost"
                                value={userInput.estAnnualChangeInOpsCost}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="md mb-3 mb-md-0">
                        <Label>
                            Est. annual repair and rehabilitation investment{' '}
                            <InfoTooltip
                                blue
                                id="annual-repair"
                                text="Estimate the amount of funding the institution would need to set aside annually to address R&R needs for the project. CHE suggests 1.5% of total construction cost"
                            />
                        </Label>
                        <InputGroup>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                            </div>
                            <Input
                                type="text"
                                onChange={handleNumberChange}
                                name="estAnnualRRInvestment"
                                value={userInput.estAnnualRRInvestment}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="md mb-3 mb-md-0">
                        <Label>
                            Est. annual debt payment{' '}
                            <InfoTooltip
                                blue
                                id="annual-debt"
                                text="If issuing debt, determine annual payment based on 20 years at 5.0% interest rate"
                            />
                        </Label>
                        <InputGroup>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                            </div>
                            <Input
                                type="text"
                                onChange={handleNumberChange}
                                name="estAnnualDebtPayment"
                                value={userInput.estAnnualDebtPayment}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <RadioButtons
                    labelName="Are all the funds for the project secured?"
                    name="isFundsSecured"
                    value={userInput.isFundsSecured}
                    onChange={handleRadioButtonChange}
                />
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Funding</h4>
                <p className="text-italic">
                    Provide additional narrative for the funding sources listed. If all
                    funds are not secured, outline cash flow for the project. If debt is
                    to be issued for the project, what is the current outstanding debt of
                    the institution? What is the repayment method for the debt? What is
                    the structure of the gift funds? What is the structure of the grant?
                </p>
                <Input
                    type="textarea"
                    rows="7"
                    placeholder="Funding"
                    onChange={handleChange}
                    name="fundingNarrative"
                    value={userInput.fundingNarrative}
                />
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Justification of Cost</h4>
                <p className="text-italic">
                    Provide justification for the cost per ASF/GSF for the project.
                    Outline the reasons for discrepancies between this project and
                    comparables. Provide additional cost metrics depending on project
                    type. Provide narrative on the change in the cost of building
                    operations. Will the building be included in the state's R&R formula;
                    what percentage is academic and administrative? What alternatives were
                    considered? Describe the site development costs.
                </p>
                <Input
                    type="textarea"
                    rows="7"
                    placeholder="Justification of Cost"
                    onChange={handleChange}
                    name="justificationOfCost"
                    value={userInput.justificationOfCost}
                />
            </Card>
            <p className="text-italic">
                If project is a lease-purchase or lease, adjust accordingly. Note the
                total cost of the lease in the project cost, and annual payments in
                project description
            </p>

            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProject={capitalProject}
            />
        </Form>
    );
};
