import React, { useEffect, useState } from 'react';

import { useErrorState } from 'shared';

export const ErrorAlert = React.memo(() => {
    const [message, setMessage] = useState(null);
    const { error, clearError } = useErrorState();
    useEffect(() => {
        if (error && error.message) {
            setMessage(error.message);
            setTimeout(() => {
                clearError();
            }, 6000);
        } else if (!error && message) {
            setMessage(null);
        }
    }, [clearError, error, message]);
    return (
        <div
            className={`alert alert-danger error-alert ${message && 'error-alert-open'}`}
            onClick={clearError}
        >
            <strong>
                CHEDSS Error {error && error.status ? `(${error.status})` : ''}
            </strong>
            <div>{message}</div>
        </div>
    );
});
