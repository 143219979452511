import React from 'react';
import { Card, Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { UrlEnum, usePermissions, useUserInstitutions } from 'shared';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

export const AcademicAffairsSearchInstitutionsTable = ({ academicSystemsData }) => {
    const history = useHistory();
    const editPermission = usePermissions({
        permissions: ['submit-changes-to-programs'],
    });
    const institutionIds = useUserInstitutions();
    const rowEvents = {
        // onClick: (e, row, rowIndex) => {
        //     const projectId = row.projectID;
        //     const location = {
        //         pathname: UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS,
        //         state: [{ projectId: projectId }],
        //     };
        //     history.push(location);
        // },
    };

    const actions = (cell, row, rowIndex, formatExtraData) => {
        const idStart = 'PopoverView-' + row.cheUnitId;
        const { editPermission, institutionIds } = formatExtraData;
        return (
            <div className="d-flex">
                {editPermission && (
                    <Button
                        className="action-circle"
                        onClick={() => {
                            const unitId = row.cheUnitId;

                            var path = "";
                            if(row.unitType == "System") path = UrlEnum.ACADEMIC_AFFAIRS_SYSTEM;
                            if(row.unitType == "Institution") path = UrlEnum.ACADEMIC_AFFAIRS_INSTITUTION;
                            if(row.unitType == "Campus") path = UrlEnum.ACADEMIC_AFFAIRS_CAMPUS;
                            if(row.unitType == "Location") path = UrlEnum.ACADEMIC_AFFAIRS_LOCATION;
                            const location = {
                                pathname: path,
                                state: [{ UnitId: unitId }],
                            };
                            history.push(location);
                        }}
                    >
                        <UncontrolledPopover
                            target={idStart + '-edit'}
                            trigger="hover"
                            placement="bottom"
                        >
                            <PopoverBody>Edit</PopoverBody>
                        </UncontrolledPopover>
                        <FontAwesomeIcon id={idStart + '-edit'} icon={faEdit} />
                    </Button>
                )}
            </div>
        );
    };

    const columns = [
        
        {
            dataField: 'unitType',
            text: 'Unit Type',
            sort: true,
            headerStyle: getWidthStyle(75),
        },
        {
            dataField: 'unitName',
            text: 'Unit Name',
            sort: true,
            headerStyle: getWidthStyle(500),
        },
        {
            dataField: 'cheUnitId',
            text: 'Unit Id',
            sort: true,
            headerStyle: getWidthStyle(50),
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: actions,
            headerStyle: getWidthStyle(70),
            formatExtraData: { editPermission, institutionIds },
        },
    ];
    const options = {
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        sizePerPage: 10,
        hideSizePerPage: true,
        alwaysShowAllBtns: true
    };
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between text-center text-md-left mb-3">
                <h2 className="mb-md-0 mb-3">
                    {academicSystemsData?.length} {academicSystemsData?.length > 1? "Units" : "Unit"} Found
                </h2>
            </div>
            <Card>
                <div className="table-responsive">
                    <BootstrapTable
                        keyField="institutionID"
                        data={academicSystemsData?.map(project => {
                            const newLastUpdateDate = new Date(project.approvalDate);
                            project.approvalDate = newLastUpdateDate.toLocaleDateString();
                            return project;
                        })}
                        columns={columns}
                        striped
                        hover
                        pagination={paginationFactory(options)}
                        rowEvents={rowEvents}
                        // defaultSorted={defaultSorted}
                    />
                </div>
            </Card>
        </React.Fragment>
    );
};
