import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { parse } from 'query-string';

import { userService } from 'services';
import { useErrorState, useToastState } from 'shared';
import { PasswordForm } from 'components/password';

export const SetPasswordPage = props => {
    const { handleError } = useErrorState();
    const { setToast } = useToastState();
    const history = useHistory();
    const location = useLocation();
    const { uid, id } = parse(location.search);
    const [loading, setLoading] = useState(false);
    const linkToLogin = () => history.replace('/login');
    console.log("setting")
    const resetPassword = async payload => {
        setLoading(true);
        try {
            await userService.setPassword(id, uid, payload.password);
            setToast({
                title: 'Successly Reset Password!',
                message: 'You may now log in with your new password.',
            });
            linkToLogin();
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };
    return (
        <div className="login-wrap">
            <div className="login-card">
                <div className="logo">
                    <img src="/logo-chedss.png" alt="CHEDSS" />
                    <h2 className="text-center my-5">Data Submission System (CHEDSS)</h2>
                    <PasswordForm
                        loading={loading}
                        onSubmit={resetPassword}
                        linkToLogin={linkToLogin}
                        uid={uid}
                    />
                </div>
            </div>
        </div>
    );
};
