import React, { useReducer, useEffect, useCallback, useMemo } from 'react';

import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { academicProgramsService } from 'services';
import { FormSelect } from './components/FormSelect';

export const AcademicAffairViewAuditModal = ({
    isOpen,
    toggle,
    auditsList,
    auditId,
    turnKey
}) => {
    const reducer = (state, newState) => ({ ...state, ...newState });

    const renderAuditRows = id => {
        const audit = auditsList.find(obj => obj.programAuditId === id);
        const retList = []
        for(const key in audit){
            if(audit[key] != null && (key != "programAuditId" && key != "requesterName" && key != "programId" && key != "programApprovalDate" && key != "lastUpdateDate")){
                retList.push(turnKey(key) + ": " + audit[key]);
            }
        }
        return retList.join(", ");
    }

    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>View Audit {auditId}</ModalHeader>
            <ModalBody>
                {renderAuditRows(auditId)}
            </ModalBody>
        </Modal>
    );
};
