import React, { useState } from 'react';
import { WizardFooter } from './wizard-footer';
import { Form, Button } from 'reactstrap';
import { capitalProjectService } from 'services';
import {
    CapitalProjectErrorEnum,
    TabEnum,
    FormEnum,
    UrlEnum,
    ConfirmActionModal,
    ObjectFilter,
} from 'shared';
import { Link } from 'react-router-dom';

export const Submit = ({
    capitalProject,
    setStep,
    prevStep,
    nextStep,
    setCapitalProject,
}) => {
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const submitCapitalProject = async () => {
        const lastUpdated = await capitalProjectService
            .getCapitalProjectWithId(capitalProject.id)
            .then(capitalProject => {
                return capitalProject?.lastUpdated;
            });
        const validationErrors = await capitalProjectService.submitCapitalProject({
            id: capitalProject.id,
            clientLastUpdateTime: lastUpdated,
        });
        const isValidationErrors =
            validationErrors &&
            (Array.isArray(validationErrors) || validationErrors.length);
        if (isValidationErrors) {
            capitalProject.validationErrors = validationErrors;
            setCapitalProject({ ...capitalProject });
        } else {
            window.location.href = UrlEnum.CAPITALPROJECTSEARCH;
        }
    };

    const displayValidationErrors = () => {
        return capitalProject.validationErrors.map((validationError, index) => {
            const filteredMessageTextToTab = ObjectFilter(
                CapitalProjectErrorEnum,
                ([name, tab]) => tab.includes(validationError.capitalProjectField)
            );
            const tabEnumName = Object.keys(filteredMessageTextToTab)[0] ?? 'SUBMIT';
            const isValidationWarning = validationError.messageType === 2;
            return (
                <li key={index}>
                    <span style={{ fontWeight: 'bold' }}>
                        {isValidationWarning ? 'WARNING:' : 'ERROR:'}
                    </span>
                    {validationError.messageText}{' '}
                    <Link
                        to={TabEnum[tabEnumName]}
                        onClick={() => {
                            setStep(FormEnum[tabEnumName]);
                            window.location.href = TabEnum[tabEnumName];
                        }}
                    >
                        Go To
                    </Link>
                </li>
            );
        });
    };
    const CapitalProjectValidationErrors = () => {
        const IsAnyValidationErrors =
            !capitalProject.validationErrors ||
            capitalProject.validationErrors.length === 0;
        return IsAnyValidationErrors ? (
            <React.Fragment />
        ) : (
            <div className="alert alert-danger mt-3">
                <div className="error-list">
                    <span>Please check the following errors/warnings:</span>
                    <ul>{displayValidationErrors()}</ul>
                </div>
            </div>
        );
    };
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">Submit </h2>

            <Button
                className="btn btn-success btn-shadow btn-lg"
                onClick={() => setIsSubmitModalOpen(true)}
            >
                {'Submit Project Request'}
            </Button>

            {CapitalProjectValidationErrors()}
            <ConfirmActionModal
                isOpen={isSubmitModalOpen}
                toggle={() => setIsSubmitModalOpen(!isSubmitModalOpen)}
                header={`Confirm Submittal of ${capitalProject.projectName}`}
                message={`Are you sure you want to submit ${capitalProject.projectName}?`}
                acceptButtonText={'Submit'}
                acceptFunction={() => submitCapitalProject()}
                acceptButtonCss="btn btn-success"
            />

            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={capitalProject}
                capitalProject={capitalProject}
                isSubmit={true}
            />
        </Form>
    );
};
