import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    // Label,
} from 'reactstrap';
import { LineItemService } from 'services';
import { Button } from 'components/blocks';

const radioButtonEnum = {
    TEXT: 'text',
    PDF: 'pdf',
};

export const UpdateDescriptionModal = ({ isOpen, toggle, scheduleId }) => {
    const [radioButtonValue, setRadioButtonValue] = useState(radioButtonEnum.TEXT);
    const [description, setDescription] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    useEffect(() => {
        const fetch = async () => {
            const res = await LineItemService.getDescription(scheduleId);
            setDescription(res?.description || '');
        };
        if (isOpen) fetch();
    }, [isOpen]);
    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );
    const onFileChange = event => {
        const newFile = event.target.files[0];
        setFile(newFile);
    };
    const handleChange = evt => {
        const newValue = evt.target.value;
        setDescription(newValue);
    };
    const onUpdate = async () => {
        setLoading(true);
        try {
            if (radioButtonValue === radioButtonEnum.TEXT) {
                const postBody = {
                    description: description,
                };
                await LineItemService.uploadDescriptionText(scheduleId, postBody);
            } else {
                if (file) {
                    const formData = new FormData();
                    formData.append('myFile', file, file.name);
                    LineItemService.uploadDescriptionPDF(scheduleId, formData);
                }
            }
            toggle();
        } catch (error) {
            setError(true);
        }
        setLoading(false);
    };
    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>Update Description</ModalHeader>
            <ModalBody>
                <RadioButtons
                    radioButtonValue={radioButtonValue}
                    setRadioButtonValue={setRadioButtonValue}
                />
                {radioButtonValue === radioButtonEnum.TEXT ? (
                    <Input
                        type="textarea"
                        rows="10"
                        placeholder="Type description..."
                        onChange={handleChange}
                        value={description}
                    />
                ) : (
                    <fieldset className="form-group upload-description">
                        <label>Upload Description</label>
                        <input
                            type="file"
                            className="form-control"
                            accept="application/pdf"
                            onChange={onFileChange}
                        />
                    </fieldset>
                )}
                {error ? (
                    <p className="alert alert-danger mt-3">There was an error</p>
                ) : null}
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" onClick={toggle}>
                    Close
                </Button>
                <Button color="primary" onClick={onUpdate} loading={loading}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const RadioButtons = ({ radioButtonValue, setRadioButtonValue }) => {
    const handleRadioButtonChange = evt => {
        const newValue =
            evt.target.value === radioButtonEnum.TEXT
                ? radioButtonEnum.TEXT
                : radioButtonEnum.PDF;
        setRadioButtonValue(newValue);
    };
    return (
        <FormGroup>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    id={radioButtonEnum.TEXT}
                    value={radioButtonEnum.TEXT}
                    checked={radioButtonValue === radioButtonEnum.TEXT}
                    onChange={handleRadioButtonChange}
                />
                <label className="form-check-label" htmlFor={radioButtonEnum.TEXT}>
                    Type In Description
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    id={radioButtonEnum.PDF}
                    value={radioButtonEnum.PDF}
                    checked={radioButtonValue === radioButtonEnum.PDF}
                    onChange={handleRadioButtonChange}
                />
                <label className="form-check-label" htmlFor={radioButtonEnum.PDF}>
                    Upload PDF
                </label>
            </div>
        </FormGroup>
    );
};
