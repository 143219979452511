import React from 'react';
import { Card, Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { UrlEnum, usePermissions, useUserInstitutions } from '../../shared';

const getWidthStyle = width => ({
    width,
    minWidth: width,
    maxWidth: width,
});

export const CollectionProcessingTable = ({ logData }) => {
    const history = useHistory();

    const columns = [
        {
            dataField: 'userName',
            text: 'User',
            sort: true,
            headerStyle: getWidthStyle(50),
        },
        {
            dataField: 'reportingYear',
            text: 'RY',
            sort: true,
            headerStyle: getWidthStyle(50),
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle: getWidthStyle(100),
        },
        /*{
            dataField: 'cipTitle',
            text: 'File Type',
            sort: true,
            headerStyle: getWidthStyle(80),
        },*/
        {
            dataField: 'timeStarted',
            text: 'Time Initiated',
            sort: true,
            formatter: cell => {
                const dateTime = new Date(cell);
                const date = dateTime.toLocaleDateString();
                const time = dateTime.toLocaleTimeString();
                return (
                    <div>
                        {date}
                        <br />
                        {time}
                    </div>
                );
            },
            headerStyle: getWidthStyle(150),
        },
        {
            dataField: 'timeCompleted',
            text: 'Time Completed',
            sort: true,
            formatter: cell => {
                console.log(cell);
                if (cell == null) return <div>--</div>;
                const dateTime = new Date(cell);
                if (dateTime == 'Invalid Date') return <div>--</div>;
                const date = dateTime.toLocaleDateString();
                const time = dateTime.toLocaleTimeString();
                return (
                    <div>
                        {date}
                        <br />
                        {time}
                    </div>
                );
            },
            headerStyle: getWidthStyle(150),
        },

        {
            dataField: 'notes',
            text: 'Notes',
            headerStyle: getWidthStyle(200),
        },
    ];
    const options = {
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        sizePerPage: 20,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
    };
    return (
        <React.Fragment>
            <Card>
                <div className="table-responsive" style={{ overflowX: 'hidden' }}>
                    <BootstrapTable
                        keyField="id"
                        data={logData?.map(project => {
                            const newLastUpdateDate = new Date(project.approvalDate);
                            project.approvalDate = newLastUpdateDate.toLocaleDateString();
                            return project;
                        })}
                        columns={columns.map(column => ({
                            ...column,
                            //headerStyle: { textAlign: 'center' },
                            style: { verticalAlign: 'middle' },
                        }))}
                        striped
                        hover
                        pagination={paginationFactory(options)}
                        // defaultSorted={defaultSorted}
                    />
                </div>
            </Card>
        </React.Fragment>
    );
};
