import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { TooltipWrapper } from '../../blocks';

export const Document = React.memo(props => {
    const { id, title, description, onEdit, onDelete, data, admin, onDownload } = props;
    return (
        <li className="list-group-item">
            <div className="row align-items-center">
                <div className="col-sm-9 mb-3 mb-sm-0">
                    <div
                        onClick={() => onDownload(data)}
                        style={{ cursor: 'pointer' }}
                        className="d-flex align-items-center text-primary"
                    >
                        <h5 className="m-0 text-center">
                            <FontAwesomeIcon
                                icon={faFilePdf}
                                size="2x"
                                className="text-muted"
                            />
                        </h5>
                        <div className="ml-3">
                            <h5 className="text-strong mb-1">{title}</h5>
                            <p className="text-muted mb-0">{description}</p>
                        </div>
                    </div>
                </div>
                {admin && (
                    <div className="col-sm-3 justify-content-sm-end justify-content-start d-flex align-items-center">
                        <TooltipWrapper
                            id={`edit-${id}`}
                            text="Edit"
                            placement="top"
                            containerStyle={{ marginRight: 8 }}
                        >
                            <div onClick={() => onEdit(data)} className="action-circle">
                                <FontAwesomeIcon icon={faPen} />
                            </div>
                        </TooltipWrapper>
                        <TooltipWrapper id={`delete-${id}`} text="Delete" placement="top">
                            <div onClick={() => onDelete(data)} className="action-circle">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>
                        </TooltipWrapper>
                    </div>
                )}
            </div>
        </li>
    );
});

export const Documents = props => {
    return <ul className="list-group no-round">{props.children}</ul>;
};

export default Document;
