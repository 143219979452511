import React, { useState, useEffect, useReducer } from 'react';
import { Table, Input } from 'reactstrap';
import { WizardFooter } from './wizard-footer';

export const PffPerUnit = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 5,
            pffPerUnits: budgetRun.pffPerUnits ?? [],
        }
    );
    const [mapOfPerUnits, setMapOfPerUnits] = useState(new Map());

    const sanitizeBudgetRun = () => {
        budgetRun.pffPerUnits = userInput.pffPerUnits;
        return budgetRun;
    };

    const handleTableChange = (evt, pffItemID) => {

        const headerValue = userInput.pffPerUnits.find(
            me => pffItemID === me.pffItemID
        );

        headerValue.perUnitValues['perUnitValue'] = +evt.target.value ?? 0;
        setUserInput({ pffPerUnits: [...userInput.pffPerUnits] });
        recalculatePercents();
    };

    const recalculatePercents = () => {
        const pffUnit = userInput.pffPerUnits;
        let thisSum = 0;
        if (null != pffUnit) {
            thisSum = pffUnit.reduce(function(a, b) {
                return (
                    a + b.perUnitValues['totalUnits'] * b.perUnitValues['perUnitValue']
                );
            }, 0);

            // eslint-disable-next-line array-callback-return
            pffUnit.map((v, i) => {
                v.perUnitValues.metricTotal = +(
                    v.perUnitValues.totalUnits * v.perUnitValues.perUnitValue
                );
                v.perUnitValues.percentOfTotal = +(
                    (v.perUnitValues.metricTotal ?? 0) / thisSum
                );
            });
            userInput.pffPerUnits = pffUnit;
            setUserInput({ pffPerUnits: [...userInput.pffPerUnits] });
        }
    };

    useEffect(() => {
        const newMapOfPerUnits = new Map();
        userInput.pffPerUnits.forEach(pffUnit => {
            if (newMapOfPerUnits.has(pffUnit.pffTypeName)) {
                const newValue = newMapOfPerUnits.get(pffUnit.pffTypeName);
                newValue.push(pffUnit);
                newMapOfPerUnits.set(pffUnit.pffTypeName, newValue);
            } else {
                newMapOfPerUnits.set(pffUnit.pffTypeName, [pffUnit]);
            }
        });
        setMapOfPerUnits(newMapOfPerUnits);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetRun]);

    const PerUnitTable = () => {
        return mapOfPerUnits ? (
            [...mapOfPerUnits.keys()].map(k => {
                const newMapOfPerUnits = new Map(mapOfPerUnits);
                const categoryPerUnits = newMapOfPerUnits.get(k);
                return (
                    <React.Fragment key={k + 'frag'}>
                        <tr key={k}>
                            <td
                                colSpan="5"
                                className="text-strong text-primary cell-light"
                            >
                                {k}
                            </td>
                        </tr>
                        {categoryPerUnits.map((perUnit, index) => {
                            const perUnitValues = perUnit.perUnitValues;
                            return (
                                <tr key={perUnit.pffItemID}>
                                    <td key={perUnit.pffItemID}>
                                        <label className="mb-0">
                                            {perUnit.pffDisplayName}
                                        </label>
                                    </td>
                                    <td className="text-right">
                                        {perUnitValues.totalUnits}
                                    </td>
                                    <td className="restricted-width-input">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    $
                                                </span>
                                            </div>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                value={perUnitValues.perUnitValue}
                                                onChange={evt => {
                                                    handleTableChange(evt, perUnit.pffItemID);
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        {perUnitValues.metricTotal}
                                    </td>
                                    <td className="text-right">
                                        {(perUnitValues.percentOfTotal * 100).toFixed(2)}%
                                    </td>
                                </tr>
                            );
                        })}
                    </React.Fragment>
                );
            })
        ) : (
            <tr key={'nullValue'}></tr>
        );
    };

    return (
        <React.Fragment>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                PFF Per Unit Values
            </h2>
            <div className="table-responsive">
                <Table className="table table-striped table-bordered table-hover align-middle table-sm per-unit-table">
                    <thead>
                        <tr key={'head'}>
                            <th></th>
                            <th className="text-right">Total Units</th>
                            <th className="text-right">Per Unit Value</th>
                            <th className="text-right">Metric Total</th>
                            <th className="text-right">% of Total</th>
                        </tr>
                    </thead>
                    <tbody>{PerUnitTable()}</tbody>
                </Table>
            </div>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={false}
                sanitizeBudgetRun={sanitizeBudgetRun}
                setBudgetRun={setBudgetRun}
            />
        </React.Fragment>
    );
};
