import React, { useState, useReducer } from 'react';
import { Form } from 'reactstrap';
import { budgetRunService } from 'services';
import { UrlEnum } from 'shared';
import { Button } from 'components/blocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

export const Output = props => {
    const {
        budgetRun,
        setBudgetRun,
        running,
        finalizing,
        setRunning,
        setFinalizing,
    } = props;
    const [userInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
        id: budgetRun.budgetRunID,
        tabNumber: 9,
        projectName: budgetRun.budgetRunTitle ? budgetRun.budgetRunTitle : '',
        budgetRunStatusId: budgetRun.budgetRunStatusId,
    });

    const showDownload = () => {
        if (userInput.budgetRunStatusId < 3) return { display: 'none' };
    };
    const showFinalize = () => {
        if (userInput.budgetRunStatusId !== 3) return { display: 'none' };
    };
    const showRunClass = () => {
        if (userInput.budgetRunStatusId !== 1) return { display: 'none' };
    };

    const showStatus = () => {
        if (running)
            return (
                <div className="alert alert-success mt-3">
                    <span>
                        Budget Run is currently processing. This may take a few minutes.
                    </span>
                </div>
            );
        if (userInput.budgetRunStatusId === 2)
            return (
                <div className="alert alert-danger mt-3">
                    <span>Run Failed.</span>
                </div>
            );
    };

    const runBudgetRun = async () => {
        setRunning(true);
        try {
            const runBudgetRunResponse = await budgetRunService.runBudgetRun(
                budgetRun.budgetRunID
            );
            if (runBudgetRunResponse) {
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
        setRunning(false);
    };

    const finalizeBudgetRun = async () => {
        budgetRun.budgetRunStatusId = 4;
        setFinalizing(true);
        try {
            await budgetRunService.saveBudgetRun(budgetRun).then(res => {
                setBudgetRun(res);
                window.location.href = UrlEnum.BUDGETRUNSEARCH;
            });
        } catch (error) {}
        setFinalizing(false);
    };

    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">Output</h2>
            {showStatus()}
            <div style={showRunClass()}>
                {/* {validationResults()} */}
                <Button
                    loading={running}
                    disabled={finalizing}
                    className="btn btn-lg btn-success text-strong px-4 mr-3"
                    onClick={runBudgetRun}
                >
                    <FontAwesomeIcon icon={faPlay} /> Run
                </Button>
                <hr />
            </div>
            <div className="row">
                <div className="col-md mb-3 mb-md-0" style={showDownload()}>
                    <div className="card no-shadow border p-3 mb-3">
                        <h4 className="mb-3 text-primary">Download Budget Run Report</h4>
                        <ul>
                            <li>
                                <a
                                    href="/#"
                                    onClick={e => {
                                        e.preventDefault();
                                        budgetRunService.downloadBudgetRun(
                                            'excel',
                                            userInput.id
                                        );
                                    }}
                                >
                                    Budget Run Report
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/#"
                                    onClick={e => {
                                        e.preventDefault();
                                        budgetRunService.downloadBudgetRun(
                                            'pdf',
                                            userInput.id
                                        );
                                    }}
                                >
                                    Overall Funding Summaries (PDF)
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    href="/#"
                                    onClick={e => {
                                        e.preventDefault();
                                        budgetRunService.downloadBudgetRun(
                                            'excel',
                                            userInput.id
                                        );
                                    }}
                                >
                                    Overall Funding Summaries (XLS)
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-md">
                    <div
                        className="card no-shadow border p-3 mb-3"
                        style={showFinalize()}
                    >
                        <h4 className="mb-3 text-primary">Finalize</h4>
                        <p>
                            Finalizing a budget run will lock the run from being updated.
                            A run should be finalized if the output of the run needs to be
                            persisted for historical reasons.
                        </p>
                        <Button
                            loading={finalizing}
                            disabled={running}
                            className="btn btn-lg btn-success text-strong px-4 mr-3"
                            onClick={finalizeBudgetRun}
                        >
                            Finalize
                        </Button>
                    </div>
                </div>
            </div>
        </Form>
    );
};
