import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Card, Container, Row } from 'reactstrap';
import _isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import { performanceScheduleService } from 'services';
import { Columns } from './components';
import { FormSelect, Button } from '../blocks';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_FORM = {
    bienniums: [],
    schedules: [],
    institutions: [],
    statuses: [],
};
const pageSize = 20;
const getOptionLabel = d => d.value;
const paginationOptions = {
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    sizePerPage: pageSize,
    hideSizePerPage: true,
};

const convertToValue = d => ({ ...d, value: d.name });
const deriveKey = (array = [], key = 'key') => {
    return array.map(d => d[key]);
};

export const PerformanceSchedulesSearch = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [form, setForm] = useReducer(reducer, INITIAL_FORM);
    const [options, setOptions] = useReducer(reducer, {});
    const [data, setData] = useState({ page: 0, count: 0, pageSize, schedules: [] });
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await Promise.all([
                    performanceScheduleService.getBienniums(),
                    performanceScheduleService.getTypes(),
                    performanceScheduleService.getInstitutions(),
                    performanceScheduleService.getStatuses(),
                    performanceScheduleService.getExportFileTypes(),
                ]);
                setOptions({
                    bienniums: res[0].map(convertToValue),
                    schedules: res[1].map(convertToValue),
                    institutions: res[2].map(convertToValue),
                    statuses: res[3].map(convertToValue),
                    exportFileTypes: res[4]?.fileTypes,
                });
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };
        fetchOptions();
        onSubmit();
    }, []);
    const onSubmit = useCallback(
        async page => {
            setLoading(true);
            const getFormPayload = () => {
                const payload = {
                    bienniums: deriveKey(form.bienniums, 'value'),
                    schedules: deriveKey(form.schedules, 'value'),
                    institutions: deriveKey(form.institutions, 'value'),
                    statuses: deriveKey(form.statuses, 'value'),
                    pageSize,
                    page,
                };
                return queryString.stringify(payload, {
                    arrayFormat: 'separator',
                    skipEmptyString: true,
                });
            };
            try {
                const query = getFormPayload();
                const res = await performanceScheduleService.getSchedules(query);
                if (res) {
                    setData(res);
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        },
        [form]
    );
    const renderTabs = () => {
        return options?.statuses?.map(status => {
            const count = data?.schedules?.filter(d => d.scheduleStatusId === status.id)
                ?.length;
            return (
                <li className="nav-item" key={status.name}>
                    <a
                        className={
                            activeTab === status.id
                                ? 'nav-link active'
                                : 'nav-link cursor-pointer'
                        }
                        data-toggle="list"
                        onClick={() => setActiveTab(status.id)}
                        role="tab"
                    >
                        {status.name} <span className="text-muted">({count})</span>
                    </a>
                </li>
            );
        });
    };
    const getBienniumName = useCallback(
        id => {
            console.log(options)
            return options.bienniums.find(b => b.id === id)?.name;
        },
        [options.bienniums]
    );
    const getScheduleName = useCallback(
        id => {
            return options.schedules.find(sch => sch.id === id)?.name;
        },
        [options.schedules]
    );
    const getInstitutionName = useCallback(
        id => {
            return options.institutions.find(ins => ins.id === id)?.name;
        },
        [options.institutions]
    );
    const getStatusName = useCallback(
        id => {
            return options.statuses.find(st => st.id === id)?.name;
        },
        [options.statuses]
    );
    const filtered = useMemo(() => {
        return data?.schedules?.filter(d => d.scheduleStatusId === activeTab);
    }, [data?.schedules, activeTab]);
    return (
        <Container fluid>
            <Row>
                <div className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                    <Card className="mb-3 p-3">
                        <h4 className="text-muted mb-4">Search Performance Schedules</h4>
                        <div className="row align-items-end">
                            <FormSelect
                                label="Bienniums"
                                onChange={setForm}
                                options={options.bienniums ? options.bienniums.map(x => {return {label: x.name, value: x.id}}) : []}
                                placeholder="All"
                                name="bienniums"
                                value={form.bienniums}
                                isMulti
                                className="col-xl-12 col-lg"
                            />
                            {/* <FormSelect
                                label="Category"
                                onChange={setForm}
                                options={options.category}
                                placeholder="All"
                                name="category"
                                value={form.category}
                                className="col-xl-12 col-lg"
                                getOptionLabel={getOptionLabel}
                            /> */}
                        </div>
                        <div className="row align-items-end">
                            <FormSelect
                                label="Institutions"
                                onChange={setForm}
                                options={options.institutions ? options.institutions.map(x => {return {label: x.name, value: x.id}}):[]}
                                placeholder="All"
                                name="institutions"
                                value={form.institutions}
                                className="col-12"
                                isMulti
                                getOptionLabel={getOptionLabel}
                            />
                        </div>
                        <div className="row align-items-end">
                            <FormSelect
                                label="Schedules"
                                onChange={setForm}
                                options={options.schedules ? options.schedules.map(x => {return {label: x.name, value: x.id}}):[]}
                                placeholder="All"
                                name="schedules"
                                value={form.schedules}
                                className="col-xl-12 col-lg-8"
                                fieldClassName="mb-lg-0 mb-xl-4"
                                isMulti
                                getOptionLabel={getOptionLabel}
                            />
                            <div className="col-xl-12 col-lg-4">
                                <Button
                                    color="primary"
                                    className="btn-primary btn-block"
                                    type="search"
                                    loading={loading}
                                    onClick={() => onSubmit(1)}
                                    disabled={_isEmpty(options)}
                                >
                                    <FontAwesomeIcon icon={faSearch} /> Search
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-xl-9 col-lg-12">
                    <div className="d-md-flex align-items-center justify-content-between text-center text-md-left mb-3">
                        <h2 className="mb-md-0 mb-3">
                            {data?.count} Performance Schedules
                        </h2>
                    </div>
                    <Card className="mb-2">
                        <div className="d-md-flex justify-content-between align-items-center text-center text-md-left">
                            <ul className="nav nav-pills nav-pills-large border-bottom">
                                {renderTabs()}
                            </ul>
                        </div>
                    </Card>
                    <Card>
                        <div className="table-responsive">
                            <PaginationProvider
                                pagination={paginationFactory({
                                    ...paginationOptions,
                                    custom: true,
                                    totalSize: data?.count,
                                    onPageChange: onSubmit,
                                    page: data?.page,
                                })}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <div>
                                        <BootstrapTable
                                            remote={{ pagination: true }}
                                            onTableChange={e => console.log(e)}
                                            classes="data-table table table-striped table-bordered table-hover align-middle dataTable no-footer dtr-inline"
                                            keyField="scheduleId"
                                            data={filtered || []}
                                            columns={Columns({
                                                history,
                                                exportFileTypes: options.exportFileTypes,
                                                getBienniumName,
                                                getScheduleName,
                                                getInstitutionName,
                                                getStatusName,
                                            })}
                                            striped
                                            hover
                                            {...paginationProps}
                                            {...paginationTableProps}
                                        />
                                        <div className="row">
                                            <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </PaginationProvider>
                        </div>
                    </Card>
                </div>
            </Row>
        </Container>
    );
};
