import React, { useState, useEffect } from 'react';
import { Input, Button } from 'reactstrap';
import { academicInstitutionsService } from 'services';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faCheck, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import { UrlEnum, usePermissions } from 'shared';
import { Confirm } from 'components/blocks';


const rowStyles = {
    container: provided => ({
        ...provided,
        width: 120,
    }),
};

export const AcademicAffairLocation = ({
    academicLocationData,
    setAcademicLocationData
}) => {


    const [rerender, setRerender] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const history = useHistory();
    const getOptionValue = d => d.value;

    const tableMaxHeightStyle = { maxHeight: '400px', overflowY: 'scroll' };
    

    const handleLocationDataChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setAcademicLocationData(prev => ({
            ...prev,
            [name]: newValue,
        }));
    };


    
    const {
        cheInstitutionUnitId,
        cheCampusUnitId,
        cheSystemUnitId,
        cheLocationName,
        cheLocationUnitId,
        LocationShortName,
    } = academicLocationData


    const checkShortName = shortName => {
        return (shortName != null && shortName != "" && shortName != cheLocationName)
    }

  
    

    const submitForm = async () => {
        setConfirm(false);


        const submitData = {
            cheInstitutionUnitId: cheInstitutionUnitId,
            cheCampusUnitId: cheCampusUnitId,
            cheSystemUnitId: cheSystemUnitId,
            cheLocationName: cheLocationName,
            cheLocationUnitId: cheLocationUnitId,
            LocationShortName: LocationShortName,
        };
        await academicInstitutionsService.editAcademicLocation(submitData, cheLocationUnitId);
    };
    return (
        <React.Fragment>
            <div className="sticky-footer">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.ACADEMIC_AFFAIRS_CAMPUS}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back
                        </a>
                    </div>
                    <div className="row sticky-bar-spacer">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <h2 className="text-strong text-primary">
                                    {cheLocationName} {(checkShortName(LocationShortName)) ? "("+LocationShortName+")" : ""}
                                </h2>
                                <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                                    <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                                        <li className="mr-3">
                                            <label className="m-0">Location ID:</label>{' '}
                                            {cheLocationUnitId} {rerender ? "" : ""}
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="card p-3">
                                <div className="row mb-4">
                                    <div className="col-md-8 mb-3 mb-md-0">
                                        <label>
                                            Location Name{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Location Name"
                                            value={cheLocationName}
                                            onChange={handleLocationDataChange}
                                            name="cheLocationName"
                                        />
                                        
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Location Short Name{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Location Short Name"
                                            value={LocationShortName}
                                            onChange={handleLocationDataChange}
                                            name="LocationShortName"
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {(
                        <div className="sticky-bottom-bar">
                            <div className="d-flex align-items-center justify-content-end">
                                <a
                                    className="btn btn-outline-primary btn-lg mr-2"
                                    href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}
                                >
                                    Cancel
                                </a>
                                <Button
                                    type="button"
                                    className="btn btn-success btn-lg px-3"
                                    onClick={() => {
                                        setConfirm(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} /> Save Changes
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            
            <Confirm
                isOpen={confirm}
                toggle={() => setConfirm(false)}
                onConfirm={submitForm}
                title="Confirm Changes"
                message="Are you sure you want to submit these changes?"
            />
        </React.Fragment>
    );
};
