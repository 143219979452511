import React, { useReducer } from 'react';
import { WizardFooter } from './wizard-footer';
import { Form, Input } from 'reactstrap';
import { ValidationErrorBox } from './validation-error-box';
import { FormEnum } from 'shared';
export const detailDescriptionForm = {
    detailDescriptionProject: '',
};

export const DetailDescription = ({ capitalProject, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 3,
            detailDescription: capitalProject.detailDescription
                ? capitalProject.detailDescription
                : '',
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };
    const sanitizeCapitalProject = () => userInput;
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Detail Description of Project
            </h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.DETAILDESCRIPTION}
            />
            <p className="text-italic">
                Describe the general nature of the project. Include the relationship of
                the project to the long-term capital plan for the institution, any
                historical significance the project might have to the campus, alternatives
                considered by the institution, the impact the project has on future
                capital projects identified by the institution, etc. Institutions may
                include how the project is being financed or other financial related
                items. If there is a change in operational costs to the institution,
                please include how the institution will cover the costs (increase) or
                reallocate savings (decrease). Note if funds have been identified to
                support future R&R needs for the project. Include unique building
                characteristics, design features, construction materials, site development
                factors and other considerations affecting the cost estimates.
            </p>
            <Input
                type="textarea"
                rows="15"
                placeholder="Detail Description of Project"
                onChange={handleChange}
                name="detailDescription"
                value={userInput.detailDescription}
            />
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProject={capitalProject}
            />
        </Form>
    );
};
