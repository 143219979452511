import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { generateReportService } from 'services';
import { Button, FormSelect } from '../../blocks';

export const SavedSearchesModal = props => {
    const { open, close, onSubmit } = props;
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await generateReportService.getSearches();
                console.log(res);
                setOptions(res);
            } catch (error) {
                console.log(error);
            }
        };
        fetchOptions();
    }, []);
    return (
        <Modal isOpen={open} className="modal-dialog">
            <ModalHeader toggle={close}>Fill with Saved Search</ModalHeader>
            <ModalBody>
                <FormSelect
                    label="Select Saved Search"
                    onChange={d => setSelected(d.savedSearch)}
                    options={
                        options
                            ? options.map(attr => ({
                                  value: attr.id,
                                  label: attr.name,
                                  searchString: attr.searchString,
                              }))
                            : []
                    }
                    placeholder="Please select one"
                    name="savedSearch"
                    value={selected}
                    isMulti={false}
                    getOptionLabel={d => d.name}
                />
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" onClick={close}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSubmit(selected)}
                    disabled={!selected}
                >
                    Apply <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </ModalFooter>
        </Modal>
    );
};
