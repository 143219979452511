import React, { useEffect, useState } from 'react';
import { Card, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { UrlEnum } from 'shared';
import { useLocation } from 'react-router-dom';
import { performanceScheduleService } from 'services';
import { Confirm, Button, DropdownButton } from 'components/blocks';
import { LineItemRow, LineItemHeaders, SummaryRow } from './components';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { downloadExcel, downloadCsv } from 'helpers';

const FIELDS = [
    'lrYminus5Val',
    'lrYminus4Val',
    'lrYminus3Val',
    'lrYminus2Val',
    'lrYminus1Val',
    'lrYminus0Val',
    'prior3ryAvg',
    'current3ryAvg',
    'diffIn3ryAvgs',
];

export const PerformanceSchedulesLineItem = props => {
    const location = useLocation();
    const { row, labels, exportFileTypes } = location?.state;
    const { biennium, institution, schedule, status } = labels || {};
    const { scheduleId } = row || {};
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    console.log(props)
    console.log(labels)
    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await performanceScheduleService.getSchedule(scheduleId);
                setData(res);
            } catch (error) {
                console.error(error);
            }
        };
        fetch();
    }, []);
    const exportAs = async type => {
        try {
            const filename = `${schedule.replace(' ', '')}_${biennium.replace(
                '-',
                '_'
            )}_${institution.replace(' ', '')}`;
            const res = await performanceScheduleService.exportFile(
                type,
                data.scheduleId
            );
            // only excel works at the moment. Others can't be tested with the api yet
            if (type === 'Excel') {
                downloadExcel(res, filename);
            } else if (type === 'Csv') {
                downloadCsv(res, filename);
            }
        } catch (error) {}
    };
    const onChange = d => {
        setData(prev => {
            return {
                ...prev,
                scheduleData: prev.scheduleData.map(schedule => {
                    if (schedule.lineItemId === d.id) {
                        return {
                            ...schedule,
                            [d.field]: d.value,
                        };
                    }
                    return schedule;
                }),
            };
        });
    };
    const submitForm = async () => {
        setLoading(true);
        try {
            const res = await performanceScheduleService.updateSchedule(
                data.scheduleId,
                data
            );
            setData(res);
        } catch (error) {}
        setConfirm(false);
        setLoading(false);
    };
    const renderRow = row => {
        return (
            <LineItemRow
                key={row.lineItemId}
                id={row.lineItemId}
                data={row}
                onChange={onChange}
            />
        );
    };
    const sums = useMemo(() => {
        let sumTotals = [];
        if (!data?.scheduleData) {
            return [];
        }
        for (let x = 0; x < FIELDS.length; x++) {
            const vals = data.scheduleData.map(d => d[FIELDS[x]] || 0);
            sumTotals[x] = vals.reduce((a, b) => a + b, 0);
        }
        return sumTotals;
    }, [data]);

    return (
        <div className="sticky-footer">
            <Container fluid>
                <div className="d-md-flex align-items-center justify-content-between mb-3">
                    <a href={UrlEnum.PERFORMANCESCHEDULES}>
                        <FontAwesomeIcon icon={faArrowLeft} /> back to search
                    </a>
                    <div className="d-flex align-items-center">
                        {/* <DropdownButton
                            className="mr-2"
                            icon={faFilePdf}
                            text="Description"
                            options={[
                                { label: 'Download', onClick: () => {} },
                                { label: 'Update', onClick: () => {} },
                            ]}
                        /> */}
                        <DropdownButton
                            // className="mr-2"
                            icon={faFileExport}
                            text="Export As..."
                            options={exportFileTypes.map(type => {
                                return { label: type, onClick: () => exportAs(type) };
                            })}
                        />
                        {/* <button className="btn btn-xs btn-outline-primary">
                            <FontAwesomeIcon icon={faTrash} /> Delete Schedule
                        </button> */}
                    </div>
                </div>
                <div className="row sticky-bar-spacer">
                    <div className="col-12">
                        <Card className="p-3 mb-3">
                            <h2 className="text-strong text-primary">
                                {data.scheduleDescription}
                            </h2>
                            <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                                <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                                    <li className="mr-3">
                                        <label className="m-0">Institution:</label>{' '}
                                        {institution || '---'}
                                    </li>
                                    <li className="mr-3">
                                        <label className="m-0">Biennium:</label>{' '}
                                        {biennium || '---'}
                                    </li>
                                    <li className="mr-3">
                                        <label className="m-0">Schedule:</label>{' '}
                                        {schedule || '---'}
                                    </li>
                                    <li className="mr-3">
                                        <label className="m-0">Status:</label> {status}
                                    </li>
                                    <li>
                                        <label className="m-0">Last Updated:</label>{' '}
                                        {data?.lastUpdated
                                            ? `${format(
                                                  new Date(data.lastUpdated),
                                                  'M/d/y h:mm aa'
                                              )} ${
                                                  data?.lastUpdatedByUser
                                                      ? 'by ' + data?.lastUpdatedByUser
                                                      : ''
                                              }`
                                            : ''}
                                    </li>
                                </ul>
                            </div>
                        </Card>
                        <Card>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered table-hover align-middle">
                                    <LineItemHeaders biennium={data.biennium} />
                                    <tbody>
                                        {data?.scheduleData?.map(renderRow)}
                                        <tr>
                                            <td colSpan="10"></td>
                                        </tr>
                                        <SummaryRow data={sums} />
                                    </tbody>
                                </table>
                                <div className="sticky-bottom-bar">
                                    <div className="d-flex align-items-center justify-content-end">
                                        <Button
                                            className="btn-success btn-lg px-3"
                                            onClick={() => setConfirm(true)}
                                            disabled={loading}
                                            loading={loading}
                                        >
                                            <FontAwesomeIcon icon={faCheck} /> Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </Container>
            <Confirm
                isOpen={confirm}
                toggle={() => setConfirm(false)}
                onConfirm={submitForm}
                title="Confirm Changes"
                message="Are you sure you want to submit these changes?"
            />
        </div>
    );
};
