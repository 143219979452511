import React from 'react';
import format from 'date-fns/format';

export const HeaderCard = props => {
    const {
        description,
        institution,
        biennium,
        schedule,
        status,
        lastUpdated,
        lastUpdatedByUser,
    } = props;
    return (
        <div className="card p-3 mb-3">
            <h2 className="text-strong text-primary">{description}</h2>
            <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                    <li className="mr-3">
                        <label className="m-0">Institution:</label> {institution}
                    </li>
                    <li className="mr-3">
                        <label className="m-0">Biennium:</label> {biennium}
                    </li>
                    <li className="mr-3">
                        <label className="m-0">Schedule:</label> {schedule}
                    </li>
                    <li className="mr-3">
                        <label className="m-0">Status:</label> {status}
                    </li>
                    <li className="mr-3">
                        <label className="m-0">Last Updated:</label>{' '}
                        {format(new Date(lastUpdated), 'M/d/y h:mm aa')}{' '}
                        {lastUpdatedByUser ? 'by ' + lastUpdatedByUser : ''}
                    </li>
                </ul>
            </div>
        </div>
    );
};
