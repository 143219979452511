import { handleServiceRequest } from '../handle-service-request';

const baseUrl = `/api/finance/PerformanceSchedules`;

const getBienniums = () => {
    const getObject = {
        url: `${baseUrl}/bienniums`,
    };
    return handleServiceRequest.get(getObject);
};

const getSchedule = id => {
    const getObject = {
        url: `${baseUrl}/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const getSchedules = query => {
    const getObject = {
        url: `${baseUrl}?${query}`,
    };
    return handleServiceRequest.get(getObject);
};

const updateSchedule = (id, body) => {
    const getObject = {
        url: `${baseUrl}/${id}`,
        patchBody: body,
    };
    return handleServiceRequest.patch(getObject);
};

const getTypes = () => {
    const getObject = {
        url: `${baseUrl}/types`,
    };
    return handleServiceRequest.get(getObject);
};

const getInstitutions = () => {
    const getObject = {
        url: `${baseUrl}/institutions`,
    };
    return handleServiceRequest.get(getObject);
};

const getStatuses = () => {
    const getObject = {
        url: `${baseUrl}/statuses`,
    };
    return handleServiceRequest.get(getObject);
};

const getExportFileTypes = () => {
    const getObject = {
        url: `${baseUrl}/exportFileTypes`,
    };
    return handleServiceRequest.get(getObject);
};

const exportFile = (ext, id) => {
    const getObject = {
        url: `${baseUrl}/export/${ext}/${id}`,
    };
    if (ext === 'Excel')
        getObject.params = {
            responseType: 'arraybuffer',
        };
    return handleServiceRequest.get(getObject);
};

export const performanceScheduleService = {
    getBienniums,
    getSchedule,
    getSchedules,
    updateSchedule,
    getTypes,
    getInstitutions,
    getStatuses,
    getExportFileTypes,
    exportFile,
};
