import React, { useReducer } from 'react';
import { Form, Input } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import Select from 'react-select';

export const Base = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 3,
            projectName: budgetRun.budgetRunTitle ? budgetRun.budgetRunTitle : '',
            opsBase: budgetRun.opsBase,
        }
    );
    // const handleChange = evt => {
    //     const name = evt.target.name;
    //     const newValue = evt.target.value;
    //     budgetRun[name] = newValue;
    //     setUserInput({ [name]: newValue });
    // };
    // const handleRadioButtonChange = evt => {
    //     const name = evt.target.name;
    //     const newValue = evt.target.value === 'true' ? true : false;
    //     budgetRun[name] = newValue;
    //     setUserInput({ [name]: newValue });
    // };

    const sanitizeBudgetRun = () => {
        budgetRun.opsBase = userInput.opsBase;
        return budgetRun;
    };

    const handleTableChange = (event, index, inputTypeChanged) => {
        const headerValue = userInput.opsBase[index];
        const withoutCommas = event.target.value.replaceAll(',', '');
        headerValue[inputTypeChanged] = +withoutCommas ?? 0;
        //setChangedTotals();
        budgetRun.opsBase = [...userInput.opsBase];
        setUserInput({ opsBase: [...userInput.opsBase] });
    };

    const handleTypeSelectChange = (evt, index) => {
        const headerValue = userInput.opsBase[index];
        headerValue['fundingType'] = evt.val;
        budgetRun.opsBase = [...userInput.opsBase];
        setUserInput({ opsBase: [...userInput.opsBase] });
    };

    const renderFundingTypeDropdown = (type, index) => {
        if (type === 1) {
            const typeOptions = [{ label: 'PFF', value: 1 }];

            return (
                <Select isDisabled={true} options={typeOptions} value={typeOptions[0]} />
            );
        } else if (type > 1) {
            const typeOptions = [
                { label: 'Flat', value: 2 },
                { label: 'Direct', value: 3 },
            ];

            return (
                <Select
                    onChange={e => handleTypeSelectChange(e, index)}
                    options={typeOptions}
                    value={typeOptions.filter(option => option.value === type)}
                />
            );
        }
        //else nothing
    };

    const renderTableRows = () => {
        return userInput.opsBase.map((row, index) => {
            return (
                <React.Fragment key={index}>
                    <tr key={index}>
                        <td>
                            <label className="m-0 nowrap">{row.displayName}</label>
                        </td>
                        <td width="100px" className="text-center">
                            {/* <label className="m-0">{row.fundingType}</label> */}
                            {renderFundingTypeDropdown(row.fundingType, index)}
                        </td>
                        <td>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                        row.operatingBase == null
                                            ? 0
                                            : row.operatingBase.toLocaleString()
                                    }
                                    name="operatingBase"
                                    onChange={e => {
                                        handleTableChange(e, index, 'operatingBase');
                                    }}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                        row.pffExclusion == null
                                            ? 0
                                            : row.pffExclusion.toLocaleString()
                                    }
                                    name="pffExclusion"
                                    onChange={e => {
                                        handleTableChange(e, index, 'pffExclusion');
                                    }}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                        row.opsFTEAdj == null
                                            ? 0
                                            : row.opsFTEAdj.toLocaleString()
                                    }
                                    name="opsFTEAdj"
                                    onChange={e => {
                                        handleTableChange(e, index, 'opsFTEAdj');
                                    }}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                        row.bY1OtherAdj == null
                                            ? 0
                                            : row.bY1OtherAdj.toLocaleString()
                                    }
                                    name="bY1OtherAdj"
                                    onChange={e => {
                                        handleTableChange(e, index, 'bY1OtherAdj');
                                    }}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                        row.bY2OtherAdj == null
                                            ? 0
                                            : row.bY2OtherAdj.toLocaleString()
                                    }
                                    name="bY2OtherAdj"
                                    onChange={e => {
                                        handleTableChange(e, index, 'bY2OtherAdj');
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Operating Base
            </h2>
            <div className="table-responsive">
                <table className="table table-striped table-bordered table-hober align-middle table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="nowrap text-center">
                                Funding Type{' '}
                                <div
                                    data-toggle="popover"
                                    data-position="top"
                                    data-html="true"
                                    data-content='<ul className="pl-4 m-0"><li><label className="m-0">PFF:</label> Funding calculated using performance funding. Cannot modify funding type for PFF institutions</li><li><label className="m-0">Flat:</label> Funding calculated by taking base and multiplying by "Flat %" indicated on the "Ops: Percents" tab</li><li><label className="m-0">Direct:</label> No funding calculations. Funding solely based on "Other Adj" values entered on this tab</li></ul>'
                                >
                                    <i className="fas fa-question-circle fa-lg ml-1"></i>
                                </div>
                            </th>
                            <th className="text-center">Operating Base</th>
                            <th className="text-center">
                                PFF Exclusion{' '}
                                <div
                                    data-toggle="popover"
                                    data-position="top"
                                    data-html="true"
                                    data-content="Any amount entered in this column will be removed from institution's base before performance funding calculations and then re-added to total appropriation after performance funding appropriation is determined."
                                >
                                    <i className="fas fa-question-circle fa-lg ml-1"></i>
                                </div>
                            </th>
                            <th className="text-center">
                                Ops FTE Adj{' '}
                                <div
                                    data-toggle="popover"
                                    data-position="top"
                                    data-html="true"
                                    data-content="Only used on the funding per FTE budget run output reports. Amount subtracted/added to operating before funding per FTE is calculated."
                                >
                                    <i className="fas fa-question-circle fa-lg ml-1"></i>
                                </div>
                            </th>
                            <th className="text-center">
                                2020 Other Adj{' '}
                                <div
                                    data-toggle="popover"
                                    data-position="top"
                                    data-html="true"
                                    data-content="Will adjust final appropriation by given amount (can be a positive or negative adjustment). Can be applied to all funding types and will be performaed AFTER all calculations."
                                >
                                    <i className="fas fa-question-circle fa-lg ml-1"></i>
                                </div>
                            </th>
                            <th className="text-center">
                                2021 Other Adj{' '}
                                <div
                                    data-toggle="popover"
                                    data-position="top"
                                    data-html="true"
                                    data-content="Will adjust final appropriation by given amount (can be a positive or negative adjustment). Can be applied to all funding types and will be performaed AFTER all calculations."
                                >
                                    <i className="fas fa-question-circle fa-lg ml-1"></i>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>{renderTableRows()}</tbody>
                </table>
            </div>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={false}
                setBudgetRun={setBudgetRun}
                sanitizeBudgetRun={sanitizeBudgetRun}
            />
        </Form>
    );
};
