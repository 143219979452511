import { handleServiceRequest } from '../handle-service-request';
import { fakeResponses } from './fake-academic-affairs-response';
import { authHeader } from 'helpers/auth-header';
import { api_base_url, axiosInstance } from '../common.service';
import { handleError } from 'shared';
import { UrlEnum } from 'shared';
import {
    convertApiResponseToOppositeSearchOptions,
    convertApiResponseToSearchOptions,
} from '../shared-service-functions';

const baseUrl = `/api/AcademicPrograms`;

const searchAcademicPrograms = params => {
    const getObject = {
        url: `${baseUrl}/program/search`,
        params: params,
        customHandleError: error => {
            const returned = fakeResponses.fakeSearchAcademicPrograms();
            return returned;
        },
    };
    return handleServiceRequest.getWithParams(getObject);
};

const getProgramExtraViewDetails = (id) => {
    const getObject = {
        url: `${baseUrl}/program/extraViewDetails/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const getAcademicProgram = (id) => {
    const getObject = {
        url: `${baseUrl}/program/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

/* Sample Response 
[
    {
        "programID: 3,
        "institutionName: "Purdue University-West Lafayette",
        "degreeLevel: null,
        "cipCode: null,
        "cipTitle: "None",
        "programName: "Associate in Agriculture in General Agriculture",
        "status: "Eliminated",
        "approvalDate: "1971-08-04T00:00:00",
        "hasPendingActionRequests: false,
        "monthYearIdentifier: null,
        "monthYearText: "None Set"
    }, 
    ...
]
*/

const createNewProgram = postBody => {
    const postObject = {
        url: `${baseUrl}/program`,
        postBody: postBody,
    };
    return handleServiceRequest.post(postObject);
};

const getAcademicProgramRequests = id => {
    const getObject = {
        url: `${baseUrl}/program/${id}/requests`,
    };
    return handleServiceRequest.get(getObject);
};

const getProgramReviewReport = params => {
    const getObject = {
        url: `${baseUrl}/ProgramReviewReport`,
        params: params,
    };
    // [FromQuery] UnitType institutionLevel = UnitType.Institution, //1 = institution, 2 = campus, 3 = location
    //         [FromQuery] List<DataOutputs> dataOutputs = null,
    //         [FromQuery] List<ProgramsToInclude> programsToInclude = null,
    //         [FromQuery] List<string> fiscalYears = null,
    //         [FromQuery] List<string> institutionTypes = null,
    //         [FromQuery] List<string> institutions = null,
    //         [FromQuery] List<string> degreeLevels = null,
    //         [FromQuery] List<string> metaMajor = null,
    //         [FromQuery] List<string> cips = null,
    //         [FromQuery] string name = null,
    //         [FromQuery] bool? stemStatus = null,
    //         [FromQuery] List<string> programStatus = null,
    //         [FromQuery] DistanceCodes.DistanceEdCode distanceEd = DistanceCodes.DistanceEdCode.All
    return handleServiceRequest.getWithParams(getObject);
};

const getProgramReviewReportExport = params => {
    const getObject = {
        url: `${baseUrl}/ProgramReviewReportExport`,
        params: params,
    };
    // [FromQuery] UnitType institutionLevel = UnitType.Institution, //1 = institution, 2 = campus, 3 = location
    //         [FromQuery] List<DataOutputs> dataOutputs = null,
    //         [FromQuery] List<ProgramsToInclude> programsToInclude = null,
    //         [FromQuery] List<string> fiscalYears = null,
    //         [FromQuery] List<string> institutionTypes = null,
    //         [FromQuery] List<string> institutions = null,
    //         [FromQuery] List<string> degreeLevels = null,
    //         [FromQuery] List<string> metaMajor = null,
    //         [FromQuery] List<string> cips = null,
    //         [FromQuery] string name = null,
    //         [FromQuery] bool? stemStatus = null,
    //         [FromQuery] List<string> programStatus = null,
    //         [FromQuery] DistanceCodes.DistanceEdCode distanceEd = DistanceCodes.DistanceEdCode.All,
    //         [FromQuery] bool groupPrograms = false
    return handleServiceRequest.getWithParams(getObject);
};



const getLocations = (search = '') => {
    const getObject = {
        url: `${baseUrl}/locations?${search}`
    };
    return handleServiceRequest.get(getObject);
};

const getOptions = () => {
    const getObject = {
        url: `${baseUrl}/options`,
    };
    return handleServiceRequest.get(getObject);
};

const getCips = () => {
    const two = handleServiceRequest.getWithParams({
        url: '/api/AcademicPrograms/TwoDigitCIP',
    });
    const four = handleServiceRequest.getWithParams({
        url: '/api/AcademicPrograms/FourDigitCIP',
    });
    const six = handleServiceRequest.getWithParams({
        url: '/api/AcademicPrograms/SixDigitCIP',
    });
    return Promise.all([two, four, six]);
};

const getSixCips = () => {
    const getObject = {
        url: `/api/AcademicPrograms/SixDigitCIP`,
    };
    return handleServiceRequest.get(getObject);
};

const getInstitutions = search => {
    const getObject = {
        url: `${baseUrl}/institutions?${search}`,
        //convertFunction: function(resp) { convertApiResponseToSearchOptions(resp.institutions); }
        convertFunction: function(responses) {
            return responses.institutions.map(response => ({
                label: response.id,
                value: response.text ? response.text : response.name,
            }));
        },
    };
    return handleServiceRequest.get(getObject);
};

const getDegreeLevels = () => {
    const getObject = {
        url: `${baseUrl}/degreeLevels`,
        //convertFunction: function(resp) { convertApiResponseToSearchOptions(resp.institutions); }
        convertFunction: function(responses) {
            return responses.degreeLevels.map(response => ({
                label: response.id,
                value: response.text ? response.text : response.name,
            }));
        },
    };
    return handleServiceRequest.get(getObject);
};

async function getDistanceEds() {
    const getObject = {
        url: `${baseUrl}/distanceEds`,
    }

    let result = await handleServiceRequest.get(getObject);
    return result
}

async function getDistanceEdsOnline() {
    const getObject = {
        url: `${baseUrl}/distanceEdsOnline`,
    }

    let result = await handleServiceRequest.get(getObject);
    return result
}

const getInstitutionTypes = () => {
    const getObject = {
        url: `${baseUrl}/institutionTypes`,
    };
    return handleServiceRequest.get(getObject);
};

const getAuditsListWithId = id => {
    const getObject = {
        url: `${baseUrl}/Audits/list/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const updateNewAcademicAffair = (id, params) => {
    const patchObject = {
        url: `${baseUrl}/program/${id}`,
        params: params,
    };
    return handleServiceRequest.patch(patchObject);
};

const editAcademicProgram = params => {
    const myObject = {
        url: `${baseUrl}/program/${params.programId}`,
        patchBody: params,
    };
    return handleServiceRequest.patch(myObject);
};

const getCredentialInformation = id => {
    const getObject = {
        url: `${baseUrl}/credentials/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const editCredential = params => {
    const myObject = {
        url: `${baseUrl}/credentials/${params.CredentialId}`,
        patchBody: params,
    };
    return handleServiceRequest.patch(myObject);
};

const createCredential = postBody => {
    const postObject = {
        url: `${baseUrl}/credential`,
        postBody: postBody,
    };
    return handleServiceRequest.post(postObject);
};


export const academicProgramsService = {
    searchAcademicPrograms,
    createCredential,
    editCredential,
    getAcademicProgramRequests,
    getCredentialInformation,
    getProgramExtraViewDetails,
    getAcademicProgram,
    createNewProgram,
    getProgramReviewReport,
    getProgramReviewReportExport,
    getLocations,
    getOptions,
    getCips,
    getSixCips,
    getInstitutions,
    getInstitutionTypes,
    getAuditsListWithId,
    updateNewAcademicAffair,
    getDegreeLevels,
    getDistanceEdsOnline,
    editAcademicProgram,
    getDistanceEds
};
