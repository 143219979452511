import React from 'react';
import { CardHeader } from 'reactstrap';

export const Header = React.memo(props => {
    return (
        <CardHeader className="border-top border-bottom">
            <p className="m-0 text-strong">{props.title}</p>
        </CardHeader>
    );
});

export default Header;
