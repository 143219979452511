import React from 'react';
import { DataAuditTable } from './data-audit-table';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const DataAuditModal = ({
    isOpen,
    toggle,
    lineItemValues,
    biennium,
    isDataAudit,
    setIsDataAudit,
}) => {
    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );
    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>Data Audits</ModalHeader>
            <ModalBody>
                <DataAuditTable
                    lineItemValues={lineItemValues}
                    biennium={biennium}
                    isDataAudit={isDataAudit}
                    setIsDataAudit={setIsDataAudit}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};
