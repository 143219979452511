import React, { useState, useEffect, useReducer, useCallback, useMemo } from 'react';
import { FormGroup, Input, Button, Card, InputGroup, Label } from 'reactstrap';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    academicProgramsService,
    capitalProjectService,
    generateReportService,
} from '../../services';
import { ConfirmActionModal, onChangeFunction, UrlEnum, usePermissions } from 'shared';
import { Confirm, FormSelect } from '../blocks';
import Select from 'react-select';
import {
    deriveKey,
    getCipLabel,
    containsValue,
} from '../academic-affair-report/program-criteria-form';
import { INITIAL_FORM } from 'components/academic-affair-report/helpers';
import { CollectionProcessingService } from '../../services/admin-services/collection-processing-service';

const initialValues = {
    buttonAction: [],
    reportingYear: '',
};

export const getOptionLabel = d => d.value;

export const CollectionProcessingForm = ({ setLogData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const reducer = (state, newState) => ({ ...state, ...newState });

    const [userInput, setUserInput] = useReducer(reducer, initialValues);

    const [options, setOptions] = useReducer(reducer, []);

    const GetLabelFromEnum = value => {
        switch (value) {
            case 'MergeWithMSI':
                return 'Merge With MSI';
            case 'RunRecordLinking':
                return 'Run Record Linking';
            case 'MoveToDataRepository':
                return 'Move To Data Repository';
            case 'CloseFallCollectionExceptFE':
                return 'Close Collection Except FE';
            case 'CloseCollectionYearFEOnly':
                return 'Close Collection Only FE';
            case 'OpenNewCollectionYear':
                return 'Open New Collection Year';
        }
    };

    const ConvertToOptions = list => {
        const listreturn = list?.map((value, index) => ({
            label: GetLabelFromEnum(value),
            value: index,
        }));
        return listreturn;
    };

    useEffect(() => {
        const fetchOptions = async () => {
            const res = await CollectionProcessingService.getOptions();
            console.log(res);
            setOptions({
                buttonAction: ConvertToOptions(res),
            });
        };
        fetchOptions();
    }, []);

    useEffect(() => {
        getLogs();
    }, [userInput.buttonAction]);

    const getLogs = () => {
        if (userInput.buttonAction.value == null) return;
        Promise.resolve(
            CollectionProcessingService.getActionLogs(userInput.buttonAction.value)
        ).then(postRes => {
            console.log(postRes);
            setLogData(postRes || []);
            return postRes;
        });
    };

    const extractValuesFromResponseCSV = unextractedResponseValues => {
        return unextractedResponseValues
            .map(currentValue => {
                return currentValue.value;
            })
            .join(',');
    };

    return (
        <React.Fragment>
            <Card className="card mb-3 p-3">
                <h4 className="text-muted mb-4">Collection Processing Management</h4>
                <FormSelect
                    label="Action"
                    onChange={setUserInput}
                    options={options.buttonAction ? options.buttonAction : []}
                    placeholder="All"
                    name="buttonAction"
                    value={userInput.buttonAction}
                    isMulti={false}
                />
                <FormGroup>
                    <label>Reporting Year</label>
                    <Input
                        type="text"
                        placeholder=""
                        onChange={evt => {
                            const name = evt.target.name;
                            const newValue = evt.target.value;
                            if (/^\d{0,4}$/.test(newValue))
                                setUserInput({ [name]: newValue });
                        }}
                        name="reportingYear"
                        value={userInput.reportingYear}
                    />
                </FormGroup>
                <button
                    className="btn btn-block btn-primary"
                    color="success"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    disabled={userInput.buttonAction?.label?.includes('Close')}
                >
                    Run Action
                </button>
                <button
                    className="btn btn-block btn-primary"
                    color="success"
                    onClick={() => {
                        getLogs();
                    }}
                >
                    Refresh Logs
                </button>
            </Card>
            <Confirm
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(!isModalOpen)}
                message={
                    'Are you sure you want to ' +
                    userInput.buttonAction.label +
                    ' for the RY of ' +
                    userInput.reportingYear
                }
                title={'Confirm Action'}
                onConfirm={() => {
                    CollectionProcessingService.commitAction(
                        userInput.buttonAction.value,
                        userInput.reportingYear
                    );
                    setIsModalOpen(false);
                }}
            />
        </React.Fragment>
    );
};
