import React, { Component } from 'react';
import Faker from 'faker';
// import { Link } from 'react-router-dom';

class UserAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getUsers() {
		const users = [];
		for (let i = 0; i < 1000; i++) {
			users.push({
				userID: Faker.random.number(),
				email: Faker.internet.email(),
				firstname: Faker.name.firstName(),
				lastname: Faker.name.lastName(),
				active: Math.random() < 0.8 ? 'Yes' : 'No',
				roles: 'THIS AN THAT',
				institutions: Faker.company.companyName(),
				edit: <a href='/#'>Edit</a>,
				delete: <a href='/#'>Delete</a>,
				resetpw: <a href='/#'>Reset PW</a>,
			});
		}
		return users;
	}

	render() {
		return (
			<div>
				<h1>User Administration</h1>
				{/* <DataGrid
					columns={[
						{ label: 'User ID', width: 90 },
						{ label: 'Email', width: 220 },
						{ label: 'First Name', width: 120 },
						{ label: 'Last Name', width: 120 },
						{ label: 'Active', width: 60 },
						{ label: 'Roles', width: 205 },
						{ label: 'Institutions', width: 205 },
						{ label: 'Edit', width: 60 },
						{ label: 'Delete', width: 60 },
						{ label: 'Reset Pwd', width: 80 },
					]}
					data={this.getUsers()}
				/> */}
			</div>
		);
	}
}

export { UserAdmin };
