import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

const numberWithCommas = x => {
    if (isNaN(x)) return '';
    return Math.round(x)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

const YearInput = props => {
    const { onChange, id, field, defaultValue } = props;
    const [value, setValue] = useState('');
    useEffect(() => {
        setValue(numberWithCommas(defaultValue));
    }, [defaultValue]);
    return (
        <Input
            placeholder={0}
            className="form-control"
            value={value}
            style={{ backgroundColor: 'white', textAlign: 'left' }}
            onChange={e => {
                if (isNaN(e.target.value)) return;
                setValue(e.target.value);
            }}
            onBlur={() => onChange({ id, field, value: Number(value) })}
        />
    );
};

const average = vals => {
    if (vals.includes(null)) return { show: false };
    const sum = vals.reduce((a, b) => a + b, 0);
    const avg = Math.round(sum / vals.length || 0);
    return { show: true, value: avg };
};

export const LineItemRow = props => {
    const {
        lineItemName,
        lrYminus0Val,
        lrYminus1Val,
        lrYminus2Val,
        lrYminus3Val,
        lrYminus4Val,
        lrYminus5Val,
    } = props?.data;
    const priorAvg = average([lrYminus3Val, lrYminus4Val, lrYminus5Val]);
    const currentAvg = average([lrYminus0Val, lrYminus1Val, lrYminus2Val]);
    return (
        <tr>
            <td>
                <label className="m-0">{lineItemName}</label>
            </td>
            <td>
                <YearInput
                    onChange={props.onChange}
                    field="lrYminus5Val"
                    id={props.id}
                    defaultValue={lrYminus5Val || ''}
                />
            </td>
            <td>
                <YearInput
                    onChange={props.onChange}
                    field="lrYminus4Val"
                    id={props.id}
                    defaultValue={lrYminus4Val || ''}
                />
            </td>
            <td>
                <YearInput
                    onChange={props.onChange}
                    field="lrYminus3Val"
                    id={props.id}
                    defaultValue={lrYminus3Val || ''}
                />
            </td>
            <td>
                <YearInput
                    onChange={props.onChange}
                    field="lrYminus2Val"
                    id={props.id}
                    defaultValue={lrYminus2Val || ''}
                />
            </td>
            <td>
                <YearInput
                    onChange={props.onChange}
                    field="lrYminus1Val"
                    id={props.id}
                    defaultValue={lrYminus1Val || ''}
                />
            </td>
            <td>
                <YearInput
                    onChange={props.onChange}
                    field="lrYminus0Val"
                    id={props.id}
                    defaultValue={lrYminus0Val || ''}
                />
            </td>
            <td className="highlight-cell text-strong text-right">
                {priorAvg.show ? numberWithCommas(priorAvg.value) : '---'}
            </td>
            <td className="highlight-cell text-strong text-right">
                {currentAvg.show ? numberWithCommas(currentAvg.value) : '---'}
            </td>
            <td className="highlight-cell text-strong text-right">
                {priorAvg.show && currentAvg.show
                    ? numberWithCommas(currentAvg.value - priorAvg.value)
                    : '---'}
            </td>
        </tr>
    );
};

const getValue = (val, offset) => {
    if (!val) return '';
    return `${val - (offset + 1)}-${val - offset}`;
};
const getSumValue = (val, offset) => {
    if (!val) return '';
    return `${val - (offset + 2)}-${String(val - offset).substring(2)} 3 Year Avg`;
};

export const LineItemHeaders = props => {
    const { biennium } = props;
    return (
        <thead>
            <tr>
                <th className="nowrap" style={getWidthStyle(260)}>
                    Summary of Budget Request
                </th>
                <th className="text-center" style={getWidthStyle(75)}>
                    {getValue(biennium, 7)}
                </th>
                <th className="text-center" style={getWidthStyle(75)}>
                    {getValue(biennium, 6)}
                </th>
                <th className="text-center" style={getWidthStyle(75)}>
                    {getValue(biennium, 5)}
                </th>
                <th className="text-center" style={getWidthStyle(75)}>
                    {getValue(biennium, 4)}
                </th>
                <th className="text-center" style={getWidthStyle(75)}>
                    {getValue(biennium, 3)}
                </th>
                <th className="text-center" style={getWidthStyle(75)}>
                    {getValue(biennium, 2)}
                </th>
                <th className="text-center" style={getWidthStyle(70)}>
                    {getSumValue(biennium, 5)}
                </th>
                <th className="text-center" style={getWidthStyle(70)}>
                    {getSumValue(biennium, 2)}
                </th>
                <th className="text-center" style={getWidthStyle(70)}>
                    Change in 3 Year Avg
                </th>
            </tr>
        </thead>
    );
};

export const SummaryRow = props => {
    const { data, total } = props;
    return (
        <tr>
            <td className="nowrap">
                <h3 className="m-0">Total Overall Degrees Conferred</h3>
            </td>
            {data?.map((d, i) => {
                if (i + 1 === data.length) {
                    return (
                        <td
                            key={i}
                            className="text-right text-strong highlight-cell-bold"
                        >
                            <h2 className="m-0">
                                {numberWithCommas(d)}
                                <small className="d-block"># of units</small>
                            </h2>
                        </td>
                    );
                }
                return (
                    <td key={i} className="text-right text-strong">
                        <h3 className="m-0">{numberWithCommas(d)}</h3>
                    </td>
                );
            })}
        </tr>
    );
};
