import React, { useReducer, useMemo } from 'react';
import { Form, Input, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { onChangeFunction } from 'shared';
import { Button } from 'components/blocks';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_STATE = {
    password: '',
    confirm: '',
};

const VALIDATION_REGEX = {
    '(?=.*[a-z])': 'At least 1 lower-case',
    '(?=.*[A-Z])': 'At least 1 upper-case',
    '(?=.*?[0-9])': 'At least 1 number',
    '(?=.*[!,@,#,$,%,^,&,*,\\[,\\],?,_,~,-,£,(,),.,:,;,<,>,},{])':
        'At least 1 special character (‘!@#$%^&*()_+[]{};:”,.<>/?)',
    '.{10,}': 'At least 10 characters',
};

export const PasswordForm = props => {
    const { loading, onSubmit, linkToLogin, uid } = props;
    const [form, setForm] = useReducer(reducer, INITIAL_STATE);
    const { password, confirm } = form;
    const submitHandler = async e => {
        e.preventDefault();
        onSubmit(form);
    };
    const validation = useMemo(() => {
        if (form.password.length > 0) {
            const errors = [];
            Object.keys(VALIDATION_REGEX).forEach(key => {
                const regTest = new RegExp(key);
                if (!regTest.test(form.password)) {
                    errors.push(VALIDATION_REGEX[key]);
                }
            });
            return errors;
        }
        return [];
    }, [form]);
    const isValid = validation.length === 0;
    const hasPassword = password.length > 0;
    const hasConfirm = confirm.length > 0;
    const confirmError = hasPassword && hasConfirm && password !== confirm;
    return (
        <Form onSubmit={loading ? null : submitHandler}>
            <Input
                type="password"
                name="password"
                className="form-control form-control-lg mb-3"
                style={{ background: '#FFF' }}
                placeholder="Password"
                value={password}
                onChange={evt => onChangeFunction.handleChange(evt, setForm)}
                id="validation_popover"
            />
            <Popover
                placement="bottom-end"
                isOpen={!isValid && hasPassword}
                target="validation_popover"
            >
                <PopoverHeader>Password Validation</PopoverHeader>
                <PopoverBody>
                    {validation.map((val, i) => {
                        return (
                            <div key={i} className="text-danger">
                                &bull; {val}
                            </div>
                        );
                    })}
                </PopoverBody>
            </Popover>
            <Input
                type="password"
                name="confirm"
                className={`form-control form-control-lg ${
                    confirmError ? 'mb-1' : 'mb-4'
                }`}
                style={{ background: '#FFF' }}
                placeholder="Confirm Password"
                value={confirm}
                onChange={evt => onChangeFunction.handleChange(evt, setForm)}
            />
            {confirmError && (
                <div className="text-danger mb-3">Passwords do not match</div>
            )}
            <Button
                type="submit"
                color="primary"
                className="btn-lg btn-block btn-round"
                loading={loading}
                disabled={password.length === 0 || confirm.length === 0 || !isValid}
            >
                {uid ? 'Reset Password' : 'Set Password'}
            </Button>
            <div className="mt-5 text-center">
                <div onClick={linkToLogin} className="body-text-link cursor-pointer">
                    <FontAwesomeIcon icon={faArrowLeft} /> back to login
                </div>
            </div>
        </Form>
    );
};
