import React, { useReducer, useEffect, useCallback, useState } from 'react';

import queryString from 'query-string';
import { UrlEnum } from '../../shared';
import { useHistory } from 'react-router-dom';
import {
    FormGroup,
    Input,
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import format from 'date-fns/format';
import { academicProgramsService, financeService } from 'services';
import { onChangeFunction } from 'shared';
import { FormSelect } from '../blocks';

import { getCipLabel } from '../academic-affair-report/program-criteria-form';
import { getOptionLabel } from './search-academic-programs-form';

export const CreateAcademicAffairModal = ({ isOpen, toggle, degreeLevels, sixCip }) => {
    const history = useHistory();
    const [onlineDropdownDisabled, setOnlineDropdownDisabled] = useState(false);
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const reducer = (state, newState) => ({ ...state, ...newState });
    const INITIAL_STATE = {
        institution: null,
        locations: null,
        programName: '',
        degreeLevel: null,
        cipCode: '',
        approvalDate: format(new Date(), 'yyyy-MM-dd'),
        distanceEdApproved: false,
        distanceEdOnlineApproved: false,
        comments: '',
        creditRequirementMinimum: '',
        creditRequirementMaximum: '',
        endDate: '',
        programDistanceEd: null,
        programDistanceEdOnline: null,
    };
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        INITIAL_STATE
    );

    const toggleModal = () => {
        toggle();
        setOnlineDropdownDisabled(false);
        setUserInput(INITIAL_STATE);
    };

    const [options, setOptions] = useReducer(reducer, {});
    const fetchOptions = useCallback(async () => {
        academicProgramsService.getInstitutions().then(res => {
            setOptions({
                institutions: res.map(x => {
                    return { value: x.label, label: x.value };
                }),
            });
        });
        academicProgramsService.getDistanceEds().then(res => {
            setOptions({ distanceEds: res });
        });

        academicProgramsService.getDistanceEdsOnline().then(res => {
            setOptions({ distanceEdsOnline: res });
        });
    }, []);

    useEffect(() => {
        setUserInput({ locations: [] });
        setOptions({ locations: [] });
        if (userInput.institution) {
            const query = queryString.stringify(
                { institutions: userInput.institution.value },
                {
                    arrayFormat: 'separator',
                    skipEmptyString: true,
                }
            );
            academicProgramsService.getLocations(query).then(res => {
                console.log(res);
                setOptions({ locations: res.locations });
            });
        }
    }, [userInput.institution]);

    useEffect(() => {
        if (options.locations) {
            if (options.locations.length == 1) {
                const singleLoc = {
                    label: options.locations[0].value,
                    value: options.locations[0].key,
                };
                setUserInput((userInput.locations = [singleLoc]));
            } else {
                setUserInput((userInput.locations = null));
            }
        }
    }, [options.locations]);

    useEffect(() => {
        fetchOptions();
    }, [fetchOptions]);

    const createNewProgram = async () => {
        try {
            // Allow for associating programs with locations explicitly using ProgramLocationStatus
            const locationStatusChanges = userInput.locations
                ? userInput.locations.map(location => {
                      return {
                          LocationId: location.value,
                          LocationName: location.label,
                          StatusId: 1,
                      };
                  })
                : null;

            const postData = {
                degreeLevel: userInput.degreeLevel
                    ? userInput.degreeLevel.value
                    : undefined,
                locationStatusChanges: locationStatusChanges,
                formalName: userInput.programName ? userInput.programName : undefined,
                cipCode: userInput.cipCode ? userInput.cipCode.value : undefined,
                approvalDate: userInput.approvalDate,
                distanceEdApproved: {
                    id: userInput.programDistanceEd.value,
                    approval: userInput.programDistanceEd.label,
                },
                distanceEdOnline: {
                    id: userInput.programDistanceEdOnline.value,
                    approval: userInput.programDistanceEdOnline.label,
                },
                comments: userInput.comments ? userInput.comments : undefined,
                creditRequirementMinimum: userInput.creditRequirementMinimum
                    ? Number(userInput.creditRequirementMinimum)
                    : undefined,
                creditRequirementMaximum: userInput.creditRequirementMaximum
                    ? Number(userInput.creditRequirementMaximum)
                    : undefined,
                institutionId: userInput.institution
                    ? userInput.institution.value
                    : undefined,
                endYear: userInput.endYear ? Number(userInput.endYear) : undefined,
            };
            const res = await academicProgramsService.createNewProgram(postData);

            toggle();
            setUserInput(INITIAL_STATE);

            const location = {
                pathname: UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS,
                state: [{ programID: res.programId }],
            };
            history.push(location);
        } catch (error) {}
    };

    useEffect(() => {
        if (userInput.programDistanceEd == null) return;
        if (userInput.programDistanceEd.value == 1) {
            setOnlineDropdownDisabled(true);
            const distEdOnline = { label: 'None', value: 0 };
            setUserInput((userInput.programDistanceEdOnline = distEdOnline));
        } else {
            setOnlineDropdownDisabled(false);
        }
    }, [userInput.programDistanceEd]);

    useEffect(() => {
        const skipKeys = [
            'endDate',
            'comments',
            'creditRequirementMinimum',
            'creditRequirementMaximum',
        ];
        var anyEmpty = false;
        for (const key in userInput) {
            if (skipKeys.includes(key)) continue;
            if (userInput[key] === null || userInput[key] === '') {
                anyEmpty = true;
            }
        }
        setAllFieldsFilled(!anyEmpty);
    }, [userInput]);

    const closeBtn = (
        <button className="close" onClick={toggleModal}>
            &times;
        </button>
    );

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>New Program</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <label>
                            Program Name<sup className="text-danger"> *</sup>
                        </label>
                        <Input
                            type="text"
                            placeholder="Program Name"
                            onChange={evt =>
                                onChangeFunction.handleChange(evt, setUserInput)
                            }
                            name="programName"
                            value={userInput.programName}
                        />
                    </FormGroup>
                    <FormSelect
                        label="CIP Code"
                        onChange={setUserInput}
                        options={
                            sixCip
                                ? sixCip.map(x => {
                                      return {
                                          value: x.cipCode,
                                          label: `${x.cipCode} - ${x.cipName}`,
                                      };
                                  })
                                : []
                        }
                        placeholder="CIP Code"
                        name="cipCode"
                        value={userInput.cipCode}
                        isMulti={false}
                        getOptionLabel={getCipLabel}
                        isClearable
                        showLoading
                        required
                    />
                    <FormSelect
                        label="Select Institution"
                        onChange={setUserInput}
                        options={options.institutions}
                        placeholder="Please select one"
                        name="institution"
                        value={userInput.institution}
                        isMulti={false}
                        isClearable
                        showLoading
                        required
                    />

                    <FormSelect
                        label="Select Locations"
                        onChange={setUserInput}
                        options={
                            options.locations
                                ? options.locations.map(x => {
                                      return { value: x.key, label: x.value };
                                  })
                                : []
                        }
                        closeAfterSelection={false}
                        placeholder="Please select one"
                        name="locations"
                        value={userInput.locations}
                        isMulti
                        isClearable
                        showLoading
                        required
                    />

                    <div className="form-row align-items-center">
                        <div className="col-lg-6">
                            <FormGroup>
                                <label>Approval Date</label>
                                <Input
                                    type="date"
                                    style={{ textAlign: 'left' }}
                                    placeholder="Approval Date"
                                    onChange={evt =>
                                        onChangeFunction.handleChange(evt, setUserInput)
                                    }
                                    name="approvalDate"
                                    value={userInput.approvalDate}
                                />
                            </FormGroup>
                        </div>
                        {/* <div className="col-lg-6">
                            <FormGroup>
                                <label>End Year</label>
                                <Input
                                    type="number"
                                    style={{ textAlign: 'left' }}
                                    placeholder="End Year"
                                    onChange={evt =>
                                        onChangeFunction.handleChange(evt, setUserInput)
                                    }
                                    name="endYear"
                                    value={userInput.endYear}
                                    isClearable
                                />
                            </FormGroup>
                        </div> */}
                        <div className="col-lg-6">
                            <FormSelect
                                label="Degree/Award Level"
                                onChange={setUserInput}
                                options={
                                    degreeLevels
                                        ? degreeLevels.map(x => {
                                              return { value: x.label, label: x.value };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="degreeLevel"
                                value={userInput.degreeLevel}
                                required
                                getOptionLabel={getOptionLabel}
                                isMulti={false}
                                isClearable
                            />
                        </div>

                        <div className="col-lg-6">
                            <FormSelect
                                label="Delivery Mode"
                                onChange={setUserInput}
                                name="programDistanceEd"
                                required
                                value={userInput.programDistanceEd}
                                isMulti={false}
                                options={
                                    options.distanceEds
                                        ? options.distanceEds.map(x => {
                                              return {
                                                  label: x.approval,
                                                  value: x.id,
                                              };
                                          })
                                        : []
                                }
                                isClearable
                                showLoading
                            />
                        </div>

                        <div className="col-lg-6">
                            <FormSelect
                                label="Nature of Online Program"
                                disabled={onlineDropdownDisabled}
                                required
                                onChange={setUserInput}
                                name="programDistanceEdOnline"
                                value={userInput.programDistanceEdOnline}
                                isMulti={false}
                                options={
                                    options.distanceEdsOnline
                                        ? options.distanceEdsOnline.map(x => {
                                              return {
                                                  label: x.approval,
                                                  value: x.id,
                                              };
                                          })
                                        : []
                                }
                                isClearable
                                showLoading
                            />
                        </div>

                        <div className="col-lg-6">
                            <FormGroup>
                                <label>Credit Requirement Minimum</label>
                                <Input
                                    type="number"
                                    style={{ textAlign: 'left' }}
                                    placeholder="Minimum Credits"
                                    onChange={evt =>
                                        onChangeFunction.handleChange(evt, setUserInput)
                                    }
                                    name="creditRequirementMinimum"
                                    value={userInput.creditRequirementMinimum}
                                />
                            </FormGroup>
                        </div>

                        <div className="col-lg-6">
                            <FormGroup>
                                <label>Credit Requirement Maximum</label>
                                <Input
                                    type="number"
                                    style={{ textAlign: 'left' }}
                                    placeholder="Maximum Credits"
                                    onChange={evt =>
                                        onChangeFunction.handleChange(evt, setUserInput)
                                    }
                                    name="creditRequirementMaximum"
                                    value={userInput.creditRequirementMaximum}
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <Input
                        type="textarea"
                        rows="8"
                        placeholder="Place Comments Here"
                        onChange={evt => onChangeFunction.handleChange(evt, setUserInput)}
                        name="comments"
                        value={userInput.comments}
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={createNewProgram}
                    disabled={!allFieldsFilled}
                >
                    Submit
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
