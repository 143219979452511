import React from 'react';
import { Input } from 'reactstrap';

import { onChangeFunction } from 'shared';

export const FormInput = props => {
    const { label, onChange, className, required, inputClassName, ...rest } = props;
    return (
        <div className={className}>
            <fieldset className="form-group">
                <label>
                    {label} {required && <sup className="text-danger">*</sup>}
                </label>
                <Input
                    type="text"
                    onChange={evt => onChangeFunction.handleChange(evt, onChange)}
                    className={inputClassName}
                    {...rest}
                />
            </fieldset>
        </div>
    );
};

export default FormInput;
