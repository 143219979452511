import { useSelector } from 'react-redux';
import _uniq from 'lodash/uniq';

import PERMISSIONS from '../constants/permissions.json';

// We do not use these permissions on the frontend
const UNUSED_PERMISSIONS = ['reset-two-factor-credentials'];
// Permissions that are related to viewing pages
const VIEW_PERMISSIONS = [
    'administer-che-all-institution-users',
    'administer-institution-academic-affairs-users',
    'administer-institution-data-submission-users',
    'administer-institution-finance-users',
    'browse-programs',
    'configure-and-generate-budget-run',
    'perform-record-linking',
    'review-and-edit-budget-and-performance-schedules',
    'submit-data',
    'view-capital-projects',
];

export const usePermissions = params => {
    const { views = false, permissions = null } = params || {};
    const roles = useSelector(state => state.user.roles) || [];
    const perms = roles.reduce((p, c) => {
        const role = PERMISSIONS.find(d => d.roleName === c.roleName);
        if (role) return _uniq([...p, ...role.permissions]);
        return p;
    }, []);
    const unused = views
        ? UNUSED_PERMISSIONS
        : [...UNUSED_PERMISSIONS, ...VIEW_PERMISSIONS];
    const filtered = perms.filter(p => !unused.includes(p)).sort();
    // if an array of permissions are passed to this hook then we want to make sure that the user
    // has all of the requested permissions
    if (Array.isArray(permissions)) {
        const vals = permissions.map(perm => {
            return filtered.includes(perm);
        });
        return !vals.includes(false);
    }
    return filtered;
};
