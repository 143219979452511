import React from 'react';
import { Card, Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { UrlEnum, usePermissions, useUserInstitutions } from '../../shared';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

export const AcademicAffairsSearchProgramsTable = ({ academicAffairsData }) => {
    const history = useHistory();
    const editPermission = usePermissions({
        permissions: ['submit-changes-to-programs'],
    });
    const institutionIds = useUserInstitutions();
    const rowEvents = {
        // onClick: (e, row, rowIndex) => {
        //     const projectId = row.projectID;
        //     const location = {
        //         pathname: UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS,
        //         state: [{ projectId: projectId }],
        //     };
        //     history.push(location);
        // },
    };

    const actions = (cell, row, rowIndex, formatExtraData) => {
        const idStart = 'PopoverView-' + row.programID;
        const { editPermission, institutionIds } = formatExtraData;
        const canView =
            editPermission || institutionIds.includes(Number(row.institutionId));
        return (
            <div className="d-flex">
                {canView && (
                    <Button
                        className="action-circle"
                        onClick={() => {
                            const programID = row.programID;

                            const location = {
                                pathname: UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS_VIEW,
                                state: [{ programID: programID }],
                            };

                            history.push(location);
                        }}
                    >
                        <UncontrolledPopover
                            target={idStart + '-view'}
                            trigger="hover"
                            placement="bottom"
                        >
                            <PopoverBody>View</PopoverBody>
                        </UncontrolledPopover>
                        <FontAwesomeIcon id={idStart + '-view'} icon={faSearchPlus} />
                    </Button>
                )}
                {editPermission && (
                    <Button
                        className="action-circle"
                        onClick={() => {
                            const programID = row.programID;

                            const location = {
                                pathname: UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS,
                                state: [{ programID: programID }],
                            };

                            history.push(location);
                        }}
                    >
                        <UncontrolledPopover
                            target={idStart + '-edit'}
                            trigger="hover"
                            placement="bottom"
                        >
                            <PopoverBody>Edit</PopoverBody>
                        </UncontrolledPopover>
                        <FontAwesomeIcon id={idStart + '-edit'} icon={faEdit} />
                    </Button>
                )}
            </div>
        );
    };

    const columns = [
        {
            dataField: 'action',
            text: 'Actions',
            formatter: actions,
            headerStyle: getWidthStyle(70),
            formatExtraData: { editPermission, institutionIds },
        },
        {
            dataField: 'programID',
            text: 'Program Id',
            sort: true,
            headerStyle: getWidthStyle(80),
        },
        {
            dataField: 'institutionName',
            text: 'Institution',
            sort: true,
            headerStyle: getWidthStyle(300),
        },
        {
            dataField: 'degreeLevel',
            text: 'Degree Level',
            sort: true,
            headerStyle: getWidthStyle(90),
        },
        {
            dataField: 'cipCode',
            text: 'CIP',
            sort: true,
            headerStyle: getWidthStyle(80),
        },
        {
            dataField: 'cipTitle',
            text: 'CIP Title',
            sort: true,
            headerStyle: getWidthStyle(80),
        },
        {
            dataField: 'programName',
            text: 'Program Name',
            sort: true,
            headerStyle: { minWidth: 300 },
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle: getWidthStyle(100),
        },
        {
            dataField: 'approvalDate',
            text: 'Approved On',
            sort: true,
            headerStyle: getWidthStyle(100),
        },
        {
            dataField: 'endYear',
            text: 'End Year',
            sort: true,
            headerStyle: getWidthStyle(100),
        },
        {
            dataField: 'suspensionYear',
            text: 'Suspension Year',
            sort: true,
            headerStyle: getWidthStyle(110),
        },
        // {
        //     dataField: 'action',
        //     text: <FontAwesomeIcon icon={faSearchPlus} />,
        //     //isDummyField: true,
        // },
    ];
    const options = {
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        sizePerPage: 20,
        hideSizePerPage: true,
        alwaysShowAllBtns: true
    };
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between text-center text-md-left mb-3">
                <h2 className="mb-md-0 mb-3">
                    {academicAffairsData?.length} Programs Found
                </h2>
                {/* <button
                    className="btn btn-sm btn-outline-primary"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i className="far fa-file-export"></i> Export as CSV
                </button> */}
            </div>
            <Card>
                <div className="table-responsive">
                    <BootstrapTable
                        keyField="programID"
                        data={academicAffairsData?.map(project => {
                            const newLastUpdateDate = new Date(project.approvalDate);
                            project.approvalDate = newLastUpdateDate.toLocaleDateString();
                            return project;
                        })}
                        columns={columns}
                        striped
                        hover
                        pagination={paginationFactory(options)}
                        rowEvents={rowEvents}
                        // defaultSorted={defaultSorted}
                    />
                </div>
            </Card>
        </React.Fragment>
    );
};
