import React, { useEffect, useRef, useState } from 'react';

export const Spinner = React.memo(props => {
    const ref = useRef(0);
    const [width, setWidth] = useState(0);
    const { size = 24, color = 'primary', fill } = props;
    useEffect(() => {
        setWidth(ref.current.clientHeight);
    }, []);
    return (
        <div className="loading-spinner">
            <div
                ref={ref}
                className="loading-spinner-icon"
                style={fill ? { height: '100%', width } : { height: size, width: size }}
            >
                <div className="background"></div>
                <div className={`spinner ${color}`}></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
});

export default Spinner;
