import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledPopover,
    PopoverBody,
    Row,
    Popover,
} from 'reactstrap';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import { generateReportService } from 'services';
import { Button, PopoverWrapper, getWidthStyle } from 'components/blocks';

const actions = (cell, row, rowIndex, formatExtraData) => {
    const idStart = 'string-' + rowIndex + '-';
    return (
        <div className="d-flex">
            <Button className="action-circle" onClick={() => formatExtraData.onEdit(row)}>
                <UncontrolledPopover
                    target={idStart + '-edit'}
                    trigger="hover"
                    placement="bottom"
                >
                    <PopoverBody>Edit</PopoverBody>
                </UncontrolledPopover>
                <FontAwesomeIcon id={idStart + '-edit'} icon={faPencilAlt} />
            </Button>
            <PopoverWrapper
                id={idStart + '-delete'}
                render={
                    <>
                        <strong>
                            Are you sure you want to delete this saved search?
                        </strong>
                        <Button
                            onClick={() => formatExtraData.onDelete(row)}
                            className="btn-danger btn-sm text-white btn-round w-100 mt-2"
                        >
                            <FontAwesomeIcon icon={faTrashAlt} /> Delete
                        </Button>
                    </>
                }
            >
                <Button className="action-circle">
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </PopoverWrapper>
        </div>
    );
};
const RenameInput = props => {
    const { value, onChange, onRename, onClear } = props;
    return (
        <table
            className="table table-striped table-bordered table-hover table-lg align-middle"
            style={{ borderTopWidth: 0 }}
        >
            <tr>
                <td colspan="3" style={{ borderTopWidth: 0 }}>
                    <div className="row">
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                value={value}
                                placeholder="Search Name Here"
                                onChange={e => onChange(e.target.value)}
                            />
                        </div>
                        <div className="col-auto">
                            <Button
                                type="button"
                                color="success"
                                className="btn-sm mr-1"
                                onClick={onRename}
                            >
                                Rename
                            </Button>
                            <Button
                                type="button"
                                className="btn-sm btn-outline-dark"
                                onClick={onClear}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    );
};
const paginationOptions = {
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    sizePerPage: 10,
    hideSizePerPage: true,
};

const date = cell => {
    const date = new Date(cell);
    return format(date, 'MM/dd/yyyy');
};

export const ManageSearchesModal = props => {
    const { open, close } = props;
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [name, setName] = useState('');
    const fetchData = async () => {
        try {
            const res = await generateReportService.getSearches();
            setData(res);
        } catch (error) {}
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        setName(selected?.name || '');
    }, [selected]);
    const deleteSearch = async d => {
        await generateReportService.deleteSearch(d?.id);
        fetchData();
    };
    const renameSearch = async () => {
        await generateReportService.updateSearch(selected?.id, {
            name,
            searchString: selected.searchString,
        });
        fetchData();
        clearRename();
    };
    const clearRename = () => {
        setSelected(null);
        setName('');
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'lastUpdatedDate',
            text: 'Last Updated',
            formatter: date,
            sort: true,
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: actions,
            headerClasses: 'text-right',
            classes: 'table-actions',
            headerStyle: getWidthStyle(150),
            formatExtraData: { onDelete: deleteSearch, onEdit: setSelected },
        },
    ];
    return (
        <Modal isOpen={open} className="modal-dialog modal-lg">
            <ModalHeader toggle={close}>Manage Saved Searches</ModalHeader>
            <ModalBody>
                <PaginationProvider
                    pagination={paginationFactory({
                        ...paginationOptions,
                        custom: true,
                        totalSize: data?.length,
                        onPageChange: () => {},
                        page: 1,
                    })}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <BootstrapTable
                                keyField="id"
                                data={data}
                                columns={columns}
                                {...paginationTableProps}
                            />
                            {selected && (
                                <RenameInput
                                    value={name}
                                    onChange={setName}
                                    onRename={renameSearch}
                                    onClear={clearRename}
                                />
                            )}
                            <Row className="mt-2">
                                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                    <PaginationTotalStandalone {...paginationProps} />
                                </div>
                                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                    <PaginationListStandalone {...paginationProps} />
                                </div>
                            </Row>
                        </>
                    )}
                </PaginationProvider>
            </ModalBody>
        </Modal>
    );
};
