import React, { useEffect, useState } from 'react';
import { Card, Row } from 'reactstrap';
import { capitalProjectService } from 'services';
import { ProjectReviewTabSwitch, ProjectReviewWizardNav } from './components';
import { FormEnum, TabEnum, ProjectHeaderCard, getKeyByValue } from 'shared';

export const ProjectReviewWizardForm = ({ projectId, stepNumber }) => {
    const [capitalProject, setCapitalProject] = useState(null);
    const [step, setStep] = useState(stepNumber);
    const nextStep = () => {
        const next = step === FormEnum.SUBMIT ? FormEnum.SUBMIT : step + 1;
        setStep(next);
        const tabEnumName = getKeyByValue(FormEnum, next);
        window.location.href = TabEnum[tabEnumName];
    };
    const prevStep = () => {
        const prev = step === FormEnum.GENERALINFO ? FormEnum.GENERALINFO : step - 1;
        setStep(prev);
        const tabEnumName = getKeyByValue(FormEnum, prev);
        window.location.href = TabEnum[tabEnumName];
    };
    const HeaderCardModel = () => {
        return {
            cardTitle: capitalProject.projectName,
            institution: capitalProject.institutionName,
            biennium: capitalProject.biennium,
            budgetAgencyProjectNo: capitalProject.budgetAgencyProjectNo,
            institutionalPriority: capitalProject.priority,
            lastUpdated: capitalProject.lastUpdated,
        };
    };

    useEffect(() => {
        const parseQueryParams = async () => {
            if (projectId) {
                const recievedCapitalProject = await capitalProjectService.getCapitalProjectWithId(
                    projectId
                );
                setCapitalProject(recievedCapitalProject);
            }
        };
        parseQueryParams();
    }, [projectId]);
    if (capitalProject) {
        return (
            <Row>
                <div className="col-12">
                    <ProjectHeaderCard {...HeaderCardModel()} />
                    <Card>
                        <div className="wizard-wrap">
                            <ProjectReviewWizardNav
                                step={step}
                                setStep={setStep}
                                capitalProject={capitalProject}
                            />
                            <div className="wizard-content">
                                <ProjectReviewTabSwitch
                                    capitalProject={capitalProject}
                                    setCapitalProject={setCapitalProject}
                                    step={step}
                                    setStep={setStep}
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </Row>
        );
    } else {
        return null;
    }
};
