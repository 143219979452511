import React, { useState, useEffect } from 'react';
import { academicInstitutionsService } from 'services';
import { AcademicAffairSystem } from '../../../components/academic-affair-institutions';

export const AcademicSystemPage = props => {
    const [academicSystemsData, setAcademicSystemsData] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedSystemID = sessionStorage.getItem('systemID');
        if (query) {
            for (let param of query) {
                if (
                    param.UnitId &&
                    (!persistedSystemID || param.UnitId !== persistedSystemID)
                ) {
                    window.sessionStorage.setItem('systemID', param.UnitId);
                    return param.UnitId;
                } else {
                    return null;
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            parseQueryParams();
            const persistedSystemID = sessionStorage.getItem('systemID');
            const data = await academicInstitutionsService.getSystemInformation(persistedSystemID);
            setAcademicSystemsData(data || []);
            // default them all with an active status
        } catch (error) {console.log(error)}
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <AcademicAffairSystem
            academicSystemsData={academicSystemsData}
            setAcademicSystemsData={setAcademicSystemsData}
            fetchData={fetchData}
        />
    );
};
