import React, { useState, useEffect } from 'react';
import { BudgetRunTabEnum, BudgetRunFormEnum } from 'shared';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { PopOver, CapitalProjectErrorEnum } from 'shared';

export const BudgetRunSingleNavTab = ({
    name,
    toggleClassName,
    step,
    activeStepEnum,
    tabIdName,
    validationErrors,
}) => {
    const [valErrors, setValErrors] = useState({});
    useEffect(() => {
        const getValidationErrors = () => {
            let valErrors = {};
            if (validationErrors) {
                validationErrors.map(validationError => {
                    const filteredMessageTextToTab = Object.filter(
                        CapitalProjectErrorEnum,
                        ([name, tab]) => tab.includes(validationError.capitalProjectField)
                    );
                    const tabEnumName = Object.keys(filteredMessageTextToTab)[0];
                    valErrors[tabEnumName]
                        ? valErrors[tabEnumName].push(validationError.messageText)
                        : (valErrors[tabEnumName] = [validationError.messageText]);
                    return valErrors;
                });
            }
            return valErrors;
        };
        setValErrors(getValidationErrors());
    }, [validationErrors]);
    const className = () => {
        const activeStep =
            step === BudgetRunFormEnum[activeStepEnum]
                ? 'nav-link active'
                : 'nav-link cursor-pointer';
        const isRed = valErrors[activeStepEnum] ? 'text-danger' : '';
        return activeStep + ' ' + isRed;
    };
    return (
        <li className="nav-item">
            <a
                id={`${tabIdName}-tab`}
                data-toggle="tab"
                role="tab"
                aria-controls={tabIdName}
                aria-selected="false"
                className={className()}
                onClick={() => {
                    toggleClassName(BudgetRunFormEnum[activeStepEnum]);
                }}
                href={BudgetRunTabEnum[activeStepEnum]}
            >
                {valErrors[activeStepEnum] ? (
                    <PopOver
                        icon={faExclamationCircle}
                        id={BudgetRunFormEnum[activeStepEnum]}
                        text={valErrors[activeStepEnum]}
                    />
                ) : null}{' '}
                {name}
            </a>
        </li>
    );
};
