import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userService } from 'services';
import { useErrorState } from 'shared';
import { VerifyCodeForm } from 'components/login';

export const VerifyCodePage = props => {
    const { handleError, setError } = useErrorState();
    const { phoneNumber, email, password, ...rest } = props?.location?.state;
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const resendCode = async () => {
        setLoading(true);
        try {
            if (phoneNumber) {
                await userService.submitPhone({ phoneNumber });
            } else {
                // resend the login info
                await userService.login(email, password);
            }
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };
    const verifyCode = async payload => {
        setLoading(true);
        try {
            const res = await userService.verifyPhone(payload);
            if (res.isExpired) {
                await userService.forgotPassword(email);
                setError({
                    message:
                        'Your password has expired. You must create a new password before you can log back in. Please follow the email we just sent you to set your new password.',
                });
            } else {
                const user = { ...rest, ...res, emailAddress: email };
                dispatch({
                    type: 'SET_USER',
                    user,
                });
                const roles = await userService.getRole();
                dispatch({
                    type: 'SET_USER',
                    user: { ...user, roles },
                });
                history.replace('/');
            }
        } catch (error) {

            if(error.response && error.response.status == 500) {
                error.message = "Too many attempts at 2-Factor Authentication. Please try again in 10 minutes";
                if(error.response.data) {
                    error.response.data.message = "Too many attempts at 2-Factor Authentication. Please try again in 10 minutes";
                }
            }
            
            handleError(error);
        }
        setLoading(false);
    };
    return (
        <div className="login-wrap">
            <div className="login-card">
                <div className="logo">
                    <img src="/logo-chedss.png" alt="CHEDSS" />
                    <h2 className="text-center my-5">Data Submission System (CHEDSS)</h2>
                    <VerifyCodeForm
                        loading={loading}
                        onSubmit={verifyCode}
                        resendCode={resendCode}
                    />
                </div>
            </div>
        </div>
    );
};
