import React, { useState, useEffect, useReducer } from 'react';
import { Table, Input, Button } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import { LineItemsBulkUpdateModal } from './line-items-bulk-update-modal';

export const LineItems = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    // const [lineItemBySchoolMap, setLineItemBySchoolMap] = useState(new Map());
    const [generalFundMap, setGeneralFundMap] = useState(new Map());
    const [stateFundMap, setStateFundMap] = useState(new Map());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 6,
            lineItems: budgetRun.lineItems ? budgetRun.lineItems : '',
        }
    );
    const currentBiennium = budgetRun.bienniumId;
    const bienniumYearOne = budgetRun.budgetYearOne;
    const bienniumYearTwo = budgetRun.budgetYearTwo;
    const [isGeneralFundDisplayed, setIsGeneralFundDisplayed] = useState(true);

    const sanitizeBudgetRun = () => {
        budgetRun.lineItems = userInput.lineItems;
        return budgetRun;
    };

    const mapToUse = isGeneralFundDisplayed ? generalFundMap : stateFundMap;

    const handleTableChange = (evt, lineItemID, field) => {
        const headerValue = userInput.lineItems.find(me => lineItemID === me.lineItemID);
        headerValue[field] = +evt.target.value ?? 0;
        setUserInput({ lineItems: [...userInput.lineItems] });
    };

    useEffect(() => {
        const newGeneralFundMap = new Map();
        const newStateFundMap = new Map();
        userInput.lineItems.forEach(lineItem => {
            //const mapToUse = lineItem.isGeneral ? newGeneralFundMap : newStateFundMap;

            if (lineItem.isGeneral) {
                if (newGeneralFundMap.has(lineItem.displayName)) {
                    const newValue = newGeneralFundMap.get(lineItem.displayName);

                    newValue.push(lineItem);
                    newGeneralFundMap.set(lineItem.displayName, newValue);
                } else {
                    newGeneralFundMap.set(lineItem.displayName, [lineItem]);
                }
            }
            if (lineItem.isStateDedicated) {
                if (newStateFundMap.has(lineItem.displayName)) {
                    const newValue = newStateFundMap.get(lineItem.displayName);

                    newValue.push(lineItem);
                    newStateFundMap.set(lineItem.displayName, newValue);
                } else {
                    newStateFundMap.set(lineItem.displayName, [lineItem]);
                }
            }
        });
        setGeneralFundMap(newGeneralFundMap);
        setStateFundMap(newStateFundMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetRun]);

    const LineItemTableBody = () => {
        return mapToUse ? (
            [...mapToUse.keys()].map(schoolNameKey => {
                const arrayOfLineItems = mapToUse.get(schoolNameKey);
                return (
                    <React.Fragment key={schoolNameKey}>
                        <tr key={schoolNameKey}>
                            <td colSpan="10" className="text-strong cell-light">
                                <h4 className="my-2">{schoolNameKey}</h4>
                            </td>
                        </tr>
                        {arrayOfLineItems.map(lineItem => {
                            const generalY1Increase = (
                                ((lineItem.bY1RequestedStateFunding -
                                    lineItem.baseFunding) /
                                    lineItem.baseFunding) *
                                100
                            ).toFixed(2);
                            const stateY1Increase = (
                                ((lineItem.dedicatedBY1RequestedStateFunding -
                                    lineItem.baseFunding) /
                                    lineItem.baseFunding) *
                                100
                            ).toFixed(2);
                            const generalY2Increase = (
                                ((lineItem.bY2RequestedStateFunding -
                                    lineItem.baseFunding) /
                                    lineItem.baseFunding) *
                                100
                            ).toFixed(2);
                            const stateY2Increase = (
                                ((lineItem.dedicatedBY2RequestedStateFunding -
                                    lineItem.baseFunding) /
                                    lineItem.baseFunding) *
                                100
                            ).toFixed(2);
                            return (
                                <tr key={lineItem.lineItemID}>
                                    <td>
                                        <a href="line-item-budget.html" target="_blank">
                                            <i className="far fa-external-link"></i>{' '}
                                            {lineItem.lineItemName}
                                        </a>
                                    </td>
                                    <td className="text-right">
                                        ${lineItem.baseFunding.toLocaleString()}
                                    </td>
                                    <td className="text-right">
                                        $
                                        {isGeneralFundDisplayed
                                            ? lineItem.bY1RequestedStateFunding.toLocaleString()
                                            : lineItem.dedicatedBY1RequestedStateFunding.toLocaleString()}
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    $
                                                </span>
                                            </div>
                                            {/* value = {{lineItem.bY1ReccomendedStateFunding}} */}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    isGeneralFundDisplayed
                                                        ? lineItem.bY1ReccomendedStateFunding
                                                        : lineItem.dedicatedBY1ReccomendedStateFunding
                                                }
                                                onChange={e =>
                                                    handleTableChange(
                                                        e,
                                                        lineItem.lineItemID,
                                                        isGeneralFundDisplayed
                                                            ? 'bY1ReccomendedStateFunding'
                                                            : 'dedicatedBY1ReccomendedStateFunding'
                                                    )
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        {isGeneralFundDisplayed
                                            ? generalY1Increase
                                            : stateY1Increase}
                                        %
                                    </td>
                                    <td className="text-right">
                                        $
                                        {isGeneralFundDisplayed
                                            ? lineItem.bY2RequestedStateFunding.toLocaleString()
                                            : lineItem.dedicatedBY2RequestedStateFunding.toLocaleString()}
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                {/* value={lineItem.bY2ReccomnededStateFunding} */}
                                                <span className="input-group-text">
                                                    $
                                                </span>
                                            </div>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    isGeneralFundDisplayed
                                                        ? lineItem.bY2ReccomnededStateFunding
                                                        : lineItem.dedicatedBY2ReccomnededStateFunding
                                                }
                                                onChange={e =>
                                                    handleTableChange(
                                                        e,
                                                        lineItem.lineItemID,
                                                        isGeneralFundDisplayed
                                                            ? 'bY2ReccomnededStateFunding'
                                                            : 'dedicatedBY2ReccomnededStateFunding'
                                                    )
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        {isGeneralFundDisplayed
                                            ? generalY2Increase
                                            : stateY2Increase}
                                        %
                                    </td>
                                </tr>
                            );
                        })}

                        <SchoolLineItemTotal
                            schoolNameKey={schoolNameKey}
                            mapToUse={mapToUse}
                        />
                    </React.Fragment>
                );
            })
        ) : (
            <tr key={'null'}></tr>
        );
    };

    const SchoolLineItemTotal = ({ schoolNameKey, mapToUse }) => {
        const arrayOfLineItems = mapToUse.get(schoolNameKey);
        const totalArray = arrayOfLineItems.reduce(
            (acc, curr) => {
                if (isGeneralFundDisplayed) {
                    acc[0] += curr.baseFunding;
                    acc[1] += curr.bY1RequestedStateFunding;
                    acc[2] += curr.bY1ReccomendedStateFunding;
                    acc[3] += 0;
                    acc[4] += curr.bY2RequestedStateFunding;
                    acc[5] += curr.bY2ReccomnededStateFunding;
                    acc[6] += 0;
                    return acc;
                } else {
                    acc[0] += curr.baseFunding;
                    acc[1] += curr.dedicatedBY1RequestedStateFunding;
                    acc[2] += curr.dedicatedBY1ReccomendedStateFunding;
                    acc[3] += 0;
                    acc[4] += curr.dedicatedBY2RequestedStateFunding;
                    acc[5] += curr.dedicatedBY2ReccomnededStateFunding;
                    acc[6] += 0;
                    return acc;
                }
            },
            [0, 0, 0, 0, 0, 0, 0]
        );
        return (
            <tr key={'total' + schoolNameKey}>
                <td>
                    <label className="m-0">{schoolNameKey} Total</label>
                </td>
                <td className="text-right">${totalArray[0].toLocaleString()}</td>
                <td className="text-right">${totalArray[1].toLocaleString()}</td>
                <td className="text-right">${totalArray[2].toLocaleString()}</td>
                <td className="text-right">{totalArray[3]}%</td>
                <td className="text-right">${totalArray[4].toLocaleString()}</td>
                <td className="text-right">${totalArray[5].toLocaleString()}</td>
                <td className="text-right">{totalArray[6]}%</td>
            </tr>
        );
    };

    const LineItemTotal = () => {
        const totalArray = [...mapToUse.keys()].reduce(
            (accMap, currMap) => {
                const arrayOfLineItems = mapToUse.get(currMap);
                const categoryTotal = arrayOfLineItems.reduce(
                    (accArr, curr) => {
                        if (isGeneralFundDisplayed) {
                            accArr[0] += curr.baseFunding;
                            accArr[1] += curr.bY1RequestedStateFunding;
                            accArr[2] += curr.bY1ReccomendedStateFunding;
                            accArr[3] += 0;
                            accArr[4] += curr.bY2RequestedStateFunding;
                            accArr[5] += curr.bY2ReccomnededStateFunding;
                            accArr[6] += 0;
                            return accArr;
                        } else {
                            accArr[0] += curr.baseFunding;
                            accArr[1] += curr.dedicatedBY1RequestedStateFunding;
                            accArr[2] += curr.dedicatedBY1ReccomendedStateFunding;
                            accArr[3] += 0;
                            accArr[4] += curr.dedicatedBY2RequestedStateFunding;
                            accArr[5] += curr.dedicatedBY2ReccomnededStateFunding;
                            accArr[6] += 0;
                            return accArr;
                        }
                    },
                    [0, 0, 0, 0, 0, 0]
                );

                accMap[0] += categoryTotal[0];
                accMap[1] += categoryTotal[1];
                accMap[2] += categoryTotal[2];
                accMap[3] += categoryTotal[3];
                accMap[4] += categoryTotal[4];
                accMap[5] += categoryTotal[5];
                accMap[6] += categoryTotal[6];
                return accMap;
            },
            [0, 0, 0, 0, 0, 0]
        );
        return (
            <tr className="cell-light">
                <td>
                    <label className="m-0">
                        <h4 className="my-2">Grand Total</h4>
                    </label>
                </td>
                <td className="text-right">${totalArray[0].toLocaleString()}</td>
                <td className="text-right">${totalArray[1].toLocaleString()}</td>
                <td className="text-right">${totalArray[2].toLocaleString()}</td>
                <td className="text-right">{totalArray[3]}%</td>
                <td className="text-right">${totalArray[4].toLocaleString()}</td>
                <td className="text-right">${totalArray[5].toLocaleString()}</td>
                <td className="text-right">0%</td>
                {/* <td className="text-right">{totalArray[6]}%</td> */}
            </tr>
        );
    };

    return (
        <React.Fragment>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-2">
                Line Items
            </h2>

            <ul className="nav nav-pills nav-pills-large border-bottom mb-4">
                <li className="nav-item">
                    <div
                        className={
                            isGeneralFundDisplayed
                                ? 'nav-link  active'
                                : 'nav-link cursor-pointer'
                        }
                        role="tab"
                        onClick={() => {
                            setIsGeneralFundDisplayed(true);
                        }}
                    >
                        General Fund
                    </div>
                </li>
                <li className="nav-item">
                    <div
                        className={
                            isGeneralFundDisplayed
                                ? 'nav-link cursor-pointer'
                                : 'nav-link active'
                        }
                        role="tab"
                        onClick={() => {
                            setIsGeneralFundDisplayed(false);
                        }}
                    >
                        State Dedicated
                    </div>
                </li>
            </ul>
            <Button className="btn btn-outline-primary" onClick={toggle}>
                Bulk Update
            </Button>
            <div className="table-responsive">
                <Table className="table table-striped table-bordered table-hover align-middle table-sm my-4">
                    <thead>
                        <tr key={'head'}>
                            <th></th>
                            <th className="text-center">{currentBiennium} Base</th>
                            <th className="text-center">
                                {bienniumYearOne} Requested Funds
                            </th>
                            <th className="text-center">
                                {bienniumYearOne} Recommended Funding
                            </th>
                            <th className="text-center">{bienniumYearOne} % Inc</th>
                            <th className="text-center">
                                {bienniumYearTwo} Requested Funds
                            </th>
                            <th className="text-center">
                                {bienniumYearTwo} Recommended Funding
                            </th>
                            <th className="text-center">{bienniumYearTwo} % Inc</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LineItemTableBody()}
                        {LineItemTotal()}
                        {/* <LineItemTableBody />
                        <LineItemTotal /> */}
                    </tbody>
                </Table>
            </div>
            <LineItemsBulkUpdateModal
                isOpen={isModalOpen}
                toggle={toggle}
                lineItems={budgetRun.lineItems}
                setLineItems={setUserInput}
                bienniumYearOne={bienniumYearOne}
                bienniumYearTwo={bienniumYearTwo}
                isGeneralFundDisplayed={isGeneralFundDisplayed}
            />

            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={false}
                setBudgetRun={setBudgetRun}
                sanitizeBudgetRun={sanitizeBudgetRun}
            />
        </React.Fragment>
    );
};
