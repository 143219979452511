import React, { useState, useEffect } from 'react';
import { AcademicAffairProgramView } from '../../../components';
import { academicProgramsService } from '../../../services';

export const AcademicProgramPageView = props => {
    const [academicAffairsData, setAcademicAffairsData] = useState([]);
    const [degreeSelectOptions, setDegreeSelectOptions] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedProgramID = sessionStorage.getItem('programID');

        if (query) {
            for (let param of query) {
                if (
                    param.programID &&
                    (!persistedProgramID || param.programID !== persistedProgramID)
                ) {
                    window.sessionStorage.setItem('programID', param.programID);
                    return param.programID;
                } else {
                    return null;
                }
            }
        }
    };
    parseQueryParams();
    const persistedProgramID = sessionStorage.getItem('programID');

    useEffect(() => {
        const res = academicProgramsService.getAcademicProgram(persistedProgramID);
        Promise.resolve(res).then(data => {
            setAcademicAffairsData(data ?? []);
        });
        academicProgramsService.getDegreeLevels().then(res => {
            setDegreeSelectOptions(res);
        });
    }, [persistedProgramID]);

    return (
        <AcademicAffairProgramView
            programID={persistedProgramID}
            academicAffairsData={academicAffairsData}
            setAcademicAffairsData={setAcademicAffairsData}
            degreeSelectOptions={degreeSelectOptions}
        />
    );
};
