import React, { useState, useEffect } from 'react';
import { Card, Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { UrlEnum } from 'shared';
import { budgetRunService } from 'services';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

export const BudgetRunSearchTable = ({ budgetRun, searchObject }) => {
    const statusMap = new Map();
    statusMap.set(1, 'Has Not Been Run');
    statusMap.set(3, 'Run Complete');
    statusMap.set(4, 'Finalized');
    const [statusTableDataMap, setStatusTableDataMap] = useState(new Map());
    const [activeTab, setActiveTab] = useState('Has Not Been Run');
    const history = useHistory();

    const getStatusTableData = async () => {
        [...statusMap.keys()].forEach(async statusNumber => {
            const statusString = statusMap.get(statusNumber);
            const singleStatusSearchObject = {
                ...searchObject,
                status: statusNumber,
            };
            const singleStatusTableData = await budgetRunService.searchBudgetRuns(
                singleStatusSearchObject
            );
            if (statusNumber === 0) {
                setActiveTab(statusString);
            }
            setStatusTableDataMap(
                new Map(statusTableDataMap.set(statusString, singleStatusTableData ?? []))
            );
        });
    };

    useEffect(() => {
        getStatusTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchObject]);

    const rowEvents = {
        // onClick: (e, row, rowIndex) => {
        //     const budgetRunID = row.budgetRunID;
        //     const location = {
        //         pathname: UrlEnum.BUDGETRUN,
        //         state: [{ budgetRunId: budgetRunID }], //can change back to 557 for testing purposes
        //     };
        //     history.push(location);
        // },
    };
    const actions = (cell, row, rowIndex, formatExtraData) => {
        //far fa-copy
        return (
            <div className="table-actions">
                {activeTab === 'Finalized' ? (
                    <Button
                        className="action-circle"
                        onClick={() =>
                            budgetRunService.downloadBudgetRun('excel', row.budgetRunID)
                        }
                    >
                        <UncontrolledPopover
                            target={'PopoverXLS-' + row.budgetRunID}
                            trigger="hover"
                            placement="bottom"
                        >
                            <PopoverBody>XLS</PopoverBody>
                        </UncontrolledPopover>
                        <FontAwesomeIcon
                            id={'PopoverXLS-' + row.budgetRunID}
                            icon={faCopy}
                        />
                    </Button>
                ) : null}

                <Button
                    className="action-circle"
                    onClick={() => {
                        const budgetRunID = row.budgetRunID;

                        const location = {
                            pathname: UrlEnum.BUDGETRUN,
                            state: [{ budgetRunId: budgetRunID }],
                        };

                        history.push(location);
                    }}
                >
                    <UncontrolledPopover
                        target={'PopoverView-' + row.budgetRunID}
                        trigger="hover"
                        placement="bottom"
                    >
                        <PopoverBody>view</PopoverBody>
                    </UncontrolledPopover>
                    <FontAwesomeIcon
                        id={'PopoverView-' + row.budgetRunID}
                        icon={faSearchPlus}
                    />
                </Button>
            </div>
        );
    };
    const columns = [
        {
            dataField: 'bienniumDescription',
            text: 'Biennium',
            sort: true,
            headerStyle: getWidthStyle(140),
        },
        {
            dataField: 'budgetRunTitle',
            text: 'Title',
            sort: true,
            headerStyle: { minWidth: 250 },
        },
        {
            dataField: 'lastUpdated',
            text: 'Last Updated/Run',
            sort: true,
            headerStyle: getWidthStyle(140),
        },
        {
            dataField: 'owner',
            text: 'Owned By',
            sort: true,
            headerStyle: getWidthStyle(200),
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: actions,
            headerStyle: getWidthStyle(80),
        },
        // {
        // 	dataField: 'action',
        // 	text: <FontAwesomeIcon icon={faSearchPlus} />,
        // 	isDummyField: true,
        // },
    ];
    const options = {
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        sizePerPage: 10,
        hideSizePerPage: true,
    };

    const TableNavigator = () => {
        return (
            <Card className="mb-2">
                <div className="d-md-flex justify-content-between align-items-center text-center text-md-left">
                    <ul className="nav nav-pills nav-pills-large border-bottom">
                        {[...statusTableDataMap.keys()].map(statusString => {
                            const singleTableData = statusTableDataMap.get(statusString);

                            return (
                                <li key={statusString} className="nav-item">
                                    <div
                                        className={
                                            activeTab === statusString
                                                ? 'nav-link active'
                                                : 'nav-link cursor-pointer'
                                        }
                                        data-toggle="list"
                                        role="tab"
                                        onClick={() => {
                                            setActiveTab(statusString);
                                        }}
                                    >
                                        {statusString}{' '}
                                        <span className="text-muted">
                                            ({singleTableData.count})
                                        </span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Card>
        );
    };

    const StatusTable = () => {
        if (statusTableDataMap) {
            return [...statusTableDataMap.keys()].map(key => {
                const value = statusTableDataMap.get(key);
                if (key === activeTab) {
                    return value.count > 0 ? (
                        <BootstrapTable
                            key={key}
                            keyField="budgetRunID"
                            data={value.budgetRuns.map(bRun => {
                                const newLastUpdateDate = new Date(bRun.lastUpdated);
                                bRun.lastUpdated = newLastUpdateDate.toLocaleDateString();
                                return bRun;
                            })}
                            columns={columns}
                            striped
                            hover
                            pagination={paginationFactory(options)}
                            rowEvents={rowEvents}
                            // defaultSorted={defaultSorted}
                        />
                    ) : (
                        <div key={key}>No Schedules Found</div>
                    );
                } else {
                    return null;
                }
            });
        } else {
            return <div>No Schedules Found!</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="p-3 d-md-flex justify-content-between align-items-center text-center text-md-left">
                <div className="mb-3 mb-md-0">
                    <h2>Budget Runs</h2>
                </div>
            </div>
            <TableNavigator />
            <Card>
                <div className="table-responsive">
                    <StatusTable key={'table' + activeTab} />
                </div>
            </Card>
        </React.Fragment>
    );
};
