import React from 'react';

export const CheckboxGroup = React.memo(props => {
    const { onChange, name, options, value } = props;
    return options.map(o => {
        const { label, ...rest } = o;
        const checked = value.includes(o.value);
        return (
            <div key={label} className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={label}
                    value={checked}
                    checked={checked}
                    onChange={() => {
                        onChange({
                            [name]: checked
                                ? value.filter(d => d !== o.value)
                                : value.concat(o.value),
                        });
                    }}
                    {...rest}
                />
                <label className="form-check-label" for={label}>
                    {label}
                </label>
            </div>
        );
    });
});
