import { handleServiceRequest } from '../handle-service-request';
import { authHeader } from 'helpers/auth-header';

const getUsers = query => {
    const getObject = {
        url: `/api/account/users?${query}`,
    };
    return handleServiceRequest.get(getObject);
};

const getRoles = () => {
    const getObject = {
        url: '/api/account/roles',
    };
    return handleServiceRequest.get(getObject);
};

const getInstitutions = () => {
    const getObject = {
        url: '/api/account/institutions',
    };
    return handleServiceRequest.get(getObject);
};

const createUser = payload => {
    const postObject = {
        url: '/api/account/CreateUser',
        postBody: payload,
    };
    return handleServiceRequest.post(postObject);
};

const getUser = id => {
    const getObject = {
        url: `/api/account/users/${id}`,
        headers: { ...authHeader(true) },
    };
    return handleServiceRequest.get(getObject);
};

const updateUser = (id, payload) => {
    const postObject = {
        url: `/api/account/user/${id}`,
        headers: { ...authHeader(true) },
        postBody: payload,
    };
    return handleServiceRequest.put(postObject);
};

const sendPasswordReset = id => {
    const postObject = {
        url: `/api/account/sendPasswordReset/${id}`,
    };
    return handleServiceRequest.post(postObject);
};

const impersonateUser = id => {
    const getObject = {
        url: `/api/account/Impersonate/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const clearImpersonation = () => {
    const getObject = {
        url: `/api/account/ClearImpersonation`,
    };
    return handleServiceRequest.get(getObject);
};

export const userManagementService = {
    getUsers,
    getRoles,
    getInstitutions,
    createUser,
    updateUser,
    getUser,
    sendPasswordReset,
    impersonateUser,
    clearImpersonation,
};
