import React, { useReducer } from 'react';
import { Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { onChangeFunction } from 'shared';
import { Button } from 'components/blocks';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_STATE = {
    email: '',
    password: '',
};

export const LoginForm = props => {
    const { loading, onSubmit, setReset } = props;
    const [form, setForm] = useReducer(reducer, INITIAL_STATE);
    const { email, password } = form;
    const submitHandler = async e => {
        e.preventDefault();
        onSubmit(form);
    };
    return (
        <Form onSubmit={loading ? null : submitHandler}>
            <Input
                type="email"
                name="email"
                className="form-control form-control-lg mb-3"
                style={{ background: '#FFF' }}
                placeholder="Email Address"
                value={email}
                onChange={evt => onChangeFunction.handleChange(evt, setForm)}
            />
            <Input
                type="password"
                name="password"
                className="form-control form-control-lg mb-4"
                style={{ background: '#FFF' }}
                placeholder="Password"
                value={password}
                onChange={evt => onChangeFunction.handleChange(evt, setForm)}
            />
            <Button
                type="submit"
                color="primary"
                className="btn-lg btn-block btn-round"
                loading={loading}
                disabled={email.length === 0 || password.length === 0}
            >
                Login <FontAwesomeIcon icon={faArrowRight} />
            </Button>
            <div className="mt-5 text-center">
                <div
                    className="body-text-link cursor-pointer"
                    onClick={() => setReset(true)}
                >
                    Forgot your password?
                </div>
            </div>
        </Form>
    );
};
