import { useEffect, useMemo } from 'react';
import _uniq from 'lodash/uniq';
import { useSelector, useDispatch } from 'react-redux';

import { partitionToggleService } from 'services';
import { usePermissions } from './usePermissions';

// these are the api blocked views. We enable them by default and override with what the api returns.
// Notably Data Submission is absent, because that page requires the "submit-data" permission to see
const LOCKED_VIEWS = ['Academic Affairs', 'Finance'];
// permissions and their associated views
const VIEW_PERMISSIONS = {
    'administer-che-all-institution-users': 'Manage Users',
    'administer-institution-academic-affairs-users': 'Manage Users',
    'administer-institution-data-submission-users': 'Manage Users',
    'administer-institution-finance-users': 'Manage Users',
    'browse-programs': 'Academic Affairs/Programs',
    'configure-and-generate-budget-run': 'Finance/Budget Runs',
    'perform-record-linking': 'Record Linking',
    'review-and-edit-budget-and-performance-schedules': 'Finance/Budget Schedules',
    'submit-data': 'Data Submission',
    'view-capital-projects': 'Finance/Capital Projects',
};

export const useDynamicViews = () => {
    const blockedViews = useSelector(state => state.blockedViews);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const permissions = usePermissions({ views: true });
    const fetchBlockedViews = async () => {
        try {
            //we need to get the views that are blocked via the partition toggle tool
            const res = await partitionToggleService.getToggles();
            const hide = res.filter(r => !r.isEnabled).map(r => r.featureName);
            dispatch({ type: 'SET_BLOCKED_VIEWS', blockedViews: hide });
        } catch (error) {}
    };
    useEffect(() => {
        fetchBlockedViews();
    }, [user]);
    return useMemo(() => {
        const permViews = permissions.reduce((p, c) => {
            // here we compile all the views that are enabled via permissions
            if (VIEW_PERMISSIONS[c]) {
                return p.concat(VIEW_PERMISSIONS[c]);
            }
            return p;
        }, []);
        const unique = _uniq([...LOCKED_VIEWS, ...permViews]);
        // even if a user has a permission to see xyz view, if it is blocked via the partition toggle feature then it will be hidden from view
        const filtered = unique.filter(view => !blockedViews.includes(view));
        return filtered.sort();
    }, [blockedViews, permissions]);
};
