import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Label, Row, Col, Card, Table } from 'reactstrap';

export const CostDetail = ({ capitalProject, prevStep, nextStep }) => {
	const year = new Date().getFullYear();
	const years = Array.from(new Array(20), (val, index) => {
		return { val: index, label: year - index };
	});
	const months = [
		{ label: 'Month', val: 0 },
		{ label: 'January', val: 1 },
		{ label: 'February', val: 2 },
		{ label: 'March', val: 3 },
		{ label: 'April', val: 4 },
		{ label: 'May', val: 5 },
		{ label: 'June', val: 6 },
		{ label: 'July', val: 7 },
		{ label: 'August', val: 8 },
		{ label: 'September', val: 9 },
		{ label: 'October', val: 10 },
		{ label: 'November', val: 11 },
		{ label: 'December', val: 12 },
	];
	const getSelectValueFromYear = (yearLabel) => {
		const toReturn = years.find(
			(yearSelectVal) => yearSelectVal.label === yearLabel
		).label;
		return toReturn;
	};
	const getSelectValueFromMonth = (monthValue) => {
		const toReturn = months.find(
			(monthSelectVal) => monthSelectVal.val === monthValue
		).label;
		return toReturn;
	};
	const [costDetailTableCategories, setCostDetailTableCategories] = useState(
		[]
	);
	const [costDetailTableRows, setCostDetailTableRows] = useState([]);

	useEffect(() => {
		const transformArray = (costDetailRowCategories) => {
			return costDetailRowCategories.map((costDetialCategory) => {
				const { id } = costDetialCategory;
				const savedCostDetailValue = capitalProject.costDetails.filter(
					(costDetail) => +costDetail.costTypeId === +id
				)[0];
				return savedCostDetailValue
					? savedCostDetailValue
					: {
							costTypeId: +id,
							costBasis: '0',
							escalationFactors: '0',
							descriptionIfOther: '',
					  };
			});
		};

		const fetchColumns = async () => {
			const costDetailTableRows = await financeService.getCostDetailTableRows();
			const getCostDetailTableUniqueCategories = costDetailTableRows.reduce(
				(accumulator, currentValue) => {
					const accumulatorHasValue = accumulator.includes(
						currentValue.category
					);
					if (!accumulatorHasValue) {
						accumulator.push(currentValue.category);
					}
					return accumulator;
				},
				[costDetailTableRows[0].category]
			);
			setCostDetailTableCategories(getCostDetailTableUniqueCategories);
			setCostDetailTableRows(costDetailTableRows);
			capitalProject.costDetails = transformArray(costDetailTableRows);
			return;
		};

		fetchColumns();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderIndividualCostRow = (categoryObject) => {
		const getCostDetailArray = capitalProject.costDetails.filter(
			(costDetail) => +costDetail.costTypeId === +categoryObject.id
		);
		return !getCostDetailArray
			? null
			: getCostDetailArray.map((costObject) => {
					const index = capitalProject.costDetails.findIndex(
						(costDetail) => costDetail === costObject
					);
					const costDetailToChange = capitalProject.costDetails[index];
					return (
						<tr key={categoryObject.id}>
							<td>
								<label className='m-0'>{categoryObject.text}</label>
							</td>

							<td>${costDetailToChange.costBasis}</td>
							<td>${costDetailToChange.escalationFactors}</td>
							<td className='text-strong text-right'>
								$
								{+costDetailToChange.costBasis +
									+costDetailToChange.escalationFactors}
							</td>
						</tr>
					);
			  });
	};

	const renderCostsTable = () => {
		return costDetailTableCategories === [] ? (
			<div></div>
		) : (
			costDetailTableCategories.map((category, index) => {
				const filteredTableRow = costDetailTableRows.filter(
					(row) => row.category === category
				);
				const rows = filteredTableRow.map((categoryObject) => {
					return renderIndividualCostRow(categoryObject, index);
				});
				return (
					<React.Fragment key={index}>
						<tr>
							<td className='text-strong text-primary cell-light' colSpan='4'>
								{category}
							</td>
						</tr>
						{rows}
					</React.Fragment>
				);
			})
		);
	};
	return (
		<React.Fragment>
			<h2 className='text-strong text-primary border-bottom pb-2 mb-4'>
				Cost Detail
			</h2>
			<Card className='no-shadow border p-3 mb-3'>
				<h4 className='mb-3 text-primary'>Anticipated Construction Schedule</h4>
				<Row>
					<Col className='col-md mb-3 mb-md-0'>
						<label>
							Bid Date <sup className='text-danger'>*</sup>
						</label>
						<div className='d-flex'>
							<p>
								{getSelectValueFromMonth(capitalProject.bidMonth)}{' '}
								{getSelectValueFromYear(capitalProject.bidYear)}
							</p>
						</div>
					</Col>
					<Col className='col-md mb-3 mb-md-0'>
						<Label tag='h4' id='formTitle'>
							Start Construction <sup className='text-danger'>*</sup>
						</Label>
						<div className='d-flex'>
							<p>
								{getSelectValueFromMonth(capitalProject.constructionMonth)}{' '}
								{getSelectValueFromYear(capitalProject.constructionYear)}
							</p>
						</div>
					</Col>
					<Col className='col-md mb-3 mb-md-0'>
						<Label tag='h4' id='formTitle'>
							Occupancy (End Date) <sup className='text-danger'>*</sup>
						</Label>
						<div className='d-flex'>
							<p>
								{getSelectValueFromMonth(capitalProject.occupancyMonth)}{' '}
								{getSelectValueFromYear(capitalProject.occupancyYear)}
							</p>
						</div>
					</Col>
				</Row>
			</Card>
			<Card className='no-shadow border p-3 mb-3'>
				<h4 className='mb-3 text-primary'>Estimated Cost for Project</h4>
				<Table
					key='largeCostDetailTable'
					className='table table-striped table-bordered table-hover align-middle table-sm'
					responsive
					borderless>
					<thead>
						<tr>
							<th className='min-tablet-l no-sort'></th>
							<th className='min-tablet-l no-sort'>
								Cost Basis{' '}
								<a
									href='/#'
									data-toggle='popover'
									data-position='top'
									data-content='State the month and year the current Cost Basis was calculated in the Cost Detail Notes.'>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className='fa-lg ml-1'
									/>
								</a>
							</th>
							<th className='min-tablet-l no-sort'>
								Escalation Factors{' '}
								<a
									href='/#'
									data-toggle='popover'
									data-position='top'
									data-content='Explain in the Cost Detail Notes the reasoning for estimated escalation factors.'>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className='fa-lg ml-1'
									/>
								</a>
							</th>
							<th className='min-tablet-l no-sort text-right'>Project Cost</th>
						</tr>
					</thead>
					<tbody>{renderCostsTable()}</tbody>
				</Table>
			</Card>
			<Card className='no-shadow border p-3 mb-3'>
				<h4 className='mb-3 text-primary'>Cost Detail Notes</h4>
				<p className='text-italic'>
					Please state the month and year the current Cost Basis was calculated.
					Additionally, explain the reasoning for the estimated escalation
					factors.
				</p>
				<hr />
				<label>Response:</label>
				<p>{capitalProject.costDetailNotes}</p>
			</Card>

			<WizardFooter
				prevStep={prevStep}
				nextStep={nextStep}
				capitalProject={capitalProject}
			/>
		</React.Fragment>
	);
};
