export const TabEnum = {
	GENERALINFO: '#general',
	COSTSIZEFUNDING: '#cost-size',
	DETAILDESCRIPTION: '#description',
	NEEDSANDPURPOSE: '#need',
	OTHERPROJECTINFO: '#other',
	SPACEDETAIL: '#space',
	COSTDETAIL: '#cost',
	ANNUALCOSTDETAIL: '#annual',
	SUBMIT: '#submit',
};
