import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { FormGroup, Input, Button, Card } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';
import { capitalProjectService, financeService } from 'services';
import { onChangeFunction, usePermissions } from 'shared';
import { CreateCapitalProjectModal } from './create-capital-project-modal';

export const SearchCapitalProjectsForm = ({ setCapitalProjects }) => {
    const submitPermission = usePermissions({
        permissions: ['submit-new-edit-capital-project'],
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            searchProjectName: '',
            bienniumSearchValue: [],
            institutionSearchValue: [],
        }
    );

    useEffect(() => {
        const getCapitalProjects = async () => {
            const searchPostData = {
                biennium: [],
                institutions: [],
                searchText: null,
                isSubmitted: false,
                isReview: false,
            };
            const res = await capitalProjectService.searchCapitalProjects(searchPostData);
            setCapitalProjects(res || []);
        };
        getCapitalProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [bienniums] = useState(financeService.getAllBienniums);
    const [institutions] = useState(financeService.getAllInstitutions);

    const extractValuesFromResponse = unextractedResponseValues => {
        return unextractedResponseValues?.reduce((acc, currentValue) => {
            return acc.concat(currentValue.value);
        }, []);
    };

    const submitForm = () => {
        const actualBienniumValue = extractValuesFromResponse(
            userInput.bienniumSearchValue || []
        );
        const actualinstitutionValue = extractValuesFromResponse(
            userInput.institutionSearchValue || []
        );

        const searchPostData = {
            biennium: actualBienniumValue.map(value => +value),
            institutions: actualinstitutionValue,
            searchText:
                userInput.searchProjectName === '' ? null : userInput.searchProjectName,
            isSubmitted: false,
            isReview: false,
        };

        capitalProjectService
            .searchCapitalProjects(searchPostData)
            .then(setCapitalProjects);
    };

    const loadBienniums = () => Promise.resolve(bienniums);
    const loadInstitutions = () => Promise.resolve(institutions);

    return (
        <div>
            {submitPermission && (
                <Button
                    className="btn btn-success btn-block new-btn mb-3"
                    onClick={() => setIsModalOpen(true)}
                >
                    <FontAwesomeIcon icon={faPlus} /> New Biennium Capital Project Request
                </Button>
            )}
            <Card className="card mb-3 p-3">
                <h4 className="text-muted mb-4">Search Capital Projects</h4>
                <FormGroup>
                    <label>Project</label>
                    <Input
                        type="text"
                        placeholder="Project Name/Title"
                        onChange={evt => onChangeFunction.handleChange(evt, setUserInput)}
                        name="searchProjectName"
                        value={userInput.searchProjectName}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <label>Bienniums</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        isMulti
                        isSearchable
                        placeholder="All"
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleAsyncSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        name="bienniumSearchValue"
                        value={userInput.bienniumSearchValue}
                        loadOptions={loadBienniums}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Institutions</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        isMulti
                        isSearchable
                        placeholder="All"
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleAsyncSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        name="institutionSearchValue"
                        value={userInput.institutionSearchValue}
                        loadOptions={loadInstitutions}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <button
                    className="btn btn-block btn-primary"
                    color="success"
                    onClick={() => {
                        submitForm();
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} /> Search
                </button>
            </Card>
            <CreateCapitalProjectModal isOpen={isModalOpen} toggle={toggle} />
        </div>
    );
};

SearchCapitalProjectsForm.propTypes = {
    setCapitalProjects: PropTypes.func.isRequired,
};
