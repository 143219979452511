import React, { useRef, useState } from 'react';
import Select from 'react-select';
import { onChangeFunction } from 'shared';

/*const customStyles = {
    groupHeading: provided => ({
        ...provided,
        color: '#000',
        fontWeight: 700,
        fontSize: '100%',
        paddingLeft: 6,
        paddingRight: 6,
        marginBottom: 6,
    }),
};*/

export const FormSelect = React.memo(props => {
    const {
        className,
        label,
        onChange,
        closeAfterSelection = true,
        isMulti = true,
        disabled = false,
        showLoading,
        isLoading,
        options = [],
        required,
        fieldClassName,
        error,
        ...rest
    } = props;

    const valueRef = useRef(props.value);
    const [inputValue, setInputValue] = useState('');
    valueRef.current = props.value;

    const selectAllOption = {
        value: 'Select All',
        label: 'Select All',
    };

    const isSelectAllSelected = () => {
        return valueRef.current ? valueRef.current.length === options : false;
    };

    const isOptionSelected = option => {
        if (valueRef.current) {
            return isMulti
                ? valueRef.current.some(value => value === option.value) ||
                      isSelectAllSelected()
                : valueRef.current === option.value;
        } else {
            return false;
        }
    };

    const getOptions = () => {
        options.sort(customSortOptions);
        if (isMulti) {
            return [selectAllOption, ...options];
        } else {
            return options;
        }
    };

    const getValue = () => {
        return isSelectAllSelected() ? [selectAllOption] : props.value;
    };

    const _onChange = (newValue, actionMeta) => {
        const { action, option, removedValue } = actionMeta;

        if (!option) {
            return onChangeFunction.handleSelectChange(
                newValue,
                { name: props.name },
                onChange
            );
        }

        if (action === 'select-option' && option.value === selectAllOption.value) {
            return onChangeFunction.handleSelectChange(
                options,
                { name: props.name },
                onChange
            );
        } else if (
            (action === 'deselect-option' && option.value === selectAllOption.value) ||
            (action === 'remove-value' && removedValue.value === selectAllOption.value)
        ) {
            return onChangeFunction.handleSelectChange(
                newValue,
                { name: props.name },
                onChange
            );
        } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
            return onChangeFunction.handleSelectChange(
                options.filter(({ value }) => value !== option.value),
                { name: props.name },
                onChange
            );
        } else {
            return onChangeFunction.handleSelectChange(
                newValue || [],
                { name: props.name },
                onChange
            );
        }
    };

    const customFilterOption = (option, inputValue) => {
        if (option == null || inputValue == null) return false;
        if (option.label == null || inputValue == null) return false;
        const startsWithMatch = option.label
            .toLowerCase()
            .startsWith(inputValue.toLowerCase());
        const containsMatch = option.label
            .toLowerCase()
            .includes(inputValue.toLowerCase());
        return containsMatch || startsWithMatch;
    };

    const customSortOptions = (a, b) => {
        if (a == null || b == null) return 1;
        if (a.label == null || b.label == null) return -1;
        // Sort options based on whether they start with the input value
        const aStartsWithInput = a.label
            .toLowerCase()
            .startsWith(inputValue.toLowerCase());
        const bStartsWithInput = b.label
            .toLowerCase()
            .startsWith(inputValue.toLowerCase());

        if (aStartsWithInput && !bStartsWithInput) {
            return -1; // a comes before b
        } else if (!aStartsWithInput && bStartsWithInput) {
            return 1; // b comes before a
        } else {
            // Sort options containing the input value alphabetically
            return a.label.localeCompare(b.label);
        }
    };

    return (
        <div className={props.className}>
            <fieldset className={`form-group ${props.fieldClassName}`}>
                <label>
                    {props.label} {props.required && <sup className="text-danger">*</sup>}
                </label>
                <Select
                    cacheOptions
                    defaultOptions
                    isOptionSelected={isOptionSelected}
                    closeMenuOnSelect={props.closeAfterSelection}
                    onInputChange={newValue => setInputValue(newValue)}
                    isMulti={props.isMulti}
                    isDisabled={props.disabled}
                    isSearchable
                    options={getOptions()}
                    value={getValue()}
                    onChange={_onChange}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    filterOption={customFilterOption} //{createFilter({ matchFrom: "start" })}
                    isLoading={
                        props.isLoading || props.showLoading
                            ? options.length === 0
                            : false
                    }
                    styles={props.customStyles}
                    {...props.rest}
                />
                {props.error && (
                    <div className="text-danger small mt-1">{props.error}</div>
                )}
            </fieldset>
        </div>
    );
});

export default FormSelect;
