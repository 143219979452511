import _omit from 'lodash/omit';

export const setLocalStorage = state => {
    return localStorage.setItem('chedds-state', JSON.stringify(state));
};

export const getLocalStorage = () => {
    if (global.localStorage) {
        return localStorage.getItem('chedds-state')
            ? JSON.parse(localStorage.getItem('chedds-state'))
            : {};
    }
    return {};
};

const storedState = getLocalStorage();
//include all keys that you wish to persist
const cleanState = state => _omit(state, ['toast', 'error']);

const DEFAULT_STATE = {
    user: {},
    blockedViews: [],
    toast: null,
    error: null,
    menuOpen: false,
    menuExpanded: true,
};

const initialState = {
    ...DEFAULT_STATE,
    ...storedState,
};


export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER': {
            const newState = {
                ...state,
                user: action.merge ? { ...state.user, ...action.user } : action.user,
            };
            if (action.setOriginal) newState.user.original = state.user;
            setLocalStorage(cleanState(newState));
            return newState;
        }
        case 'LOGOUT': {
            const newState = {
                ...state,
                user: {},
                blockedViews: [],
            };
            setLocalStorage(newState);
            return newState;
        }
        case 'FAILED_AUTH' : {
            const newState = {
                ...state,
            }
            setLocalStorage(newState);
            return newState;
        }
        case 'SET_TOAST': {
            const { type, ...rest } = action;
            return {
                ...state,
                toast: rest,
            };
        }
        case 'CLEAR_TOAST': {
            return {
                ...state,
                toast: null,
            };
        }
        case 'SET_ERROR': {
            const { type, ...rest } = action;
            return {
                ...state,
                error: rest,
            };
        }
        case 'CLEAR_ERROR': {
            return {
                ...state,
                error: null,
            };
        }
        case 'TOGGLE_MENU': {
            return {
                ...state,
                menuOpen:
                    typeof action.open !== 'undefined' ? action.open : !state.menuOpen,
            };
        }
        case 'TOGGLE_EXPAND': {
            return {
                ...state,
                menuExpanded:
                    typeof action.open !== 'undefined'
                        ? action.open
                        : !state.menuExpanded,
            };
        }
        case 'SET_BLOCKED_VIEWS': {
            const newState = {
                ...state,
                blockedViews: action.blockedViews,
            };
            setLocalStorage(cleanState(newState));
            return newState;
        }
        default:
            return state;
    }
};
