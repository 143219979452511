import React, { useState, useEffect, useReducer, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { LineItemService } from 'services';
import { onChangeFunction } from 'shared';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UrlEnum } from 'shared';
import { FormSelect, Button, FormInput } from 'components/blocks';

const NEW_ID = 'new';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_FORM = {
    biennium: null,
    institution: null,
    lineItem: null,
    name: '',
};

const lineItemStyles = {
    option: (provided, params) => {
        if (params?.data?.budgetLineItemId === NEW_ID) {
            return {
                ...provided,
                fontWeight: 'bold',
                color: '#6da34d',
            };
        }
        return provided;
    },
};

export const CreateLineItemModal = ({ isOpen, toggle, institutions, bienniums }) => {
    const history = useHistory();
    const [form, setForm] = useReducer(reducer, INITIAL_FORM);
    const [lineItems, setLineItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchNewLineItems = async () => {
        try {
            const items = await LineItemService.getLineItemsForNewModal(
                form.institution.value,
                form.biennium.id
            );
            setLineItems(
                items?.budgetLineItems?.filter(item => item.scheduleExists === false) ||
                    []
            );
        } catch (error) {}
    };

    useEffect(() => {
        if (form.institution && form.biennium) {
            fetchNewLineItems();
        }
    }, [form.institution, form.biennium]);

    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );
    const onSubmit = async () => {
        const { biennium, institution, lineItem, name } = form;
        setLoading(true);
        try {
            if (lineItem?.budgetLineItemId === NEW_ID) {
                const newRes = await LineItemService.createNewLineItem(
                    institution.value,
                    biennium.id,
                    {
                        name,
                    }
                );
                const res = await LineItemService.createLineItem({
                    biennium: biennium.id,
                    budgetLineItemId: newRes.budgetLineItemId,
                });
                const location = {
                    pathname: UrlEnum.LINEITEMBUDGET,
                    state: {
                        row: res,
                    },
                };
                history.push(location);
            } else {
                const res = await LineItemService.createLineItem({
                    biennium: biennium.id,
                    budgetLineItemId: lineItem.budgetLineItemId,
                });
                const location = {
                    pathname: UrlEnum.LINEITEMBUDGET,
                    state: {
                        row: res,
                    },
                };
                history.push(location);
            }
        } catch (error) {}
        setLoading(false);
    };
    const isDisabled = useMemo(() => {
        if (form.lineItem?.budgetLineItemId === NEW_ID) {
            return !form.biennium || !form.institution || !form.name;
        }
        return !form.biennium || !form.institution || !form.lineItem;
    }, [form]);
    return (
        <Modal isOpen={isOpen}>
            <ModalHeader close={closeBtn}>New Line Item Schedule</ModalHeader>
            <ModalBody>
                <FormSelect
                    label="Biennium"
                    onChange={setForm}
                    options={bienniums}
                    placeholder="Select a biennium"
                    name="biennium"
                    value={form.biennium}
                    isMulti={false}
                    getOptionLabel={d => d.value}
                />
                <FormSelect
                    label="Institution"
                    onChange={setForm}
                    options={institutions}
                    placeholder="Select an institution"
                    name="institution"
                    value={form.institution}
                    isMulti={false}
                    getOptionLabel={d => d.label}
                    getOptionValue={d => d.value}
                />
                <FormSelect
                    styles={lineItemStyles}
                    label="Line Item"
                    onChange={setForm}
                    options={[
                        {
                            budgetLineItemName: '+ Create new line item',
                            budgetLineItemId: NEW_ID,
                            style: { fontWeight: 'bold' },
                        },
                        ...lineItems,
                    ]}
                    placeholder="Select a line item"
                    name="lineItem"
                    value={form.lineItem}
                    isMulti={false}
                    getOptionLabel={d => d.budgetLineItemName}
                    getOptionValue={d => d.budgetLineItemId}
                />
                {form.lineItem?.budgetLineItemId === NEW_ID && (
                    <FormInput
                        styles={lineItemStyles}
                        label="New Line Item Name"
                        onChange={setForm}
                        placeholder="Line item name"
                        name="name"
                        value={form.name}
                    />
                )}
                <p className="text-italic m-0">
                    A line item must be uniquely named. The name will be used to track the
                    line item from biennium to biennium and cannot be changed once it has
                    been created. Please confirm line item name before pressing the
                    "Begin" button.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={isDisabled}
                    onClick={onSubmit}
                    loading={loading}
                >
                    Begin <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </ModalFooter>
        </Modal>
    );
};
