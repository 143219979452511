import React from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';

import { onChangeFunction } from 'shared';

export const FormSelect = React.memo(props => {
    const { className, label, onChange, ...rest } = props;
    return (
        <FormGroup className={className}>
            <label>{label}</label>
            <Select
                cacheOptions
                defaultOptions
                isSearchable
                isClearable
                onChange={(valueObj, options) =>
                    onChangeFunction.handleSelectChange(valueObj, options, onChange)
                }
                components={{
                    IndicatorSeparator: () => null,
                }}
                {...rest}
            />
        </FormGroup>
    );
});
