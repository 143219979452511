import React from 'react';

export const Toggle = React.memo(props => {
    const { className, label, id, onChange, value, ...rest } = props;
    return (
        <div className="form-check checkbox-slider--b-flat checkbox-slider-md" {...rest}>
            <label className="m-0">
                <input
                    type="checkbox"
                    checked={value}
                    value={value}
                    onChange={() => onChange(id, !value)}
                />
                <span>{label}</span>
            </label>
        </div>
    );
});
