import React, { useState, useEffect } from 'react';
import { AcademicCredential } from 'components';
import { academicProgramsService } from 'services';

export const AcademicCredentialPage = props => {
    const [credentialData, setCredentialData] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedCredentialId = sessionStorage.getItem('credentialId');

        if (query) {
            for (let param of query) {
                if (
                    param.credentialId &&
                    (!persistedCredentialId || param.credentialId !== persistedCredentialId)
                ) {
                    window.sessionStorage.setItem('credentialId', param.credentialId);
                    return param.credentialId;
                } else {
                    return null;
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            parseQueryParams();
            const persistedCredentialId = sessionStorage.getItem('credentialId');
            const data = await academicProgramsService.getCredentialInformation(
                persistedCredentialId
            );
            setCredentialData(data || []);
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <AcademicCredential
            credentialData={credentialData}
            setCredentialData={setCredentialData}
            fetchData={fetchData}
        />
    );
};
