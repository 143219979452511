import { faArrowLeft, faArrowRight, faSave } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { budgetRunService } from '../../../../../services/finance-pages-services/budget-run.service';

export const WizardFooter = ({ nextStep, prevStep, isGeneralInfo, isSubmit, sanitizeBudgetRun, setBudgetRun }) => {
    const saveForm = async () => {
        setBudgetRun(await budgetRunService.saveBudgetRun(sanitizeBudgetRun()));
    };
    const previousForm = () => {
        prevStep();
    };
    const nextFormAndSave = () => {
        saveForm();
        nextStep();
    };
    const saveButton = (
        <button type="button" className="btn btn-outline-primary" onClick={saveForm}>
            <FontAwesomeIcon icon={faSave} /> Save
        </button>
    );
    const previousButton = (
        <button
            type="button"
            className="btn btn-outline-primary mr-2 px-3"
            onClick={previousForm}
        >
            <FontAwesomeIcon icon={faArrowLeft} /> Previous
        </button>
    );
    const nextButton = (
        <button type="button" className="btn btn-primary px-3" onClick={nextFormAndSave}>
            Save & Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
    );

    const showSaveButton = () => {
        return isSubmit ? null : saveButton;
    };
    const showPreviousButton = () => {
        return isGeneralInfo ? null : previousButton;
    };
    const showNextButton = () => {
        return isSubmit ? null : nextButton;
    };
    return (
        <div className="wizard-footer">
            {showSaveButton()}
            <div className="nav-buttons">
                {showPreviousButton()}
                {showNextButton()}
            </div>
        </div>
    );
};
