import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { Form, Row, Table, Card, CardTitle } from 'reactstrap';
import { PopOver } from 'shared';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const CostSizeAndFunding = ({ capitalProject, prevStep, nextStep }) => {
	return (
		<Form>
			<h2 className='text-strong text-primary border-bottom pb-2 mb-4'>
				Cost, Size, & Funding
			</h2>
			<Card className='no-shadow border p-3 mb-3'>
				<CardTitle>
					{' '}
					<h4 className='mb-3 text-primary'>Project Size</h4>
				</CardTitle>
				<Table
					responsive
					className='table table-striped table-bordered table-hover align-middle table-sm'>
					<thead>
						<tr>
							<th></th>
							<th>
								GSF{' '}
								<a
									href='/'
									data-toggle='popover'
									data-position='top'
									data-content='Gross Square Feet (GSF)- Sum of all area within the exterior envelope of the structure.'>
									<FontAwesomeIcon
										className='fas fa-question-circle fa-lg ml-1'
										icon={faQuestionCircle}
									/>
								</a>
							</th>
							<th>
								ASF{' '}
								<div
									href='/'
									data-toggle='popover'
									data-position='top'
									data-content='Assignable Square Feet (ASF)- Amount of space that can be used by people or programs within the interior walls of a structure. For Information on assignable space use categories, see Space Detail Tab'>
									<FontAwesomeIcon
										className='fas fa-question-circle fa-lg ml-1'
										icon={faQuestionCircle}
									/>
								</div>
							</th>
							<th className='min-tablet-l no-sort text-right'>ASF/GSF</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope='row'>Project Size</th>
							<td>{capitalProject.sizeGSF}</td>
							<td>{capitalProject.sizeASF}</td>
							<td>
								{capitalProject.sizeGSF === 0
									? 0
									: +(capitalProject.sizeASF / capitalProject.sizeGSF).toFixed(
											2
									  )}
							</td>
						</tr>
						<tr>
							<th scope='row'>Net Change in Overall Campus Space</th>
							<td>{capitalProject.netChangeGSF}</td>
							<td>{capitalProject.netChangeASF}</td>
							<td>
								{' '}
								{capitalProject.netChangeGSF === 0
									? 0
									: +(
											capitalProject.netChangeASF / capitalProject.netChangeGSF
									  ).toFixed(2)}
							</td>
						</tr>
					</tbody>
				</Table>
			</Card>
			<Card className='no-shadow border p-3 mb-3'>
				<CardTitle>
					<h4 className='mb-3 text-primary'>Project Cost Summary</h4>
				</CardTitle>
				<Row>
					<div className='col-md-auto mb-3 mb-md-0'>
						<label>
							Total Project Cost <sup className='text-danger'>*</sup>{' '}
							<PopOver
								icon={faQuestionCircle}
								id={'estAnnualRepair'}
								text={
									'Projects should include all costs associated with the project (structure, A&E, infrastructure, consulting, FF&E, etc.)'
								}
							/>
						</label>
						<div className='input-group'>
							${capitalProject.totalProjectCost ?? 0}
						</div>
					</div>
					<div className='col-md mb-3 mb-md-0'>
						<label>Cost Per GSF/ASF</label>
						<ul className='no-bullets m-0 p-0'>
							<li>
								$10 <label className='m-0'>GSF</label>
							</li>
							<li>
								$25 <label className='m-0'>ASF</label>
							</li>
						</ul>
					</div>
				</Row>
			</Card>
			<Card className=' no-shadow border p-3 mb-3'>
				<h4 className='mb-3 text-primary'>
					Funding Source(s) for Project{' '}
					<PopOver
						icon={faQuestionCircle}
						id={'estAnnualRepair'}
						text={
							'Be consistent in the naming of funds to be used for projects. If bonding, note Bonding Authority Year (1965, 1929, 1927, etc.)'
						}
					/>
				</h4>
				<table className='table table-bordered table-hover table-striped table-sm'>
					<thead>
						<tr key={'head'}>
							<th>Funding Amount</th>
							<th>Funding Type</th>
							<th>Funding Source Description</th>
						</tr>
					</thead>
					<tbody>
						{capitalProject.fundingList.map((fundingSource, index) => {
							return (
								<tr key={index}>
									<td>${+capitalProject.fundingList[index].fundingAmount}</td>
									<td>{capitalProject.fundingList[index].fundingTypeId}</td>
									<td>
										{capitalProject.fundingList[index].fundingDescription}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className='d-flex align-items-center justify-content-between mt-3'>
					<label className='m-0'>
						Total Funding: $
						{capitalProject.fundingList.reduce(function(acc, fundingSource) {
							return acc + +fundingSource.fundingAmount;
						}, 0)}
					</label>
				</div>
			</Card>

			<Card className='no-shadow border p-3 mb-3'>
				<h4 className='mb-3 text-primary'>Annual Cost</h4>
				<table className='table review-table'>
					<tbody>
						<tr>
							<td className='question-label'>
								<label className='mb-0 mr-2'>
									Est. annual change in cost of building operations based on the
									project:
								</label>
							</td>
							<td>
								<p className='m-0'>
									${capitalProject.estAnnualChangeInOpsCost}
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<label className='mb-0 mr-2'>
									Est. annual repair and rehabilitation investment:{' '}
									<PopOver
										icon={faQuestionCircle}
										id={'estAnnualRepair'}
										text={
											'Estimate the amount of funding the institution would need to set aside annually to address R&R needs for the project. CHE suggests 1.5% of total construction cost'
										}
									/>
								</label>
							</td>
							<td>
								<p className='m-0'>${capitalProject.estAnnualRRInvestment}</p>
							</td>
						</tr>
						<tr>
							<td className='question-label'>
								<label className='mb-0 mr-2'>
									Est. annual debt payment:{' '}
									<PopOver
										icon={faQuestionCircle}
										id={'estAnnualDebtPayment'}
										text={
											'If issuing debt, determine annual payment based on 20 years at 5.0% interest rate'
										}
									/>
								</label>
							</td>
							<td>
								<p className='m-0'>${capitalProject.estAnnualDebtPayment}</p>
							</td>
						</tr>
						<tr>
							<td className='question-label'>
								<label className='mb-0 mr-2'>
									Are all the funds for the project secured?
								</label>
							</td>
							<td>
								<p className='m-0'>
									{capitalProject.isFundsSecured ? 'Yes' : 'No'}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</Card>
			<Card className='no-shadow border p-3 mb-3'>
				<h4 className='mb-3 text-primary'>Funding</h4>
				<p className='text-italic'>
					Provide additional narrative for the funding sources listed. If all
					funds are not secured, outline cash flow for the project. If debt is
					to be issued for the project, what is the current outstanding debt of
					the institution? What is the repayment method for the debt? What is
					the structure of the gift funds? What is the structure of the grant?
				</p>
				<hr />
				<label>Response:</label>
				<p>{capitalProject.fundingNarrative}</p>
			</Card>
			<Card className='no-shadow border p-3 mb-3'>
				<h4 className='mb-3 text-primary'>Justification of Cost</h4>
				<p className='text-italic'>
					Provide justification for the cost per ASF/GSF for the project.
					Outline the reasons for discrepancies between this project and
					comparables. Provide additional cost metrics depending on project
					type. Provide narrative on the change in the cost of building
					operations. Will the building be included in the state's R&R formula;
					what percentage is academic and administrative? What alternatives were
					considered? Describe the site development costs.
				</p>
				<hr />
				<label>Response:</label>
				<p>{capitalProject.justificationOfCost}</p>
			</Card>
			<p className='text-italic'>
				If project is a lease-purchase or lease, adjust accordingly. Note the
				total cost of the lease in the project cost, and annual payments in
				project description
			</p>

			<WizardFooter
				prevStep={prevStep}
				nextStep={nextStep}
				capitalProject={capitalProject}
			/>
		</Form>
	);
};
