import store from 'redux/store';

export function authHeader(useOriginal = false) {
    const state = store.getState();
    const user = state.user;
    const values = useOriginal && user?.original ? user.original : user;
    if (values && values.token) { 
        return { Authorization: 'Bearer ' + values.token };
    } else {
        return {};
    }
}
