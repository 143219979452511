import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { FormGroup, Input, Button, Card } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';
import { onChangeFunction } from 'shared';
import { CreateBudgetRunModal } from './create-budget-run-modal';
import { budgetRunService, financeService } from 'services';

export const SearchBudgetRunForm = ({ setBudgetRun, searchObject, setSearchObject }) => {
    const allValue = { value: 'all', label: 'All' };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            bienniumSearchValue: allValue,
            budgetRunTitle: '',
        }
    );
    const [bienniums] = useState(financeService.getAllBienniums);
    const loadBienniums = () =>
        Promise.resolve(bienniums).then(allBienniums => {
            if (!allBienniums.includes(allValue)) {
                allBienniums.unshift(allValue);
            }
            return allBienniums;
        });

    useEffect(() => {
        const getBudgetRuns = async () => {
            const res = await budgetRunService.searchBudgetRuns(searchObject);
            setBudgetRun(res.budgetRuns ?? []);
        };
        getBudgetRuns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = () => {
        const actualBienniumValue =
            userInput.bienniumSearchValue.value === allValue.value
                ? null
                : +userInput.bienniumSearchValue.value;

        const searchPostData = {
            ...searchObject,
            bienniums: actualBienniumValue,
            title:
                userInput.budgetRunTitle || userInput.budgetRunTitle !== ''
                    ? userInput.budgetRunTitle
                    : null,
        };
        setSearchObject(searchPostData);
    };

    return (
        <div>
            <Button
                className="btn btn-success btn-block new-btn mb-3"
                onClick={() => setIsModalOpen(true)}
            >
                <FontAwesomeIcon icon={faPlus} /> Create New Budget Run
            </Button>
            <Card className="card mb-3 p-3">
                <h4 className="text-muted mb-4">Search Budget Runs</h4>
                <FormGroup>
                    <label>Bienniums</label>
                    <AsyncSelect
                        classNamePrefix="AsyncSelect"
                        cacheOptions
                        defaultOptions
                        isSearchable
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleAsyncSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        name="bienniumSearchValue"
                        value={userInput.bienniumSearchValue}
                        loadOptions={loadBienniums}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Budget Run Title</label>
                    <Input
                        type="text"
                        placeholder="Budget Run Title"
                        onChange={evt => onChangeFunction.handleChange(evt, setUserInput)}
                        name="budgetRunTitle"
                        value={userInput.budgetRunTitle}
                        required
                    />
                </FormGroup>
                <button
                    className="btn btn-block btn-primary"
                    color="success"
                    onClick={() => {
                        submitForm();
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} /> Search
                </button>
            </Card>
            <CreateBudgetRunModal isOpen={isModalOpen} toggle={toggle} />
        </div>
    );
};

SearchBudgetRunForm.propTypes = {
    setBudgetRun: PropTypes.func.isRequired,
};
