import { authHeader } from 'helpers/auth-header';
import { handleAxiosResponse, api_base_url, axiosInstance } from '../common.service';
import { convertApiResponseToSearchOptions } from '../shared-service-functions';
import { handleError } from 'shared';
import { fakeResponses } from '../fakeresponses';

const getAllProjects = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/CapitalProjectType/projects`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return convertApiResponseToSearchOptions(res);
        })
        .catch(error => {
            handleError(error);
        });
};

const getAllProjectTypes = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/CapitalProjectType/projects`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return convertApiResponseToSearchOptions(res);
        })
        .catch(error => {
            handleError(error);
        });
};

const getAllBienniums = id => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/CapitalProjectType/biennium`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return convertApiResponseToSearchOptions(res);
        })
        .catch(error => {
            handleError(error);
        });
};

const getAllInstitutions = () => {
    return axiosInstance
        .get(`${api_base_url}/api/AcademicPrograms/institutions`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return convertApiResponseToSearchOptions(fakeResponses.getInstitutions());
            // return convertApiResponseToSearchOptions(res);
        })
        .catch(error => {
            handleError(error);
            // return convertApiResponseToSearchOptions(fakeResponses.getInstitutions());
        });
};

const getBudgetRunById = id => {
    return axiosInstance
        .post(`${api_base_url}/api/finance/budgetruns/${id}`)
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const getSpaceDetailTableColumns = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/CapitalProjectType/spacetype`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const getCostDetailTableRows = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/CapitalProjectType/projectcosttype`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const getAnnualCostDetailTableColumns = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/CapitalProjectType/projectannualcosttype`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const saveNewBudgetRun = data => {
    return axiosInstance
        .post(
            `${api_base_url}/api/finance/budgetruns/new`,
            JSON.stringify({
                headers: authHeader(),
                body: data,
            })
        )
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

export const financeService = {
    getBudgetRunById,
    saveNewBudgetRun,
    getAllProjectTypes,
    getAllBienniums,
    getSpaceDetailTableColumns,
    getCostDetailTableRows,
    getAnnualCostDetailTableColumns,
    getAllProjects,
    getAllInstitutions,
};
