import React from 'react';
import { CapitalProjectErrorEnum, FormEnum, ObjectFilter, getKeyByValue } from 'shared';

const ValidationErrors = ({ validationErrors }) => {
    return validationErrors.map((validationError, index) => {
        const isValidationWarning = validationError.messageType === 2;
        return (
            <li key={index}>
                <span style={{ fontWeight: 'bold' }}>
                    {isValidationWarning ? 'WARNING:' : 'ERROR:'}
                </span>
                {validationError.messageText}{' '}
            </li>
        );
    });
};

export const ValidationErrorBox = ({ capitalProject, tabNumber }) => {
    const validationErrors = capitalProject?.validationErrors ?? [];
    const validationErrorsForTab = validationErrors.reduce(
        (accumulator, currentValue) => {
            const filteredMessageTextToTab = ObjectFilter(
                CapitalProjectErrorEnum,
                ([name, tab]) => tab.includes(currentValue.capitalProjectField)
            );
            const tabEnumName = Object.keys(filteredMessageTextToTab)[0] ?? '';
            const tabName = getKeyByValue(FormEnum, tabNumber);
            if (tabEnumName === tabName && filteredMessageTextToTab[tabEnumName]) {
                accumulator.push(currentValue);
            }
            return accumulator;
        },
        []
    );
    return validationErrorsForTab && validationErrorsForTab.length > 0 ? (
        <div className="alert alert-danger mt-3">
            <div className="error-list">
                <span>Please check the following errors/warnings:</span>
                <ul>{<ValidationErrors validationErrors={validationErrorsForTab} />}</ul>
            </div>
        </div>
    ) : (
        <React.Fragment />
    );
};
