import React from 'react';

import {
	GeneralInfo,
	CostSizeAndFunding,
	DetailDescription,
	NeedAndPurpose,
	OtherProjectInfo,
	SpaceDetail,
	CostDetail,
	AnnualCostDetail,
	Submit,
} from './project-review-tabs';

import { FormEnum } from 'shared';

export const ProjectReviewTabSwitch = ({
	capitalProject,
	setCapitalProject,
	step,
	setStep,
	nextStep,
	prevStep,
}) => {
	switch (step) {
		case FormEnum.GENERALINFO:
			return (
				<GeneralInfo
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></GeneralInfo>
			);
		case FormEnum.COSTSIZEFUNDING:
			return (
				<CostSizeAndFunding
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></CostSizeAndFunding>
			);
		case FormEnum.DETAILDESCRIPTION:
			return (
				<DetailDescription
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></DetailDescription>
			);
		case FormEnum.NEEDSANDPURPOSE:
			return (
				<NeedAndPurpose
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></NeedAndPurpose>
			);
		case FormEnum.OTHERPROJECTINFO:
			return (
				<OtherProjectInfo
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></OtherProjectInfo>
			);
		case FormEnum.SPACEDETAIL:
			return (
				<SpaceDetail
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></SpaceDetail>
			);
		case FormEnum.COSTDETAIL:
			return (
				<CostDetail
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></CostDetail>
			);
		case FormEnum.ANNUALCOSTDETAIL:
			return (
				<AnnualCostDetail
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></AnnualCostDetail>
			);
		case FormEnum.SUBMIT:
			return (
				<Submit
					nextStep={nextStep}
					prevStep={prevStep}
					setStep={setStep}
					capitalProject={capitalProject}
					setCapitalProject={setCapitalProject}></Submit>
			);
		default: {
			return (
				<GeneralInfo
					nextStep={nextStep}
					prevStep={prevStep}
					capitalProject={capitalProject}></GeneralInfo>
			);
		}
	}
};
