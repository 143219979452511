import React from 'react';
import { WizardFooter } from './wizard-footer';
import { Form } from 'reactstrap';

export const NeedAndPurpose = ({ capitalProject, prevStep, nextStep }) => {
	return (
		<Form>
			<h2 className='text-strong text-primary border-bottom pb-2 mb-4'>
				Need and Purpose of the Project
			</h2>
			<p className='text-italic'>
				Describe how the project relates to the mission and long-term strategic
				plan of the institution. Include the impact of the project on the
				academic and educational attainment of students, programmatic changes
				that the project would have to the campus, how the project might improve
				the education quality of students at the campus, etc. Identify the
				impact if the project is not approved or recommended by the state.
				Describe, if necessary, any health and safety issues that result in the
				need for the project. Describe, if applicable, any financial impact the
				project might have regarding the cost of attendance to students.
			</p>
			<p className='text-italic'>
				Provide additional narrative for the funding sources listed. If all
				funds are not secured, outline cash flow for the project. If debt is to
				be issued for the project, what is the current outstanding debt of the
				institution? What is the repayment method for the debt? What is the
				structure of the gift funds? What is the structure of the grant?
			</p>
			<hr />
			<label>Response:</label>
			<p>{capitalProject.needAndPurpose}</p>

			<WizardFooter
				prevStep={prevStep}
				nextStep={nextStep}
				capitalProject={capitalProject}
			/>
		</Form>
	);
};
