export const convertApiResponseToSearchOptions = responses => {
    const toReturn = responses.map(response => ({
        value: response.id,
        label: response.text ? response.text : response.name,
    }));
    return toReturn;
};

export const convertApiResponseToOppositeSearchOptions = responses => {
    const toReturn = responses.map(response => ({
        label: response.id,
        value: response.text ? response.text : response.name,
    }));
    return toReturn;
};

export const convertAbnormalApiResponseToSearchOptions = (
    responses,
    idVariableName,
    textVariableNameName
) => {
    const toReturn = responses.map(response => ({
        value: response[idVariableName],
        label: response[textVariableNameName],
        ...response,
    }));
    return toReturn;
};
