import React, { useEffect, useState, useMemo } from 'react';
import { Card, Container, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import queryString from 'query-string';
import Spinner from 'components/blocks/Spinner';
import Button from 'components/blocks/Button';

import {
    getYears
} from './helpers';

import { generateReportService } from 'services';
import { Title, SubTitle, Body, Institution, BodyUngrouped, Row } from './components';

// import json from './test3.json';
const EMPTY = ['', null];

const getHiddenColumns = (obj, columns) => {
    let titleWidth = columns.titleWidth;
    let colWidth = columns.colWidth;
    // TODO: add back when we can use this field
    delete obj.demographicInfo;
    const params = queryString.parse(window.location.search);
    const hideYears = _isEmpty(params.dataOutputs);
    const hidden = Object.keys(obj).reduce((p, c) => {
        const val = obj[c];
        if (c === 'countsByYear') {
            const arr = Object.keys(val[0]).reduce((a, b) => {
                if (EMPTY.includes(val[0][b])) {
                    return a.concat(b);
                }
                return a;
            }, []);
            colWidth = colWidth - arr.length;
            return p.concat(...arr);
        } else if (EMPTY.includes(val)) {
            titleWidth--;
            return p.concat(c);
        }
        return p;
    }, []);
    return { hidden, titleWidth, colWidth, hideYears };
};

const getDemographicsColumns = options => {
    const toShow = [];
    Object.keys(options).forEach(prop => {
        if (options[prop] === 'true') {
            toShow.push(prop);
        }
    });
    return toShow;
};

export const ProgramReviewReport = () => {
    const {
        groupPrograms,
        fiscalYears,
        Race,
        Gender,
        Residency,
        PellRecipient,
    } = queryString.parse(window.location.search);
    const yearsToShow = fiscalYears ? fiscalYears.split(',') : getYears(2000);
    const demographicsToShow = getDemographicsColumns({
        Race,
        Gender,
        Residency,
        PellRecipient,
    });
    const isGrouped = groupPrograms === 'true';
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const [columns, setColumns] = useState({
        hidden: [],
        titleWidth: isGrouped ? 9 : 11,
        colWidth: 3,
        hideYears: false,
    });
    const fetchReport = async () => {
        try {
            const search = window.location.search;
            const res = await generateReportService.getReport(search);
            console.log(res)
            // const res = json;
            if (res && res.length > 0) {
                const cols = getHiddenColumns(res[0], columns);
                setColumns(cols);
            } else {
                setNoDataFound(true)
            }
            const sorted = res?.map(item => ({
                ...item,
                countsByYear: _orderBy(item.countsByYear, d => Number(d.year)),
            }));
            setRows(sorted || []);
            setLoading(false);
        } catch (error) {}
    };
    const exportCsv = async () => {
        setExporting(true);
        const search = window.location.search;
        await generateReportService.getReportExport(search);
        setExporting(false);
    };
    useEffect(() => {
        fetchReport();
    }, []);
    const grouped = useMemo(() => {
        const groups = _groupBy(rows, 'rowTitle');
        const getRows = groups => {
            return Object.keys(groups).map(key => {
                const items = groups[key];
                if (!items) return null;
                const years = columns.hideYears ? [] : yearsToShow;
                return (
                    <Table
                        key={key}
                        className="table table-striped table-bordered table-hover align-middle"
                    >
                        <Title text={key} info={items[0].degreeLevel} />
                        <SubTitle
                            years={years}
                            demographicsToShow={demographicsToShow}
                            {...columns}
                        />
                        <Body
                            hide={columns.hidden}
                            years={years}
                            demographicsToShow={demographicsToShow}
                        >
                            {items.map((row, i) => {
                                return (
                                    <Institution
                                        key={i}
                                        institutionName={row.institutionName}
                                        sector={row.sector}
                                        programId={row.programId}
                                        programName={row.programName}
                                        distanceEd={row.distanceEd}
                                        programStatus={row.programStatus}
                                        programApprovalDate={row.programApprovalDate}
                                        programEndYear={row.programEndYear}
                                        creditRange={row.creditRange}
                                        countsByYear={row.countsByYear}
                                        demographicsToShow={demographicsToShow}
                                        demographicInfo={row.demographicInfo}
                                        years={years}
                                    />
                                );
                            })}
                        </Body>
                    </Table>
                );
            });
        };
        return getRows(groups);
    }, [rows, columns]);
    const ungrouped = useMemo(() => {
        const years = columns.hideYears ? [] : yearsToShow;
        return (
            <Table className="table table-striped table-bordered table-hover align-middle">
                {/* <Title text={key} info={items[0].degreeLevel} /> */}
                <SubTitle
                    years={years}
                    demographicsToShow={demographicsToShow}
                    {...columns}
                />
                <BodyUngrouped hide={columns.hidden}>
                    {rows?.map((row, i) => {
                        return (
                            <Row
                                key={i}
                                demographicsToShow={demographicsToShow}
                                years={years}
                                {...row}
                            />
                        );
                    })}
                </BodyUngrouped>
            </Table>
        );
    }, [rows, columns]);
    return (
        <Container fluid>
            <div className="d-md-flex align-items-center justify-content-between mb-3 pt-3">
                <h2 className="mb-0">Program Review Report</h2>
                <Button
                    disabled={loading}
                    loading={exporting}
                    className="btn-xs btn-outline-primary"
                    onClick={exportCsv}
                >
                    <FontAwesomeIcon icon={faFileExport} /> Export As Excel
                </Button>
            </div>
            {loading ? (
                <Spinner size={40} />
            ) : noDataFound ? (
                <Card>
                    <div className="table-responsive">No Report Data Was Found For The Selected Criteria.</div>
                </Card>
            ) : isGrouped ? (
                <Card>
                    <div className="table-responsive">{grouped}</div>
                </Card>
            ) : (
                <Card>
                    <div className="table-responsive">{ungrouped}</div>
                </Card>
            )}
        </Container>
    );
};
