import React, { useState, useReducer } from 'react';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';
import { RadioButtons, FormEnum } from 'shared';
import AsyncSelect from 'react-select/async';
import { ValidationErrorBox } from './validation-error-box';

export const GeneralInfo = ({ capitalProject, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 1,
            projectName: capitalProject.projectName ? capitalProject.projectName : '',
            projectTypeId: capitalProject.projectTypeId
                ? capitalProject.projectTypeId
                : 0,
            priority: capitalProject.priority ? capitalProject.priority : 0,
            budgetAgencyProjectNo: capitalProject.budgetAgencyProjectNo
                ? capitalProject.budgetAgencyProjectNo
                : '',
            isPreviouslyApprovedByGA: capitalProject.isPreviouslyApprovedByGA,
            isPreviouslyRecommendedCHE: capitalProject.isPreviouslyRecommendedCHE,
            isLongTermCapitalPlan: capitalProject.isLongTermCapitalPlan,
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };
    const handleProjectTypeChange = projectType => {
        capitalProject.projectTypeId = projectType.value;
        setUserInput({ projectTypeId: projectType });
    };

    const handleRadioButtonChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value === 'true' ? true : false;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    const [projectTypes] = useState(financeService.getAllProjectTypes);

    const loadProjectTypes = () => {
        return Promise.resolve(projectTypes).then(projectTypes => {
            projectTypes.forEach(projectType => {
                projectType.value = +projectType.value;
                const actuallyProjectTypeIdNotObject = userInput.projectTypeId;
                if (+projectType.value === actuallyProjectTypeIdNotObject) {
                    setUserInput({ projectTypeId: projectType });
                }
            });
            return projectTypes;
        });
    };
    const sanitizeCapitalProject = () => {
        userInput.projectTypeId = +userInput.projectTypeId.value;
        userInput.priority = userInput.priority === 0 ? null : +userInput.priority;
        const sanitizedUserInput = userInput;
        return sanitizedUserInput;
    };
    return (
        <Form>
            <h2 className="title pb-2 mb-4">General Project Information</h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.GENERALINFO}
            />
            <Row mb="4">
                <Col md="8" mb="3">
                    <FormGroup>
                        <Label>
                            Project Name/Title <sup className="text-danger">*</sup>
                        </Label>
                        <Input
                            type="text"
                            placeholder="Project Name/Title"
                            onChange={handleChange}
                            name="projectName"
                            value={userInput.projectName}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label>
                            Project Type <sup className="text-danger">*</sup>
                        </Label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            onChange={handleProjectTypeChange}
                            value={userInput.projectTypeId}
                            loadOptions={loadProjectTypes}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row mb="4">
                <Col md="auto">
                    <FormGroup>
                        <Label>
                            Institutional Priority <sup className="text-danger">*</sup>
                        </Label>
                        <Input
                            type="text"
                            placeholder="Institutional Priority"
                            onChange={handleChange}
                            name="priority"
                            value={userInput.priority}
                            required
                        />
                    </FormGroup>
                </Col>

                <Col md="auto">
                    <FormGroup>
                        <Label>
                            Budget Agency Project No <sup className="text-danger">*</sup>
                        </Label>
                        <Input
                            type="text"
                            placeholder="Budget Agency Project No"
                            onChange={handleChange}
                            name="budgetAgencyProjectNo"
                            value={userInput.budgetAgencyProjectNo}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <RadioButtons
                labelName="Previously Approved by General Assembly?"
                name="isPreviouslyApprovedByGA"
                value={userInput.isPreviouslyApprovedByGA}
                onChange={handleRadioButtonChange}
            />
            <RadioButtons
                labelName="Previously Recommended by CHE?"
                name="isPreviouslyRecommendedCHE"
                value={userInput.isPreviouslyRecommendedCHE}
                onChange={handleRadioButtonChange}
            />
            <RadioButtons
                labelName="Part of the Institution’s Long term Capital plan?"
                name="isLongTermCapitalPlan"
                value={userInput.isLongTermCapitalPlan}
                onChange={handleRadioButtonChange}
            />
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProject={capitalProject}
                isGeneralInfo={true}
            />
        </Form>
    );
};
