import React, { useEffect, useState } from 'react';
import { Card, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { recordLinkingService } from 'services';
import { UrlEnum } from 'shared';
import { Columns } from './components';

const paginationOptions = {
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    sizePerPage: 15,
    hideSizePerPage: true,
};

export const RecordLinking = props => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [genders, setGenders] = useState([]);
    const [races, setRaces] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await recordLinkingService.getRecords();
                const codes = await recordLinkingService.getRaceAndGenderCodes();
                setData(res);
                setGenders(codes?.genderList || []);
                setRaces(codes?.raceList || []);
            } catch (error) {}
        };
        fetch();
    }, []);
    const onLink = state => {
        const location = {
            pathname: UrlEnum.LINK_REVIEW,
            state: { ...state, genders, races },
        };
        history.push(location);
    };
    return (
        <Container fluid>
            <Row>
                <div className="col-12">
                    <h2 className="mb-3">Current Active Link Requests</h2>
                    <Card className="mb-4">
                        <div className="table-responsive">
                            <BootstrapTable
                                classes="data-table table table-striped table-bordered table-hover align-middle dataTable no-footer dtr-inline"
                                keyField="linkRequestID"
                                data={data || []}
                                columns={Columns({ onLink })}
                                striped
                                hover
                                pagination={paginationFactory(paginationOptions)}
                            />
                        </div>
                    </Card>
                </div>
            </Row>
        </Container>
    );
};
