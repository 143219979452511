import { useSelector, useDispatch } from 'react-redux';

export const useToastState = () => {
    const toast = useSelector(state => state.toast);
    const dispatch = useDispatch();
    const setToast = payload => {
        dispatch({ type: 'SET_TOAST', ...payload });
    };
    const clearToast = () => {
        dispatch({ type: 'CLEAR_TOAST' });
    };
    return { toast, setToast, clearToast };
};
