import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import {
    SearchAcademicProgramsForm,
    AcademicAffairsSearchProgramsTable,
} from '../../../components';
import {
    CollectionProcessingForm,
    CollectionProcessingTable,
} from '../../../components/che-admin';

export const CollectionProcessingPage = props => {
    const [logData, setLogData] = useState([]);

    return (
        <Row>
            <Col className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                <CollectionProcessingForm
                    setLogData={setLogData}
                ></CollectionProcessingForm>
            </Col>
            <Col className="col-xl-9 col-lg-12">
                <CollectionProcessingTable logData={logData}></CollectionProcessingTable>
            </Col>
        </Row>
    );
};
