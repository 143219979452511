import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
    useRef,
} from 'react';
import { Input, Card, Container, Row, Alert } from 'reactstrap';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog,
    faExternalLinkAlt,
    faMagic,
    faSave,
    faUndo,
} from '@fortawesome/free-solid-svg-icons';
import _isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { FormSelect, DropdownButton, Button } from '../blocks';
import {
    OutputItem,
    RadioGroup,
    CheckboxGroup,
    SavedSearchesModal,
    SaveSearchModal,
    ManageSearchesModal,
} from './components';
import { generateReportService } from 'services';
import { onChangeFunction } from 'shared';
import { RadioCheckGroup } from './components/RadioCheckGroup';
import {
    getOptionLabel,
    convertToValue,
    convertCipToValue,
    convertCampusToValue,
    convertMetaMajorToValue,
    getDefaultValues,
    getYears,
    PROGRAM_STATUS,
    DEMOGRAPHIC_FILTERS,
    INITIAL_FORM,
    convertToArray,
    hydrateFormArray,
    getCips,
    getDemographics,
} from './helpers';

const TEST_QUERY =
    'Gender=true&PellRecipient=false&Race=false&Residency=true&cips=03,03.05,03.0508&dataOutputs=2&degreeLevels=05&distanceEd=1&fiscalYears=2021&groupPrograms=false&institutionLevel=1&institutions=15013600&metaMajor=2&name=test&programAttributes=1,5,7,8&programStatus=1&programsToInclude=2';

export const deriveKey = (array, key = 'value') => {
    //const newArr = [array]
    if (array == null) return [];
    return array.map(d => d[key]);
};

export const deriveYears = (array, key = 'value') => {
    //const newArr = [array]
    const year = new Date().getFullYear() - 2;
    if (array == null || array.length == 0) array = getYears(year);
    return array.map(d => d[key]);
};

export const getCipLabel = d => `${d.cipCode}-${d.cipName}`;
const reducer = (state, newState) => ({ ...state, ...newState });
export const containsValue = (value, array, modifier) => {
    const vals = array.map(val => {
        let comparison = val;
        if (modifier) comparison = modifier(val);
        return value?.includes(comparison);
    });
    return vals.some(d => d === true);
};

const hydrateQueryParams = async data => {
    const { options, initial, params } = data;
    console.log(data);
    if (!_isEmpty(params)) {
        const hydrated = {
            ...initial,
            ...getCips(params, options),
            institutionLevel: Number(params.institutionLevel),
            dataOutputs: convertToArray(params.dataOutputs, true),
            programsToInclude: convertToArray(params.programsToInclude, true),
            programAttributes: convertToArray(params.programAttributes, true),
            fiscalYears: hydrateFormArray(params.fiscalYears, options.fiscalYears, 'key'),
            institutions: [],
            degreeLevels: hydrateFormArray(
                params.degreeLevels,
                options.degreeLevels,
                'key'
            ),
            metaMajor: hydrateFormArray(params.metaMajor, options.metaMajor, 'value'),
            programStatus: hydrateFormArray(
                params.programStatus,
                options.programStatus,
                'key'
            ),
            distanceEd: Number(params.distanceEd),
            groupPrograms: params.groupPrograms,
            name: params.name,
            demographics: getDemographics(params),
        };
        const institutionOptions = await getInstitutionOptions(
            params.institutionLevel,
            '',
            options
        );
        hydrated.institutions = hydrateFormArray(
            params.institutions,
            institutionOptions,
            'id'
        );
        return hydrated;
    }
    return initial;
};

const getInstitutionOptions = async (insLevel, query, options) => {
    try {
        let data = [];
        if (Number(insLevel) === 1 || Number(insLevel) === 4) {
            // institution level
            const res = await generateReportService.getInstitutions(query);
            data = res.institutions.map(convertToValue);
        } else if (Number(insLevel) === 2) {
            // campus level
            const res = await generateReportService.getCampuses(query);
            data = res.campuses.map(convertCampusToValue);
        } else if (Number(insLevel) === 3) {
            // location level
            query = 'institutions=' + options.institutions.map(x => x.id).join(',');
            const res = await generateReportService.getLocations(query);
            data = res.locations.map(convertCampusToValue);
        }
        return data;
    } catch (error) {
        return [];
    }
};

export const ProgramCriteriaForm = () => {
    const history = useHistory();
    const [fetchingInstitutions, setFetchingInstitutions] = useState(false);
    const [searchesOpen, setSearchesOpen] = useState(false);
    const [managingSearchesOpen, setManagingSearchesOpen] = useState(false);
    const [savingSearchOpen, setSavingSearchOpen] = useState(false);
    const [activeSearch, setActiveSearch] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const resetOptions = useRef({});
    const [form, setForm] = useReducer(reducer, INITIAL_FORM);
    const [options, setOptions] = useReducer(reducer, {});

    const [allSystems, setAllSystems] = useState([]);
    const generateQuery = () => {
        const payload = {
            // if set to system, pull data for all institutions, so set inst level to 1 for backend processing
            //institutionLevel: form.institutionLevel == "4" ? "1" : form.institutionLevel,
            institutionLevel: form.institutionLevel,
            dataOutputs: form.dataOutputs,
            fiscalYears: deriveYears(form.fiscalYears),
            institutions: deriveKey(form.institutions, 'value'),
            degreeLevels: deriveKey(form.degreeLevels),
            metaMajor: deriveKey(form.metaMajor),
            name: form.name,
            programStatus: deriveKey(form.programStatus),
            distanceEd: form.distanceEd,
            distanceEdOnline: form.distanceEdOnline,
            cips: [
                ...deriveKey(form.twoCip, 'value'),
                ...deriveKey(form.fourCip, 'value'),
                ...deriveKey(form.sixCip, 'value'),
            ],
            programsToInclude: form.programsToInclude,
            programAttributes: form.programAttributes,
            groupPrograms: form.groupPrograms,
            Race: form.demographics.includes('race'),
            Gender: form.demographics.includes('gender'),
            Residency: form.demographics.includes('residency'),
            PellRecipient: form.demographics.includes('pellrecipient'),
        };
        return queryString.stringify(payload, {
            arrayFormat: 'separator',
            skipEmptyString: true,
        });
    };
    const GetLabelForInsLevel = () => {
        if (Number(form.institutionLevel) === 1) {
            // institution level
            return 'Institutions';
        } else if (Number(form.institutionLevel) === 2) {
            // campus level
            return 'Campuses';
        } else if (Number(form.institutionLevel) === 3) {
            // location level
            return 'Locations';
        }
        return 'Select An Institution Level Above';
    };
    const handleSavedSearch = async savedSearch => {
        setActiveSearch(savedSearch);
        console.log(savedSearch);
        setSearchesOpen(false);
        const query = '?' + savedSearch.searchString;
        history.push({
            pathname: window.location.pathname,
            search: query,
        });
        const newValues = getDefaultValues(options);
        const params = queryString.parse(query, {
            parseBooleans: true,
            arrayFormat: 'comma',
        });
        const formValues = await hydrateQueryParams({
            options,
            initial: newValues,
            params,
        });
        console.log(formValues);
        formValues.degreeLevels = formValues.degreeLevels.map(x => {
            return { label: x.value, value: x.key };
        });
        formValues.fiscalYears = formValues.fiscalYears.map(x => {
            return { label: x.value, value: x.key };
        });
        formValues.institutions = formValues.institutions.map(x => {
            return { label: x.name, value: x.id };
        });
        formValues.twoCip = formValues.twoCip.map(x => {
            return { label: x.cipName, value: x.cipCode };
        });
        formValues.fourCip = formValues.fourCip.map(x => {
            return { label: x.cipName, value: x.cipCode };
        });
        formValues.sixCip = formValues.sixCip.map(x => {
            return { label: x.cipName, value: x.cipCode };
        });
        formValues.programStatus = formValues.programStatus.map(x => {
            return { label: x.value, value: x.key };
        });
        console.log(formValues);
        setForm(formValues);
    };
    const onSubmit = async () => {
        console.log(form);
        const query = generateQuery(form);
        console.log(query);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = `/academic-affairs/report?${query}`;
        a.target = '_blank';
        a.click();
        a.remove();
    };

    const FixFieldLength = list => {
        return list.map(x => {
            let valueMeta = x.metaMajor.toString();
            //adds leading zeros to make it two digits
            while (valueMeta.length < 2) {
                valueMeta = '0' + valueMeta;
            }
            return { label: x.value, value: valueMeta };
        });
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const params = queryString.parse(window.location.search, {
                    parseBooleans: true,
                    arrayFormat: 'comma',
                });
                const res = await Promise.all([
                    generateReportService.getOptions(),
                    generateReportService.getSystems(),
                    generateReportService.getAttributes(),
                    generateReportService.getCips(),
                    generateReportService.getInstitutionTypes(),
                    generateReportService.getMetaMajor(),
                ]);
                res[0].institutionTypes.push({
                    key: 'BPE',
                    value: 'BPE-Authorized',
                    levelCode: '4',
                    levelName: '4-year or above',
                });
                res[0].institutionTypes.push({
                    key: 'ICI',
                    value: 'ICI-Authorized',
                    levelCode: '4',
                    levelName: '4-year or above',
                });

                console.log(res);
                const opts = {
                    ...res[0],
                    fiscalYears: getYears(2000),
                    institutions: [],
                    systems: res[1].systems.map(convertToValue),
                    programAttributes: res[2].map(convertToValue),
                    twoCip: res[3][0].map(convertCipToValue),
                    fourCip: res[3][1].map(convertCipToValue),
                    sixCip: res[3][2].map(convertCipToValue),
                    //institutionTypes: res[4].map(convertToValue),
                    metaMajor: FixFieldLength(res[5].map(convertMetaMajorToValue)),
                };

                setAllSystems(opts.systems);
                setOptions(opts);
                // we want to set default values here
                const newValues = getDefaultValues(opts);
                const formValues = await hydrateQueryParams({
                    options: opts,
                    initial: newValues,
                    params,
                });
                setForm(formValues);
                // keep the default values in the clearable state
                resetOptions.current = newValues;
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };
        fetchOptions();
    }, []);
    useEffect(() => {
        //if (!_isEmpty(form.institutionTypes) && !_isEmpty(form.systems)) {
        const fetchNewInstitutions = async () => {
            setFetchingInstitutions(true);
            try {
                const query = queryString.stringify(
                    {
                        institutionTypes: deriveKey(form.institutionTypes, 'value'),
                        systems: deriveKey(form.systems, 'value'),
                    },
                    {
                        arrayFormat: 'separator',
                        skipEmptyString: true,
                    }
                );
                console.log(query);
                const data = await getInstitutionOptions(
                    form.institutionLevel,
                    query,
                    options
                );
                console.log(data);
                if (Number(form.institutionLevel) === 4) {
                    if (form.systems.length > 0) {
                        setForm({
                            institutions: data.map(x => {
                                return { label: x.value, value: x.id };
                            }),
                        });
                    } else {
                        setForm({ institutions: [] });
                    }
                } else {
                    setForm({
                        institutions: form.institutions.filter(institution => {
                            const index = data.findIndex(
                                d => Number(institution.value) === Number(d.id)
                            );
                            return index > -1;
                        }),
                    });
                }
                setOptions({
                    institutions: data,
                });
            } catch (error) {}
            setFetchingInstitutions(false);
        };
        fetchNewInstitutions();
        //}
    }, [form.institutionTypes, form.systems]);

    useEffect(() => {
        setForm({ institutions: [] });
        setForm({ systems: [] });
    }, [form.institutionLevel]);

    useEffect(() => {
        if (form.institutions) {
            if (form.institutions.length > 0) {
                setDisableSubmit(false);
            } else {
                setDisableSubmit(true);
            }
        } else {
            setDisableSubmit(true);
        }
    }, [form.institutions]);

    useEffect(() => {
        if (form.systems == null || options.institutions == null) return;
        if (form.systems.length === 0) {
            const queriedSystems = [];
            for (let institution of options.institutions) {
                let matchedSystem = allSystems.find(
                    system => system.id === institution.system
                );
                if (
                    matchedSystem &&
                    !queriedSystems.some(system => system.id === matchedSystem.id)
                ) {
                    queriedSystems.push(matchedSystem);
                }
            }
            setOptions({ systems: queriedSystems });
        }
    }, [options.institutions]);

    const institutionLevelOptions = useMemo(() => {
        if (options.institutionLevel)
            return options.institutionLevel.map(ins => {
                return {
                    value: ins.key,
                    label: ins.value,
                };
            });
        return [];
    }, [options.institutionLevel]);
    const distanceEdOptions = useMemo(() => {
        if (options.distanceEd)
            return options.distanceEd.map(ed => {
                return {
                    value: ed.key,
                    label: ed.value,
                };
            });
        return [];
    }, [options.distanceEd]);
    const dataOutputOptions = useMemo(() => {
        if (options.dataOutputs)
            return options.dataOutputs.map(data => {
                return {
                    value: data.key,
                    label: data.value,
                };
            });
        return [];
    }, [options.dataOutputs]);
    const programsToIncludeOptions = useMemo(() => {
        if (options.programsToInclude)
            return options.programsToInclude.map(data => {
                return {
                    value: data.key,
                    label: data.value,
                };
            });
        return [];
    }, [options.programsToInclude]);
    const checkForDisablingSubmit = () => {
        //true on disable, false on enable
        return form.institutions.length == 0;
    };
    const twoCipOptions = useMemo(() => {
        const metaValues = form?.metaMajor?.map(cip => cip.value);
        if (metaValues == null || metaValues.length === 0) return options.twoCip;
        return options?.twoCip?.filter(cip => metaValues.includes(cip?.metaMajor));
    }, [options.twoCip, form.metaMajor]);
    const fourCipOptions = useMemo(() => {
        const values = form?.twoCip ? form?.twoCip.map(cip => cip.value) : [];
        const metaValues = form?.metaValues ? form?.metaMajor.map(cip => cip.value) : [];
        let filtered = options?.fourCip || [];
        if (metaValues.length > 0)
            filtered = filtered.filter(cip => metaValues.includes(cip?.metaMajor));
        if (values.length > 0)
            filtered = filtered.filter(cip =>
                containsValue(cip.value, values, d => d + '.')
            );
        return filtered;
    }, [options.fourCip, form.twoCip, form.metaMajor]);
    const sixCipOptions = useMemo(() => {
        const twoVals = form?.twoCip ? form?.twoCip.map(cip => cip.value) : [];
        const fourVals = form?.fourCip ? form?.fourCip.map(cip => cip.value) : [];
        const metaValues = form?.metaMajor ? form?.metaMajor.map(cip => cip.value) : [];
        let filtered = options?.sixCip || [];
        if (metaValues.length > 0)
            filtered = filtered.filter(cip => metaValues.includes(cip?.metaMajor));
        if (twoVals.length > 0)
            filtered = filtered.filter(cip =>
                containsValue(cip.value, twoVals, d => d + '.')
            );
        if (fourVals.length > 0)
            filtered = filtered.filter(cip => containsValue(cip.value, fourVals));
        return filtered;
    }, [options.sixCip, form.twoCip, form.fourCip, form.metaMajor]);
    return (
        <Container fluid>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h2 className="text-primary text-strong mb-0">
                    Generate Program Review Report
                </h2>
                <DropdownButton
                    icon={faMagic}
                    text="Saved Search"
                    dropdownProps={{
                        size: '',
                    }}
                    dropdownMenuProps={{
                        right: true,
                    }}
                    options={[
                        {
                            label: (
                                <>
                                    <FontAwesomeIcon icon={faMagic} /> Fill Form with
                                    Saved Search
                                </>
                            ),
                            onClick: () => setSearchesOpen(true),
                        },
                        {
                            label: (
                                <>
                                    <FontAwesomeIcon icon={faCog} /> Manage Saved Searches
                                </>
                            ),
                            onClick: () => setManagingSearchesOpen(true),
                        },
                    ]}
                />
            </div>
            {activeSearch && (
                <Alert color="info" className="text-strong">
                    <FontAwesomeIcon icon={faMagic} /> Form Filled with:{' '}
                    {activeSearch?.label}
                </Alert>
            )}
            <Row className="sticky-bar-spacer">
                <div className="col-md-8 mb-3 mb-md-0">
                    <Card className="card p-3 mb-4">
                        <h2 className="text-primary text-strong mb-3">
                            Program Criteria
                        </h2>
                        <div className="row">
                            <FormSelect
                                label="Fiscal Years"
                                onChange={setForm}
                                options={
                                    options.fiscalYears
                                        ? options.fiscalYears.map(x => {
                                              return { label: x.value, value: x.key };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="fiscalYears"
                                isMulti={true}
                                value={form.fiscalYears}
                                className="col-md"
                                getOptionLabel={getOptionLabel}
                            />
                            <FormSelect
                                label="Degree Level"
                                onChange={setForm}
                                options={
                                    options.degreeLevels
                                        ? options.degreeLevels.map(x => {
                                              return { label: x.value, value: x.key };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="degreeLevels"
                                isMulti
                                value={form.degreeLevels}
                                className="col-md"
                                getOptionLabel={getOptionLabel}
                                showLoading
                            />
                        </div>
                        <h3 className="text-muted text-strong">Institutions</h3>
                        <OutputItem
                            label="Institution Level"
                            text="Determines whether report outputs data by system (e.g. All Ivy Tech Institutions), institution (e.g. some or all Ivy Tech institutions), campus (e.g. 14 Ivy Tech Campuses), or by each location data is collected for."
                            className="mb-2"
                        >
                            <RadioCheckGroup
                                onChange={setForm}
                                name="institutionLevel"
                                value={form.institutionLevel}
                                options={institutionLevelOptions}
                            />
                        </OutputItem>
                        <div className="row">
                            <FormSelect
                                label="Type"
                                onChange={setForm}
                                options={
                                    options.institutionTypes
                                        ? options.institutionTypes.map(x => {
                                              return { label: x.value, value: x.key };
                                          })
                                        : []
                                }
                                placeholder="All"
                                isMulti
                                name="institutionTypes"
                                value={form.institutionTypes}
                                className="col-12"
                                getOptionLabel={getOptionLabel}
                                showLoading
                            />
                            <FormSelect
                                label="Systems"
                                onChange={setForm}
                                options={
                                    options.systems
                                        ? options.systems.map(x => {
                                              return { label: x.value, value: x.id };
                                          })
                                        : []
                                }
                                placeholder="All"
                                isMulti
                                name="systems"
                                value={form.systems}
                                required={Number(form.institutionLevel) === 4}
                                className="col-12"
                                getOptionLabel={getOptionLabel}
                                showLoading
                            />
                            {form.institutionLevel != 4 && (
                                <FormSelect
                                    label={GetLabelForInsLevel()}
                                    required
                                    onChange={setForm}
                                    options={
                                        options.institutions
                                            ? options.institutions.map(x => {
                                                  return { label: x.value, value: x.id };
                                              })
                                            : []
                                    }
                                    placeholder="All"
                                    name="institutions"
                                    isMulti={true}
                                    value={form.institutions}
                                    className="col-12"
                                    getOptionLabel={getOptionLabel}
                                    loading={fetchingInstitutions}
                                    // isDisabled={
                                    //     !form.institutionLevel ||
                                    //     form.institutionTypes.length === 0 ||
                                    //     form.systems.length === 0
                                    // }
                                />
                            )}
                        </div>
                        <h3 className="text-muted text-strong">Program</h3>
                        <div className="row">
                            <FormSelect
                                label="Meta-Major"
                                onChange={setForm}
                                options={options.metaMajor ? options.metaMajor : []}
                                placeholder="All"
                                name="metaMajor"
                                isMulti={true}
                                value={form.metaMajor}
                                className="col-md"
                                getOptionLabel={getOptionLabel}
                                showLoading
                            />
                        </div>
                        <div className="row">
                            <FormSelect
                                label="Two Digit CIP"
                                onChange={setForm}
                                options={
                                    twoCipOptions
                                        ? twoCipOptions.map(x => {
                                              return {
                                                  label: x.value + ' - ' + x.cipName,
                                                  value: x.value,
                                              };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="twoCip"
                                value={form.twoCip}
                                className="col-12"
                                getOptionLabel={getCipLabel}
                                showLoading
                                isMulti
                            />
                            <FormSelect
                                label="Four Digit CIP"
                                onChange={setForm}
                                options={
                                    fourCipOptions
                                        ? fourCipOptions.map(x => {
                                              return {
                                                  label: x.value + ' - ' + x.cipName,
                                                  value: x.value,
                                              };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="fourCip"
                                value={form.fourCip}
                                className="col-12"
                                getOptionLabel={getCipLabel}
                                showLoading
                                isMulti
                            />
                            <FormSelect
                                label="Six Digit CIP"
                                onChange={setForm}
                                options={
                                    sixCipOptions
                                        ? sixCipOptions.map(x => {
                                              return {
                                                  label: x.cipCode + '-' + x.cipName,
                                                  value: x.cipCode,
                                              };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="sixCip"
                                value={form.sixCip}
                                className="col-12"
                                getOptionLabel={getCipLabel}
                                showLoading
                                isMulti
                            />
                        </div>
                        <div className="row mb-md-4">
                            <div className="col-md mb-3 mb-md-0">
                                <div className="form-row align-items-center">
                                    <div className="col-md-auto">
                                        <label className="mb-0">Name:</label>
                                    </div>
                                    <div className="col-md-auto">
                                        <p className="m-0">
                                            CIP matches selected above and program name
                                            contains
                                        </p>
                                    </div>
                                    <div className="col-md">
                                        <Input
                                            type="text"
                                            placeholder=""
                                            onChange={evt =>
                                                onChangeFunction.handleChange(
                                                    evt,
                                                    setForm
                                                )
                                            }
                                            name="name"
                                            value={form.name}
                                            className="white"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <FormSelect
                                label="Status"
                                onChange={setForm}
                                options={
                                    PROGRAM_STATUS
                                        ? PROGRAM_STATUS.map(x => {
                                              return { label: x.value, value: x.key };
                                          })
                                        : []
                                }
                                placeholder="All"
                                isMulti
                                name="programStatus"
                                value={form.programStatus}
                                className="col-md"
                                getOptionLabel={getOptionLabel}
                            />
                            <div className="col-md">
                                <label className="d-block">Distance Education</label>
                                <RadioCheckGroup
                                    onChange={setForm}
                                    name="distanceEd"
                                    value={form.distanceEd}
                                    options={distanceEdOptions}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md">
                                <label className="d-block">Demographic Information</label>
                                <CheckboxGroup
                                    onChange={setForm}
                                    name="demographics"
                                    value={form.demographics}
                                    options={DEMOGRAPHIC_FILTERS}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-md">
                    <div className="card p-3 mb-4">
                        <h2 className="text-primary text-strong mb-3">Report Output</h2>
                        <div className="row">
                            <OutputItem
                                label="Group Programs"
                                text="Determines whether programs are grouped by CIP code and degree level. Grouping makes the report easier to read, but may not be ideal if you wish to save report to excel."
                                className="col-12 mb-3"
                            >
                                <RadioCheckGroup
                                    onChange={setForm}
                                    name="groupPrograms"
                                    value={form.groupPrograms}
                                    options={[
                                        {
                                            value: true,
                                            label: 'Yes',
                                        },
                                        {
                                            value: false,
                                            label: 'No',
                                        },
                                    ]}
                                />
                            </OutputItem>
                            <OutputItem
                                label="Program Attributes"
                                text="Determines which program attributes are included in the report."
                                className="col-12 mb-3"
                            >
                                <CheckboxGroup
                                    onChange={setForm}
                                    name="programAttributes"
                                    value={form.programAttributes}
                                    options={
                                        options.programAttributes
                                            ? options.programAttributes.map(attr => ({
                                                  value: attr.id,
                                                  label: attr.name,
                                              }))
                                            : []
                                    }
                                />
                            </OutputItem>
                            <OutputItem
                                label="Data Outputs"
                                text="Determines which program data are included in report for each fiscal year selected."
                                className="col-12 mb-3"
                            >
                                <CheckboxGroup
                                    onChange={setForm}
                                    name="dataOutputs"
                                    value={form.dataOutputs}
                                    options={dataOutputOptions}
                                />
                            </OutputItem>
                            <OutputItem
                                label="Programs to Include"
                                text="Determines whether non API mappped data is included or not."
                                className="col-12 mb-3"
                            >
                                <CheckboxGroup
                                    onChange={setForm}
                                    name="programsToInclude"
                                    value={form.programsToInclude}
                                    options={programsToIncludeOptions}
                                />
                            </OutputItem>
                        </div>
                    </div>
                    {/* <a
                        href="report"
                        target="_blank"
                        className="btn btn-primary btn-block btn-lg"
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Generate Report
                    </a> */}

                    {/* {form.degreeLevels.length === 0 && (
                        <div className="help-block text-danger mt-1">
                            * Degree level is required
                        </div>
                    )} */}
                </div>
            </Row>
            <div className="sticky-bottom-bar">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <div className="mb-3 mb-sm-0">
                        <Button
                            outline
                            color="secondary"
                            className="btn-lg"
                            onClick={() => setSavingSearchOpen(true)}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save this Search
                        </Button>
                    </div>
                    <div>
                        <Button
                            outline
                            color="secondary"
                            className="btn-lg mr-2"
                            onClick={() => setForm(resetOptions.current)}
                        >
                            <FontAwesomeIcon icon={faUndo} /> Reset Form
                        </Button>
                        <Button
                            color="primary"
                            onClick={onSubmit}
                            className="btn-lg"
                            disabled={loading || disableSubmit}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Generate Report
                        </Button>
                    </div>
                </div>
            </div>
            {searchesOpen && (
                <SavedSearchesModal
                    open={searchesOpen}
                    close={() => setSearchesOpen(false)}
                    onSubmit={handleSavedSearch}
                />
            )}
            {savingSearchOpen && (
                <SaveSearchModal
                    open={savingSearchOpen}
                    close={() => setSavingSearchOpen(false)}
                    getQueryString={generateQuery}
                />
            )}
            {managingSearchesOpen && (
                <ManageSearchesModal
                    open={managingSearchesOpen}
                    close={() => setManagingSearchesOpen(false)}
                />
            )}
        </Container>
    );
};
