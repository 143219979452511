import { handleServiceRequest } from '../handle-service-request';

const getRecords = () => {
    const getObject = {
        url: `/api/DataSubmission/RecordLinking`,
    };
    return handleServiceRequest.get(getObject);
};

const getRecordDuplicate = id => {
    const getObject = {
        url: `/api/DataSubmission/RecordLinking/DuplicateReview/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const getRecordManual = id => {
    const getObject = {
        url: `/api/DataSubmission/RecordLinking/ManualReview/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const getRaceAndGenderCodes = () => {
    const getObject = {
        url: `/api/DataSubmission/RecordLinking/RaceAndGenderCodes`,
    };
    return handleServiceRequest.get(getObject);
};

const getReviewRecord = (linkRequestId, dataLinkId) => {
    const getObject = {
        url: `/api/DataSubmission/RecordLinking/ManualReview/${linkRequestId}/${dataLinkId}`,
    };
    return handleServiceRequest.get(getObject);
};

const submitRecordLink = (linkRequestId, dataLinkId, masterIndexId) => {
    const postObject = {
        url: `/api/DataSubmission/RecordLinking/ManualReview/${linkRequestId}/${dataLinkId}/${masterIndexId}`,
    };
    return handleServiceRequest.post(postObject);
};

const submitDuplicate = (linkRequestId, indexId, duplicateGroupNum) => {
    const postObject = {
        url: `/api/DataSubmission/RecordLinking/DuplicateReview/${linkRequestId}/${indexId}/${duplicateGroupNum}`,
    };
    return handleServiceRequest.post(postObject);
};

export const recordLinkingService = {
    getRecords,
    getRecordDuplicate,
    getRecordManual,
    getRaceAndGenderCodes,
    getReviewRecord,
    submitRecordLink,
    submitDuplicate,
};
