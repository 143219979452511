export const BudgetRunTabEnum = {
    GENERALINFO: '#general',
    PERCENTS: '#percents',
    BASE: '#base',
    PFFOUTPUT: '#pff-output',
    PFFPERUNIT: '#pff-per-unit',
    CAPITALPROJECTS: '#capital-projects',
    LINEITEMS: '#line-items',
    DUALCREDIT: '#dual-credit',
    RANDR: '#r&r',
    OUTPUT: '#output',
};
