import React, { useState, useEffect } from 'react';
import { AcademicAffairInstitution } from 'components';
import { academicInstitutionsService } from 'services';
import { AcademicAffairLocation } from 'components/academic-affair-institutions';

export const AcademicLocationPage = props => {
    const [academicLocationData, setAcademicLocationData] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedLocationId = sessionStorage.getItem('locationId');

        if (query) {
            for (let param of query) {
                
                if (
                    param.UnitId &&
                    (!persistedLocationId || param.UnitId !== persistedLocationId)
                ) {
                    window.sessionStorage.setItem('locationId', param.UnitId);
                    return param.UnitId;
                } else {
                    return null;
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            parseQueryParams();
            const persistedLocationId = sessionStorage.getItem('locationId');

            const data = await academicInstitutionsService.getLocation(
                persistedLocationId
            );
            data.vwLocation.LocationShortName = data.locationShortName;
            setAcademicLocationData(data.vwLocation || []);            
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <AcademicAffairLocation
            academicLocationData={academicLocationData}
            setAcademicLocationData={setAcademicLocationData}
        />
    );
};
