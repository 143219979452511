const fakeSearchAcademicPrograms = () => {
    return [
        {
            programID: 3,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'Associate in Agriculture in General Agriculture',
            status: 'Eliminated',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 4,
            institutionName: 'Vincennes University',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'A.S. in Agriculture',
            status: 'Eliminated',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 6,
            institutionName: 'Ivy Tech Community College',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'A.A.S./A.S. in Agriculture - TEST ONLY',
            status: 'Active',
            approvalDate: '2007-03-09T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 18,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'B.S. in General Agriculture',
            status: 'Active',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 19,
            institutionName: 'Purdue University-North Central Campus',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'B.S. in General Agriculture',
            status: 'Eliminated',
            approvalDate: '2004-02-13T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 22,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'M.S. in General Agriculture',
            status: 'Eliminated',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 23,
            institutionName: 'Vincennes University',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'Precision Ag Certificate C.P.C.',
            status: 'Eliminated',
            approvalDate: '2007-01-11T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 25,
            institutionName: 'Vincennes University',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'A.S. in Agribusiness',
            status: 'Active',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 27,
            institutionName: 'Indiana University-Purdue University-Indianapolis',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'M.S. in Agriculture Extension (PU)',
            status: 'Eliminated',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 29,
            institutionName: 'Purdue University-Calumet Campus',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'M.S. in Agriculture Extension (PU)',
            status: 'Eliminated',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 30,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'M.S. in Agriculture Extension',
            status: 'Eliminated',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 32,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'B.S. in Agricultural Economics',
            status: 'Active',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 33,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'M.S. in Agricultural Economics',
            status: 'Active',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 34,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'Ph.D. in Agricultural Economics',
            status: 'Active',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 35,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'M.B.A. in Food and Agricultural Business',
            status: 'Active',
            approvalDate: '1998-10-09T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
        {
            programID: 36,
            institutionName: 'Purdue University-West Lafayette',
            degreeLevel: null,
            cipCode: null,
            cipTitle: 'None',
            programName: 'B.S. in Agricultural Systems Management',
            status: 'Active',
            approvalDate: '1971-08-04T00:00:00',
            hasPendingActionRequests: false,
            monthYearIdentifier: null,
            monthYearText: 'None Set',
        },
    ];
};

const fakeGetSingleAcademicAffair = () => {
    return {
        programDetails: {
            programId: 3,
            cheInstitutionUnitId: '24378000',
            cipSixDigitCode: '01.0000',
            cipSixDigitCode2020: null,
            degreeLevelCode: '03',
            programFormalName: 'Associate in Agriculture in General Agriculture',
            programStartYear: 1972,
            programEndYear: 2016,
            programStatusId: 4,
            programApprovalDate: '1971-08-04T00:00:00',
            programDistanceEdApprovedFlag: false,
            programComments: null,
            lastUpdateDate: '2016-01-20T12:54:30.63',
            programIuMedicalFlag: false,
            programPffStemFlag: false,
            programPffLessThan18CrFlag: false,
            programCreditReqMin: null,
            programCreditReqMax: null,
            monthYearCommitteeSort: null,
            committeeText: null,
            cheInstitutionUnit: null,
            cipSixDigitCodeNavigation: null,
            cipSixDigitCode2020Navigation: null,
            degreeLevelCodeNavigation: null,
            iuMedicalProgram: [],
            lessThan18CrCerts: [],
            programActionRequest: null,
            programLocation: [],
            programLookup: [],
            programMajor: [],
        },
        approvedLocations: [],
        pendingProgramActionRequests: [],
        programAuditHistory: [
            {
                requestid: 597,
                requesterName: 'Noelle Wikert',
                comment: null,
                reviewerName: 'Noelle Wikert',
                reviewerComments: null,
                reviewDate: '1/20/2016 12:00:00 AM',
                newName: null,
                newCIP: null,
                newApprovalDate: null,
                newStatusID: null,
                newStartYear: null,
                newEndYear: null,
                newProgramDistanceEdApprovesFlag: null,
                newProgramCreditsMin: null,
                newProgramCreditsMax: null,
                addedLocations: [],
                removedLocations: [],
                attatchedFileNames: null,
            },
        ],
        programInstitution: {
            locationId: 24378000,
            locationName: 'Purdue University-West Lafayette',
        },
        degreeLevelDesc: 'Associate',
    };
};

export const fakeResponses = {
    fakeSearchAcademicPrograms,
    fakeGetSingleAcademicAffair,
};
