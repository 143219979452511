export const downloadCsv = (text, filename) => {
    let csv = 'data:text/csv;charset=utf-8,' + text;
    let data = encodeURI(csv);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = data;
    a.download = `${filename}.csv`;
    a.click();
    a.remove();
};

export const downloadExcel = (data, filename) => {
    var reader = new FileReader();
    var blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    reader.readAsDataURL(blob);
    reader.onloadend = function(e) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = reader.result;
        a.download = `${filename}.xls`;
        a.click();
        a.remove();
    };
};
