import React, { useState, useEffect, useReducer } from 'react';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';
import { Form, Input, Row, Table, Card } from 'reactstrap';
import { ValidationErrorBox } from './validation-error-box';
import { FormEnum } from 'shared';
import { InfoTooltip } from 'components/blocks';

export const SpaceDetail = ({ capitalProject, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 6,
            spaceDetailNotes: capitalProject.spaceDetailNotes
                ? capitalProject.spaceDetailNotes
                : '',
            spaceDetails: capitalProject.spaceDetails ? capitalProject.spaceDetails : [],
        }
    );

    const [spaceDetailRowHeaders, setSpaceDetailRowHeaders] = useState([]);
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    const handleTableChange = (event, index, inputTypeChanged) => {
        const headerValue = userInput.spaceDetails[index];
        headerValue[inputTypeChanged] = event.target.value ?? 0;
        setChangedTotals();
        capitalProject.spaceDetails = [...userInput.spaceDetails];
        setUserInput({ spaceDetails: [...userInput.spaceDetails] });
    };

    const sanitizeCapitalProject = () => {
        userInput.spaceDetails.forEach(spaceDetail => {
            spaceDetail.currentUse = +spaceDetail.currentUse;
            spaceDetail.construction = +spaceDetail.construction;
            spaceDetail.plannedAndFunded = +spaceDetail.plannedAndFunded;
            spaceDetail.terminated = +spaceDetail.terminated;
            spaceDetail.newRequest = +spaceDetail.newRequest;
        });
        return userInput;
    };

    useEffect(() => {
        const transformArray = spaceDetailRowHeaderArray => {
            return spaceDetailRowHeaderArray.map(spaceDetialRow => {
                const { id } = spaceDetialRow;
                const savedSpaceDetailValue = userInput.spaceDetails.filter(
                    spaceDetail => +spaceDetail.spaceTypeId === +id
                )[0];
                return savedSpaceDetailValue
                    ? savedSpaceDetailValue
                    : {
                          spaceTypeId: +id,
                          currentUse: '0',
                          construction: '0',
                          plannedAndFunded: '0',
                          terminated: '0',
                          newRequest: '0',
                      };
            });
        };

        const fetchColumns = async () => {
            const res = await financeService.getSpaceDetailTableColumns();
            setSpaceDetailRowHeaders(res);
            setUserInput({ spaceDetails: transformArray(res) });
            return;
        };
        fetchColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRowHeaderTextFromSpaceTypeId = spaceTypeId => {
        const header = spaceDetailRowHeaders.find(header => +header.id === +spaceTypeId);
        return header ? header.text : 'loading';
    };

    const getTotalOfColumn = key => {
        let sum = 0;
        userInput.spaceDetails.forEach(spaceDetail => {
            spaceDetail[key] ? (sum += +spaceDetail[key]) : (sum += 0);
        });
        return sum;
    };
    const [totals, setTotals] = useState([
        getTotalOfColumn('currentUse'),
        getTotalOfColumn('construction'),
        getTotalOfColumn('plannedAndFunded'),
        getTotalOfColumn('currentUse') +
            getTotalOfColumn('construction') +
            getTotalOfColumn('plannedAndFunded'),
        getTotalOfColumn('terminated'),
        getTotalOfColumn('newRequest'),
        getTotalOfColumn('terminated') + getTotalOfColumn('newRequest'),
    ]);

    const setChangedTotals = () => {
        setTotals(prevTotal => {
            prevTotal[0] = getTotalOfColumn('currentUse');
            prevTotal[1] = getTotalOfColumn('construction');
            prevTotal[2] = getTotalOfColumn('plannedAndFunded');
            prevTotal[3] = prevTotal[0] + prevTotal[1] + prevTotal[2];
            prevTotal[4] = getTotalOfColumn('terminated');
            prevTotal[5] = getTotalOfColumn('newRequest');
            prevTotal[6] = prevTotal[4] + prevTotal[5];
            return prevTotal;
        });
    };
    const renderTotals = () => {
        return (
            <tr>
                <td>
                    <label className="m-0">Total Space</label>
                </td>
                <td className="text-strong text-right">{totals[0]}</td>
                <td className="text-strong text-right">{totals[1]}</td>
                <td className="text-strong text-right">{totals[2]}</td>
                <td className="text-strong text-right">{totals[3]}</td>
                <td className="text-strong text-right">{totals[4]}</td>
                <td className="text-strong text-right">{totals[5]}</td>
                <td className="text-strong text-right">{totals[6]}</td>
            </tr>
        );
    };

    const renderTableRows = () => {
        return userInput.spaceDetails.map((row, index) => {
            return (
                <React.Fragment key={index}>
                    <tr key={index}>
                        <td className="nowrap">
                            {getRowHeaderTextFromSpaceTypeId(row.spaceTypeId)}
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={userInput.spaceDetails[
                                    index
                                ].currentUse.toString()}
                                onChange={e => {
                                    handleTableChange(e, index, 'currentUse');
                                }}
                            />
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={userInput.spaceDetails[
                                    index
                                ].construction.toString()}
                                onChange={e => {
                                    handleTableChange(e, index, 'construction');
                                }}
                            />
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={userInput.spaceDetails[
                                    index
                                ].plannedAndFunded.toString()}
                                onChange={e => {
                                    handleTableChange(e, index, 'plannedAndFunded');
                                }}
                            />
                        </td>
                        <td className="text-strong text-right highlight-cell">
                            {+row.currentUse + +row.construction + +row.plannedAndFunded}
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={userInput.spaceDetails[
                                    index
                                ].terminated.toString()}
                                onChange={e => {
                                    handleTableChange(e, index, 'terminated');
                                }}
                            />
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={userInput.spaceDetails[
                                    index
                                ].newRequest.toString()}
                                onChange={e => {
                                    handleTableChange(e, index, 'newRequest');
                                }}
                            />
                        </td>
                        <td className="text-strong text-right highlight-cell">
                            {+row.terminated + +row.newRequest}
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    };
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Space Detail
            </h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.SPACEDETAIL}
            />
            <Row>
                <Table
                    responsive
                    className="table table-striped table-bordered table-hover align-middle table-sm"
                >
                    <thead className="text-center thead-light">
                        <tr>
                            <th className="min-tablet-l no-sort"></th>
                            <th className="min-tablet-l no-sort" colSpan="4">
                                Current Campus Total
                            </th>
                            <th className="min-tablet-l" colSpan="3">
                                Capital Request
                            </th>
                        </tr>
                    </thead>
                    <thead className="text-center">
                        <tr>
                            <th className="min-tablet-l no-sort">
                                <span className="nowrap">Space Classification</span>{' '}
                                <InfoTooltip
                                    id="space-classification"
                                    text="Space/Room codes based on Postsecondary Education Facilities Inventory and Classification Manual (2006)"
                                />
                            </th>
                            <th className="min-tablet-l no-sort">Current Space in Use</th>
                            <th className="min-tablet-l no-sort">
                                Space Under Construction{' '}
                                <InfoTooltip
                                    id="space-construction"
                                    text="Identify in Space Detail Notes the specific facilities that are included in these data columns. Do not include pending approval, non-submitted projects or non-funded projects."
                                />
                            </th>
                            <th className="min-tablet-l no-sort">
                                Space Planned and Funded{' '}
                                <InfoTooltip
                                    id="space-planned"
                                    text="Identify in Space Detail Notes the specific facilities that are included in these data columns. Do not include pending approval, non-submitted projects or non-funded projects."
                                />
                            </th>
                            <th className="min-tablet-l no-sort">
                                Subtotal Current and Future Space
                            </th>
                            <th className="min-tablet-l no-sort">
                                Space to be Terminated{' '}
                                <InfoTooltip
                                    id="space-terminated"
                                    text="Identify in Space Detail Notes the specific facilities that are included in these data columns. Do not include pending approval, non-submitted projects or non-funded projects."
                                />
                            </th>
                            <th className="min-tablet-l no-sort">
                                New Space in Capital Request
                            </th>
                            <th className="min-tablet-l no-sort">Net Future Space</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableRows()}
                        {renderTotals()}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Card className=" no-shadow border p-3 mt-3">
                    <h4 className="mb-3 text-primary">Space Detail Notes</h4>
                    <p className="text-italic">
                        Please state specific facilities that are under construction,
                        planned and funded and to be terminated. Do not include pending
                        approval, non-submitted projects or non-funded projects
                    </p>
                    <Input
                        type="textarea"
                        rows="8"
                        placeholder="Space Detail Notes"
                        onChange={handleChange}
                        name="spaceDetailNotes"
                        value={userInput.spaceDetailNotes}
                    />
                </Card>
            </Row>

            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProject={capitalProject}
            />
        </Form>
    );
};
