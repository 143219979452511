const getProjectTypes = () => {
    return [
        {
            id: '1',
            text: 'New Construction',
            category: null,
        },
        {
            id: '2',
            text: 'Repair and Rehabilitation',
            category: null,
        },
        {
            id: '3',
            text: 'Land or Facility Acquisition',
            category: null,
        },
        {
            id: '4',
            text: 'Equipment',
            category: null,
        },
        {
            id: '5',
            text: 'Lease Approvals',
            category: null,
        },
        {
            id: '6',
            text: 'Other',
            category: null,
        },
    ];
};

const getBienniums = () => {
    return [
        {
            id: '2003',
            text: '2003-2005',
            category: null,
        },
        {
            id: '2005',
            text: '2005-2007',
            category: null,
        },
        {
            id: '2007',
            text: '2007-2009',
            category: null,
        },
        {
            id: '2009',
            text: '2009-2011',
            category: null,
        },
        {
            id: '2011',
            text: '2011-2013',
            category: null,
        },
        {
            id: '2013',
            text: '2013-2015',
            category: null,
        },
        {
            id: '2015',
            text: '2015-2017',
            category: null,
        },
        {
            id: '2017',
            text: '2017-2019',
            category: null,
        },
        {
            id: '2019',
            text: '2019-2021',
            category: null,
        },
        {
            id: '2021',
            text: '2021-2023',
            category: null,
        },
    ];
};

const getInstitutions = () => {
    return [
        {
            id: '15013600',
            text: 'Ball State University',
            category: null,
        },
        {
            id: '15132400',
            text: 'Indiana State University',
            category: null,
        },
        {
            id: '15111100',
            text: 'Indiana University Schools of Medicine and Dentistry, Indianapolis',
            category: null,
        },
        {
            id: '15135100',
            text: 'Indiana University System',
            category: null,
        },
        {
            id: '15135100',
            text: 'Indiana University-Bloomington',
            category: null,
        },
        {
            id: '15138800',
            text: 'Indiana University-East',
            category: null,
        },
        {
            id: '15133300',
            text: 'Indiana University-Kokomo',
            category: null,
        },
        {
            id: '15136000',
            text: 'Indiana University-Northwest',
            category: null,
        },
        {
            id: '15111100',
            text: 'Indiana University-Purdue University-Indianapolis',
            category: null,
        },
        {
            id: '15111100',
            text: 'Indiana University-Purdue University-Indianapolis/Fort Wayne',
            category: null,
        },
        {
            id: '15111100',
            text: 'Indiana University-Purdue University-Indianapolis: General Academic',
            category: null,
        },
        {
            id: '15134200',
            text: 'Indiana University-South Bend',
            category: null,
        },
        {
            id: '15137900',
            text: 'Indiana University-Southeast',
            category: null,
        },
        {
            id: '36356300',
            text: 'Ivy Tech Community College',
            category: null,
        },
        {
            id: '24378000',
            text: 'Purdue University System',
            category: null,
        },
        {
            id: '15224800',
            text: 'Purdue University-Calumet Campus',
            category: null,
        },
        {
            id: '24378000',
            text: 'Purdue University-College of Veterinary Medicine',
            category: null,
        },
        {
            id: '15110200',
            text: 'Purdue University-Fort Wayne',
            category: null,
        },
        {
            id: '15135201',
            text: 'Purdue University-Fort Wayne (Finance Only)',
            category: null,
        },
        {
            id: '15226600',
            text: 'Purdue University-North Central Campus',
            category: null,
        },
        {
            id: '24378016',
            text: 'Purdue University-Northwest',
            category: null,
        },
        {
            id: '24378000',
            text: 'Purdue University-West Lafayette',
            category: null,
        },
        {
            id: '15130600',
            text: 'University of Southern Indiana',
            category: null,
        },
        {
            id: '15263700',
            text: 'Vincennes University',
            category: null,
        },
        {
            id: 'CHE00000',
            text: 'Commission for Higher Education',
            category: null,
        },
        {
            id: 'CHE00000',
            text: 'Indiana Department of Administration',
            category: null,
        },
        {
            id: 'CHE00000',
            text: 'Indiana Medical Education Board',
            category: null,
        },
        {
            id: 'CHE00000',
            text: 'State Budget Agency',
            category: null,
        },
        {
            id: 'CHE00000',
            text: 'Student Financial Aid',
            category: null,
        },
    ];
};

const getFundings = () => {
    return [
        {
            id: '23',
            text: 'State',
            category: null,
        },
        {
            id: '10',
            text: 'Auxillary Funds - Athletics',
            category: null,
        },
        {
            id: '11',
            text: 'Auxillary Funds - Housing/Dining',
            category: null,
        },
        {
            id: '12',
            text: 'Auxillary Funds - Other',
            category: null,
        },
        {
            id: '13',
            text: 'Auxillary Funds - Parking',
            category: null,
        },
        {
            id: '24',
            text: 'Federal - Grant',
            category: null,
        },
        {
            id: '25',
            text: 'Federal - Indirect Cost Recovery',
            category: null,
        },
        {
            id: '16',
            text: 'Gift Funds',
            category: null,
        },
        {
            id: '26',
            text: 'Non - Fee Replaced Debt - Auxiliary Athletics',
            category: null,
        },
        {
            id: '27',
            text: 'Non - Fee Replaced Debt - Auxiliary Housing / Dining',
            category: null,
        },
        {
            id: '28',
            text: 'Non - Fee Replaced Debt - Auxiliary Parking',
            category: null,
        },
        {
            id: '29',
            text: 'Non - Fee Replaced Debt - Gift Funds',
            category: null,
        },
        {
            id: '30',
            text: 'Non - Fee Replaced Debt - Interest Income',
            category: null,
        },
        {
            id: '31',
            text: 'Non - Fee Replaced Debt - Other',
            category: null,
        },
        {
            id: '32',
            text: 'Non - Fee Replaced Debt - Research Bonding',
            category: null,
        },
        {
            id: '33',
            text: 'Operating Funds',
            category: null,
        },
        {
            id: '34',
            text: 'Operating Funds - Reserves',
            category: null,
        },
        {
            id: '20',
            text: 'R&R Appropriation',
            category: null,
        },
        {
            id: '21',
            text: 'R&R Student Fees',
            category: null,
        },
        {
            id: '19',
            text: 'Other',
            category: null,
        },
    ];
};

const getSpaceType = () => {
    return [
        {
            id: '1',
            text: '(100) Classroom ',
            category: null,
        },
        {
            id: '2',
            text: '(200) Class Lab ',
            category: null,
        },
        {
            id: '3',
            text: '(200) Non-class Lab ',
            category: null,
        },
        {
            id: '4',
            text: '(300) Office Facilities ',
            category: null,
        },
        {
            id: '5',
            text: '(400) Study Facilities ',
            category: null,
        },
        {
            id: '6',
            text: '(500) Special Use Facilities ',
            category: null,
        },
        {
            id: '7',
            text: '(600) General Use Facilities ',
            category: null,
        },
        {
            id: '8',
            text: '(700) Support Facilities ',
            category: null,
        },
        {
            id: '9',
            text: '(800) Health Care Facilities ',
            category: null,
        },
        {
            id: '10',
            text: '(900) Resident Facilities ',
            category: null,
        },
        {
            id: '11',
            text: '(000) Unclassified ',
            category: null,
        },
    ];
};

const getProjectCostType = () => {
    return [
        {
            id: '1',
            text: 'Engineering',
            category: 'Planning Costs',
        },
        {
            id: '4',
            text: 'Structure',
            category: 'Construction',
        },
        {
            id: '7',
            text: 'Movable Equipment',
            category: 'Other',
        },
        {
            id: '8',
            text: 'Fixed Equipment',
            category: 'Other',
        },
        {
            id: '5',
            text: 'Mechanical (HVAC, plumbing, etc.)',
            category: 'Construction',
        },
        {
            id: '2',
            text: 'Architectural',
            category: 'Planning Costs',
        },
        {
            id: '3',
            text: 'Consulting',
            category: 'Planning Costs',
        },
        {
            id: '6',
            text: 'Electrical',
            category: 'Construction',
        },
        {
            id: '9',
            text: 'Site Development/Land Acquisition',
            category: 'Other',
        },
        {
            id: '99',
            text: 'Other',
            category: 'Other',
        },
    ];
};

const getProjectAnnualCostType = () => {
    return [
        {
            id: '1',
            text: 'Operations',
            category: null,
        },
        {
            id: '2',
            text: 'Maintenance',
            category: null,
        },
        {
            id: '3',
            text: 'Fuel',
            category: null,
        },
        {
            id: '4',
            text: 'Utilities',
            category: null,
        },
        {
            id: '99',
            text: 'Other',
            category: null,
        },
    ];
};

export const getCapitalProjectWithId = () => {
    return {
        tabNumber: 0,
        id: 180,
        institutionName: 'Indiana State University',
        lastUpdated: '2020-10-20T18:20:39.61',
        biennium: '2021-2023',
        isSubmitted: false,
        projectName: 'Josh Test 2',
        projectTypeId: 2,
        projectTypeName: 'Repair and Rehabilitation',
        priority: 0,
        budgetAgencyProjectNo: '2',
        isPreviouslyApprovedByGA: false,
        isPreviouslyRecommendedCHE: false,
        isLongTermCapitalPlan: false,
        sizeGSF: 1,
        sizeASF: 2,
        netChangeGSF: 3,
        netChangeASF: 4,
        totalProjectCost: null,
        fundingList: [
            {
                fundingAmount: 4,
                fundingTypeId: 11,
                fundingDescription: 'description of funding',
                fundingTypeName: 'Auxillary Funds - Housing/Dining',
            },
            {
                fundingAmount: 2,
                fundingTypeId: 24,
                fundingDescription: 'asdfasdfasdfasdfasdfasdf',
                fundingTypeName: 'Federal - Grant',
            },
        ],
        estAnnualChangeInOpsCost: 7,
        estAnnualRRInvestment: 8,
        estAnnualDebtPayment: 9,
        isFundsSecured: true,
        fundingNarrative: 'fun',
        justificationOfCost: 'j',
        detailDescription: 'De',
        needAndPurpose: 'N',
        spaceUtilization: 'Spa',
        comparableProjects: 'C',
        backgroundMaterials: 'B',
        files: [
            {
                documentId: 19,
                documentName: 'testUpload.txt.txt',
                uploadDate: '2020-10-21T00:00:00',
            },
            {
                documentId: 22,
                documentName: 'testUpload.txt.txt',
                uploadDate: '2020-10-26T00:00:00',
            },
            {
                documentId: 23,
                documentName: 'testUpload.txt.txt',
                uploadDate: '2020-10-26T00:00:00',
            },
        ],
        spaceDetails: [
            {
                spaceTypeId: 1,
                currentUse: 1,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(100) Classroom ',
            },
            {
                spaceTypeId: 2,
                currentUse: 0,
                construction: 1,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(200) Class Lab ',
            },
            {
                spaceTypeId: 3,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(200) Non-class Lab ',
            },
            {
                spaceTypeId: 4,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(300) Office Facilities ',
            },
            {
                spaceTypeId: 5,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(400) Study Facilities ',
            },
            {
                spaceTypeId: 6,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(500) Special Use Facilities ',
            },
            {
                spaceTypeId: 7,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(600) General Use Facilities ',
            },
            {
                spaceTypeId: 8,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(700) Support Facilities ',
            },
            {
                spaceTypeId: 9,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(800) Health Care Facilities ',
            },
            {
                spaceTypeId: 10,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(900) Resident Facilities ',
            },
            {
                spaceTypeId: 11,
                currentUse: 0,
                construction: 0,
                plannedAndFunded: 0,
                terminated: 0,
                newRequest: 0,
                spaceTypeName: '(000) Unclassified ',
            },
        ],
        spaceDetailNotes: 'sp',
        bidYear: 2020,
        bidMonth: 1,
        constructionYear: 2019,
        constructionMonth: 2,
        occupancyYear: 2020,
        occupancyMonth: 1,
        costDetails: [
            {
                costTypeId: 1,
                costBasis: 1,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Engineering',
                categoryName: 'Planning Costs',
            },
            {
                costTypeId: 2,
                costBasis: 0,
                escalationFactors: 1,
                descriptionIfOther: '',
                costTypeName: 'Architectural',
                categoryName: 'Planning Costs',
            },
            {
                costTypeId: 3,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Consulting',
                categoryName: 'Planning Costs',
            },
            {
                costTypeId: 4,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Structure',
                categoryName: 'Construction',
            },
            {
                costTypeId: 5,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Mechanical (HVAC, plumbing, etc.)',
                categoryName: 'Construction',
            },
            {
                costTypeId: 6,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Electrical',
                categoryName: 'Construction',
            },
            {
                costTypeId: 7,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Movable Equipment',
                categoryName: 'Other',
            },
            {
                costTypeId: 8,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Fixed Equipment',
                categoryName: 'Other',
            },
            {
                costTypeId: 9,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Site Development/Land Acquisition',
                categoryName: 'Other',
            },
            {
                costTypeId: 99,
                costBasis: 0,
                escalationFactors: 0,
                descriptionIfOther: '',
                costTypeName: 'Other',
                categoryName: 'Other',
            },
        ],
        costDetailNotes: 'C',
        annualDetails: [
            {
                costTypeId: 1,
                personnelServices: 1,
                suppAndExp: 1,
                costTypeName: 'Operations',
            },
            {
                costTypeId: 2,
                personnelServices: 0,
                suppAndExp: 1,
                costTypeName: 'Maintenance',
            },
            {
                costTypeId: 3,
                personnelServices: 1,
                suppAndExp: 0,
                costTypeName: 'Fuel',
            },
            {
                costTypeId: 4,
                personnelServices: 1,
                suppAndExp: 0,
                costTypeName: 'Utilities',
            },
            {
                costTypeId: 99,
                personnelServices: 0,
                suppAndExp: 0,
                costTypeName: 'Other',
            },
        ],
        annualDetailNote: 'A',
    };
};

const searchResults = () => {
    return {
        runList: [
            {
                projectID: 158,
                bienniumID: 0,
                budgetYearOne: 2022,
                budgetYearTwo: 2023,
                bienniumDesc: '2021-2023',
                bienniumLockedFlag: false,
                institutionID: '15013600',
                financeUnitID: '15013600',
                financeUnitName: 'Ball State University',
                financeUnitAbbreviation: 'BSU',
                systemUnitID: '15013600',
                systemName: 'Ball State University',
                systemAbbreviation: 'BSU',
                isSubmitted: false,
                projectName: 'Test',
                institutionalPriority: null,
                budgetAgencyProjectNumber: null,
                projectTypeID: -1,
                projectTypeName: '',
                budgetAgencyProjectTypeCode: '',
                prevApprovedByGenAssembly: false,
                prevApprovedByCHE: false,
                projectSizeGSF: null,
                projectSizeASF: null,
                netChangeInSpaceGSF: null,
                netChangeInSpaceASF: null,
                projectTotalCost: null,
                estAnnualChangeInOpsCost: null,
                estAnnualRRInvestment: null,
                constBidDate: null,
                constStartDate: null,
                constEndDate: null,
                lastUpdateByUser: 'adeaton@che.in.gov',
                justificationOfCost: null,
                funding: null,
                lastUpdatedDate: '2020-08-04T09:54:25.51',
                institutionType: '4 yr',
                estAnnualDebtPayment: null,
                fundsAreSecured: false,
                longTermPlan: null,
            },
            {
                projectID: 180,
                bienniumID: 0,
                budgetYearOne: 2022,
                budgetYearTwo: 2023,
                bienniumDesc: '2021-2023',
                bienniumLockedFlag: false,
                institutionID: '15132400',
                financeUnitID: '15132400',
                financeUnitName: 'Indiana State University',
                financeUnitAbbreviation: 'ISU',
                systemUnitID: '15132400',
                systemName: 'Indiana State University',
                systemAbbreviation: 'ISU',
                isSubmitted: false,
                projectName: 'Tyler Test 9.25 Update',
                institutionalPriority: 1,
                budgetAgencyProjectNumber: '867-5309',
                projectTypeID: 1,
                projectTypeName: 'New Construction',
                budgetAgencyProjectTypeCode: '1',
                prevApprovedByGenAssembly: false,
                prevApprovedByCHE: false,
                projectSizeGSF: 20,
                projectSizeASF: 15,
                netChangeInSpaceGSF: 5,
                netChangeInSpaceASF: 3,
                projectTotalCost: null,
                estAnnualChangeInOpsCost: 4,
                estAnnualRRInvestment: 17000,
                constBidDate: '1983-01-01T00:00:00',
                constStartDate: '1984-12-01T00:00:00',
                constEndDate: '1986-07-01T00:00:00',
                lastUpdateByUser: 'tyler.smith@counterpart.biz',
                justificationOfCost:
                    "Lucas ipsum dolor sit amet binks darth wicket moff anakin padmé obi-wan wicket han kessel. Zabrak darth watto ewok. Luke solo grievous r2-d2 mara. Hoth coruscant solo anakin zabrak skywalker skywalker darth obi-wan. Jar lando kenobi mace yoda ventress c-3p0 bespin. Hutt jinn darth coruscant antilles sidious secura. Darth tatooine ackbar solo sidious dantooine darth. Calamari mara kit calamari moff kit. Organa fett watto moff calamari. Solo luke wedge obi-wan wedge leia solo hutt mandalore. Vader ackbar moff sebulba twi'lek moff kenobi c-3p0.",
                funding:
                    'Doggo ipsum you are doing me a frighten puggo heckin wrinkler, h*ck shoob. Smol borking doggo with a long snoot for pats borkdrive much ruin diet very hand that feed shibe big ol boofers, borkf tungg he made many woofs tungg. Heckin long water shoob smol length boy sub woofer you are doing me a frighten, thicc he made many woofs you are doin me a concern big ol pupper. Doing me a frighten lotsa pats shibe, heckin. Length boy wow very biscit noodle horse doggorino, ur givin me a spook fat boi. corgo big ol pupper much ruin diet. Many pats the neighborhood pupper I am bekom fat blep, stop it fren woofer. Porgo h*ck you are doing me a frighten fat boi puggorino, long doggo ruff what a nice floof.',
                lastUpdatedDate: '2020-09-25T10:54:02.183',
                institutionType: '4 yr',
                estAnnualDebtPayment: 5000000,
                fundsAreSecured: false,
                longTermPlan: true,
            },
        ],
        totalRecords: 2,
    };
};

const lineItem = () => {
    return {
        schedule: [
            {
                lastChanged: '2016-08-08T16:19:14.08',
                detailList: [
                    {
                        lineItemId: 1,
                        lineItemName: 'Salary and Wages',
                        lineItemCategoryId: 1,
                        lineItemCategoryName: 'Personnel Services',
                        lineItemCategorySort: 1,
                        lineItemSort: 1,
                        bplus2Val: 588206,
                        bplus1Val: 588206,
                        bminus0Val: 571074,
                        bminus1Val: 575022,
                        bminus2Val: 588740,
                        bminus3Val: 634926,
                        bminus4Val: 627329,
                        bminus5Val: 548580,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 2,
                        lineItemName: 'Fringe Benefits',
                        lineItemCategoryId: 1,
                        lineItemCategoryName: 'Personnel Services',
                        lineItemCategorySort: 1,
                        lineItemSort: 2,
                        bplus2Val: 147051,
                        bplus1Val: 147051,
                        bminus0Val: 142768,
                        bminus1Val: 150755,
                        bminus2Val: 135417,
                        bminus3Val: 151259,
                        bminus4Val: 150007,
                        bminus5Val: 142142,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 3,
                        lineItemName: 'Other Personnel Services',
                        lineItemCategoryId: 1,
                        lineItemCategoryName: 'Personnel Services',
                        lineItemCategorySort: 1,
                        lineItemSort: 3,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 4,
                        lineItemName: 'Services by Contract',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 1,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: 0,
                        bminus1Val: 0,
                        bminus2Val: 54000,
                        bminus3Val: 24000,
                        bminus4Val: 25315,
                        bminus5Val: 39300,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 5,
                        lineItemName: 'Materials and Supplies',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 2,
                        bplus2Val: 93275,
                        bplus1Val: 93275,
                        bminus0Val: 90558,
                        bminus1Val: 53776,
                        bminus2Val: 82970,
                        bminus3Val: 81317,
                        bminus4Val: 101606,
                        bminus5Val: 174971,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 6,
                        lineItemName: 'Equipment',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 3,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 7,
                        lineItemName: 'Land and Structures - Rental',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 4,
                        bplus2Val: 253118,
                        bplus1Val: 253118,
                        bminus0Val: 245746,
                        bminus1Val: 274276,
                        bminus2Val: 222388,
                        bminus3Val: 192588,
                        bminus4Val: 177554,
                        bminus5Val: 176329,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 8,
                        lineItemName:
                            'Grants, Subsidies, Refunds, Awards, Scholarships, Etc.',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 5,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 9,
                        lineItemName: 'In-State Travel',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 6,
                        bplus2Val: 7820,
                        bplus1Val: 7820,
                        bminus0Val: 7592,
                        bminus1Val: 3909,
                        bminus2Val: 6937,
                        bminus3Val: 6362,
                        bminus4Val: 6737,
                        bminus5Val: 8821,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 10,
                        lineItemName: 'Out-of-State Travel',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 7,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: 0,
                        bminus1Val: 0,
                        bminus2Val: 0,
                        bminus3Val: 0,
                        bminus4Val: 1904,
                        bminus5Val: 309,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 11,
                        lineItemName: 'Internal Transfers',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 8,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 12,
                        lineItemName: 'General Fund',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 1,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 15,
                        lineItemName: 'State Dedicated Funds',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 2,
                        bplus2Val: 1089470,
                        bplus1Val: 1089470,
                        bminus0Val: 1057738,
                        bminus1Val: 1057738,
                        bminus2Val: 1090452,
                        bminus3Val: 1090452,
                        bminus4Val: 1090452,
                        bminus5Val: 1090452,
                        bminus6Val: null,
                        bminus0Val_PrePop: 1057738,
                        bminus1Val_PrePop: 1057738,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 13,
                        lineItemName: 'Other Dedicated Funds',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 3,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 14,
                        lineItemName: 'Federal Funds',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 4,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                ],
                rpPer: [
                    {
                        lineItemId: 1,
                        lineItemName: 'Salary and Wages',
                        lineItemCategoryId: 1,
                        lineItemCategoryName: 'Personnel Services',
                        lineItemCategorySort: 1,
                        lineItemSort: 1,
                        bplus2Val: 588206,
                        bplus1Val: 588206,
                        bminus0Val: 571074,
                        bminus1Val: 575022,
                        bminus2Val: 588740,
                        bminus3Val: 634926,
                        bminus4Val: 627329,
                        bminus5Val: 548580,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 2,
                        lineItemName: 'Fringe Benefits',
                        lineItemCategoryId: 1,
                        lineItemCategoryName: 'Personnel Services',
                        lineItemCategorySort: 1,
                        lineItemSort: 2,
                        bplus2Val: 147051,
                        bplus1Val: 147051,
                        bminus0Val: 142768,
                        bminus1Val: 150755,
                        bminus2Val: 135417,
                        bminus3Val: 151259,
                        bminus4Val: 150007,
                        bminus5Val: 142142,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 3,
                        lineItemName: 'Other Personnel Services',
                        lineItemCategoryId: 1,
                        lineItemCategoryName: 'Personnel Services',
                        lineItemCategorySort: 1,
                        lineItemSort: 3,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                ],
                rpOthOp: [
                    {
                        lineItemId: 4,
                        lineItemName: 'Services by Contract',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 1,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: 0,
                        bminus1Val: 0,
                        bminus2Val: 54000,
                        bminus3Val: 24000,
                        bminus4Val: 25315,
                        bminus5Val: 39300,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 5,
                        lineItemName: 'Materials and Supplies',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 2,
                        bplus2Val: 93275,
                        bplus1Val: 93275,
                        bminus0Val: 90558,
                        bminus1Val: 53776,
                        bminus2Val: 82970,
                        bminus3Val: 81317,
                        bminus4Val: 101606,
                        bminus5Val: 174971,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 6,
                        lineItemName: 'Equipment',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 3,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 7,
                        lineItemName: 'Land and Structures - Rental',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 4,
                        bplus2Val: 253118,
                        bplus1Val: 253118,
                        bminus0Val: 245746,
                        bminus1Val: 274276,
                        bminus2Val: 222388,
                        bminus3Val: 192588,
                        bminus4Val: 177554,
                        bminus5Val: 176329,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 8,
                        lineItemName:
                            'Grants, Subsidies, Refunds, Awards, Scholarships, Etc.',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 5,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 9,
                        lineItemName: 'In-State Travel',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 6,
                        bplus2Val: 7820,
                        bplus1Val: 7820,
                        bminus0Val: 7592,
                        bminus1Val: 3909,
                        bminus2Val: 6937,
                        bminus3Val: 6362,
                        bminus4Val: 6737,
                        bminus5Val: 8821,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 10,
                        lineItemName: 'Out-of-State Travel',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 7,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: 0,
                        bminus1Val: 0,
                        bminus2Val: 0,
                        bminus3Val: 0,
                        bminus4Val: 1904,
                        bminus5Val: 309,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 11,
                        lineItemName: 'Internal Transfers',
                        lineItemCategoryId: 2,
                        lineItemCategoryName: 'Other Operating',
                        lineItemCategorySort: 2,
                        lineItemSort: 8,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                ],
                rpfunding: [
                    {
                        lineItemId: 12,
                        lineItemName: 'General Fund',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 1,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 15,
                        lineItemName: 'State Dedicated Funds',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 2,
                        bplus2Val: 1089470,
                        bplus1Val: 1089470,
                        bminus0Val: 1057738,
                        bminus1Val: 1057738,
                        bminus2Val: 1090452,
                        bminus3Val: 1090452,
                        bminus4Val: 1090452,
                        bminus5Val: 1090452,
                        bminus6Val: null,
                        bminus0Val_PrePop: 1057738,
                        bminus1Val_PrePop: 1057738,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 13,
                        lineItemName: 'Other Dedicated Funds',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 3,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                    {
                        lineItemId: 14,
                        lineItemName: 'Federal Funds',
                        lineItemCategoryId: 3,
                        lineItemCategoryName: 'Funding',
                        lineItemCategorySort: 3,
                        lineItemSort: 4,
                        bplus2Val: null,
                        bplus1Val: null,
                        bminus0Val: null,
                        bminus1Val: null,
                        bminus2Val: null,
                        bminus3Val: null,
                        bminus4Val: null,
                        bminus5Val: null,
                        bminus6Val: null,
                        bminus0Val_PrePop: null,
                        bminus1Val_PrePop: null,
                        bminus0DataAudit: null,
                        bminus1DataAudit: null,
                    },
                ],
            },
        ],
    };
};

const searchLineItems = () => {
    return {
        schedules: [
            {
                id: 1667,
                institution: 'Indiana State University',
                biennium: '2017-2019',
                status: 'Submitted',
                schedule: 'BRS XI',
                category: 'Budget Report Schedule',
                scheduleDescription: 'Line Item Appropriation Request',
                lastUpdate: '2016-09-01T16:13:44.18',
                lastUpdatedBy: 'Diann McKee',
                updatesAllowed: true,
            },
            {
                id: 1668,
                institution: 'Indiana State University',
                biennium: '2017-2019',
                status: 'Submitted',
                schedule: 'BRS XI',
                category: 'Budget Report Schedule',
                scheduleDescription: 'Line Item Appropriation Request',
                lastUpdate: '2016-09-01T16:15:22.307',
                lastUpdatedBy: 'Diann McKee',
                updatesAllowed: true,
            },
            {
                id: 1669,
                institution: 'Indiana State University',
                biennium: '2017-2019',
                status: 'Submitted',
                schedule: 'BRS XI',
                category: 'Budget Report Schedule',
                scheduleDescription: 'Line Item Appropriation Request',
                lastUpdate: '2016-09-01T16:15:54.843',
                lastUpdatedBy: 'Diann McKee',
                updatesAllowed: true,
            },
        ],
    };
};

export const fakeResponses = {
    getProjectTypes,
    getBienniums,
    getInstitutions,
    getFundings,
    getSpaceType,
    getProjectCostType,
    getProjectAnnualCostType,
    getCapitalProjectWithId,
    searchResults,
    lineItem,
    searchLineItems,
};
