import React, { useState, useReducer, useCallback, useMemo } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    Card,
    CardBody,
    CardHeader,
    Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faFile, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { onChangeFunction } from 'shared';
import { dataSubmissionService } from 'services';
import { Button, FormSelect } from '../../blocks';

const getOptionLabel = d => d.value;
const reducer = (state, newState) => ({ ...state, ...newState });

const Section = props => {
    const { title, children, open, onClick } = props;
    return (
        <Card className="no-shadow card-border">
            <CardHeader className="p-2">
                <h4 className="mb-0">
                    <button
                        onClick={onClick || null}
                        className="btn btn-link btn-block text-left text-strong"
                        type="button"
                    >
                        {onClick && (
                            <FontAwesomeIcon icon={faCaretDown} className="mr-2" />
                        )}{' '}
                        {title}
                    </button>
                </h4>
            </CardHeader>
            <Collapse isOpen={open}>
                <CardBody>{children}</CardBody>
            </Collapse>
        </Card>
    );
};

const INITIAL_STATE = {
    fileType: '',
    year: '',
    campusId: '',
};

export const TransmissionModal = props => {
    const { open, close, fileTypes } = props;
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [form, setForm] = useReducer(reducer, INITIAL_STATE);
    const { fileType, campusId, year } = form;
    const upload = async () => {
        setLoading(true);
        try {
            if(file.name.split(".").pop() == "zip"){
                await dataSubmissionService.submitBulkFile(file);
            } else {
                await dataSubmissionService.submitFile(file);
            }
            
            setForm(INITIAL_STATE);
            setFile(null);
            close();
        } catch (error) {}
        setLoading(false);
    };
    const onFileChange = useCallback(e => {
        setFile(e.target.files[0]);
    }, []);
    const disabled = useMemo(() => {
        return !file;
    }, [fileType, campusId, year, file]);
    return (
        <Modal isOpen={open} className="modal-dialog modal-lg">
            <ModalHeader toggle={close}>Transmit File</ModalHeader>
            <ModalBody>
                <Section open title="Standard Data Submission File(s)">
                    <ol className="pl-2 mb-4">
                        <li className="mb-3">
                            Create data file(s) per file specifications found <a href="../help">here</a>.
                            A data file must must contain all data for all locations associated
                            with a campus in a single file. A file cannot contain data for
                            more than one campus. Each valid file transmission in a given
                            reporting year will overwrite any previous file transmissions
                            from that campus for the same reporting year.
                        </li>
                        <li>
							<span>Name the file(s) using the naming convention <strong>FFYYYYCCCCCCCC.ext</strong> where:</span>
							<table class="filenameformattbl">
								<tr><td>FF</td><td>=</td><td>Two character file type code (e.g. SP = "Student Profile" data file)</td></tr>
								<tr><td>YYYY</td><td>=</td><td>Reporting year that the file is for (e.g. 2012 = "2011-2012" reporting year)</td></tr>
								<tr><td>CCCCCCCC</td><td>=</td><td>8 digit CHE assigned Campus Unit Id</td></tr>
								<tr><td>.ext</td><td>=</td><td>Must be ".csv"</td></tr>
							</table>
						</li>
                        <li className="mb-3">
                            <span className="text-strong">(Optional Step)</span> Create a
                            zip file containing the file(s) created in steps 1 through 2.
                            A zip file allows the following: 1) submission of multiple
                            files in a single upload. 2) compression of file(s) for a
                            quicker upload. Each file in the zip archive must follow the
                            naming convention listed in step 2. The zip file itself can
                            have any name but must have a ".zip" file extension.
                        </li>
                        <li className="mb-3">
                            Select a single file created in steps 1 through 2 OR the zip
                            file created in step 3. Once the file is selected press the
                            "Transmit File" button.
                        </li>
                        <li className="mb-3">
                            Wait for upload to complete and review results of the file
                            transmission(s).
                        </li>
                    </ol>
                    {false && <div className="row" style={{ position: 'relative', zIndex: 20 }}>
                        <FormSelect
                            label="File Type"
                            onChange={setForm}
                            options={fileTypes? fileTypes.map(x => {return {label: x.typeDesc, value: x.typeCode}}): []}
                            placeholder="Select File Type"
                            name="fileType"
                            value={fileType}
                            className="col-xl-4"
                            isMulti={false}
                            getOptionLabel={getOptionLabel}
                        />
                        <div className="col-xl-4">
                            <fieldset className="form-group">
                                <label>Reporting Year</label>
                                <Input
                                    type="text"
                                    placeholder="e.g. 2012"
                                    onChange={evt =>
                                        onChangeFunction.handleChange(evt, setForm)
                                    }
                                    name="year"
                                    value={year}
                                    className="white"
                                />
                            </fieldset>
                        </div>
                        <div className="col-xl-4">
                            <fieldset className="form-group">
                                <label>Campus Unit Id</label>
                                <Input
                                    type="text"
                                    placeholder=" Campus Unit Id"
                                    onChange={evt =>
                                        onChangeFunction.handleChange(evt, setForm)
                                    }
                                    name="campusId"
                                    value={campusId}
                                    maxLength={8}
                                    className="white"
                                />
                            </fieldset>
                        </div>
                    </div>}

                    <div className="w-100 hidden-file-input">
                        <input type="file" accept=".csv,.zip" onChange={onFileChange} />
                        <Button
                            type="button"
                            outline
                            color="primary"
                            className="btn-block"
                        >
                            <FontAwesomeIcon icon={faFile} /> Choose File
                        </Button>
                    </div>
                    {file && (
                        <h5 className="mb-0 mt-4 text-italic">
                            <FontAwesomeIcon icon={faPaperclip} />
                            {file.name}
                        </h5>
                    )}
                </Section>
                {/* <Section open={second} onClick={secondAction} title="Data Share File">
                    <ol className="pl-2 mb-4">
                        <li>
                            Create data file per file specification found{' '}
                            <a href="#">here</a>. Each valid data share file transmission
                            will create a new <a href="#">data share request</a>. A data
                            share request will be accessible ONLY to the user that
                            transmitted the given data share file. NOTE: A data share file
                            has a maximum limit of 100,000 records per file.
                        </li>
                        <li>
                            Name the file <span className="text-strong">DS.csv</span>
                        </li>
                        <li>
                            <span className="text-strong">(Optional Step)</span> Create a
                            zip file contatining the file created in steps 1 through 2. A
                            zip file allows for compression of file for a quicker upload.
                            The zip file can have any name but must have a ".zip" file
                            extension.
                        </li>
                        <li>
                            Select the file created in steps 1 through 2 OR the zip file
                            created in step 3. Once the file is selected press the
                            "Transmit File" button.
                        </li>
                        <li>
                            Wait for upload to complete and review results of the file
                            transmission.
                        </li>
                    </ol>
                    <div className="w-100 hidden-file-input">
                        <input type="file" />
                        <Button
                            type="button"
                            outline
                            color="primary"
                            className="btn-block"
                        >
                            <FontAwesomeIcon icon={faFile} /> Choose File
                        </Button>
                    </div>
                </Section> */}
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" className="btn-lg" onClick={close}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    className="btn-lg"
                    onClick={upload}
                    disabled={disabled}
                    loading={loading}
                >
                    Transmit File
                </Button>
            </ModalFooter>
        </Modal>
    );
};
