import React, { useState, useReducer } from 'react';

import {
	FormGroup,
	Label,
	Input,
	Button,
	Form,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { capitalProjectService, financeService } from 'services';
import { onChangeFunction } from 'shared';

export const CreateCapitalProjectModal = ({ isOpen, toggle }) => {
	const history = useHistory();
	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			modalProjectName: '',
			bienniumModalValue: [],
			institutionModalValue: [],
		}
	);

	const [bienniums] = useState(financeService.getAllBienniums);
	const [institutions] = useState(financeService.getAllInstitutions);

	const loadModalBienniums = () =>
		Promise.resolve(bienniums).then((allBienniums) => {
			return allBienniums;
		});

	const loadModalInstitutions = () =>
		Promise.resolve(institutions).then((allInstitutions) => {
			return allInstitutions;
		});

	const createCapitalProject = () => {
		const postData = {
			Biennium: userInput.bienniumModalValue.value,
			institutionId: +userInput.institutionModalValue.value,
			projectName: userInput.modalProjectName,
		};
		capitalProjectService.createCapitalProject(postData, history);
		toggle();
	};

	const closeBtn = (
		<button className='close' onClick={toggle}>
			&times;
		</button>
	);

	return (
		<Modal isOpen={isOpen}>
			<ModalHeader close={closeBtn}>
				New Biennium Captial Project Request
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup>
						<label>Bienniums</label>
						<AsyncSelect
							cacheOptions
							defaultOptions
							onChange={(valueObj, options) =>
								onChangeFunction.handleAsyncSelectChange(
									valueObj,
									options,
									setUserInput
								)
							}
							name='bienniumModalValue'
							value={userInput.bienniumModalValue}
							loadOptions={loadModalBienniums}
							components={{
								IndicatorSeparator: () => null,
							}}
						/>
					</FormGroup>
					<FormGroup>
						<Label for='institutionsSelect'>Institutions</Label>
						<AsyncSelect
							cacheOptions
							defaultOptions
							onChange={(valueObj, options) =>
								onChangeFunction.handleAsyncSelectChange(
									valueObj,
									options,
									setUserInput
								)
							}
							name='institutionModalValue'
							value={userInput.institutionModalValue}
							loadOptions={loadModalInstitutions}
							components={{
								IndicatorSeparator: () => null,
							}}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Project</Label>
						<Input
							type='text'
							placeholder='Project Name/No'
							onChange={(evt) =>
								onChangeFunction.handleChange(evt, setUserInput)
							}
							name='modalProjectName'
							value={userInput.modalProjectName}
							required
						/>
					</FormGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button color='secondary' onClick={toggle}>
					Cancel
				</Button>
				<Button color='primary' onClick={createCapitalProject}>
					Begin
				</Button>{' '}
			</ModalFooter>
		</Modal>
	);
};
