import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';

export const GeneralInfo = ({ capitalProject, prevStep, nextStep }) => {
	const [projectType, setProjectType] = useState('');

	useEffect(() => {
		const getProjectTypesAndSetProjectType = () => {
			financeService.getAllProjectTypes().then((projectTypes) => {
				projectTypes.forEach((projectType) => {
					if (+projectType.value === capitalProject.projectTypeId) {
						setProjectType(projectType.label);
					}
				});
			});
		};
		getProjectTypesAndSetProjectType();
	}, [capitalProject.projectTypeId]);
	const isPreviouslyApprovedByGA = capitalProject.isPreviouslyApprovedByGA
		? 'Yes'
		: 'No';
	const isPreviouslyRecommendedCHE = capitalProject.isPreviouslyRecommendedCHE
		? 'Yes'
		: 'No';
	const isLongTermCapitalPlan = capitalProject.isLongTermCapitalPlan
		? 'Yes'
		: 'No';

	return (
		<React.Fragment>
			<h2 className='text-strong text-primary border-bottom pb-2 mb-4'>
				General Project Information
			</h2>
			<Table className='table review-table'>
				<tbody>
					<tr>
						<td className='question-label'>
							<label className='mb-0 mr-2'>Project Name:</label>
						</td>
						<td>{capitalProject.projectName}</td>
					</tr>
					<tr>
						<td className='question-label'>
							<label className='mb-0 mr-2'>Project Type:</label>
						</td>
						<td>{projectType}</td>
					</tr>
					<tr>
						<td className='question-label'>
							<label className='mb-0 mr-2'>Institutional Priority:</label>
						</td>
						<td>{capitalProject.priority}</td>
					</tr>
					<tr>
						<td className='question-label'>
							<label className='mb-0 mr-2'>Budget Agency Project No:</label>
						</td>
						<td>{capitalProject.budgetAgencyProjectNo}</td>
					</tr>
					<tr>
						<td className='question-label'>
							<label className='mb-0 mr-2'>
								Previously Approved by General Assembly?
							</label>
						</td>
						<td>{isPreviouslyApprovedByGA}</td>
					</tr>
					<tr>
						<td className='question-label'>
							<label className='mb-0 mr-2'>
								Previously Recommended by CHE?
							</label>
						</td>
						<td>{isPreviouslyRecommendedCHE}</td>
					</tr>
					<tr>
						<td className='question-label'>
							<label className='mb-0 mr-2'>
								Part of the Institution’s Long term Capital plan?
							</label>
						</td>
						<td>{isLongTermCapitalPlan}</td>
					</tr>
				</tbody>
			</Table>
			<WizardFooter
				prevStep={prevStep}
				nextStep={nextStep}
				capitalProject={capitalProject}
				isGeneralInfo={true}
			/>
		</React.Fragment>
	);
};
