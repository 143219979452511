import React from 'react';
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { UrlEnum } from 'shared';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

const actions = (cell, row, rowIndex, formatExtraData) => {
    const id = 'PopoverView-' + row.programID;
    console.log(row)
    const biennium = formatExtraData.getBienniumName(row.biennium);
    const institution = formatExtraData.getInstitutionName(row.cheFinanceUnitId);
    const schedule = formatExtraData.getScheduleName(row.scheduleTypeId);
    const status = formatExtraData.getStatusName(row.scheduleStatusId);
    return (
        <div className="d-flex">
            <Button
                className="action-circle"
                onClick={() => {
                    const location = {
                        pathname: UrlEnum.LINEITEMPERFORMANCE,
                        state: {
                            row,
                            labels: { biennium, institution, schedule, status },
                            exportFileTypes: formatExtraData.exportFileTypes,
                        },
                    };

                    formatExtraData.history.push(location);
                }}
            >
                <UncontrolledPopover target={id} trigger="hover" placement="bottom">
                    <PopoverBody>View</PopoverBody>
                </UncontrolledPopover>
                <FontAwesomeIcon id={id} icon={faSearchPlus} />
            </Button>
        </div>
    );
};

export const Columns = props => [
    {
        dataField: 'biennium',
        text: 'Biennium',
        sort: true,
        headerStyle: getWidthStyle(140),
        formatter: props.getBienniumName,
    },
    {
        dataField: 'scheduleTypeId',
        text: 'Schedule',
        sort: true,
        headerStyle: { minWidth: 140 },
        formatter: props.getScheduleName,
    },
    {
        dataField: 'scheduleDescription',
        text: 'Schedule Description',
        sort: true,
        headerStyle: { minWidth: 250 },
    },
    {
        dataField: 'cheFinanceUnitId',
        text: 'Institution',
        sort: true,
        headerStyle: getWidthStyle(300),
        formatter: props.getInstitutionName,
    },
    {
        dataField: 'lastUpdated',
        text: 'Last Updated',
        sort: true,
        headerStyle: getWidthStyle(140),
        formatter: d => {
            const date = new Date(d);
            return date.toLocaleDateString();
        },
    },
    {
        dataField: 'lastUpdatedByUser',
        text: 'Updated By',
        sort: true,
        headerStyle: getWidthStyle(200),
    },
    {
        dataField: 'action',
        text: '',
        formatter: actions,
        headerStyle: getWidthStyle(70),
        formatExtraData: props,
    },
];
