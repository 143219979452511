import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const Confirm = props => {
    const { title, message, isOpen, toggle, onConfirm, ...rest } = props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} {...rest}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>{message}</ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        toggle();
                        onConfirm();
                    }}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default Confirm;
