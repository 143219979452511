import React, { useState } from 'react';
import { WizardFooter } from './wizard-footer';
import { Form } from 'reactstrap';
import { capitalProjectService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    CapitalProjectErrorEnum,
    TabEnum,
    FormEnum,
    ConfirmActionModal,
    ObjectFilter,
    UrlEnum,
} from 'shared';
import { Link } from 'react-router-dom';

export const Submit = ({
    capitalProject,
    setStep,
    prevStep,
    nextStep,
    setCapitalProject,
}) => {
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
    const approveCapitalProject = async () => {
        const validationErrors = await capitalProjectService.approveCapitalProject({
            id: capitalProject.id,
            clientLastUpdateTime: capitalProject.lastUpdated,
        });
        if (Array.isArray(validationErrors) || validationErrors.length) {
            capitalProject.validationErrors = validationErrors;
            setCapitalProject({ ...capitalProject });
        } else {
            window.location.href = UrlEnum.PROJECTREVIEWSEARCH;
        }
    };

    const rejectCapitalProject = async () => {
        await capitalProjectService.rejectCapitalProject({
            id: capitalProject.id,
            clientLastUpdateTime: capitalProject.lastUpdated,
        });
        window.location.href = UrlEnum.PROJECTREVIEWSEARCH;
    };
    const displayValidationErrors = () => {
        return capitalProject.validationErrors.map((validationError, index) => {
            const filteredMessageTextToTab = ObjectFilter(
                CapitalProjectErrorEnum,
                ([name, tab]) => tab.includes(validationError.capitalProjectField)
            );
            const tabEnumName = Object.keys(filteredMessageTextToTab)[0] ?? 'SUBMIT';
            const isValidationWarning = validationError.messageType === 2;
            return (
                <li key={index}>
                    <span style={{ fontWeight: 'bold' }}>
                        {isValidationWarning ? 'WARNING:' : 'ERROR:'}
                    </span>
                    {validationError.messageText}{' '}
                    <Link
                        to={TabEnum[tabEnumName] ?? '#submit'}
                        onClick={() => {
                            setStep(FormEnum[tabEnumName]);
                            window.location.href = TabEnum[tabEnumName];
                        }}
                    >
                        Go To
                    </Link>
                </li>
            );
        });
    };

    const CapitalProjectValidationErrors = () => {
        const IsAnyValidationErrors =
            !capitalProject.validationErrors ||
            capitalProject.validationErrors.length === 0;
        return IsAnyValidationErrors ? (
            <React.Fragment />
        ) : (
            <div className="alert alert-danger mt-3">
                <div className="error-list">
                    <span>Please check the following errors/warnings:</span>
                    <ul>{displayValidationErrors()}</ul>
                </div>
            </div>
        );
    };

    const CapitalProjectApprovedDisclaimer = () => {
        const projectApprovedDisclaimer = (
            <div className="alert alert-success mt-3">
                <span>This Project has been approved already</span>
            </div>
        );
        return capitalProject.isSubmitted && capitalProject.isReviewed
            ? projectApprovedDisclaimer
            : null;
    };

    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Complete Review{' '}
            </h2>
            <p>Please click the button below to complete your project review.</p>
            <button
                type="button"
                className="btn btn-danger btn-shadow btn-lg"
                onClick={() => setIsRejectModalOpen(true)}
            >
                <FontAwesomeIcon icon={faTimes} /> {'Reject Project Review'}
            </button>
            {'		'}
            <button
                type="button"
                className="btn btn-success btn-shadow btn-lg"
                onClick={() => setIsAcceptModalOpen(true)}
            >
                <FontAwesomeIcon icon={faCheck} /> {'Complete Project Review'}
            </button>

            {CapitalProjectApprovedDisclaimer()}

            {CapitalProjectValidationErrors()}
            <ConfirmActionModal
                isOpen={isAcceptModalOpen}
                toggle={() => setIsAcceptModalOpen(!isAcceptModalOpen)}
                header={`Confirm Approval of ${capitalProject.projectName}`}
                message={`Are you sure you want to approve ${capitalProject.projectName}?`}
                acceptButtonText={'Approve'}
                acceptFunction={() => approveCapitalProject()}
                acceptButtonCss="btn btn-success"
            />
            <ConfirmActionModal
                isOpen={isRejectModalOpen}
                toggle={() => setIsRejectModalOpen(!isRejectModalOpen)}
                header={`Confirm Reject ${capitalProject.projectName}`}
                message={`Are you sure you want to reject ${capitalProject.projectName}?`}
                acceptButtonText={'Reject'}
                acceptFunction={() => rejectCapitalProject()}
                acceptButtonCss="btn btn-danger"
            />

            <WizardFooter prevStep={prevStep} nextStep={nextStep} isSubmit={true} />
        </Form>
    );
};
