import React, { useEffect, useReducer, useState } from 'react';
import { Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, FormSelect } from '../../blocks'
import { FormInput } from 'components/blocks';
import { createAcademicSystem, getCreateAcademicSystemOptions, validateCheUnitId } from 'services/admin-services/academic-institutions.service';
import { useToastState } from 'shared';

export const InstitutionAddSystemModal = ({
    isOpen,
    toggle
}) => {
    const reducer = (state, newState) => ({ ...state, ...newState });

    const { setToast } = useToastState();

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [options, setOptions] = useReducer(reducer, {});
    const [invalidId, setInvalidId] = useState(false);
    const [missingName, setMissingName] = useState(false);
    const [missingId, setMissingId] = useState(false);
    const [missingSector, setMissingSector] = useState(false);
    const [missingDegree, setMissingDegree] = useState(false);
    const [missingReport, setMissingReport] = useState(false);
    const [missingResearch, setMissingResearch] = useState(false);
    
    function clearErrors() {
        setMissingDegree(false);
        setMissingSector(false);
        setMissingReport(false);
        setMissingResearch(false);
        setMissingId(false);
        setMissingName(false);
    }

    function empty(object) {
        return Object.keys(object).length == 0
    }

    // true if all are valid
    function validateRequiredFields(system) {
        var varMissingName = system.cheUnitName == "";
        setMissingName(varMissingName)
        var varMissingId = system.systemId == "";
        setMissingId(varMissingId)
        var varMissingSector = system.sectorId == ""
        setMissingSector(varMissingSector)
        var varMissingDegree = system.degreeGrantingStatusId == ""
        setMissingDegree(varMissingDegree)
        var varMissingReport = system.reportingGroupCode == ""
        setMissingReport(varMissingReport)
        var varMissingResearch = system.institutionResearchClassificationCode == ""
        setMissingResearch(varMissingResearch)

        let missingSomething = (varMissingName || varMissingId || varMissingSector || varMissingDegree || varMissingReport || varMissingResearch);

        console.log(missingSomething)

        return !missingSomething;
    }

    const [system, setSystem] = useReducer(reducer, {
        cheUnitName: "",
        cheUnitAbbreviation: "",
        systemId: "",
        active: true,
        sectorId: {}, // These will initially be set to option objects from the FormSelect
        degreeGrantingStatusId: {},
        reportingGroupCode: {},
        institutionResearchClassificationCode: {}
    });

    // This will prepare the system object to be sent over the server
    function prepareSystem(system) {
        let newSystem = Object.assign({}, system)

        for(const key in newSystem) {
            if (!['sectorId', 'degreeGrantingStatusId', 'reportingGroupCode', 'institutionResearchClassificationCode'].includes(key)) {
                continue;
            }
            if(newSystem[key].hasOwnProperty('value')) {
                newSystem[key] = newSystem[key]['value']
            } else if (empty(newSystem[key])) {
                newSystem[key] = ""
            }
        }

        return newSystem 
    }

    // Makes post to create the system to the backend
    const create = async () => {
        let systemToSend = prepareSystem(system)
        console.log(systemToSend)

        if (!validateRequiredFields(systemToSend)) {
            return
        }

        setLoading(true);

        const validUnitId = await validateCheUnitId(systemToSend.systemId)

        if (!validUnitId) {
            setLoading(false);
            setInvalidId(true);
            return
        }

        const systemCreated = await createAcademicSystem(systemToSend)
        setLoading(false);
        toggle();
        if (systemCreated) {
            setToast({
                title: 'Success!',
                message: 'System successfully created'
            })
        }
    }

    // Grab all the options for the form on component creation
    useEffect(() => {
        let logic = async () => {
            const sys_options = await getCreateAcademicSystemOptions();
            console.log(sys_options);
            setOptions(sys_options);
        }
        logic()
    }, [])

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-md">
            <ModalHeader>Create a New System</ModalHeader>
            <ModalBody>
                <Form>
                    <div className="form-row">

                        <div className="col-md-8">
                            <FormInput 
                                label="Name"
                                required={true}
                                value={system.name}
                                name="cheUnitName"
                                onChange={setSystem}
                                onClick={clearErrors}
                            ></FormInput>
                            {missingName && <p className="text-danger">
                                Name is required
                            </p>}
                        </div>
                        <FormInput 
                            label="Abbreviation"
                            required={false}
                            value={system.abbr}
                            name="cheUnitAbbreviation"
                            onChange={setSystem}
                            className="col-md-4"
                        ></FormInput>
                    </div>
                    <div className="form-row">
                        <div className="col-md">
                            <FormInput 
                                label="CHEUnitId"
                                required={true}
                                value={system.unitId}
                                name="systemId"
                                onChange={setSystem}
                                onClick={clearErrors}
                            ></FormInput>
                            {invalidId && <p className="text-danger">
                                ID taken, please choose another
                            </p>}
                            {missingId && <p className="text-danger">
                                ID is required
                            </p>}
                       </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6">
                            <FormSelect
                                label="Sector"
                                required={true}
                                onChange={setSystem}
                                options={
                                    options.sectors ?
                                    options.sectors.map(
                                        o => {
                                            return {
                                                'label': o.sectorName,
                                                'value': o.sectorId
                                            }
                                        }
                                    ) : []
                                }
                                name="sectorId"
                                value={system.sectorId}
                                isMulti={false}
                                getOptionLabel={null}
                                onClick={clearErrors}
                            ></FormSelect>
                            {missingSector && <p className="text-danger">
                                Sector is required
                            </p>}
                        </div>

                        <div className="col-md-6">
                            <FormSelect
                                label="Degree Granting Status"
                                required={true}
                                onChange={setSystem}
                                options={
                                    options.degreeGrantingStatusId ?
                                    options.degreeGrantingStatusId.map(
                                        o => {
                                            return {
                                                'label': o.degreeGrantingStatus,
                                                'value': o.degreeGrantingStatusId
                                            }
                                        }
                                    ) : []
                                }
                                name="degreeGrantingStatusId"
                                value={system.degreeGrantingStatusId}
                                isMulti={false}
                                getOptionLabel={null}
                                onClick={clearErrors}
                            ></FormSelect>
                            {missingDegree && <p className="text-danger">
                                Degree Granting Status is required
                            </p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6">
                            <FormSelect
                                label="Reporting Group"
                                required={true}
                                onChange={setSystem}
                                options={
                                    options.reportingGroupCode ?
                                    options.reportingGroupCode.map(
                                        o => {
                                            return {
                                                'label': o.reportingGroupName,
                                                'value': o.reportingGroupCode
                                            }
                                        }
                                    ) : []
                                }
                                name="reportingGroupCode"
                                value={system.reportingGroupCode}
                                isMulti={false}
                                getOptionLabel={null}
                                onClick={clearErrors}
                            ></FormSelect>
                            {missingReport && <p className="text-danger">
                                Reporting Group is required
                            </p>}
                        </div>
                        <div className="col-md-6">
                            <FormSelect
                                label="Research Classification"
                                required={true}
                                onChange={setSystem}
                                options={
                                    options.institutionResearchClassificationCode ?
                                    options.institutionResearchClassificationCode
                                    .map(
                                        o => {
                                            return {
                                                'label': o.institutionResearchClassification1,
                                                'value': o.institutionResearchClassificationCode
                                            }
                                        }
                                    ) : []
                                }
                                name="institutionResearchClassificationCode"
                                value={system.institutionResearchClassificationCode}
                                isMulti={false}
                                onClick={clearErrors}
                                getOptionLabel={null}
                            ></FormSelect>
                            {missingResearch && <p className="text-danger">
                                Research Classification is required
                            </p>}
                        </div>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="secondary" 
                    onClick={toggle}
                >
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    disabled={disabled}
                    loading={loading}
                    onClick={create}
                >
                    Create System 
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}