import { authHeader } from 'helpers/auth-header';
import { handleAxiosResponse, api_base_url, axiosInstance } from '../common.service';

import { handleError } from 'shared';
import { convertApiResponseToSearchOptions } from '../shared-service-functions';

const getBienniums = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/bienniums`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const getInstitutions = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/institutions`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return convertApiResponseToSearchOptions(res);
        })
        .catch(error => {
            handleError(error);
        });
};

const getStatuses = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/statuses`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const getBudgetScheduleTypes = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/types`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const getPerformanceScheduleTypes = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/PerformanceSchedules/types`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const getBudgetScheduleWithId = id => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/${id}`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const createNewLineItem = (institutionId, bienniumId, postBody) => {
    return axiosInstance
        .post(
            `${api_base_url}/api/finance/BudgetSchedules/lineItems/institution/${institutionId}/biennium/${bienniumId}`,
            postBody,
            {
                headers: authHeader(),
            }
        )
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const createLineItem = postBody => {
    return axiosInstance
        .post(`${api_base_url}/api/finance/BudgetSchedules/lineItems`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const saveLineItem = (id, postBody) => {
    return axiosInstance
        .patch(`${api_base_url}/api/finance/BudgetSchedules/${id}`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const deleteLineItemWithId = id => {
    return axiosInstance
        .delete(`${api_base_url}/api/finance/BudgetSchedules/${id}`, {
            headers: authHeader(),
        })
        .then(res => {})
        .catch(error => {
            handleError(error);
        });
};

const getLineItemsForNewModal = (institutionId, bienniumId) => {
    return (
        axiosInstance
            .get(
                `${api_base_url}/api/finance/BudgetSchedules/lineItems/institution/${institutionId}/biennium/${bienniumId}`,
                {
                    headers: authHeader(),
                }
            )
            .then(handleAxiosResponse)
            // .then(res => {
            //     return convertAbnormalApiResponseToSearchOptions(
            //         res,
            //         'budgetLineItemId',
            //         'budgetLineItemName'
            //     );
            // })
            .catch(error => {
                handleError(error);
                // return fakeResponses.lineItem();
            })
    );
};

const searchLineItems = query => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules?${query}`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            // handleError(error);
            // return fakeResponses.searchLineItems();
        });
};

const getLineItemCategories = () => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/lineitems`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const getLineItemWithId = id => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/lineitems/${id}`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const getDescription = (id, pdf = false) => {
    if (pdf)
        return axiosInstance
            .get(
                `${api_base_url}/api/finance/BudgetSchedules/lineItems/description/${id}?pdf=true`,
                {
                    headers: authHeader(),
                    responseType: 'blob',
                }
            )
            .then(handleAxiosResponse)
            .then(res => {
                const blob = new Blob([res], { encoding: 'UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `LineItemDescription - ${id}.pdf`;
                link.click();
            })
            .catch(error => {
                handleError(error);
            });
    return axiosInstance
        .get(`${api_base_url}/api/finance/BudgetSchedules/lineItems/description/${id}`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const uploadDescriptionText = (id, postBody) => {
    return axiosInstance
        .post(
            `${api_base_url}/api/finance/BudgetSchedules/lineItems/description/text/${id}`,
            postBody,
            {
                headers: authHeader(),
            }
        )
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const uploadDescriptionPDF = (id, formData) => {
    const url = `${api_base_url}/api/finance/BudgetSchedules/lineItems/description/pdf/${id}`;

    return axiosInstance
        .post(url, formData, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

const downloadLineItem = (fileType, projectId) => {
    const url = `${api_base_url}/api/finance/BudgetSchedules/export/${fileType}/${projectId}`;

    return axiosInstance
        .get(url, {
            headers: authHeader(),
            responseType: 'arraybuffer',
        })
        .catch(error => {
            handleError(error);
        });
};

const getExportTypes = () => {
    const url = `${api_base_url}/api/finance/BudgetSchedules/exportFileTypes`;

    return axiosInstance
        .get(url, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .catch(error => {
            handleError(error);
        });
};

export const LineItemService = {
    getBudgetScheduleWithId,
    createNewLineItem,
    createLineItem,
    saveLineItem,
    deleteLineItemWithId,
    getLineItemsForNewModal,
    getLineItemsForNewModal,
    searchLineItems,
    getBienniums,
    getInstitutions,
    getStatuses,
    getPerformanceScheduleTypes,
    getBudgetScheduleTypes,
    getLineItemCategories,
    getDescription,
    uploadDescriptionText,
    uploadDescriptionPDF,
    getLineItemWithId,
    downloadLineItem,
    getExportTypes,
};
