import { authHeader } from 'helpers/auth-header';
import { handleAxiosResponse, api_base_url, axiosInstance } from './common.service';
import { handleError } from 'shared';

const get = ({ url, convertFunction, customHandleError, headers = {}, params = {} }) => {
    return axiosInstance
        .get(`${api_base_url}${url}`, {
            headers: { ...authHeader(), ...headers },
            ...params,
        })
        .then(handleAxiosResponse)
        .then(res => {
            if (convertFunction) {
                return convertFunction(res);
            } else {
                return res;
            }
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

const getWithParams = ({ url, params, convertFunction, customHandleError }) => {
    return axiosInstance
        .get(`${api_base_url}${url}`, {
            headers: authHeader(),
            params: params,
        })
        .then(handleAxiosResponse)
        .then(res => {
            if (convertFunction) {
                return convertFunction(res);
            } else {
                return res;
            }
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

const getWithBody = ({ url, body, convertFunction, customHandleError }) => {
    return axiosInstance
        .get(
            `${api_base_url}${url}`,
            JSON.stringify({
                headers: authHeader(),
                body: body,
            })
        )
        .then(handleAxiosResponse)
        .then(res => {
            if (convertFunction) {
                return convertFunction(res);
            } else {
                return res;
            }
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

const post = ({
    url,
    postBody,
    convertFunction,
    onCompleteFunction,
    customHandleError,
    headers,
}) => {
    return axiosInstance
        .post(`${api_base_url}${url}`, postBody, {
            headers: {
                ...authHeader(),
                ...headers,
            },
        })
        .then(handleAxiosResponse)
        .then(res => {
            if (convertFunction) {
                return convertFunction(res);
            } else if (onCompleteFunction) {
                onCompleteFunction();
                return res;
            } else {
                return res;
            }
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

const put = ({
    url,
    postBody,
    convertFunction,
    onCompleteFunction,
    customHandleError,
    headers,
}) => {
    return axiosInstance
        .put(`${api_base_url}${url}`, postBody, {
            headers: {
                ...authHeader(),
                ...headers,
            },
        })
        .then(handleAxiosResponse)
        .then(res => {
            if (convertFunction) {
                return convertFunction(res);
            } else if (onCompleteFunction) {
                onCompleteFunction();
                return res;
            } else {
                return res;
            }
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

const patch = ({
    url,
    patchBody,
    convertFunction,
    onCompleteFunction,
    customHandleError,
}) => {
    return axiosInstance
        .patch(`${api_base_url}${url}`, patchBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            if (convertFunction) {
                return convertFunction(res);
            } else if (onCompleteFunction) {
                onCompleteFunction();
                return res;
            } else {
                return res;
            }
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

const deleteItem = ({ url, onCompleteFunction, customHandleError }) => {
    return axiosInstance
        .delete(`${api_base_url}${url}`, {
            headers: authHeader(),
        })
        .then(res => {
            if (onCompleteFunction) {
                onCompleteFunction();
                return res;
            } else {
                return res;
            }
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

const download = ({ url, filename, ext, customHandleError }) => {
    return axiosInstance
        .get(`${api_base_url}${url}`, {
            headers: authHeader(),
            responseType: 'blob',
        })
        .then(res => {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${filename}${ext}`;
            link.click();
            link.remove();
        })
        .catch(error => {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                return handleError(error);
            }
        });
};

export const handleServiceRequest = {
    get,
    getWithBody,
    getWithParams,
    post,
    deleteItem,
    patch,
    put,
    download,
};
