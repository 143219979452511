import { handleServiceRequest } from '../handle-service-request';

const baseUrl = `/api/CollectionProcessing`;

export const getActionLogs = id => {
    const getObject = {
        url: `${baseUrl}/Logs/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

export const getOptions = () => {
    const getObject = {
        url: `${baseUrl}/ActionOptions`,
    };
    return handleServiceRequest.get(getObject);
};

export const commitAction = (actionId, reportingYear) => {
    const getObject = {
        url: `${baseUrl}/Run/${actionId}/${reportingYear}`,
    };
    return handleServiceRequest.get(getObject);
};

export const CollectionProcessingService = {
    getActionLogs,
    commitAction,
    getOptions,
};
