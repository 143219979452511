import React, { useReducer } from 'react';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import { GenericRadioButtons } from 'shared';

export const GeneralInfo = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 1,
            projectName: budgetRun.budgetRunTitle ? budgetRun.budgetRunTitle : '',
            output_IncludeCashInOverview: budgetRun.output_IncludeCashInOverview,
            output_IsRecommendation: budgetRun.output_IsRecommendation,
            output_CreateBooks: budgetRun.output_CreateBooks,
            output_IncludeOthDedLnItms: budgetRun.output_IncludeOthDedLnItms,
            budgetRunNotes: budgetRun.budgetRunNotes ?? '',
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        budgetRun[name] = newValue;
        setUserInput({ [name]: newValue });
    };
    const handleRadioButtonChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value === 'true' ? true : false;
        budgetRun[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    const sanitizeBudgetRun = () => {
        budgetRun.budgetRunTitle = userInput.projectName;
        budgetRun.output_IncludeCashInOverview = userInput.output_IncludeCashInOverview;
        budgetRun.output_IsRecommendation = userInput.output_IsRecommendation;
        budgetRun.output_CreateBooks = userInput.output_CreateBooks;
        budgetRun.output_IncludeOthDedLnItms = userInput.output_IncludeOthDedLnItms;
        budgetRun.budgetRunNotes = userInput.budgetRunNotes;
        return budgetRun;
    };


    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">General</h2>
            <Row mb="4">
                <Col mb="3">
                    <FormGroup>
                        <Label>
                            Budget Run Title <sup className="text-danger">*</sup>
                        </Label>
                        <Input
                            type="text"
                            placeholder="Project Name/Title"
                            onChange={handleChange}
                            name="projectName"
                            value={userInput.projectName}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row mb="2" className="align-items-center mb-2">
                <Col className="col-md mb-3 mb-md-0">
                    <label className="d-block">
                        Include Capital Project (Cash) in the Overview Sections?
                    </label>
                    <p className="mb-0 text-italic">
                        This setting determines if the capital project (cash) lines are
                        displayed (and included in totals) on the overview sheets.
                    </p>
                </Col>
                <Col className="col-md">
                    <GenericRadioButtons
                        name="output_IncludeCashInOverview"
                        onChange={handleRadioButtonChange}
                        value={userInput.output_IncludeCashInOverview}
                    ></GenericRadioButtons>
                </Col>
            </Row>
            <hr />
            <Row mb="2" className="align-items-center mb-2">
                <Col className="col-md mb-3 mb-md-0">
                    <label className="d-block">Is this a Recommendation?</label>
                    <p className="mb-0 text-italic">
                        This setting determines if the label "Recommended" or "Proposed"
                        is used on any sheets displaying budget run input selections.
                        Typically, only the final run for a biennium is an actual
                        recommendation.
                    </p>
                </Col>
                <Col className="col-md">
                    <GenericRadioButtons
                        name="output_IsRecommendation"
                        onChange={handleRadioButtonChange}
                        value={userInput.output_IsRecommendation}
                    ></GenericRadioButtons>
                </Col>
            </Row>
            <hr />
            <Row mb="2" className="align-items-center mb-2">
                <Col className="col-md mb-3 mb-md-0">
                    <label className="d-block">Include "Book" Output?</label>
                    <p className="mb-0 text-italic">
                        This setting determines whether a book (i.e. large pdf) is created
                        for each institution. Creating books significantly increases
                        budget run time.
                    </p>
                </Col>
                <Col className="col-md">
                    <GenericRadioButtons
                        name="output_CreateBooks"
                        onChange={handleRadioButtonChange}
                        value={userInput.output_CreateBooks}
                    ></GenericRadioButtons>
                </Col>
            </Row>
            <hr />
            <Row mb="2" className="align-items-center mb-2">
                <Col className="col-md mb-3 mb-md-0">
                    <label className="d-block">Include Other Dedicated Line Items?</label>
                    <p className="mb-0 text-italic">
                        This setting determines whether other dedicated line items (i.e.
                        non BIF) are displayed in line item output sheets.
                    </p>
                </Col>
                <Col className="col-md">
                    <GenericRadioButtons
                        name="output_IncludeOthDedLnItms"
                        onChange={handleRadioButtonChange}
                        value={userInput.output_IncludeOthDedLnItms}
                    ></GenericRadioButtons>
                </Col>
            </Row>
            <hr />
            <div className="card no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Budget Run Notes</h4>
                <Input
                    type="textarea"
                    rows="7"
                    placeholder="Notes"
                    name="budgetRunNotes"
                    onChange={handleChange}
                    value={userInput.budgetRunNotes}
                />
            </div>

            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={true}
                sanitizeBudgetRun={sanitizeBudgetRun}
                setBudgetRun={setBudgetRun}
            />
        </Form>
    );
};
