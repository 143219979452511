import React, { useReducer, useEffect, useCallback, useMemo, useState } from 'react';

import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createAcademicSystem, validateCheUnitId, validateCheOpeId, validateCheIpedsId } from 'services/admin-services/academic-institutions.service';
import { FormInput } from 'components/blocks';
import { academicInstitutionsService } from 'services';
import { FormSelect } from './components/FormSelect';
import { ReactReduxContext } from 'react-redux';
import Select from 'react-select';

export const AcademicAffairAddInstitutionModal = ({
    isOpen,
    toggle,
    addNewInstitution,
    reportingGroupCodeOptions,
    sectorSelectOptions,
    InstitutionResearchClassificationCode,
}) => {

    
    
    const sectorSelectOptionLabels = sectorSelectOptions.map(x => {return {label: x.sectorName, value: x.sectorId}});
    const reportingGroupCodeLabels = reportingGroupCodeOptions.map(x => {return {label: x.reportingGroupName, value: x.reportingGroupCode}});
    const researchClassificationCodeLabels = InstitutionResearchClassificationCode.map(x => {return {label: x.institutionResearchClassification1, value: x.institutionResearchClassificationCode}});
    const reducer = (state, newState) => ({ ...state, ...newState });
    const [options, setOptions] = useReducer(reducer, { campuses: [] });
    const [loading, setLoading] = useState(false);
    const [invalidId, setInvalidId] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [missingName, setMissingName] = useState(false);
    const [missingCHEId, setMissingCHEId] = useState(false);
    const [systemId, setSystemId] = useState("");


    const [institution, setInstitution] = useReducer(reducer, {
        InstitutionName: "",
        CHEInstitutionUnitId: "",
        CHESystemUnitId: "",
        SectorId: {},
        DegreeGrantingStatusId: true,
        Active: true,
        CHEInstitutionStateCode: "IN",
        ReportingGroupCode: {},
        InstitutionResearchClassificationCode: "",
        InstitutionAbbreviation: "",
    });

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            institutions: [],
        }
    );




    const handleSectorSelectChange = evt => {
        console.log(evt.value);
        setInstitution(prev => ({
            ...prev,
            SectorId: evt.value
        }));
    };

    const handleReportingGroupCodeChange = evt => {
        setInstitution(prev => ({
            ...prev,
            ReportingGroupCode: evt.value
            
        }));
    };


    const getSectorOption = val => {
        console.log(val)
        return sectorSelectOptionLabels.find(x => x.value === val);
    };

    const getReportingGroupCode = val => {
        return reportingGroupCodeLabels.find(x => x.value === val);
    }

    function empty(object) {
        return Object.keys(object).length == 0
    }

    
    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    

    function clearErrors() {
        setMissingCHEId(false);
        setMissingName(false);
    }


    function prepareInstitution(institution) {
        let newInstitution = Object.assign({}, institution)

        for(const key in newInstitution) {
            if (!['sectorId', 'reportingGroupCode'].includes(key)) {
                continue;
            }
            if(newInstitution[key].hasOwnProperty('value')) {
                newInstitution[key] = newInstitution[key]['value']
            } else if (empty(newInstitution[key])) {
                newInstitution[key] = ""
            }
        }

        return newInstitution 
    }

    function validateRequiredFields(institution) {
        var varMissingName = institution.InstitutionName == "";
        setMissingName(varMissingName)
        var varMissingId = institution.CHEInstitutionUnitId == "";
        setMissingCHEId(varMissingId)


        let missingSomething = (varMissingName || varMissingId );

        return !missingSomething;
    }


    // Makes post to create the system to the backend
    const create = async () => {
        console.log(institution)
        let institutionToSend = prepareInstitution(institution)
        console.log(institutionToSend)

        if (!validateRequiredFields(institutionToSend)) {
            return
        }

        setLoading(true);

        const validUnitId = await validateCheUnitId(institutionToSend.CHEInstitutionUnitId)

        if (!validUnitId) {
            setLoading(false);
            setInvalidId(true);
            return
        }

        const institutionAdded = addNewInstitution(institutionToSend)
        setLoading(false);
        toggle();
        
    }

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-md">
            <ModalHeader>Create a New Institution</ModalHeader>
            <ModalBody>
                <Form>
                    <div className="form-row">

                        <div className="col-md-8">
                            <FormInput 
                                label="Name"
                                required={true}
                                value={institution.InstitutionName}
                                name="InstitutionName"
                                onChange={setInstitution}
                                onClick={clearErrors}
                            ></FormInput>
                            {missingName && <p className="text-danger">
                                Institution Name is required
                            </p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div>
                            <FormInput 
                                label="CHEUnitId"
                                required={true}
                                value={institution.CHEInstitutionUnitId}
                                name="CHEInstitutionUnitId"
                                onChange={setInstitution}
                                className="col-md-8"
                            ></FormInput>
                            {invalidId && <p className="text-danger">
                                    ID taken, please choose another
                                </p>}
                                {missingCHEId && <p className="text-danger">
                                    ID is required
                                </p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md">
                        <FormSelect
                            label="Sector"
                            onChange={setInstitution}
                            name="SectorId"
                            options={sectorSelectOptionLabels}
                            value={institution.SectorId}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                        />
                       </div>
                       <div className="col-md">
                        <FormSelect
                            onChange={setInstitution}
                            options={reportingGroupCodeLabels}
                            value={institution.ReportingGroupCode}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            label="Reporting Group Code"
                            name="ReportingGroupCode"
                        />
                       </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-8">
                            <FormInput 
                                label="Abbreviation"
                                required={false}
                                value={institution.InstitutionAbbreviation}
                                name="InstitutionAbbreviation"
                                onChange={setInstitution}
                            ></FormInput>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md">
                            <FormSelect
                                onChange={setInstitution}
                                options={researchClassificationCodeLabels}
                                value={institution.InstitutionResearchClassificationCode}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                label="Research Classification"
                                name="InstitutionResearchClassificationCode"
                            />
                       </div>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="secondary" 
                    onClick={toggle}
                >
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    disabled={disabled}
                    loading={loading}
                    onClick={create}
                >
                    Add Institution 
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
};