import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { Card, Container } from 'reactstrap';

import { partitionToggleService } from 'services';
import { Toggle } from './components';
import { Button } from '../blocks';

export const PartitionToggle = () => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchOptions = async () => {
        try {
            const res = await partitionToggleService.getToggles();
            setOptions(res);
        } catch (error) {
            console.error(error);
        }
    };
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const res = await partitionToggleService.postToggles(options);
            setOptions(res);
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }, [options]);
    const onToggleChange = (id, value) => {
        setOptions(prev => {
            return prev.map(p => {
                if (p.id === id) {
                    return { ...p, isEnabled: value };
                }
                return p;
            });
        });
    };
    const renderOption = useCallback(
        (item, i) => {
            return (
                <Fragment key={item.id}>
                    <Toggle
                        id={item.id}
                        label={item.featureName}
                        value={item.isEnabled}
                        onChange={onToggleChange}
                    />
                    {i !== options.length - 1 && <hr />}
                </Fragment>
            );
        },
        [options.length]
    );
    useEffect(() => {
        fetchOptions();
    }, []);
    return (
        <Container fluid>
            <Card className="p-3">
                <div className="mb-4 d-sm-flex text-center text-sm-left align-items-center justify-content-between">
                    <h3 className="mb-3 mb-sm-0">Toggle Visibility</h3>
                    <Button
                        color="success"
                        className="btn-shadow px-4"
                        onClick={onSubmit}
                        loading={loading}
                    >
                        Save Changes
                    </Button>
                </div>
                {options.map(renderOption)}
            </Card>
        </Container>
    );
};
