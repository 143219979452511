import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { capitalProjectService, financeService } from 'services';

import { onChangeFunction } from 'shared';

export const SearchCapitalProjectReviewForm = props => {
    const { setCapitalProjects } = props;
    const allValue = { value: 'all', label: 'All' };
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            searchProjectName: '',
            bienniumSearchValue: [],
            institutionSearchValue: [],
            submitStatusValue: allValue,
        }
    );

    useEffect(() => {
        const getCapitalProjects = async () => {
            const searchPostData = {
                biennium: [],
                institutions: [],
                searchText: null,
                isSubmitted: null,
                isReview: true,
            };
            const res = await capitalProjectService.searchCapitalProjects(searchPostData);
            setCapitalProjects(res || []);
        };
        getCapitalProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [bienniums] = useState(financeService.getAllBienniums);
    const [institutions] = useState(financeService.getAllInstitutions);

    const extractValuesFromResponse = unextractedResponseValues => {
        return unextractedResponseValues?.reduce((acc, currentValue) => {
            return acc.concat(currentValue.value);
        }, []);
    };

    const submitForm = () => {
        const actualBienniumValue = extractValuesFromResponse(
            userInput.bienniumSearchValue || []
        );
        const actualinstitutionValue = extractValuesFromResponse(
            userInput.institutionSearchValue || []
        );

        const isSubmittedHasAllValue = userInput.submitStatusValue
            ? userInput.submitStatusValue.value === allValue.value
            : null;
        const actualisSubmittedValue = isSubmittedHasAllValue
            ? null
            : userInput.submitStatusValue.value;

        const searchPostData = {
            biennium: actualBienniumValue.map(value => +value),
            institutions: actualinstitutionValue,
            searchText:
                userInput.searchProjectName === '' ? null : userInput.searchProjectName,
            isSubmitted: actualisSubmittedValue,
            isReview: true,
        };

        Promise.resolve(capitalProjectService.searchCapitalProjects(searchPostData)).then(
            postRes => {
                setCapitalProjects(postRes || []);
                return postRes;
            }
        );
    };

    const loadBienniums = () => Promise.resolve(bienniums);
    const loadInstitutions = () => Promise.resolve(institutions);

    const submitStatusValues = [
        allValue,
        { value: true, label: 'Submitted' },
        { value: false, label: 'Not Submitted' },
    ];

    return (
        <div>
            <Card className="card mb-3 p-3">
                <h4 className="text-muted mb-4">Search Capital Project Reviews</h4>
                <FormGroup>
                    <label>Project</label>
                    <Input
                        type="text"
                        placeholder="Project Name/Title"
                        onChange={evt => onChangeFunction.handleChange(evt, setUserInput)}
                        name="searchProjectName"
                        value={userInput.searchProjectName}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <label>Bienniums</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        isMulti
                        isSearchable
                        placeholder="All"
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleAsyncSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        name="bienniumSearchValue"
                        value={userInput.bienniumSearchValue}
                        loadOptions={loadBienniums}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Institutions</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        isMulti
                        isSearchable
                        placeholder="All"
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleAsyncSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        name="institutionSearchValue"
                        value={userInput.institutionSearchValue}
                        loadOptions={loadInstitutions}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="submitStatusSelect">Submit Status</Label>

                    <Select
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        options={submitStatusValues}
                        placeholder="Year"
                        name="submitStatusValue"
                        value={userInput.submitStatusValue}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <button
                    className="btn btn-block btn-primary"
                    color="success"
                    onClick={() => {
                        submitForm();
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} /> Search
                </button>
            </Card>
        </div>
    );
};

SearchCapitalProjectReviewForm.propTypes = {
    setCapitalProjects: PropTypes.func.isRequired,
};
