import React, { useEffect } from 'react';
import { Table, InputGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });
export const format = number =>
    number.toLocaleString('en-US', {
        style: 'currency',
        maximumFractionDigits: 0,
        currency: 'USD',
    });

export const SummaryBudgetRequestTable = ({
    biennium,
    lineItemCategories,
    lineItemValues,
    setLineItemValues,
    setModalObject,
    setIsEditPrePopulatedModalOpen,
    mapOfTotals,
    disabled,
    setMapOfTotals,
}) => {
    const getBienniumHeaders = biennium => {
        if (biennium) {
            const bMinusZeroBienniumEndYear = biennium.split('-')[0];
            const firstYear = +bMinusZeroBienniumEndYear - 7;
            const bienniumHeaders = [];
            for (let i = 0; i < 9; i += 1) {
                const firstYearString = (firstYear + i).toString();
                const secondYearString = (firstYear + i + 1).toString();
                const secondYearShortened = secondYearString[2] + secondYearString[3];
                const bienniumHeader = firstYearString + '-' + secondYearShortened;
                bienniumHeaders.push(bienniumHeader);
            }
            return bienniumHeaders;
        } else {
            return [];
        }
    };
    const bienniumHeaders = getBienniumHeaders(biennium);
    const valueNameArray = [
        'bminus6Val',
        'bminus5Val',
        'bminus4Val',
        'bminus3Val',
        'bminus2Val',
        'bminus1Val',
        'bminus0Val',
        'bplus1Val',
        'bplus2Val',
    ];
    const getTotalOperatingBudget = totalsMap => {
        const operatingBudgetMap = new Map();
        if (totalsMap && totalsMap.size !== 0) {
            lineItemCategories.forEach(lineItemCategory => {
                if (lineItemCategory.lineItemCategoryName !== 'Funding') {
                    valueNameArray.forEach(valueName => {
                        const valueNameTotal = totalsMap
                            .get(lineItemCategory.lineItemCategoryName)
                            .get(valueName);
                        const valueToAdd = operatingBudgetMap.has(valueName)
                            ? operatingBudgetMap.get(valueName)
                            : 0;
                        operatingBudgetMap.set(valueName, +valueToAdd + +valueNameTotal);
                    });
                }
            });
        }
        return operatingBudgetMap;
    };

    const getTotals = () => {
        const newMapOfTotals = new Map();
        lineItemCategories.forEach(lineItemCategory => {
            const totalMap = new Map();
            lineItemCategory.lineItems.forEach(lineItem => {
                [...lineItemValues.keys()].forEach(k => {
                    if (k === lineItem.lineItemName) {
                        const currentLineItem = lineItemValues.get(k);
                        valueNameArray.forEach(valueName => {
                            const valueToAdd = totalMap.has(valueName)
                                ? totalMap.get(valueName)
                                : 0;
                            const newTotal = +valueToAdd + +currentLineItem[valueName];
                            totalMap.set(valueName, newTotal);
                        });
                    }
                });
            });
            newMapOfTotals.set(lineItemCategory.lineItemCategoryName, totalMap);
        });
        newMapOfTotals.set('Operating Budget', getTotalOperatingBudget(newMapOfTotals));
        return newMapOfTotals;
    };

    useEffect(() => {
        setMapOfTotals(getTotals());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItemValues, lineItemCategories]);

    const DisplayTotal = ({ nameOfTotal, categoryName }) => {
        return (
            <tr>
                <td className="nowrap">
                    <label className="m-0">{nameOfTotal}</label>
                </td>
                {valueNameArray.map(valueName => {
                    const mapOfOneCategory = mapOfTotals.has(categoryName)
                        ? mapOfTotals.get(categoryName)
                        : null;
                    const valueToAdd =
                        mapOfOneCategory && mapOfOneCategory.has(valueName)
                            ? mapOfOneCategory.get(valueName)
                            : 0;
                    return (
                        <td
                            key={'categoryName' + valueName}
                            className="text-right text-strong "
                        >
                            {format(valueToAdd)}
                        </td>
                    );
                })}
            </tr>
        );
    };

    const DisplayTotalFunding = () => {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="10"></td>
                </tr>
                <tr>
                    <td className="nowrap">
                        <h3 className="m-0">Total Funding</h3>
                    </td>
                    {valueNameArray.map(valueName => {
                        const mapOfOneCategory = mapOfTotals.has('Funding')
                            ? mapOfTotals.get('Funding')
                            : null;
                        const valueToAdd =
                            mapOfOneCategory && mapOfOneCategory.has(valueName)
                                ? mapOfOneCategory.get(valueName)
                                : 0;
                        return (
                            <td
                                key={'funding' + valueName}
                                style={getWidthStyle(100)}
                                className="text-right text-strong"
                            >
                                <h3 className="m-0">{format(valueToAdd)}</h3>
                            </td>
                        );
                    })}
                </tr>
            </React.Fragment>
        );
    };

    const RenderTotalRow = ({ categoryName }) => {
        switch (categoryName) {
            case 'Other Operating':
                return (
                    <React.Fragment>
                        {
                            <DisplayTotal
                                nameOfTotal="Total Other Operating"
                                categoryName={categoryName}
                            />
                        }
                        <DisplayTotal
                            nameOfTotal="Total Operating Budget"
                            categoryName={'Operating Budget'}
                        />
                        <tr>
                            <td colSpan="10"></td>
                        </tr>
                    </React.Fragment>
                );
            case 'Funding':
                return <DisplayTotalFunding />;
            default:
                return (
                    <React.Fragment>
                        <DisplayTotal
                            nameOfTotal={`Total ${categoryName}`}
                            categoryName={categoryName}
                        />
                        <tr>
                            <td colSpan="10"></td>
                        </tr>
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <Table
                className="table-striped table-bordered table-hover line-item-table align-middle"
                responsive
            >
                <thead>
                    <tr>
                        <th className="nowrap">Summary of Budget Request</th>
                        {bienniumHeaders?.map((bienniumHeader, index) => {
                            if (index === 5) {
                                return (
                                    <th
                                        key={bienniumHeader}
                                        className="text-center"
                                        style={getWidthStyle(100)}
                                    >
                                        PROJ
                                        <br />
                                        {bienniumHeader} (required)
                                    </th>
                                );
                            } else if (index === 6) {
                                return (
                                    <th
                                        key={bienniumHeader}
                                        className="text-center"
                                        style={getWidthStyle(100)}
                                    >
                                        BUDGET
                                        <br />
                                        {bienniumHeader} (required)
                                    </th>
                                );
                            } else if (index > 6) {
                                return (
                                    <th
                                        key={bienniumHeader}
                                        className="text-center"
                                        style={getWidthStyle(100)}
                                    >
                                        PROP
                                        <br />
                                        {bienniumHeader} (required)
                                    </th>
                                );
                            } else {
                                return (
                                    <th
                                        key={bienniumHeader}
                                        className="text-center"
                                        style={getWidthStyle(100)}
                                    >
                                        ACTUAL
                                        <br />
                                        {bienniumHeader}
                                    </th>
                                );
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {lineItemCategories?.map(lineItemCategory => {
                        return (
                            <React.Fragment key={lineItemCategory.lineItemCategoryName}>
                                <tr>
                                    <td
                                        className="text-strong text-primary cell-light"
                                        colSpan="10"
                                    >
                                        {lineItemCategory.lineItemCategoryName}
                                    </td>
                                </tr>

                                {lineItemCategory?.lineItems?.map(lineItem => {
                                    return (
                                        <tr key={lineItem.lineItemId}>
                                            <td>
                                                <label>{lineItem.lineItemName}</label>
                                            </td>
                                            {/*eslint-disable-next-line array-callback-return*/}
                                            {[...lineItemValues.keys()]?.map(k => {
                                                if (k === lineItem.lineItemName) {
                                                    const newLineItemMap = new Map(
                                                        lineItemValues
                                                    );
                                                    const value = newLineItemMap.get(k);
                                                    return valueNameArray?.map(
                                                        (valueName, index) => {
                                                            const canEdit =
                                                                [
                                                                    'bminus0Val',
                                                                    'bminus1Val',
                                                                ].includes(valueName) &&
                                                                !disabled;
                                                            const numberForInput =
                                                                value[valueName] ?? 0;
                                                            return index > 6 ? (
                                                                <td
                                                                    key={valueName}
                                                                    style={getWidthStyle(
                                                                        100
                                                                    )}
                                                                    className={
                                                                        index > 6
                                                                            ? 'highlight-cell'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <InputGroup>
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faDollarSign
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                        <Input
                                                                            type="text"
                                                                            value={
                                                                                numberForInput
                                                                            }
                                                                            readOnly={
                                                                                disabled
                                                                            }
                                                                            disabled={
                                                                                disabled
                                                                            }
                                                                            onChange={e => {
                                                                                value[
                                                                                    valueName
                                                                                ] = +e
                                                                                    .target
                                                                                    .value;
                                                                                setLineItemValues(
                                                                                    newLineItemMap
                                                                                );
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={valueName}
                                                                    style={getWidthStyle(
                                                                        100
                                                                    )}
                                                                    className={`nowrap ${canEdit &&
                                                                        'text-primary table-cell-edit'}`}
                                                                    onClick={() => {
                                                                        if (canEdit) {
                                                                            const lastYear = +biennium.split(
                                                                                '-'
                                                                            )[0];
                                                                            const year =
                                                                                valueName ===
                                                                                'bminus0Val'
                                                                                    ? `${lastYear -
                                                                                          1} - ${lastYear}`
                                                                                    : `${lastYear -
                                                                                          2} - ${lastYear -
                                                                                          1}`;
                                                                            const dataItem = `${year} ${value.lineItemCategoryName} ${value.lineItemName}`;
                                                                            setModalObject(
                                                                                {
                                                                                    dataItem: dataItem,
                                                                                    dataAuditName:
                                                                                        valueName ===
                                                                                        'bminus0Val'
                                                                                            ? 'bminus0DataAudit'
                                                                                            : 'bminus1DataAudit',
                                                                                    prepopName:
                                                                                        valueName ===
                                                                                        'bminus0Val'
                                                                                            ? 'bminus0Val_PrePop'
                                                                                            : 'bminus1Val_PrePop',
                                                                                    value: value,
                                                                                    newLineItemMap: newLineItemMap,
                                                                                    valueName: valueName,
                                                                                    key: k,
                                                                                }
                                                                            );
                                                                            setIsEditPrePopulatedModalOpen(
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="mr-2 text-strong">
                                                                            {format(
                                                                                numberForInput
                                                                            )}
                                                                        </span>
                                                                        {canEdit && (
                                                                            <FontAwesomeIcon
                                                                                className="icon ml-auto"
                                                                                tag="i"
                                                                                icon={
                                                                                    faPencilAlt
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            );
                                                        }
                                                    );
                                                }
                                            })}
                                        </tr>
                                    );
                                })}
                                <RenderTotalRow
                                    categoryName={lineItemCategory.lineItemCategoryName}
                                />
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </React.Fragment>
    );
};
