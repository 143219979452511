import React, { useState, useEffect, useReducer, useCallback, useMemo } from 'react';
import { FormGroup, Input, Button, Card, InputGroup, Label } from 'reactstrap';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faPlus,
    faUndo,
    faFileExport,
    faFile,
} from '@fortawesome/free-solid-svg-icons';
import { academicProgramsService, generateReportService } from '../../services';
import { onChangeFunction, usePermissions } from 'shared';
import { CreateAcademicAffairModal } from './create-academic-affair-modal';
import { FormSelect } from '../blocks';
import Select from 'react-select';
import {
    deriveKey,
    getCipLabel,
    containsValue,
} from '../academic-affair-report/program-criteria-form';
import { INITIAL_FORM } from 'components/academic-affair-report/helpers';

const initialValues = {
    pendingReview: false,
    institutionLevels: [],
    institutionControls: [],
    institutions: [],
    locations: [],
    degreeLevels: [],
    twoDigitCips: [],
    fourDigitCips: [],
    sixDigitCips: [],
    distanceEducationId: [],
    distanceEducationOnlineId: [],
    programName: '',
    programId: '',
    programStatus: [],
    maxApprovalYear: new Date().getFullYear().toString(),
    minApprovalYear: '1971',
    monthYearIdentifier: null,
    distanceEducation: null,
    stemPff: null,
    workforceReadyGrant: null,
};

const originalCIPList = {
    twoCip: [],
    fourCip: [],
    sixCip: [],
};
const convertCipToValue = d => ({ ...d, value: d.cipCode });

const getControlsFromInstitutionTypes = institutionTypes => {
    return Array.from(new Set(institutionTypes.map(x => x.controlCode)))
        .map(code => institutionTypes.find(obj => obj.controlCode === code))
        .reduce((controls, item) => {
            controls.push({ key: item.controlCode, value: item.controlName });
            return controls;
        }, []);
};
const getLevelsFromInstitutionTypes = institutionTypes => {
    return Array.from(new Set(institutionTypes.map(x => x.levelCode)))
        .map(code => institutionTypes.find(obj => obj.levelCode === code))
        .reduce((levels, item) => {
            levels.push({ key: item.levelCode, value: item.levelName });
            return levels;
        }, []);
};

export const getOptionLabel = d => d.value;

export const SearchAcademicProgramsForm = ({ setAcademicAffairs }) => {
    const submitPermission = usePermissions({
        permissions: ['submit-new-program'],
    });
    const [fetchingInstitutions, setFetchingInstitutions] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const reducer = (state, newState) => ({ ...state, ...newState });

    const [userInput, setUserInput] = useReducer(reducer, initialValues);

    const [options, setOptions] = useReducer(reducer, []);

    useEffect(() => {
        const fetchNewInstitutions = async () => {
            setFetchingInstitutions(true);
            try {
                const query = queryString.stringify(
                    {
                        institutionLevels: userInput.institutionLevels
                            ? deriveKey(userInput.institutionLevels, 'value')
                            : [],
                        institutionControls: userInput.institutionControls
                            ? deriveKey(userInput.institutionControls, 'value')
                            : [],
                        systems: userInput.systems
                            ? deriveKey(userInput.systems, 'value')
                            : [],
                    },
                    {
                        arrayFormat: 'separator',
                        skipEmptyString: true,
                    }
                );
                const data = await academicProgramsService.getInstitutions(query);
                const locationsQuery = queryString.stringify(
                    {
                        institutions: data.map(x => x.label),
                    },
                    {
                        arrayFormat: 'separator',
                        skipEmptyString: true,
                    }
                );
                const locations = await academicProgramsService.getLocations(
                    locationsQuery
                );
                setUserInput({ institutions: [] });
                setUserInput({ locations: [] });
                setOptions({
                    institutions: data,
                    locations: locations ? locations : [],
                });
            } catch (error) {
                console.log(error);
            }
            setFetchingInstitutions(false);
        };
        fetchNewInstitutions();
    }, [userInput.institutionControls, userInput.institutionLevels, userInput.systems]);

    useEffect(() => {
        console.log(userInput.locations);
        console.log('userInput.institutions');
        if (userInput.institutions && userInput.institutions.length > 0) {
            const query = queryString.stringify(
                { institutions: userInput.institutions.map(x => x.value) },
                {
                    arrayFormat: 'separator',
                    skipEmptyString: true,
                }
            );
            console.log(query);
            //setOptions({locations: []});
            academicProgramsService.getLocations(query).then(res => {
                setOptions({ locations: res.locations ? res.locations : [] });
                console.log(res);
            });
        }
    }, [userInput.institutions]);

    useEffect(() => {
        if (options.locations) {
            if (options.locations.length == 1) {
                const singleLoc = {
                    label: options.locations[0].value,
                    value: options.locations[0].key,
                };
                setUserInput((userInput.locations = [singleLoc]));
            } else {
                setUserInput((userInput.locations = null));
            }
        }
    }, [options.locations]);

    useEffect(() => {
        if (options.institutions) {
            if (options.institutions.length == 1) {
                console.log(options.institutions);
                const singleIns = {
                    label: options.institutions[0].value,
                    value: options.institutions[0].label,
                };
                setUserInput((userInput.institutions = [singleIns]));
            }
        }
    }, [options.institutions]);

    useEffect(() => {
        UpdateCipValue();
    }, [userInput.twoDigitCips]);

    useEffect(() => {
        if (userInput.fourDigitCips) {
            if (userInput.fourDigitCips.length > 0) {
                setOptions({
                    sixCip: GetSubstringOfCip(
                        5,
                        userInput.fourDigitCips,
                        originalCIPList.sixCip
                    ),
                });
            } else {
                UpdateCipValue();
            }
        } else {
            UpdateCipValue();
        }
    }, [userInput.fourDigitCips]);

    const UpdateCipValue = () => {
        if (userInput.twoDigitCips) {
            if (userInput.twoDigitCips.length > 0) {
                setOptions({
                    fourCip: GetSubstringOfCip(
                        2,
                        userInput.twoDigitCips,
                        originalCIPList.fourCip
                    ),
                    sixCip: GetSubstringOfCip(
                        2,
                        userInput.twoDigitCips,
                        originalCIPList.sixCip
                    ),
                });
            } else {
                setOptions({
                    twoCip: originalCIPList.twoCip,
                    fourCip: originalCIPList.fourCip,
                    sixCip: originalCIPList.sixCip,
                });
            }
        } else {
            setOptions({
                twoCip: originalCIPList.twoCip,
                fourCip: originalCIPList.fourCip,
                sixCip: originalCIPList.sixCip,
            });
        }
    };

    const GetSubstringOfCip = (stringLength, smallerDigitCipList, fullCipList) => {
        const smallerDigitCipListValue = smallerDigitCipList.map(currentValue => {
            return currentValue.value;
        });
        return fullCipList.filter(cip =>
            smallerDigitCipListValue.includes(cip.cipCode.substring(0, stringLength))
        );
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await Promise.all([
                    academicProgramsService.getInstitutions(),
                    academicProgramsService.getInstitutionTypes(),
                    academicProgramsService.getCips(),
                    academicProgramsService.getDegreeLevels(),
                    academicProgramsService.getDistanceEds(),
                    academicProgramsService.getDistanceEdsOnline(),
                ]);
                // Get all locations once all institutions have been retrieved
                const query = queryString.stringify(
                    { institutions: res[0].map(x => x.label) },
                    {
                        arrayFormat: 'separator',
                        skipEmptyString: true,
                    }
                );

                const locations = await academicProgramsService.getLocations(query);
                res[1].push({
                    controlCode: 'BPE',
                    controlName: 'BPE-Authorized',
                    levelCode: '4',
                    levelName: '4-year or above',
                });
                res[1].push({
                    controlCode: 'ICI',
                    controlName: 'ICI-Authorized',
                    levelCode: '4',
                    levelName: '4-year or above',
                });
                await setOptions({
                    locations: locations,
                    institutions: res[0],
                    institutionControls: getControlsFromInstitutionTypes(res[1]),
                    institutionLevels: getLevelsFromInstitutionTypes(res[1]),
                    twoCip: res[2][0].map(convertCipToValue),
                    fourCip: res[2][1].map(convertCipToValue),
                    sixCip: res[2][2].map(convertCipToValue),
                    degreeLevels: res[3],
                    distanceEds: res[4],
                    distanceEdsOnline: res[5],
                });
                originalCIPList.twoCip = res[2][0].map(convertCipToValue);
                originalCIPList.fourCip = res[2][1].map(convertCipToValue);
                originalCIPList.sixCip = res[2][2].map(convertCipToValue);
            } catch (error) {}
        };
        fetchOptions();
    }, []);

    const extractLabelsFromResponseCSV = unextractedResponseValues => {
        return unextractedResponseValues
            .map(currentValue => {
                return currentValue.label;
            })
            .join(',');
    };

    const extractValuesFromResponseCSV = unextractedResponseValues => {
        return unextractedResponseValues
            .map(currentValue => {
                return currentValue.value;
            })
            .join(',');
    };

    const CreateSearchPostData = () => {
        const searchPostData = JSON.parse(
            JSON.stringify({
                pendingReview: false,
                institutionControls:
                    userInput.institutionControls &&
                    userInput.institutionControls.length > 0
                        ? extractLabelsFromResponseCSV(userInput.institutionControls)
                        : undefined,
                institutionLevels:
                    userInput.institutionLevels && userInput.institutionLevels.length > 0
                        ? extractLabelsFromResponseCSV(userInput.institutionLevels)
                        : undefined,
                institutions:
                    userInput.institutions && userInput.institutions.length > 0
                        ? extractValuesFromResponseCSV(userInput.institutions)
                        : undefined,
                cheLocationUnitIds:
                    userInput.locations && userInput.locations.length > 0
                        ? extractValuesFromResponseCSV(userInput.locations)
                        : undefined,
                degreeLevels:
                    userInput.degreeLevels && userInput.degreeLevels.length > 0
                        ? extractValuesFromResponseCSV(userInput.degreeLevels)
                        : undefined,
                twoDigitCips:
                    userInput.twoDigitCips && userInput.twoDigitCips.length > 0
                        ? extractValuesFromResponseCSV(userInput.twoDigitCips)
                        : undefined,
                fourDigitCips:
                    userInput.fourDigitCips && userInput.fourDigitCips.length > 0
                        ? extractValuesFromResponseCSV(userInput.fourDigitCips)
                        : undefined,
                sixDigitCips:
                    userInput.sixDigitCips && userInput.sixDigitCips.length > 0
                        ? extractValuesFromResponseCSV(userInput.sixDigitCips)
                        : undefined,
                programName: userInput.programName ? userInput.programName : undefined,
                programStatus: userInput.programStatus
                    ? extractValuesFromResponseCSV(userInput.programStatus)
                    : undefined,
                maxApprovalYear:
                    userInput.maxApprovalYear > 0 ? userInput.maxApprovalYear : 2005,
                minApprovalYear:
                    userInput.minApprovalYear > 0
                        ? userInput.minApprovalYear
                        : new Date().getFullYear().toString(),
                // monthYearIdentifier: userInput.monthYearIdentifier > 0 ? userInput.monthYearIdentifier : undefined,
                distanceEducationId: userInput.distanceEducationId
                    ? userInput.distanceEducationId.value
                    : null,
                distanceEducationOnlineId: userInput.distanceEducationOnlineId
                    ? userInput.distanceEducationOnlineId.value
                    : null,
                stemPff: userInput.stemPff ? userInput.stemPff.value : null,
                workforceReadyGrant: userInput.workforceReadyGrant
                    ? userInput.workforceReadyGrant.value
                    : null,
                programId: userInput.programId || null,
            })
        );
        return searchPostData;
    };

    const submitForm = () => {
        const searchPostData = CreateSearchPostData();
        Promise.resolve(
            academicProgramsService.searchAcademicPrograms(searchPostData)
        ).then(postRes => {
            setAcademicAffairs(postRes.results || []);
            return postRes;
        });
    };

    const submitFormAndExport = () => {
        const searchPostData = CreateSearchPostData();
        console.log(searchPostData);
        Promise.resolve(generateReportService.getProgramsForExport(searchPostData)).then(
            post => {
                console.log(post);
            }
        );
        // userInput.stemPff.value
        Promise.resolve(
            academicProgramsService.searchAcademicPrograms(searchPostData)
        ).then(postRes => {
            setAcademicAffairs(postRes.results || []);
            return postRes;
        });
    };

    const fourCipOptions = useMemo(() => {
        const value = userInput?.twoDigitCips?.value;
        if (!value) return options.fourCip;
        return options?.fourCip?.filter(cip =>
            containsValue(cip.value, [value], d => d + '.')
        );
    }, [options.fourCip, userInput.twoDigitCips]);
    const sixCipOptions = useMemo(() => {
        const twoVal = userInput?.twoDigitCips?.value;
        const fourVal = userInput?.fourDigitCips?.value;
        let filtered = options?.sixCip || [];
        if (twoVal)
            filtered = filtered.filter(cip =>
                containsValue(cip.value, [twoVal], d => d + '.')
            );
        if (fourVal)
            filtered = filtered.filter(cip => containsValue(cip.value, [fourVal]));
        return filtered;
    }, [options.sixCip, userInput.twoDigitCips, userInput.fourDigitCips]);

    const submitDistanceEducationValues = [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];
    const submitSTEMPFFValues = [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];
    const submitWRGValues = [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];

    const statusSelectOptions = [
        { label: 'New', value: 0 },
        { label: 'Active', value: 1 },
        { label: 'Suspended', value: 3 },
        { label: 'Eliminated', value: 4 },
        { label: 'Merged', value: 5 },
    ];

    return (
        <React.Fragment>
            {submitPermission && (
                <Button
                    className="btn btn-success btn-block new-btn mb-3"
                    onClick={() => setIsModalOpen(true)}
                >
                    <FontAwesomeIcon icon={faPlus} /> New Program
                </Button>
            )}
            <Card className="card mb-3 p-3">
                <h4 className="text-muted mb-4">Search Academic Program Inventory</h4>
                <FormSelect
                    label="Institution Level"
                    onChange={setUserInput}
                    options={
                        options.institutionLevels
                            ? options.institutionLevels.map(x => {
                                  return { label: x.value, value: x.key };
                              })
                            : []
                    }
                    placeholder="All"
                    name="institutionLevels"
                    value={userInput.institutionLevels}
                    // getOptionLabel={getOptionLabel}
                    isMulti
                />
                <FormSelect
                    label="Institution Type"
                    onChange={setUserInput}
                    options={
                        options.institutionControls
                            ? options.institutionControls.map(x => {
                                  return { label: x.value, value: x.key };
                              })
                            : []
                    }
                    placeholder="All"
                    name="institutionControls"
                    value={userInput.institutionControls}
                    isMulti
                />
                <FormSelect
                    label="Institutions"
                    onChange={setUserInput}
                    options={
                        options.institutions
                            ? options.institutions.map(x => {
                                  return { label: x.value, value: x.label };
                              })
                            : []
                    }
                    placeholder="All"
                    name="institutions"
                    value={userInput.institutions}
                    loading={fetchingInstitutions}
                    required
                    //isDisabled={userInput.institutionTypes.length === 0}
                    isMulti
                />
                {options.locations && options.locations.length > 0 && (
                    <FormSelect
                        label="Locations"
                        onChange={setUserInput}
                        closeAfterSelection={false}
                        options={
                            options.locations
                                ? options.locations.map(x => {
                                      return { value: x.key, label: x.value };
                                  })
                                : []
                        }
                        placeholder="Please select one"
                        name="locations"
                        value={userInput.locations}
                        isMulti
                        isClearable
                        showLoading
                    />
                )}

                <div className="form-row align-items-center">
                    <div className="col-lg-8">
                        <FormGroup>
                            <label>Program Name</label>
                            <Input
                                type="text"
                                placeholder="Program Name"
                                onChange={evt =>
                                    onChangeFunction.handleChange(evt, setUserInput)
                                }
                                name="programName"
                                value={userInput.programName}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-4">
                        <FormGroup>
                            <label>ID</label>
                            <Input
                                type="text"
                                placeholder="Program ID"
                                onChange={evt =>
                                    onChangeFunction.handleChange(evt, setUserInput)
                                }
                                name="programId"
                                value={userInput.programId}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="form-row">
                    <FormSelect
                        label="Two Digit CIP"
                        onChange={setUserInput}
                        options={
                            options.twoCip
                                ? options.twoCip.map(x => {
                                      return {
                                          label: x.cipCode + ' - ' + x.cipName,
                                          value: x.cipCode,
                                      };
                                  })
                                : []
                        }
                        placeholder="All"
                        name="twoDigitCips"
                        value={userInput.twoDigitCips}
                        className="col-12"
                        // getOptionLabel={getCipLabel}
                        showLoading
                        isMulti={true}
                        isClearable
                    />
                    <FormSelect
                        label="Four Digit CIP"
                        onChange={setUserInput}
                        options={
                            fourCipOptions
                                ? fourCipOptions.map(x => {
                                      return {
                                          label: x.cipCode + ' - ' + x.cipName,
                                          value: x.cipCode,
                                      };
                                  })
                                : []
                        }
                        placeholder="All"
                        name="fourDigitCips"
                        value={userInput.fourDigitCips}
                        className="col-12"
                        // getOptionLabel={getCipLabel}
                        showLoading
                        isMulti={true}
                        isClearable
                    />
                    <FormSelect
                        label="Six Digit CIP"
                        onChange={setUserInput}
                        options={
                            sixCipOptions
                                ? sixCipOptions.map(x => {
                                      return {
                                          label: x.cipCode + ' - ' + x.cipName,
                                          value: x.cipCode,
                                      };
                                  })
                                : []
                        }
                        placeholder="All"
                        name="sixDigitCips"
                        value={userInput.sixDigitCips}
                        className="col-12"
                        // getOptionLabel={getCipLabel}
                        showLoading
                        isMulti={true}
                        isClearable
                    />
                </div>
                <div className="form-row">
                    <FormSelect
                        label="Status"
                        onChange={setUserInput}
                        options={
                            statusSelectOptions
                                ? statusSelectOptions.map(x => {
                                      return { label: x.label, value: x.value };
                                  })
                                : []
                        }
                        placeholder="All"
                        name="programStatus"
                        value={userInput.programStatus}
                        className="col-lg"
                        getOptionLabel={getOptionLabel}
                        isMulti={true}
                        isClearable
                    />
                </div>
                <div className="form-row align-items-center">
                    <div className="col-auto">
                        <small>from</small>
                    </div>
                    <div className="col-lg">
                        <InputGroup>
                            <Input
                                type="number"
                                placeholder="YYYY"
                                onChange={evt =>
                                    onChangeFunction.handleChange(evt, setUserInput)
                                }
                                name="minApprovalYear"
                                value={userInput.minApprovalYear}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">to</span>
                            </div>
                            <Input
                                type="number"
                                placeholder="YYYY"
                                onChange={evt =>
                                    onChangeFunction.handleChange(evt, setUserInput)
                                }
                                name="maxApprovalYear"
                                value={userInput.maxApprovalYear}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="col-xl-12">
                        <FormSelect
                            label="Degree/Award Level"
                            onChange={setUserInput}
                            options={
                                options.degreeLevels
                                    ? options.degreeLevels.map(x => {
                                          return { label: x.value, value: x.label };
                                      })
                                    : []
                            }
                            placeholder="All"
                            name="degreeLevels"
                            value={userInput.degreeLevels}
                            // getOptionLabel={getOptionLabel}
                            isMulti={true}
                            isClearable
                        />
                    </div>
                    <div className="col-xl-12 col-lg-4">
                        <FormGroup>
                            <FormSelect
                                label="Delivery Mode"
                                onChange={setUserInput}
                                options={
                                    options.distanceEds
                                        ? options.distanceEds.map(x => {
                                              return {
                                                  label: x.approval,
                                                  value: x.id,
                                              };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="distanceEducationId"
                                value={userInput.distanceEducationId}
                                isMulti={false}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-xl-12 col-lg-4">
                        <FormGroup>
                            <FormSelect
                                label="Nature of Online Delivery"
                                onChange={setUserInput}
                                options={
                                    options.distanceEdsOnline
                                        ? options.distanceEdsOnline.map(x => {
                                              return {
                                                  label: x.approval,
                                                  value: x.id,
                                              };
                                          })
                                        : []
                                }
                                placeholder="All"
                                name="distanceEducationOnlineId"
                                value={userInput.distanceEducationOnlineId}
                                isMulti={false}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </FormGroup>
                    </div>
                    {false && (
                        <div className="col-xl-12 col-lg-4">
                            <FormGroup>
                                <Label for="submitStatusSTEMPFF">STEM PFF Status</Label>
                                <Select
                                    onChange={(valueObj, options) =>
                                        onChangeFunction.handleSelectChange(
                                            valueObj,
                                            options,
                                            setUserInput
                                        )
                                    }
                                    options={submitSTEMPFFValues}
                                    placeholder="All"
                                    defaultValue={{ label: 'All', value: null }}
                                    name="stemPff"
                                    value={userInput.stemPff}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </FormGroup>
                        </div>
                    )}
                    <div className="col-xl-12 col-lg-4">
                        {/*<fieldset className="form-group">*/}
                        {/*    <div className="form-check d-flex align-items-center">*/}
                        {/*        <Input*/}
                        {/*            type="checkbox"*/}
                        {/*            onClick={evt =>*/}
                        {/*                onChangeFunction.handleCheckChange(*/}
                        {/*                    evt,*/}
                        {/*                    setUserInput*/}
                        {/*                )*/}
                        {/*            }*/}
                        {/*            name="workforceReadyGrant"*/}
                        {/*            id="workforceReadyGrant"*/}
                        {/*            checked={userInput.workforceReadyGrant}*/}
                        {/*        />*/}
                        {/*        <label*/}
                        {/*            className="form-check-label m-0"*/}
                        {/*            for="workforceReadyGrant"*/}
                        {/*        >*/}
                        {/*            Next Level Jobs*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</fieldset>*/}
                        {false && (
                            <FormGroup>
                                <Label for="submitStatusWorkforceReadyGrant">
                                    Next Level Jobs Status
                                </Label>
                                <Select
                                    onChange={(valueObj, options) =>
                                        onChangeFunction.handleSelectChange(
                                            valueObj,
                                            options,
                                            setUserInput
                                        )
                                    }
                                    options={submitWRGValues}
                                    placeholder="All"
                                    name="workforceReadyGrant"
                                    value={userInput.workforceReadyGrant}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </FormGroup>
                        )}
                    </div>
                </div>
                <button
                    className="btn btn-block btn-sm btn-outline-primary"
                    onClick={() => setUserInput(initialValues)}
                >
                    <FontAwesomeIcon icon={faUndo} /> Reset Form
                </button>
                <button
                    className="btn btn-block btn-primary"
                    color="success"
                    onClick={() => {
                        submitForm();
                    }}
                    disabled={
                        userInput.institutions ? userInput.institutions.length == 0 : true
                    }
                >
                    <FontAwesomeIcon icon={faSearch} />
                    &nbsp;Search
                </button>
                <button
                    className="btn btn-block btn-primary"
                    disabled={
                        userInput.institutions ? userInput.institutions.length == 0 : true
                    }
                    color="success"
                    onClick={() => {
                        submitFormAndExport();
                    }}
                >
                    <FontAwesomeIcon icon={faFileExport} />
                    &nbsp;Search and Export to File
                </button>
            </Card>
            <CreateAcademicAffairModal
                isOpen={isModalOpen}
                toggle={toggle}
                degreeLevels={options.degreeLevels}
                sixCip={options.sixCip}
            />
        </React.Fragment>
    );
};
