import React, { useEffect, useState } from 'react';
import { Card, Row } from 'reactstrap';
import { BudgetRunTabSwitch, BudgetRunWizardNav } from './components';
import { budgetRunService } from 'services';
import {
    BudgetRunFormEnum,
    BudgetRunTabEnum,
    BudgetRunHeaderCard,
    getKeyByValue,
} from 'shared';
// import { fakeResponse } from '../../../services/finance-pages-services/fake-response-budget-run';

export const BudgetRunWizardForm = ({
    budgetRunId,
    stepNumber,
    budgetRun,
    setBudgetRun,
}) => {
    const [step, setStep] = useState(stepNumber);
    const [running, setRunning] = useState(false);
    const [finalizing, setFinalizing] = useState(false);
    const nextStep = () => {
        const next =
            step === BudgetRunFormEnum.OUTPUT ? BudgetRunFormEnum.OUTPUT : step + 1;
        setStep(next);
        const tabEnumName = getKeyByValue(BudgetRunFormEnum, next);
        window.location.href = BudgetRunTabEnum[tabEnumName];
    };
    const prevStep = () => {
        const prev =
            step === BudgetRunFormEnum.GENERALINFO
                ? BudgetRunFormEnum.GENERALINFO
                : step - 1;
        setStep(prev);
        const tabEnumName = getKeyByValue(BudgetRunFormEnum, prev);
        window.location.href = BudgetRunTabEnum[tabEnumName];
    };
    const HeaderCardModel = () => {
        return {
            cardTitle: budgetRun.budgetRunTitle,
            biennium: budgetRun.bienniumDesc,
            lastUpdated: budgetRun.lastUpdateDate,
            status: budgetRun.budgetRunStatusId,
            statusName: budgetRunStatusIdName(budgetRun.budgetRunStatusId),
        };
    };

    const budgetRunStatusIdName = function(statusid) {
        switch (statusid) {
            case 0:
                return 'Running';
            case 1:
                return 'Has Not Been Run';
            case 2:
                return 'Run Failed';
            case 3:
                return 'Run Complete';
            case 4:
                return 'Finalized';
            default:
                return 'Running';
        }
    };
    const fetchData = async () => {
        const parseQueryParams = async () => {
            if (budgetRunId) {
                const recievedBudgetRun = await budgetRunService.getBudgetRunWithId(
                    budgetRunId
                );
                setBudgetRun(recievedBudgetRun);
            }
        };
        parseQueryParams();
    };
    useEffect(() => {
        fetchData();
    }, [budgetRunId]);
    if (budgetRun) {
        return (
            <Row>
                <div className="col-12">
                    <BudgetRunHeaderCard {...HeaderCardModel()} />
                    <Card>
                        <div className="wizard-wrap">
                            <BudgetRunWizardNav
                                step={step}
                                setStep={setStep}
                                budgetRun={budgetRun}
                            />
                            <div className="wizard-content">
                                <BudgetRunTabSwitch
                                    budgetRun={budgetRun}
                                    setBudgetRun={setBudgetRun}
                                    step={step}
                                    setStep={setStep}
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                    running={running}
                                    finalizing={finalizing}
                                    setRunning={setRunning}
                                    setFinalizing={setFinalizing}
                                    fetchData={fetchData}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </Row>
        );
    } else {
        return null;
    }
};
