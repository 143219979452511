import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
export const RadioButtons = ({ labelName, name, value, onChange }) => {
    const option1 = { name } + 'one';
    const option2 = { name } + 'two';
    return (
        <Fragment>
            <Label className="d-block">
                {labelName}
                <sup className="text-danger">*</sup>
            </Label>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={option1}
                    value={false}
                    checked={value === false}
                    onChange={onChange}
                />
                <label className="form-check-label" htmlFor={option1}>
                    No
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={option2}
                    value={true}
                    checked={value === true}
                    onChange={onChange}
                />
                <label className="form-check-label" htmlFor={option2}>
                    Yes
                </label>
            </div>
        </Fragment>
    );
};
