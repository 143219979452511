import React, { useReducer, useEffect, useCallback, useMemo } from 'react';

import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { academicProgramsService } from 'services';
import { FormSelect } from './components/FormSelect';

export const AcademicAffairAddLocationModal = ({
    isOpen,
    toggle,
    addApprovedLocation,
    locationId,
    existingLocations,
}) => {
    const reducer = (state, newState) => ({ ...state, ...newState });
    const [options, setOptions] = useReducer(reducer, { locations: [] });

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            locations: [],
        }
    );

    const fetchOptions = useCallback(async () => {
        academicProgramsService
            .getLocations(`?systems=${locationId}`)
            .then(res => formatLocations(res))
            .then(res => setOptions({ locations: res }));
    }, []);

    useEffect(() => {
        fetchOptions();
    }, [fetchOptions]);

    const formatLocations = responses => {
        var returnMe = responses.locations.map(response => ({
            value: response.key,
            label: response.value,
        }));
        return returnMe;
    };

    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    const addApprovedLocations = () => {
        userInput.locations.map(value => {
            addApprovedLocation(value.value, value.label);
        });
        userInput.locations = [];
        setUserInput(userInput);
        toggle();
    };

    const locOptions = useMemo(() => {
        const exists = existingLocations.map(d => Number(d.locationId));
        return options.locations.filter(loc => !exists.includes(Number(loc.value)));
    }, [existingLocations, options.locations]);

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>Add Approved Locations</ModalHeader>
            <ModalBody>
                <Form>
                    <FormSelect
                        label="Select Locations"
                        onChange={setUserInput}
                        options={locOptions}
                        placeholder="Please select one"
                        name="locations"
                        value={userInput.locations}
                        isMulti
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={addApprovedLocations}>
                    Add Locations
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
