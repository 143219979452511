import React, { Fragment } from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBan,
    faCheck,
    faEllipsisV,
    faKey,
    faPencilAlt,
    faUser,
} from '@fortawesome/free-solid-svg-icons';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

const nameFormatter = (cell, row) => {
    return (
        <Fragment>
            <h5 className="mb-1">
                {row.lastName}, {row.firstName}
            </h5>
            <small>{row.emailAddress}</small>
        </Fragment>
    );
};

const roleFormatter = (cell, row) => {
    return (
        <Fragment>
            {row.roleNames.map(role => (
                <h5 key={role.roleID} className="m-0">
                    <span className="badge badge-info text-strong">{role.roleName}</span>
                </h5>
            ))}
        </Fragment>
    );
};

const institutionFormatter = (cell, row) => {
    return (
        <ul className="no-bullets p-0 m-0">
            {row.institutionNames.map(inst => (
                <li key={inst.institutionID}>{inst.institutionName}</li>
            ))}
        </ul>
    );
};

const statusFormatter = (cell, row) => {
    return <Fragment>{row.isDeleted ? 'Inactive' : 'Active'}</Fragment>;
};

const dropdownCell = (cell, row, i, data) => {
    const onEdit = e => {
        e.preventDefault();
        data.onEdit(row);
    };
    const onImpersonate = e => {
        e.preventDefault();
        data.onImpersonate(row);
    };
    const onSendEmail = e => {
        e.preventDefault();
        data.onSendEmail(row);
    };
    const onDeactivate = e => {
        e.preventDefault();
        data.onDeactivate(row);
    };
    const onActivate = e => {
        e.preventDefault();
        data.onActivate(row);
    };
    return (
        <div className="table-actions">
            <UncontrolledDropdown>
                <DropdownToggle className="action-circle">
                    <FontAwesomeIcon icon={faEllipsisV} />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem className="cursor-pointer" onClick={onEdit}>
                        <FontAwesomeIcon icon={faPencilAlt} /> Edit
                    </DropdownItem>
                    {data.admin && (
                        <DropdownItem className="cursor-pointer" onClick={onImpersonate}>
                            <FontAwesomeIcon icon={faUser} /> Impersonate
                        </DropdownItem>
                    )}
                    <DropdownItem className="cursor-pointer" onClick={onSendEmail}>
                        <FontAwesomeIcon icon={faKey} /> Send Reset Password Email
                    </DropdownItem>
                    {row.isDeleted ? (
                        <DropdownItem
                            className="text-success cursor-pointer"
                            onClick={onActivate}
                        >
                            <FontAwesomeIcon icon={faCheck} /> Activate
                        </DropdownItem>
                    ) : (
                        <DropdownItem
                            className="text-danger cursor-pointer"
                            onClick={onDeactivate}
                        >
                            <FontAwesomeIcon icon={faBan} /> Deactivate
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

export const UserColumns = props => {
    return [
        {
            dataField: 'id',
            text: 'User',
            sort: true,
            headerStyle: getWidthStyle(250),
            formatter: nameFormatter,
        },
        {
            dataField: 'roles',
            text: 'Role',
            sort: true,
            headerStyle: { minWidth: 200 },
            formatter: roleFormatter,
        },
        {
            dataField: 'institutions',
            text: 'Institutions',
            sort: true,
            headerStyle: getWidthStyle(300),
            formatter: institutionFormatter,
        },
        {
            dataField: 'isDeleted',
            text: 'Status',
            sort: true,
            headerStyle: getWidthStyle(100),
            formatter: statusFormatter,
        },
        {
            dataField: '',
            text: '',
            formatter: dropdownCell,
            formatExtraData: props,
            headerStyle: getWidthStyle(60),
        },
    ];
};
