import React, { Fragment, useEffect } from 'react';
import _get from 'lodash/get';
import { getWidthStyle } from 'components/blocks';

const COL_STYLE = {
    institutionName: getWidthStyle(175),
    sector: getWidthStyle(150),
    programName: getWidthStyle(225),
    programStatus: getWidthStyle(75),
    distanceEd: getWidthStyle(75),
    programId: getWidthStyle(75),
    programApprovalDate: getWidthStyle(75),
    programEndYear: getWidthStyle(75),
    creditRange: getWidthStyle(75),
    headCount: getWidthStyle(75),
    fte: getWidthStyle(75),
    degrees: getWidthStyle(75),
    demographic: getWidthStyle(75),
};

const YEAR_EMPTY = {
    degrees: 0,
    fte: 0,
    headCount: 0,
    year: null,
};

const DEMO_BINDINGS = {
    Race: 'Race',
    PellRecipient: 'Pell Recipient',
    Gender: 'Gender',
    Residency: 'Indiana Resident',
};
const DEMO_DATA_BINDINGS = {
    Race: 'race',
    PellRecipient: 'pellEligibility',
    Gender: 'gender',
    Residency: 'residency',
};

export const Title = React.memo(props => {
    return (
        <thead className="no-border">
            <tr>
                <th colSpan={100}>
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 mr-4 text-strong">{props.text}</h5>
                        <p className="text-italic m-0">{props.info}</p>
                    </div>
                </th>
            </tr>
        </thead>
    );
});

export const SubTitle = React.memo(props => {
    return (
        <thead className="thead-gray large">
            <tr>
                <th colSpan={props.titleWidth} className="text-strong">
                    Program Information
                </th>
                {props.demographicsToShow.length > 0 && (
                    <th
                        colSpan={props.demographicsToShow.length}
                        className="text-center text-strong thick-border"
                    >
                        Demographic Information
                    </th>
                )}
                {props.years.map((year, i) => {
                    return (
                        <th
                            key={i}
                            colSpan={props.colWidth}
                            className="text-center text-strong thick-border"
                        >
                            {year}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
});

export const Institution = React.memo(props => {
    const {
        institutionName,
        countsByYear,
        sector,
        programName,
        distanceEd,
        programId,
        programStatus,
        programApprovalDate,
        programEndYear,
        creditRange,
        years,
        demographicInfo,
        demographicsToShow,
    } = props;
    return (
        <tr>
            <td style={COL_STYLE.institutionName} id="institutionName">
                {institutionName}
            </td>
            <td style={COL_STYLE.sector} id="sector">
                {sector}
            </td>
            <td style={COL_STYLE.programId} id="programId">
                {programId}
            </td>
            <td style={COL_STYLE.programName} id="programName">
                {programName}
            </td>
            <td
                style={COL_STYLE.programStatus}
                className="text-center"
                id="programStatus"
            >
                {programStatus}
            </td>
            <td style={COL_STYLE.distanceEd} className="text-center" id="distanceEd">
                {distanceEd}
            </td>
            <td
                style={COL_STYLE.programApprovalDate}
                className="text-center"
                id="programApprovalDate"
            >
                {programApprovalDate}
            </td>
            <td
                style={COL_STYLE.programEndYear}
                className="text-center"
                id="programEndYear"
            >
                {programEndYear}
            </td>
            <td style={COL_STYLE.creditRange} className="text-center" id="creditRange">
                {creditRange}
            </td>
            {demographicsToShow.map((demo, i) => {
                return (
                    <td
                        key={demo}
                        style={COL_STYLE.demographic}
                        className={`text-center ${i === 0 && 'thick-border'}`}
                        id={demo}
                    >
                        {_get(demographicInfo, `${DEMO_DATA_BINDINGS[demo]}`, '---')}
                    </td>
                );
            })}
            {years.map((year, i) => {
                const obj = countsByYear.find(
                    count => Number(count.year) === Number(year)
                );
                return (
                    <Fragment key={year}>
                        <td
                            style={COL_STYLE.headCount}
                            className="thick-border text-center"
                            id="headCount"
                        >
                            {obj?.headCount || YEAR_EMPTY.headCount}
                        </td>
                        <td style={COL_STYLE.fte} className="text-center" id="fte">
                            {obj?.fte || YEAR_EMPTY.fte}
                        </td>
                        <td
                            style={COL_STYLE.degrees}
                            className="text-center"
                            id="degrees"
                        >
                            {obj?.degrees || YEAR_EMPTY.degrees}
                        </td>
                    </Fragment>
                );
            })}
        </tr>
    );
});

export const Body = React.memo(props => {
    useEffect(() => {
        if (props.hide.length > 0) {
            const selector = props.hide.map(h => '#' + h).join(', ');
            const nodes = document.querySelectorAll(selector);
            nodes.forEach(n => n.classList.add('hiddenColumn'));
        }
    }, [props.hide]);
    return (
        <tbody>
            <tr className="text-strong">
                <td className="nowrap" id="institutionName">
                    Institution
                </td>
                <td className="nowrap" id="sector">
                    Sector
                </td>
                <td className="nowrap" id="programId">
                    Program Id
                </td>
                <td className="nowrap" id="programName">
                    Name
                </td>
                <td className="nowrap text-center" id="programStatus">
                    Status
                </td>
                <td className="text-center" id="distanceEd">
                    Dist. Ed.
                </td>
                <td className="text-center" id="programApprovalDate">
                    Approval Date
                </td>
                <td className="text-center" id="programEndYear">
                    End Year
                </td>
                <td className="text-center" id="creditRange">
                    Credit Range
                </td>
                {props.demographicsToShow.map((demo, i) => {
                    return (
                        <td
                            key={demo}
                            className={`text-center ${i === 0 && 'thick-border'}`}
                            id={demo}
                        >
                            {DEMO_BINDINGS[demo]}
                        </td>
                    );
                })}
                {props.years.map((a, i) => {
                    return (
                        <Fragment key={i}>
                            <td className="thick-border text-center" id="headCount">
                                Head Count
                            </td>
                            <td className="nowrap text-center" id="fte">
                                FTE
                            </td>
                            <td className="nowrap" id="degrees">
                                Degrees
                            </td>
                        </Fragment>
                    );
                })}
            </tr>
            {props.children}
        </tbody>
    );
});

export const BodyUngrouped = React.memo(props => {
    useEffect(() => {
        if (props.hide.length > 0) {
            const selector = props.hide.map(h => '#' + h).join(', ');
            const nodes = document.querySelectorAll(selector);
            nodes.forEach(n => n.classList.add('hiddenColumn'));
        }
    }, [props.hide]);
    return <tbody>{props.children}</tbody>;
});

export const Row = React.memo(props => {
    const {
        cipCode,
        cipTitle,
        degreeLevel,
        institutionName,
        countsByYear,
        sector,
        programId,
        programName,
        distanceEd,
        programStatus,
        programApprovalDate,
        programEndYear,
        creditRange,
        years,
        demographicInfo,
        demographicsToShow,
    } = props;
    return (
        <tr>
            <td style={{ minWidth: 75 }} id="cipCode">
                {cipCode}
            </td>
            <td style={{ minWidth: 250 }} id="cipTitle">
                {cipTitle}
            </td>
            <td style={{ minWidth: 150 }} id="degreeLevel">
                {degreeLevel}
            </td>
            <td style={{ minWidth: 250 }} id="institutionName">
                {institutionName}
            </td>
            <td style={{ minWidth: 150 }} id="sector">
                {sector}
            </td>
            <td style={{ minWidth: 75 }} id="programId">
                {programId}
            </td>
            <td style={{ minWidth: 200 }} id="programName">
                {programName}
            </td>
            <td className="text-center" id="programStatus">
                {programStatus}
            </td>
            <td className="text-center" id="distanceEd">
                {distanceEd}
            </td>
            <td className="text-center" id="programApprovalDate">
                {programApprovalDate}
            </td>
            <td className="text-center" id="programEndYear">
                {programEndYear}
            </td>
            <td className="text-center" id="creditRange">
                {creditRange}
            </td>
            {demographicsToShow.map((demo, i) => {
                return (
                    <td
                        key={demo}
                        style={COL_STYLE.demographic}
                        className={`text-center ${i === 0 && 'thick-border'}`}
                        id={demo}
                    >
                        {_get(demographicInfo, `${DEMO_DATA_BINDINGS[demo]}`, '---')}
                    </td>
                );
            })}
            {years.map(year => {
                const obj = countsByYear.find(
                    count => Number(count.year) === Number(year)
                );
                return (
                    <Fragment key={year}>
                        <td
                            style={COL_STYLE.headCount}
                            className="thick-border text-center"
                            id="headCount"
                        >
                            {obj?.headCount || YEAR_EMPTY.headCount}
                        </td>
                        <td style={COL_STYLE.fte} className="text-center" id="fte">
                            {obj?.fte || YEAR_EMPTY.fte}
                        </td>
                        <td
                            style={COL_STYLE.degrees}
                            className="text-center"
                            id="degrees"
                        >
                            {obj?.degrees || YEAR_EMPTY.degrees}
                        </td>
                    </Fragment>
                );
            })}
        </tr>
    );
});
