import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Card, Container, Row, Input, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileExport,
    faMagic,
    faSearch,
    faUpload,
} from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { ErrorColumns, SubmissionColumns, TransmissionModal } from './components';
import { Button, FormSelect } from '../blocks';
import { onChangeFunction, usePermissions, useToastState } from 'shared';
import { generateReportService, dataSubmissionService } from 'services';
import { downloadCsv } from 'helpers';
import { ToastAlert } from 'components/toast/toast';
import { ErrorAlert } from 'components/error/error';

const deriveKey = (array = [], key = 'key') => {
    if (array == null) return [];
    return array.map(d => d[key]);
};
const getOptionLabel = d => d.value;
const convertToValue = d => ({ ...d, value: d.name });
const statusToValue = d => ({ ...d, value: d.submissionStatusDesc, id: 'submission' });
const transmissionToValue = d => ({
    ...d,
    value: d.transmissionStatusDesc,
    id: 'transmission',
});

const getControlsFromInstitutionTypes = institutionTypes => {
    return Array.from(new Set(institutionTypes.map(x => x.controlCode)))
        .map(code => institutionTypes.find(obj => obj.controlCode === code))
        .reduce((controls, item) => {
            controls.push({ key: item.controlCode, value: item.controlName });
            return controls;
        }, []);
};
const getLevelsFromInstitutionTypes = institutionTypes => {
    return Array.from(new Set(institutionTypes.map(x => x.levelCode)))
        .map(code => institutionTypes.find(obj => obj.levelCode === code))
        .reduce((levels, item) => {
            levels.push({ key: item.levelCode, value: item.levelName });
            return levels;
        }, []);
};

const fileTypeToValue = d => ({ ...d, value: d.typeDesc });
const reducer = (state, newState) => ({ ...state, ...newState });

const paginationOptions = {
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    sizePerPage: 10,
    hideSizePerPage: true,
    alwaysShowAllBtns: true,
};

export const DataSubmissionForm = () => {
    const approvePermission = usePermissions({
        permissions: ['approve-data-from-institution'],
    });
    const setToast = useToastState().setToast;
    const [fetchingInstitutions, setFetchingInstitutions] = useState(false);
    const [sort, setSort] = useState({ dataField: 'transmissionDate', order: 'desc' });
    const [sortSub, setSortSub] = useState({
        dataField: 'lastStatusUpdate',
        order: 'desc',
    });
    const [fetchingSystems, setFetchingSystems] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [form, setForm] = useReducer(reducer, {
        reportingYear: '',
        institutionControls: [],
        institutionLevels: [],
        systems: [],
        campuses: [],
        statuses: [],
        fileTypes: [],
    });
    const [options, setOptions] = useReducer(reducer, {});
    const [transmissions, setTransmissions] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const fetchOptions = async () => {
        try {
            const res = await Promise.all([
                generateReportService.getInstitutionTypes(),
                generateReportService.getSystems(),
                dataSubmissionService.getFileTypes(),
            ]);
            var optionsToSet = {
                institutionControls: getControlsFromInstitutionTypes(res[0]),
                institutionLevels: getLevelsFromInstitutionTypes(res[0]),
                systems: res[1].systems.map(convertToValue),
                submissionStatus: res[2].submissionStatusList.map(statusToValue),
                transmissionStatus: res[2].transmissionStatusList.map(
                    transmissionToValue
                ),
                fileTypes: res[2].typeList.map(fileTypeToValue),
            };
            setOptions(optionsToSet);
        } catch (error) {
            console.error(error);
        }
    };
    const getFilteredList = submissionFilter => {
        const submissionStatusArray = [
            'Submission Edits Running',
            'Submission Approved',
            'Pending Approval',
            'Warnings Exist',
            'Errors Exist',
            'Edit run failure',
            'No Data Submitted',
        ];
        const transmissionStatusArray = [
            'Uploading',
            'Received',
            'Processing',
            'Transmittal OK',
            'No Changes',
            'Replaced',
            'Validation Error',
            'Unknown Error',
            'Empty File',
        ];
        if (submissionFilter) {
            return form.statuses.filter(x => submissionStatusArray.includes(x.label));
        } else {
            return form.statuses.filter(x => transmissionStatusArray.includes(x.label));
        }
    };
    const getFormPayload = useCallback(() => {
        const transList = getFilteredList(false);
        const subList = getFilteredList(true);
        const payload = {
            filetypes: deriveKey(form.fileTypes, 'value'),
            submissionstatus: deriveKey(subList, 'value'),
            transmissionstatus: deriveKey(transList, 'value'),
            institutionlevels: deriveKey(form.institutionLevels, 'value'),
            institutioncontrols: deriveKey(form.institutionControls, 'value'),
            systems: deriveKey(form.systems, 'value'),
            campuses: deriveKey(form.campuses, 'value'),
            year: form.reportingYear,
        };
        return queryString.stringify(payload, {
            skipEmptyString: true,
        });
    }, [form]);
    const updateRow = useCallback(row => {
        setSubmissions(prev =>
            prev.map(submission => {
                if (submission.submissionID === row.submissionID) {
                    return row;
                }
                return submission;
            })
        );
    }, []);
    const onSubmit = async e => {
        setLoading(true);
        try {
            console.log(form);
            e.preventDefault();
            const query = getFormPayload();
            console.log(query);
            const res = await dataSubmissionService.searchData(query);
            if (res) {
                setSubmissions(res.submissionRows);
                setTransmissions(res.transmissionRows);
                setSort({ ...sort });
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };
    const exportSubmissions = async () => {
        setExporting(true);
        try {
            const query = getFormPayload();
            const res = await dataSubmissionService.submissionsExport(query);
            downloadCsv(res, 'Submissions');
        } catch (error) {
            console.error(error);
        }
        setExporting(false);
    };
    useEffect(() => {
        const fetchNewInstitutions = async () => {
            setFetchingInstitutions(true);
            try {
                const query = queryString.stringify(
                    {
                        institutionLevels: deriveKey(form.institutionLevels, 'label'),
                        institutionControls: deriveKey(form.institutionControls, 'label'),
                        systems: deriveKey(form.systems, 'value'),
                    },
                    {
                        arrayFormat: 'separator',
                        skipEmptyString: true,
                    }
                );
                const res = await generateReportService.getCampuses(query);
                setForm({ campuses: [] });
                setOptions({
                    campuses: res.campuses.map(x => {
                        return { label: x.value, value: x.key };
                    }),
                });
            } catch (error) {}
            setFetchingInstitutions(false);
        };
        fetchNewInstitutions();
    }, [form.systems]);

    useEffect(() => {
        const fetchNewSystems = async () => {
            setFetchingSystems(true);
            try {
                const query = queryString.stringify(
                    {
                        institutionLevels: deriveKey(form.institutionLevels, 'label'),
                        institutionControls: deriveKey(form.institutionControls, 'label'),
                    },
                    {
                        //arrayFormat: 'separator',
                        skipEmptyString: true,
                    }
                );
                const res = await generateReportService.getSystems(query);
                setForm({ systems: [] });
                setOptions({
                    systems: res.systems.map(convertToValue),
                });
            } catch (error) {}
            setFetchingSystems(false);
        };
        fetchNewSystems();
    }, [form.institutionControls, form.institutionLevels]);

    useEffect(() => {
        fetchOptions();
    }, []);
    return (
        <Container fluid>
            <Row>
                <div className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                    <Button
                        color="success"
                        className="btn-block new-btn mb-3"
                        onClick={() => setOpen(true)}
                    >
                        <FontAwesomeIcon icon={faUpload} /> Transmit File
                    </Button>
                    <Card className="mb-3 p-3">
                        <form onSubmit={loading ? null : onSubmit}>
                            <h4 className="text-muted mb-4">Search</h4>
                            <div className="form-row">
                                <div className="col-xl-12 col-lg-6">
                                    <fieldset className="form-group">
                                        <label>Reporting Year</label>
                                        <Input
                                            type="text"
                                            placeholder="All Active Submissions"
                                            onChange={evt =>
                                                onChangeFunction.handleChange(
                                                    evt,
                                                    setForm
                                                )
                                            }
                                            name="reportingYear"
                                            value={form.reportingYear}
                                            className="white"
                                        />
                                    </fieldset>
                                </div>
                                <FormSelect
                                    label="Institution Level"
                                    onChange={setForm}
                                    options={
                                        options.institutionLevels
                                            ? options.institutionLevels.map(x => {
                                                  return { label: x.value, value: x.key };
                                              })
                                            : []
                                    }
                                    placeholder="All"
                                    name="institutionLevels"
                                    value={form.institutionLevels}
                                    className="col-xl-12 col-lg-6"
                                    //getOptionLabel={getOptionLabel}
                                    showLoading
                                    isMulti
                                />
                                <FormSelect
                                    label="Institution Type"
                                    onChange={setForm}
                                    options={
                                        options.institutionControls
                                            ? options.institutionControls.map(x => {
                                                  return { label: x.value, value: x.key };
                                              })
                                            : []
                                    }
                                    placeholder="All"
                                    name="institutionControls"
                                    value={form.institutionControls}
                                    className="col-xl-12 col-lg-6"
                                    // getOptionLabel={getOptionLabel}
                                    showLoading
                                    isMulti
                                />
                                <FormSelect
                                    label="Systems"
                                    onChange={setForm}
                                    options={
                                        options.systems
                                            ? options.systems.map(x => {
                                                  return { label: x.name, value: x.id };
                                              })
                                            : []
                                    }
                                    placeholder="All"
                                    name="systems"
                                    value={form.systems}
                                    className="col-xl-12 col-lg-6"
                                    getOptionLabel={getOptionLabel}
                                    loading={fetchingSystems}
                                    isMulti
                                />
                                <FormSelect
                                    label="Campus"
                                    onChange={setForm}
                                    options={
                                        options.campuses
                                            ? options.campuses.map(x => {
                                                  return {
                                                      label: x.label,
                                                      value: x.value,
                                                  };
                                              })
                                            : []
                                    }
                                    placeholder="All"
                                    name="campuses"
                                    value={form.campuses}
                                    className="col-xl-12 col-lg-6"
                                    getOptionLabel={getOptionLabel}
                                    loading={fetchingInstitutions}
                                    isMulti
                                />
                                <FormSelect
                                    label="Status"
                                    onChange={setForm}
                                    options={[
                                        {
                                            label: 'Submission Status',
                                            options: options.submissionStatus
                                                ? options.submissionStatus.map(x => {
                                                      return {
                                                          label: x.submissionStatusDesc,
                                                          value: x.submissionStatusCode,
                                                      };
                                                  })
                                                : [],
                                        },
                                        {
                                            label: 'Transmission Status',
                                            options: options.transmissionStatus
                                                ? options.transmissionStatus.map(x => {
                                                      return {
                                                          label: x.transmissionStatusDesc,
                                                          value: x.transmissionStatusCode,
                                                      };
                                                  })
                                                : [],
                                        },
                                    ]}
                                    placeholder="All"
                                    name="statuses"
                                    value={form.statuses}
                                    className="col-xl-12 col-lg-6"
                                    getOptionLabel={getOptionLabel}
                                    showLoading
                                    isMulti
                                />
                                <FormSelect
                                    label="File Type"
                                    onChange={setForm}
                                    options={
                                        options.fileTypes
                                            ? options.fileTypes.map(x => {
                                                  return {
                                                      label: x.typeDesc,
                                                      value: x.typeCode,
                                                  };
                                              })
                                            : []
                                    }
                                    placeholder="All"
                                    name="fileTypes"
                                    value={form.fileTypes}
                                    className="col-xl-12 col-lg-6"
                                    getOptionLabel={getOptionLabel}
                                    showLoading
                                    isMulti
                                />
                                <div className="col-12">
                                    <Button
                                        color="primary"
                                        className="btn-primary btn-block"
                                        type="search"
                                        loading={loading}
                                    >
                                        <FontAwesomeIcon icon={faSearch} /> Search /
                                        Refresh
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
                <div className="col-xl-9 col-lg-12">
                    <h2 className="mb-3">Transmissions</h2>
                    <Card className="mb-4">
                        <div className="table-responsive">
                            <BootstrapTable
                                classes="data-table table table-striped table-bordered table-hover data-submission-table align-middle dataTable no-footer dtr-inline"
                                keyField="id"
                                data={transmissions}
                                columns={ErrorColumns}
                                sort={sort}
                                striped
                                hover
                                pagination={paginationFactory(paginationOptions)}
                                onTableChange={(type, newState) => {
                                    if (type == 'sort') {
                                        setSort({
                                            dataField: 'transmissionDate',
                                            order: 'desc',
                                        });
                                    }
                                }}
                            />
                        </div>
                    </Card>
                    <div className="d-md-flex align-items-center justify-content-between text-center text-md-left mb-3">
                        <h2 className="mb-md-0 mb-3">Submissions</h2>
                        <Button
                            color="primary"
                            outline
                            className="btn-sm"
                            onClick={exportSubmissions}
                            disabled={submissions.length === 0}
                            loading={exporting}
                        >
                            <FontAwesomeIcon icon={faFileExport} /> Export as Excel File
                        </Button>
                    </div>
                    <Card className="mb-4">
                        <div className="table-responsive">
                            <BootstrapTable
                                classes="data-table table table-striped table-bordered table-hover data-submission-table align-middle dataTable no-footer dtr-inline"
                                keyField="id"
                                data={submissions}
                                sort={sortSub}
                                columns={SubmissionColumns({
                                    onUpdate: updateRow,
                                    approvePermission,
                                })}
                                striped
                                hover
                                pagination={paginationFactory(paginationOptions)}
                            />
                        </div>
                    </Card>
                </div>
            </Row>
            <TransmissionModal
                open={open}
                close={() => setOpen(false)}
                fileTypes={options.fileTypes}
            />
        </Container>
    );
};
