import React, { useEffect, useState } from 'react';

import { useToastState } from 'shared';

export const ToastAlert = React.memo(() => {
    const [localMessage, setLocalMessage] = useState(null);
    const { toast, clearToast } = useToastState();
    useEffect(() => {
        if (toast) {
            setLocalMessage(toast.message);
            setTimeout(() => {
                clearToast();
            }, 6000);
        } else if (!toast && localMessage) {
            setLocalMessage(null);
        }
    }, [clearToast, localMessage, toast]);
    return (
        <div
            className={`alert alert-success error-alert ${localMessage &&
                'error-alert-open'}`}
            onClick={clearToast}
        >
            <strong>{toast?.title || 'CHEDSS Alert'}</strong>
            <div>{localMessage}</div>
        </div>
    );
});
