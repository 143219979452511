import { handleServiceRequest } from '../handle-service-request';

const getFileTypes = () => {
    const getObject = {
        url: '/api/DataSubmission/RecordLinking/FileTypes',
    };
    return handleServiceRequest.get(getObject);
};
const searchData = search => {
    const getObject = {
        url: '/api/DataSubmission/search?' + search,
    };
    return handleServiceRequest.get(getObject);
};
const downloadFile = id => {
    const getObject = {
        url: '/api/DataSubmission/transmit/' + id,
    };
    return handleServiceRequest.get(getObject);
};
const approve = id => {
    const getObject = {
        url: '/api/DataSubmission/Approve/' + id,
    };
    return handleServiceRequest.post(getObject);
};
const unapprove = id => {
    const getObject = {
        url: '/api/DataSubmission/UnApprove/' + id,
    };
    return handleServiceRequest.post(getObject);
};
const rerun = id => {
    const getObject = {
        url: '/api/DataSubmission/RerunEdits/' + id,
    };
    return handleServiceRequest.post(getObject);
};
const transmissionErrors = id => {
    const getObject = {
        url: '/api/DataSubmission/TransmissionErrors/' + id,
    };
    return handleServiceRequest.get(getObject);
};
const submissionErrors = id => {
    const getObject = {
        url: '/api/DataSubmission/SubmissionErrors/' + id,
    };
    return handleServiceRequest.get(getObject);
};
const submissionWarnings = id => {
    const getObject = {
        url: '/api/DataSubmission/SubmissionWarnings/' + id,
    };
    return handleServiceRequest.get(getObject);
};
const submissionsExport = search => {
    const getObject = {
        url: '/api/DataSubmission/SubmissionSearchExport?' + search,
    };
    return handleServiceRequest.get(getObject);
};
const submitFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const getObject = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        url: `/api/DataSubmission/transmit/single`,
        postBody: formData,
    };
    return handleServiceRequest.post(getObject);
};

const submitBulkFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const getObject = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        url: `/api/DataSubmission/transmit/bulk`,
        postBody: formData,
    };
    return handleServiceRequest.post(getObject);
};

export const dataSubmissionService = {
    getFileTypes,
    searchData,
    downloadFile,
    approve,
    unapprove,
    rerun,
    transmissionErrors,
    submissionErrors,
    submissionWarnings,
    submissionsExport,
    submitFile,
    submitBulkFile,
};
