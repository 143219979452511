import PropTypes from 'prop-types';

const handleChange = (evt, setUserInput) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
};
const handleCheckChange = (evt, setUserInput) => {
    const name = evt.target.name;
    const newValue = evt.target.checked;
    setUserInput({ [name]: newValue });
};
const handleAsyncSelectChange = (valueObj, options, setUserInput) => {
    const name = options.name;
    setUserInput({ [name]: valueObj });
};
const handleSelectChange = (valueObj, options, setUserInput) => {
    const name = options.name;
    setUserInput({ [name]: valueObj });
};

handleChange.propTypes = {
    setUserInput: PropTypes.func.isRequired,
};

handleAsyncSelectChange.propTypes = {
    setUserInput: PropTypes.func.isRequired,
};

handleSelectChange.propTypes = {
    setUserInput: PropTypes.func.isRequired,
};

export const onChangeFunction = {
    handleChange,
    handleAsyncSelectChange,
    handleSelectChange,
    handleCheckChange,
};
