import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const WizardFooter = ({ nextStep, prevStep, isGeneralInfo, isSubmit }) => {
    const previousForm = () => {
        prevStep();
    };
    const nextFormAndSave = () => {
        nextStep();
    };
    const previousButton = (
        <button
            type="button"
            className="btn btn-outline-primary mr-2 px-3"
            onClick={previousForm}
        >
            <FontAwesomeIcon icon={faArrowLeft} /> Previous
        </button>
    );
    const nextButton = (
        <button type="button" className="btn btn-primary px-3" onClick={nextFormAndSave}>
            Next <FontAwesomeIcon icon={faArrowRight} />
        </button>
    );
    const showPreviousButton = () => {
        return isGeneralInfo ? null : previousButton;
    };
    const showNextButton = () => {
        return isSubmit ? null : nextButton;
    };
    return (
        <div className="wizard-footer justify-content-end">
            <div className="nav-buttons">
                {showPreviousButton()}
                {showNextButton()}
            </div>
        </div>
    );
};
