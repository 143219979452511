import React, { useState, useReducer } from 'react';

import {
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { financeService } from 'services';
import { onChangeFunction } from 'shared';

export const CapitalProjectsBulkUpdateModal = ({
    isOpen,
    toggle,
    capitalProjects,
    setCapitalProjects,
}) => {
    const fundingMethodOptions = [
        { label: 'Debt Service Starting in Year 2', value: 1 },
        { label: 'Debt Service Starting in Year 1', value: 2 },
        { label: 'Cash All in Year 1', value: 3 },
        { label: 'Cash All in Year 2', value: 4 },
        { label: 'Cash Split 50/50', value: 5 },
    ];
    const allValue = { value: 'all', label: 'All' };
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            institutionModalValue: [allValue],
            priorityOne: true,
            priorityTwo: true,
            priorityThree: true,
            priorityFour: true,
            priorityFive: true,
            prioritySix: true,
            prevApprovedByGAYes: true,
            prevApprovedByGANo: true,
            requestedFundingPercent: 0,
            fundingMethod: fundingMethodOptions[0],
        }
    );

    const handleCheckButtonChange = name => {
        const newValue = !userInput[name];
        setUserInput({ [name]: newValue });
    };

    const handleSelectChange = evt => {
        setUserInput({ fundingMethod: evt });
    };

    const [institutions] = useState(financeService.getAllInstitutions);

    const loadModalInstitutions = () =>
        Promise.resolve(institutions).then(allInstitutions => {
            if (!allInstitutions.includes(allValue)) {
                allInstitutions.unshift(allValue);
            }
            return allInstitutions;
        });

    const extractLabelsFromResponse = unextractedResponseValues => {
        return unextractedResponseValues.reduce((acc, currentValue) => {
            return acc.concat(currentValue.label);
        }, []);
    };

    const applyBulkUpdate = () => {
        const prioritiesCheckedArray = [
            userInput.priorityOne ? 1 : 0,
            userInput.priorityTwo ? 2 : 0,
            userInput.priorityThree ? 3 : 0,
            userInput.priorityFour ? 4 : 0,
            userInput.priorityFive ? 5 : 0,
            userInput.prioritySix ? 6 : 0,
        ];
        const hasAllValue = userInput.institutionModalValue.find(value => {
            return value.value === allValue.value;
        });
        const institutionsSelected = extractLabelsFromResponse(
            userInput.institutionModalValue
        );
        capitalProjects.map(project => {
            const percentOfStateFunding =
                project.requestedStateFunding * (userInput.requestedFundingPercent / 100);
            if (
                (hasAllValue || institutionsSelected.includes(project.displayName)) &&
                prioritiesCheckedArray.includes(project.priorityNumber) &&
                (project.preApprovedByGenAssembly === userInput.prevApprovedByGAYes ||
                    !project.preApprovedByGenAssembly === userInput.prevApprovedByGANo)
            )
                switch (userInput.fundingMethod.val) {
                    case 1:
                        project.startDebtServiceBY1 = false;
                        project.recommendedFunding = percentOfStateFunding;
                        setCapitalProjects({ capitalProjects: [...capitalProjects] });
                        break;
                    case 2:
                        project.startDebtServiceBY1 = true;
                        project.recommendedFunding = percentOfStateFunding;
                        setCapitalProjects({ capitalProjects: [...capitalProjects] });
                        break;
                    case 3:
                        project.recommendedFunding = percentOfStateFunding;
                        project.bY1Cash = percentOfStateFunding;
                        project.bY2Cash = 0;
                        setCapitalProjects({ capitalProjects: [...capitalProjects] });
                        break;
                    case 4:
                        project.recommendedFunding = percentOfStateFunding;
                        project.bY2Cash = percentOfStateFunding;
                        project.bY1Cash = 0;
                        setCapitalProjects({ capitalProjects: [...capitalProjects] });
                        break;
                    case 5:
                        project.recommendedFunding = percentOfStateFunding;
                        const halfOfPercentOfStateFunding = percentOfStateFunding / 2;
                        project.bY1Cash = halfOfPercentOfStateFunding;
                        project.bY2Cash = halfOfPercentOfStateFunding;
                        setCapitalProjects({ capitalProjects: [...capitalProjects] });
                        break;

                    default:
                        break;
                }
            return null;
        });

        toggle();
    };

    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>Bulk Udpate</ModalHeader>
            <ModalBody>
                <h3 className="text-strong text-primary mb-3">
                    Select criteria that will determine which projects are affected by
                    bulk update
                </h3>

                <FormGroup>
                    <Label>Select Institution(s)</Label>
                    <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleAsyncSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        name="institutionModalValue"
                        value={userInput.institutionModalValue}
                        loadOptions={loadModalInstitutions}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <div className="row align-items-end">
                    <div className="col-md mb-3">
                        <label className="d-block">Priorities</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value="option1"
                                checked={userInput.priorityOne}
                                onChange={() => {
                                    handleCheckButtonChange('priorityOne');
                                }}
                            />
                            <label className="form-check-label">1</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox2"
                                value="option2"
                                checked={userInput.priorityTwo}
                                onChange={() => {
                                    handleCheckButtonChange('priorityTwo');
                                }}
                            />
                            <label className="form-check-label">2</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox3"
                                value="option3"
                                checked={userInput.priorityThree}
                                onChange={() => {
                                    handleCheckButtonChange('priorityThree');
                                }}
                            />
                            <label className="form-check-label">3</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox4"
                                value="option4"
                                checked={userInput.priorityFour}
                                onChange={() => {
                                    handleCheckButtonChange('priorityFour');
                                }}
                            />
                            <label className="form-check-label">4</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox5"
                                value="option5"
                                checked={userInput.priorityFive}
                                onChange={() => {
                                    handleCheckButtonChange('priorityFive');
                                }}
                            />
                            <label className="form-check-label">5</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox6"
                                value="option6"
                                checked={userInput.prioritySix}
                                onChange={() => {
                                    handleCheckButtonChange('prioritySix');
                                }}
                            />
                            <label className="form-check-label">6</label>
                        </div>
                    </div>

                    <div className="col-md mb-3">
                        <label className="d-block">Prev Apprv By GA</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckboxyes"
                                value="yes"
                                checked={userInput.prevApprovedByGAYes}
                                onChange={() => {
                                    handleCheckButtonChange('prevApprovedByGAYes');
                                }}
                            />
                            <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckboxno"
                                value="optionno"
                                checked={userInput.prevApprovedByGANo}
                                onChange={() => {
                                    handleCheckButtonChange('prevApprovedByGANo');
                                }}
                            />
                            <label className="form-check-label">No</label>
                        </div>
                    </div>
                </div>
                <hr />
                <h3 className="text-strong text-primary mb-3">
                    Select bulk updates to make
                </h3>
                <div className="row">
                    <div className="col-md mb-3">
                        <label>% of requested funding to recommend</label>
                        <div className="input-group">
                            <input
                                type="number"
                                name="requestedFundingPercent"
                                className="form-control"
                                value={userInput.requestedFundingPercent}
                                onChange={evt =>
                                    onChangeFunction.handleChange(evt, setUserInput)
                                }
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                        <p className="m-0 mt-2">
                            Enter value from 0 to 100. Leave blank if you do not wish to
                            update recommended funding
                        </p>
                    </div>
                    <div className="col-md mb-3">
                        <label>Funding Method</label>
                        <Select
                            onChange={e => handleSelectChange(e)}
                            options={fundingMethodOptions}
                            value={fundingMethodOptions.filter(
                                option => option.value === userInput.fundingMethod.value
                            )}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={applyBulkUpdate}>
                    Apply
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
