import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";

// import { userService } from 'services';

class HomePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: {},
			users: [],
			userRoles: [],
		};
	}

	componentDidMount() {
		this.setState({
			user: JSON.parse(localStorage.getItem('user')),
			users: { loading: true },
			//userRoles: JSON.parse(localStorage.getItem("userRoles"))
		});
		// userService.getAll().then(users => this.setState({ users }));
		// return (<Redirect to='/login' />);
	}

	render() {
		// const { user } = this.state; //, userRoles
		return (
			<div>
				<h1>CHE Data Submission System</h1>
				<p>Login successful</p>
			</div>
		);
	}
}

export { HomePage };
