import React, { useState, useEffect } from 'react';
import { Input, Button } from 'reactstrap';
import { academicInstitutionsService } from 'services';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UrlEnum, usePermissions } from 'shared';
import { AcademicAffairAddCampusModal } from './academic-affair-add-campus-modal';
import { Confirm } from 'components/blocks';
import { Institution } from '../academic-affair-report/components';


const rowStyles = {
    container: provided => ({
        ...provided,
        width: 120,
    }),
};

export const AcademicAffairInstitution = ({
    academicInstitutionData,
    setAcademicInstitutionData,
    sectorSelectOptions,
    reportingGroupCodeOptions,
    campuses,
    setCampuses,
}) => {

    const sectorSelectOptionLabels = sectorSelectOptions.map(x => {return {label: x.sectorName, value: x.sectorId}});
    const reportingGroupCodeLabels = reportingGroupCodeOptions.map(x => {return {label: x.reportingGroupName, value: x.reportingGroupCode}});
    const [campusesToAdd, setCampusesToAdd] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [campusesToRemove, setCampusesToRemove] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const history = useHistory();
    const getOptionValue = d => d.value;

    const tableMaxHeightStyle = { maxHeight: '400px', overflowY: 'scroll' };
    


    const addNewCampus = campus => {
        var newCampus = {
            InstitutionUnitId: cheInstitutionUnitId,
            CampusUnitId: campus.campusId,
            SystemUnitId: cheSystemUnitId,
            CampusName: campus.cheUnitName,
            CampusStatus: campus.Active == null,
            CampusShortName: "",
            CampusAddress: campus.campusAddress,
            CampusStateCode: "IN",
            CampusZipCode: campus.campusZipCode,
            CampusCity: campus.campusCity,
            IPEDSId: campus.ipedsId,
            OPECampusId: campus.OPEid,
        }
        campusesToAdd.push(newCampus);
    };

    const handleInstitutionDetailsChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setAcademicInstitutionData(prev => ({
            ...prev,
            [name]: newValue,
            
            
        }));
    };

    const RemoveFromAddList = campusId => {
        const newList = campusesToAdd.filter((x) => x.CampusUnitId !== campusId);

        setCampusesToAdd(newList);
    }

    const toggleRemoval = campusId => {
        const alreadyRemoved = campusesToRemove.find(x => x == campusId);

        if(alreadyRemoved != null) { // needs to be readded to the institution
            const newList = campusesToRemove.filter((x) => x !== campusId);

            setCampusesToRemove(newList);
        } else { // needs to be removed from institution
            campusesToRemove.push(campusId);
            setCampusesToRemove(campusesToRemove);
        }

        //async???

        setRerender(!rerender);
    }

    
    const {
        cheSystemUnitId,
        cheInstitutionUnitId,
        cheInstitutionName,
        reportingGroupCode,
        sectorId,
    } = academicInstitutionData


    const parentIds = [cheSystemUnitId, cheInstitutionUnitId];


    const renderApprovedCampusRows = () => {
        let rows = [];
        
        if(campusesToAdd != null) {
            campusesToAdd.map(campus => {
                rows.push(
                    <tr key={campus.CampusUnitId}
                        onClick={() => RemoveFromAddList(campus.CampusUnitId)}>
    
                        <td>{campus.CampusName}</td>
                        <td className="table-actions">
                            <div className="text-success">Pending Addition</div>
                        </td>
                    </tr>
                );
            });
        }
        


        if(campuses != null) {
            campuses.map(campus => {
                rows.push(
                    <tr key={campus.cheCampusUnitId}>
                        <td>{campus.cheCampusName}</td>
                        <td className="table-actions">
                            <div className="text-danger">{checkForRemoval(campus.cheCampusUnitId) ? "Pending Unit Deactivation" : ""}</div>
                            <Button
                                className="action-circle"
                                onClick={() => {
                                    const location = {
                                        pathname: UrlEnum.ACADEMIC_AFFAIRS_CAMPUS,
                                        state: [{ campusId: campus.cheCampusUnitId }],
                                    };
                                    history.push(location);
                                }}
                                >
                                <FontAwesomeIcon id={campus.cheCampusUnitId + '-edit'} icon={faEdit} />
                            </Button>
                            {!parentIds.some(x => x == campus.cheCampusUnitId) && (
                                <Button
                                    className="action-circle"
                                    onClick={() => {
                                        toggleRemoval(campus.cheCampusUnitId)
                                    }}
                                    >
                                    <FontAwesomeIcon id={campus.cheCampusUnitId + '-delete'} icon={faTrash} />
                                </Button>
                            )} 
                        </td>
                    </tr>
                );
            });
        }
        return rows;
    };


    const checkForRemoval = id => {
        const check = campusesToRemove.find(x => x == id);
        return check != null;
    }

    const handleSectorSelectChange = evt => {
        setAcademicInstitutionData(prev => ({
            ...prev,
            sectorId: evt.value
            
        }));
    };

    const handleReportingGroupCodeChange = evt => {
        setAcademicInstitutionData(prev => ({
            ...prev,
            reportingGroupCode: evt.value
            
        }));
    };

    const getSectorOption = val => {
        return sectorSelectOptionLabels.find(x => x.value === val);
    };

    const getReportingGroupCode = val => {
        return reportingGroupCodeLabels.find(x => x.value === val);
    }

    

    const submitForm = async () => {
        setConfirm(false);
        const submitData = {
            InstitutionId: cheInstitutionUnitId,
            CampusesToAdd: campusesToAdd,
            CampusesToRemove: campusesToRemove,
            ReportingGroupCode: reportingGroupCode,
            SectorId: sectorId,
            InstitutionName: cheInstitutionName,
        };
        await academicInstitutionsService.editAcademicInstitution(submitData, cheInstitutionUnitId);
        setCampusesToAdd([]);
    };
    return (
        <React.Fragment>
            <div className="sticky-footer">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.ACADEMIC_AFFAIRS_SYSTEM}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back
                        </a>
                    </div>
                    <div className="row sticky-bar-spacer">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <h2 className="text-strong text-primary">
                                    {cheInstitutionName}
                                </h2>
                                <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                                    <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                                        <li className="mr-3">
                                            <label className="m-0">Institution ID:</label>{' '}
                                            {cheInstitutionUnitId} {rerender ? "" : ""}
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="card p-3">
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Institution Name{' '}
                                            <sup className="text-danger">*</sup>
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Institution Name"
                                            value={cheInstitutionName}
                                            onChange={handleInstitutionDetailsChange}
                                            name="cheInstitutionName"
                                        />
                                        
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Sector{' '}
                                            <sup className="text-danger">*</sup>
                                        </label>
                                        <Select
                                            onChange={handleSectorSelectChange}
                                            options={sectorSelectOptionLabels}
                                            value={getSectorOption(sectorId)}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>
                                    
                                </div>

                                <div className="row mb-4">
                                    
                                    <div className="col-md">
                                        <label>
                                            Reporting Group Code <sup className="text-danger">*</sup>
                                        </label>
                                        <div className="form-row align-items-center">
                                            <div className="col-md-3">
                                            <Select
                                            onChange={handleReportingGroupCodeChange}
                                            options={reportingGroupCodeLabels}
                                            value={getReportingGroupCode(reportingGroupCode)}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row gutter-60">
                                    <div className="col-md-7">
                                        <div className="d-md-flex align-items-center justify-content-between text-md-left text-center mb-3">
                                            <h3 className="text-primary mb-3 mb-md-0">
                                                Approved Campuses
                                            </h3>
                                            <button
                                                type="button"
                                                onClick={() => setIsModalOpen(true)}
                                                className="btn btn-sm btn-primary"
                                            >
                                                <i className="far fa-plus"></i> Add
                                                Campus
                                            </button>
                                        </div>
                                        <div className="input-group input-group-btn input-group-sm mb-3 d-none">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="search approved campuss..."
                                            />
                                            <div className="input-group-append white hide">
                                                <span className="input-group-text px-3">
                                                    <div className="body-text-link text-primary">
                                                        <i className="fal fa-times"></i>{' '}
                                                        clear
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="button"
                                                >
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Campus</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderApprovedCampusRows()}
                                                    {/*                                                 
                                            <tr>
                                                <td>
                                                    Ivy Tech Community College-Terre
                                                    Haute
                                                </td>
                                                <td className="table-actions">
                                                    <a
                                                        href="#"
                                                        className="text-danger"
                                                    >
                                                        Remove
                                                    </a>
                                                </td>
                                            </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {(
                        <div className="sticky-bottom-bar">
                            <div className="d-flex align-items-center justify-content-end">
                                <a
                                    className="btn btn-outline-primary btn-lg mr-2"
                                    href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}
                                >
                                    Cancel
                                </a>
                                <Button
                                    type="button"
                                    className="btn btn-success btn-lg px-3"
                                    onClick={() => {
                                        setConfirm(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} /> Save Changes
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <AcademicAffairAddCampusModal
                isOpen={isModalOpen}
                toggle={toggle}
                addNewCampus={addNewCampus}
                institutionId={cheInstitutionUnitId}
            />
            
            <Confirm
                isOpen={confirm}
                toggle={() => setConfirm(false)}
                onConfirm={submitForm}
                title="Confirm Changes"
                message="Are you sure you want to submit these changes?"
            />
        </React.Fragment>
    );
};
