import { handleServiceRequest } from '../handle-service-request';

const getToggles = () => {
    const getObject = {
        url: '/api/System/Toggle',
    };
    return handleServiceRequest.get(getObject);
};
const postToggles = toggles => {
    const postObject = {
        url: '/api/System/Toggle',
        postBody: toggles,
    };
    return handleServiceRequest.post(postObject);
};

export const partitionToggleService = {
    getToggles,
    postToggles,
};
