import React, { useReducer } from 'react';
import { Form, Label, Input, Row, Col } from 'reactstrap';
import { WizardFooter } from './wizard-footer';

export const DualCredit = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 7,
            projectName: budgetRun.budgetRunTitle ? budgetRun.budgetRunTitle : '',
            budgetYearOne: budgetRun.budgetYearOne,
            budgetYearTwo: budgetRun.budgetYearTwo,
            bY1_DualCreditFunding: budgetRun.bY1_DualCreditFunding,
            bY2_DualCreditFunding: budgetRun.bY2_DualCreditFunding,
            dualCreditDetails: budgetRun.dualCreditDetails,
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        budgetRun[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    const handleTableChange = (event, index, inputTypeChanged) => {
        const headerValue = userInput.dualCreditDetails[index];
        headerValue[inputTypeChanged] = +event.target.value ?? 0;
        //setChangedTotals();
        budgetRun.dualCreditDetails = [...userInput.dualCreditDetails];
        setUserInput({ dualCreditDetails: [...userInput.dualCreditDetails] });
    };

    const sanitizeBudgetRun = () => {
        budgetRun.budgetYearOne = +userInput.budgetYearOne;
        budgetRun.budgetYearTwo = +userInput.budgetYearTwo;
        budgetRun.bY1_DualCreditFunding = +userInput.bY1_DualCreditFunding;
        budgetRun.bY2_DualCreditFunding = +userInput.bY2_DualCreditFunding;
        budgetRun.dualCreditDetails = userInput.dualCreditDetails;
        return budgetRun;
    };

    const renderTotalRow = () => {
        var totCol1 = 0;
        var totCol2 = 0;
        var totCol3 = 0;
        userInput.dualCreditDetails.forEach(function(row) {
            totCol1 += +row.thpCredits;
            totCol2 += +row.bY1Funding;
            totCol3 += +row.bY2FUnding;
        });
        return (
            <React.Fragment>
                <tr>
                    <td>
                        <Label className="m-0">Totals</Label>
                    </td>
                    <td className="text-right">{totCol1.toLocaleString()}</td>
                    <td className="text-right">${totCol2.toLocaleString()}</td>
                    <td className="text-right">${totCol3.toLocaleString()}</td>
                </tr>
            </React.Fragment>
        );
    };

    const renderTableRows = () => {
        return userInput.dualCreditDetails.map((row, index) => {
            return (
                <React.Fragment key={index}>
                    <tr key={index}>
                        <td>
                            <Label className="m-0">{row.displayName}</Label>
                        </td>
                        <td className="restricted-width-input">
                            <Input
                                type="number"
                                className="form-control"
                                value={row.thpCredits}
                                name="thpCredits"
                                onChange={e => {
                                    handleTableChange(e, index, 'thpCredits');
                                }}
                            ></Input>
                        </td>
                        <td className="text-right">${row.bY1Funding.toLocaleString()}</td>
                        <td className="text-right">${row.bY2FUnding.toLocaleString()}</td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Dual Credit Line Item Funding
            </h2>
            <div className="card no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">
                    Dollar amount per Technical or High Priority dual credit awarded by
                    campus
                </h4>
                <Row>
                    <Col md="auto" mb="3" className="mb-md-0">
                        <Label>{userInput.budgetYearOne}</Label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <Input
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                name="bY1_DualCreditFunding"
                                value={userInput.bY1_DualCreditFunding}
                            ></Input>
                        </div>
                    </Col>
                    <Col md="auto" mb="3" className="mb-md-0">
                        <Label>{userInput.budgetYearTwo}</Label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <Input
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                name="bY2_DualCreditFunding"
                                value={userInput.bY2_DualCreditFunding}
                            ></Input>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="card no-shadow border p-3">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover align-middle table-sm">
                        <thead>
                            <tr>
                                <th>Campus</th>
                                <th className="text-center">T+HP Credits</th>
                                <th className="text-center">
                                    {userInput.budgetYearOne} Funding
                                </th>
                                <th className="text-center">
                                    {userInput.budgetYearTwo} Funding
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows()}
                            {renderTotalRow()}
                        </tbody>
                    </table>
                </div>
            </div>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={false}
                setBudgetRun={setBudgetRun}
                sanitizeBudgetRun={sanitizeBudgetRun}
            />
        </Form>
    );
};
