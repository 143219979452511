import React, { useReducer } from 'react';
import { Form, Label, Input, Row, Col } from 'reactstrap';
import { WizardFooter } from './wizard-footer';

export const RandR = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 7,
            projectName: budgetRun.budgetRunTitle ? budgetRun.budgetRunTitle : '',
            budgetYearOne: budgetRun.budgetYearOne,
            budgetYearTwo: budgetRun.budgetYearTwo,
            rrAssetFundingPercent: budgetRun.rrAssetFundingPercent * 100,
            infrastructureAssetFundingPercent:
                budgetRun.infrastructureAssetFundingPercent * 100,
            rrDetails: budgetRun.rrDetails,
        }
    );

    // const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

    // const handlePercentChange = evt => {
    //     const name = evt.target.name;
    //     const newValue = +evt.target.value ?? 0;

    //     if(floatRegExp.test(evt.target.value))
    //         setUserInput({ [name]: (newValue / 100) });
    // };

    const sanitizeBudgetRun = () => {
        budgetRun.rrAssetFundingPercent = +userInput.rrAssetFundingPercent / 100;
        budgetRun.infrastructureAssetFundingPercent =
            +userInput.infrastructureAssetFundingPercent / 100;
        return budgetRun;
    };

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        budgetRun[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    // const handleTableChange = (event, index, inputTypeChanged) => {
    //     const headerValue = userInput.opsBase[index];
    //     headerValue[inputTypeChanged] = event.target.value ?? 0;
    //     //setChangedTotals();
    //     budgetRun.rrDetails = [...userInput.opsBase];
    //     setUserInput({ rrDetails: [...userInput.opsBase] });
    // };

    const renderTotalRow = () => {
        var totCol1 = 0;
        var totCol2 = 0;
        var totCol3 = 0;
        var totCol4 = 0;
        userInput.rrDetails.forEach(function(row) {
            totCol1 += row.rrAssetTotal;
            totCol2 += row.infrastructureAssetTotal;
            totCol3 += +(
                (row.rrAssetTotal * userInput.rrAssetFundingPercent) /
                100
            ).toFixed(0);
            totCol4 += +(
                (row.infrastructureAssetTotal *
                    userInput.infrastructureAssetFundingPercent) /
                100
            ).toFixed(0);
        });
        return (
            <React.Fragment>
                <tr>
                    <td>
                        <Label className="m-0">Totals</Label>
                    </td>
                    <td className="text-right">${totCol1.toLocaleString()}</td>
                    <td className="text-right">${totCol2.toLocaleString()}</td>
                    <td className="text-right">${totCol3.toLocaleString()}</td>
                    <td className="text-right">${totCol4.toLocaleString()}</td>
                </tr>
            </React.Fragment>
        );
    };

    const renderTableRows = () => {
        return userInput.rrDetails.map((row, index) => {
            return (
                <React.Fragment key={index}>
                    <tr key={index}>
                        <td>
                            <Label className="m-0">{row.displayName}</Label>
                        </td>
                        <td className="text-right">
                            ${row.rrAssetTotal.toLocaleString()}
                        </td>
                        <td className="text-right">
                            ${row.infrastructureAssetTotal.toLocaleString()}
                        </td>
                        <td className="text-right">
                            $
                            {(+(
                                (row.rrAssetTotal * userInput.rrAssetFundingPercent) /
                                100
                            ).toFixed(0)).toLocaleString()}
                        </td>
                        <td className="text-right">
                            $
                            {(+(
                                (row.infrastructureAssetTotal *
                                    userInput.infrastructureAssetFundingPercent) /
                                100
                            ).toFixed(0)).toLocaleString()}
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Repair & Rehabilitation Funding
            </h2>
            <div className="card no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">
                    Percentage of R&amp;R assets to fund
                </h4>
                <Row>
                    <Col md="auto" mb="3" className="mb-md-0">
                        <Label>% R&amp;R Assets</Label>
                        <div className="input-group">
                            <Input
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                name="rrAssetFundingPercent"
                                value={userInput.rrAssetFundingPercent}
                            ></Input>
                            <div className="input-group-prepend">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="auto" mb="3" className="mb-md-0">
                        <Label>% Infstr Assets</Label>
                        <div className="input-group">
                            <Input
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                name="infrastructureAssetFundingPercent"
                                value={userInput.infrastructureAssetFundingPercent}
                            ></Input>
                            <div className="input-group-prepend">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="card no-shadow border p-3">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover align-middle table-sm">
                        <thead>
                            <tr>
                                <th>Campus</th>
                                <th className="text-center">R&amp;R Asset Total</th>
                                <th className="text-center">Infstr Asset Total</th>
                                <th className="text-center">R&amp;R Funding </th>
                                <th className="text-center">Infstr Funding</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows()}
                            {renderTotalRow()}
                        </tbody>
                    </table>
                </div>
            </div>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={false}
                setBudgetRun={setBudgetRun}
                sanitizeBudgetRun={sanitizeBudgetRun}
            />
        </Form>
    );
};
