export const FormEnum = {
    GENERALINFO: 1,
    COSTSIZEFUNDING: 2,
    DETAILDESCRIPTION: 3,
    NEEDSANDPURPOSE: 4,
    OTHERPROJECTINFO: 5,
    SPACEDETAIL: 6,
    COSTDETAIL: 7,
    ANNUALCOSTDETAIL: 8,
    SUBMIT: 9,
};
