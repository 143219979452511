import React, {
    useEffect,
    useState,
    useReducer,
    useCallback,
    useMemo,
    Fragment,
} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import { Button, FormInput, FormSelect, FormRadio } from '../../blocks';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_STATE = {
    friendlyName: '',
    fileDescription: '',
    categoryId: '',
    isDocumentation: true,
};

const getOptionLabel = d => d.label;
const getOptionValue = d => d.value;

export const Upload = props => {
    const { open, close, onSubmit, editing, sections } = props;
    const [loading, setLoading] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [file, setFile] = useState(null);
    const [form, setForm] = useReducer(reducer, INITIAL_STATE);
    const { friendlyName, categoryId, fileDescription, isDocumentation } = form;
    const isEdit = Boolean(editing.id);
    const onSave = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('friendlyName', friendlyName);
        formData.append('categoryId', categoryId.value);
        formData.append('fileDescription', fileDescription);
        formData.append('isDocumentation', isDocumentation);
        await onSubmit(formData);
        setLoading(false);
        close();
    };
    const onSaveAdd = async () => {
        setLoadingAdd(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('friendlyName', friendlyName);
        formData.append('categoryId', categoryId.value);
        formData.append('fileDescription', fileDescription);
        formData.append('isDocumentation', isDocumentation);
        await onSubmit(formData);
        setForm(INITIAL_STATE);
        setFile(null);
        setLoadingAdd(false);
    };
    const onUpdate = async () => {
        setLoading(true);
        const payload = {
            id: editing.id,
            friendlyName: friendlyName,
            categoryId: categoryId.id,
            fileDescription: fileDescription,
            isDocumentation: isDocumentation,
        };
        await onSubmit(payload, editing);
        setLoading(false);
        close();
    };
    const onFileChange = useCallback(e => {
        setFile(e.target.files[0]);
    }, []);
    useEffect(() => {
        if (open) {
            setForm({
                ...editing,
                categoryId: editing.categoryId
                    ? sections.find(s => s.id === editing.categoryId)
                    : '',
            });
        } else {
            setForm(INITIAL_STATE);
            setFile(null);
        }
    }, [editing, open, sections]);
    const disabled = useMemo(() => {
        if (isEdit) {
            return !friendlyName || !categoryId;
        }
        return !file || !friendlyName || !categoryId;
    }, [friendlyName, categoryId, file, isEdit]);
    return (
        <Modal isOpen={open} className="modal-dialog">
            <ModalHeader close={close}>
                {isEdit ? 'Edit Document' : 'Upload Document'}
            </ModalHeader>
            <ModalBody>
                <FormSelect
                    required
                    label="Section"
                    onChange={setForm}
                    value={categoryId}
                    options={sections?.map(x => {
                        return { label: x.name, value: x.id };
                    })}
                    placeholder="Choose a section..."
                    name="categoryId"
                    isMulti={false}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                />
                <FormRadio
                    required
                    label="Type"
                    name="isDocumentation"
                    onChange={setForm}
                    value={isDocumentation}
                    options={[
                        {
                            value: true,
                            label: 'Documentation',
                        },
                        {
                            value: false,
                            label: 'File Layout',
                        },
                    ]}
                />
                <FormInput
                    required
                    label="Name"
                    placeholder="Document Name..."
                    name="friendlyName"
                    value={friendlyName}
                    onChange={setForm}
                />
                <FormInput
                    label="Description"
                    placeholder="Document Description (optional)..."
                    name="fileDescription"
                    value={fileDescription}
                    onChange={setForm}
                />
                {!isEdit && (
                    <fieldset className="form-group">
                        <label>File</label>
                        <div className="w-100 hidden-file-input">
                            <input
                                type="file"
                                accept=".csv,.pdf,.xls,.xlsx"
                                onChange={onFileChange}
                            />
                            <Button
                                type="button"
                                outline
                                color="primary"
                                className="btn-block"
                            >
                                <FontAwesomeIcon icon={faFile} /> Choose File
                            </Button>
                        </div>
                        {file && <p className="text-strong m-0 mt-3">{file.name}</p>}
                    </fieldset>
                )}
            </ModalBody>
            <ModalFooter>
                <Button outline onClick={close}>
                    Cancel
                </Button>
                {isEdit ? (
                    <Button
                        color="success"
                        onClick={onUpdate}
                        disabled={disabled}
                        loading={loading}
                    >
                        Save
                    </Button>
                ) : (
                    <Fragment>
                        <Button
                            color="success"
                            onClick={onSaveAdd}
                            disabled={disabled}
                            loading={loadingAdd}
                        >
                            Save & Add Another
                        </Button>
                        <Button
                            color="success"
                            onClick={onSave}
                            disabled={disabled}
                            loading={loading}
                        >
                            Save
                        </Button>
                    </Fragment>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default Upload;
