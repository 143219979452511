export const BudgetRunFormEnum = {
    GENERALINFO: 1,
    PERCENTS: 2,
    BASE: 3,
    PFFOUTPUT: 4,
    PFFPERUNIT: 5,
    CAPITALPROJECTS: 6,
    LINEITEMS: 7,
    DUALCREDIT: 8,
    RANDR: 9,
    OUTPUT: 10,
};
