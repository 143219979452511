import React, { useReducer, useEffect, useCallback, useMemo, useState } from 'react';

import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createAcademicSystem, validateCheUnitId, validateCheOpeId, validateCheIpedsId } from 'services/admin-services/academic-institutions.service';
import { FormInput } from 'components/blocks';
import { academicInstitutionsService } from 'services';
import { FormSelect } from './components/FormSelect';

export const AcademicAffairAddCampusModal = ({
    isOpen,
    toggle,
    institutionId,
    addNewCampus,
}) => {
    
    const reducer = (state, newState) => ({ ...state, ...newState });
    const [options, setOptions] = useReducer(reducer, { campuses: [] });
    const [loading, setLoading] = useState(false);
    const [invalidIpedsId, setInvalidIpedsId] = useState(false);
    const [invalidOpeId, setInvalidOpeId] = useState(false);
    const [invalidId, setInvalidId] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [missingName, setMissingName] = useState(false);
    const [missingCHEId, setMissingCHEId] = useState(false);
    const [missingIPEDSId, setMissingIPEDSId] = useState(false);
    const [missingOPEId, setMissingOPEId] = useState(false);
    const [missingCity, setMissingCity] = useState(false);
    const [missingZipCode, setMissingZipCode] = useState(false);
    const [missingAddress, setMissingAddress] = useState(false);

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            campuses: [],
        }
    );


    function empty(object) {
        return Object.keys(object).length == 0
    }


    const fetchOptions = useCallback(async () => {
        academicInstitutionsService
            .getInstitutionCampuses(institutionId)
            .then(res => formatLocations(res))
            .then(res => setOptions({campuses: res}));
    }, []);

    /*
    useEffect(() => {
        fetchOptions();
    }, [fetchOptions]);
    */

    const formatLocations = responses => {
        console.log(responses);
        var returnMe = responses.campuses.map(response => ({
            value: response.key,
            label: response.value,
        }));
        return returnMe;
    };

    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    const [campus, setCampus] = useReducer(reducer, {
        cheUnitName: "",
        campusId: "",
        active: true,
        ipedsId: "",
        OPEid: "",
        campusAddress: "",
        campusCity: "",
        campusZipCode: "",
    });

    function clearErrors() {
        setMissingAddress(false);
        setMissingCHEId(false);
        setMissingCity(false);
        setMissingIPEDSId(false);
        setMissingName(false);
        setMissingZipCode(false);
        setMissingOPEId(false);
    }


    function prepareCampus(campus) {
        let newCampus = Object.assign({}, campus)

        for(const key in newCampus) {
            
            if(newCampus[key].hasOwnProperty('value')) {
                newCampus[key] = newCampus[key]['value']
            } else if (empty(newCampus[key])) {
                newCampus[key] = ""
            }
        }

        return newCampus 
    }

    function validateRequiredFields(campus) {
        var varMissingName = campus.cheUnitName == "";
        setMissingName(varMissingName)
        var varMissingId = campus.campusId == "";
        setMissingCHEId(varMissingId)
        var varMissingIpedsId = campus.ipedsId == ""
        setMissingIPEDSId(varMissingIpedsId)
        var varMissingOpeId = campus.OPEid == ""
        setMissingOPEId(varMissingOpeId)
        var varMissingAddress = campus.campusAddress == ""
        setMissingAddress(varMissingAddress)
        var varMissingCity = campus.campusCity == ""
        setMissingCity(varMissingCity)
        var varMissingZipCode = campus.campusZipCode == ""
        setMissingZipCode(varMissingZipCode)



        let missingSomething = (varMissingName || varMissingId || varMissingIpedsId || varMissingOpeId || varMissingAddress || varMissingCity || varMissingZipCode);

        return !missingSomething;
    }


    // Makes post to create the system to the backend
    const create = async () => {
        let campusToSend = prepareCampus(campus)
        console.log(campusToSend)

        if (!validateRequiredFields(campusToSend)) {
            return
        }

        setLoading(true);

        const validUnitId = await validateCheUnitId(campusToSend.campusId)
        const validIpedsId = await validateCheIpedsId(campusToSend.ipedsId)
        const validOpeId = await validateCheOpeId(campusToSend.OPEid)

        if (!validUnitId) {
            setLoading(false);
            setInvalidId(true);
            return
        }

        if (!validIpedsId) {
            setLoading(false);
            setInvalidIpedsId(true);
            return
        }

        if (!validOpeId) {
            setLoading(false);
            setInvalidOpeId(true);
            return
        }

        const campusAdded = addNewCampus(campusToSend)
        setLoading(false);
        toggle();
        
    }

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-md">
            <ModalHeader>Create a New Campus</ModalHeader>
            <ModalBody>
                <Form>
                    <div className="form-row">

                        <div className="col-md-8">
                            <FormInput 
                                label="Name"
                                required={true}
                                value={campus.cheUnitName}
                                name="cheUnitName"
                                onChange={setCampus}
                                onClick={clearErrors}
                            ></FormInput>
                            {missingName && <p className="text-danger">
                                Campus Name is required
                            </p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div>
                            <FormInput 
                                label="CHEUnitId"
                                required={true}
                                value={campus.campusId}
                                name="campusId"
                                onChange={setCampus}
                                className="col-md-8"
                            ></FormInput>
                            {invalidId && <p className="text-danger">
                                    ID taken, please choose another
                                </p>}
                                {missingCHEId && <p className="text-danger">
                                    ID is required
                                </p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md">
                            <FormInput 
                                label="ipedsId"
                                required={true}
                                value={campus.ipedsId}
                                name="ipedsId"
                                onChange={setCampus}
                                onClick={clearErrors}
                            ></FormInput>
                            {invalidIpedsId && <p className="text-danger">
                            IPEDS Id does not exist
                        </p>}
                            {missingIPEDSId && <p className="text-danger">
                                Ipeds ID is required
                            </p>}
                       </div>
                       <div className="col-md">
                            <FormInput 
                                label="opeId"
                                required={true}
                                value={campus.OPEid}
                                name="OPEid"
                                onChange={setCampus}
                                onClick={clearErrors}
                            ></FormInput>
                            {invalidOpeId && <p className="text-danger">
                            OPE Id does not exist
                        </p>}
                            {missingOPEId && <p className="text-danger">
                                OPE ID is required
                            </p>}
                       </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-8">
                            <FormInput 
                                label="Address"
                                required={true}
                                value={campus.campusAddress}
                                name="campusAddress"
                                onChange={setCampus}
                                onClick={clearErrors}
                            ></FormInput>
                            {missingAddress && <p className="text-danger">
                                Campus Address is required
                            </p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md">
                            <FormInput 
                                label="City"
                                required={true}
                                value={campus.campusCity}
                                name="campusCity"
                                onChange={setCampus}
                                onClick={clearErrors}
                            ></FormInput>
                            {missingCity && <p className="text-danger">
                                City is required
                            </p>}
                       </div>
                       <div className="col-md">
                            <FormInput 
                                label="Zip Code"
                                required={true}
                                value={campus.campusZipCode}
                                name="campusZipCode"
                                onChange={setCampus}
                                onClick={clearErrors}
                            ></FormInput>
                            {missingZipCode && <p className="text-danger">
                                Zip Code is required
                            </p>}
                       </div>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="secondary" 
                    onClick={toggle}
                >
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    disabled={disabled}
                    loading={loading}
                    onClick={create}
                >
                    Add Campus 
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
};