import React, { useState, useEffect } from 'react';
import { AcademicAffairInstitution } from 'components';
import { academicInstitutionsService } from 'services';
import { AcademicAffairCampus } from 'components/academic-affair-institutions';

export const AcademicCampusPage = props => {
    const [academicCampusData, setAcademicCampusData] = useState([]);
    const [locations, setLocations] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedCampusId = sessionStorage.getItem('campusId');

        if (query) {
            for (let param of query) {
                if (
                    param.UnitId &&
                    (!persistedCampusId || param.UnitId !== persistedCampusId)
                ) {
                    window.sessionStorage.setItem('campusId', param.UnitId);
                    return param.UnitId;
                } else {
                    return null;
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            parseQueryParams();
            const persistedCampusId = sessionStorage.getItem('campusId');

            const data = await academicInstitutionsService.getAcademicCampus(
                persistedCampusId
            );
            data.vwCampus.CampusShortName = data.campusShortName;
            setAcademicCampusData(data.vwCampus || []);
            
            
            const locations = await academicInstitutionsService.getCampusLocations(persistedCampusId);
            setLocations(locations);
            
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <AcademicAffairCampus
            locations={locations}
            setLocations={setLocations}
            academicCampusData={academicCampusData}
            setAcademicCampusData={setAcademicCampusData}
        />
    );
};
