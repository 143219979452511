import React, { useState, useReducer } from 'react';
import { Form, Input } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import { GenericRadioButtons } from 'shared';

export const Percents = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 2,
            projectName: budgetRun.budgetRunTitle ? budgetRun.budgetRunTitle : '',
            isPFFFullyFunded: budgetRun.isPFFFullyFunded,
            budgetYearOne: budgetRun.budgetYearOne,
            budgetYearTwo: budgetRun.budgetYearTwo,
            bY1_PFFPercentOfTotalOpsInput:
                budgetRun.bY1_PFFPercentOfTotalOpsInput != null
                    ? (budgetRun.bY1_PFFPercentOfTotalOpsInput * 100).toFixed(2)
                    : null,
            bY2_PFFPercentOfTotalOps_Input:
                budgetRun.bY2_PFFPercentOfTotalOps_Input != null
                    ? (budgetRun.bY2_PFFPercentOfTotalOps_Input * 100).toFixed(2)
                    : null,
            bY1_ReallocPercentOfBaseInput:
                budgetRun.bY1_ReallocPercentOfBaseInput != null
                    ? (budgetRun.bY1_ReallocPercentOfBaseInput * 100).toFixed(2)
                    : null,
            bY2_ReallocPercentOfBase_Input:
                budgetRun.bY2_ReallocPercentOfBase_Input != null
                    ? (budgetRun.bY2_ReallocPercentOfBase_Input * 100).toFixed(2)
                    : null,
            bY1_NewFundingPercentOfBaseInput:
                budgetRun.bY1_NewFundingPercentOfBaseInput != null
                    ? (budgetRun.bY1_NewFundingPercentOfBaseInput * 100).toFixed(2)
                    : null,
            bY2_NewFundingPercentOfBase_Input:
                budgetRun.bY2_NewFundingPercentOfBase_Input != null
                    ? (budgetRun.bY2_NewFundingPercentOfBase_Input * 100).toFixed(2)
                    : null,
            bY1_FlatAdjPercent_Input: (budgetRun.bY1_FlatAdjPercent_Input * 100).toFixed(
                2
            ),
            bY2_FlatAdjPercent_Input: (budgetRun.bY2_FlatAdjPercent_Input * 100).toFixed(
                2
            ),
            bonusInputs: budgetRun.bonusInputs,
            byBonusPercentFloor: budgetRun.byBonusPercentFloor,
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        const divBy100 = evt.target.value / 100;
        if (name == 'byBonusPercentFloor') budgetRun[name] = newValue;
        else budgetRun[name] = divBy100;
        setUserInput({ [name]: newValue });
    };

    const handleChangeBonus = (evt, financeID) => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        const thisBonus = userInput.bonusInputs.find(x => x.financeID === financeID);
        thisBonus[name] = +newValue;
        setUserInput({ bonusInputs: [...userInput.bonusInputs] });
    };

    const sanitizeBudgetRun = () => {
        budgetRun.isPFFFullyFunded = userInput.isPFFFullyFunded;
        budgetRun.budgetYearOne = userInput.budgetYearOne;
        budgetRun.budgetYearTwo = userInput.budgetYearTwo;
        if (budgetRun.bY1_PFFPercentOfTotalOpsInput)
            budgetRun.bY1_PFFPercentOfTotalOpsInput =
                userInput.bY1_PFFPercentOfTotalOpsInput / 100;
        else budgetRun.bY1_PFFPercentOfTotalOpsInput = null;

        if (budgetRun.bY2_PFFPercentOfTotalOps_Input)
            budgetRun.bY2_PFFPercentOfTotalOps_Input =
                userInput.bY2_PFFPercentOfTotalOps_Input / 100;
        else budgetRun.bY2_PFFPercentOfTotalOps_Input = null;

        if (budgetRun.bY1_ReallocPercentOfBaseInput)
            budgetRun.bY1_ReallocPercentOfBaseInput =
                userInput.bY1_ReallocPercentOfBaseInput / 100;
        else budgetRun.bY1_ReallocPercentOfBaseInput = null;

        if (budgetRun.bY2_ReallocPercentOfBase_Input)
            budgetRun.bY2_ReallocPercentOfBase_Input =
                userInput.bY2_ReallocPercentOfBase_Input / 100;
        else budgetRun.bY2_ReallocPercentOfBase_Input = null;

        if (budgetRun.bY1_NewFundingPercentOfBaseInput)
            budgetRun.bY1_NewFundingPercentOfBaseInput =
                userInput.bY1_NewFundingPercentOfBaseInput / 100;
        else budgetRun.bY1_NewFundingPercentOfBaseInput = null;

        if (budgetRun.bY2_NewFundingPercentOfBase_Input)
            budgetRun.bY2_NewFundingPercentOfBase_Input =
                userInput.bY2_NewFundingPercentOfBase_Input / 100;
        else budgetRun.bY2_NewFundingPercentOfBase_Input = null;

        budgetRun.bY1_FlatAdjPercent_Input = userInput.bY1_FlatAdjPercent_Input / 100;
        budgetRun.bY2_FlatAdjPercent_Input = userInput.bY2_FlatAdjPercent_Input / 100;
        budgetRun.bonusInputs = userInput.bonusInputs;
        if (
            userInput.byBonusPercentFloor != null &&
            userInput.byBonusPercentFloor != ''
        ) {
            if (userInput.byBonusPercentFloor < 75) budgetRun.byBonusPercentFloor = 75;
            else if (userInput.byBonusPercentFloor > 80)
                budgetRun.byBonusPercentFloor = 80;
            else budgetRun.byBonusPercentFloor = +userInput.byBonusPercentFloor;
        } else budgetRun.byBonusPercentFloor = null;
        return budgetRun;
    };

    // const addBonusInputRows = () => {
    //     return userInput.bonusInputs
    //         ? userInput.bonusInputs.map(thisUnit => {
    //               return (
    //                   <tr key={thisUnit.financeID}>
    //                       <td>
    //                           <label className="m-0">{thisUnit.displayName}</label>
    //                       </td>
    //                       <td className="restricted-width-input">
    //                           <div className="input-group">
    //                               <Input
    //                                   type="text"
    //                                   onChange={e =>
    //                                       handleChangeBonus(e, thisUnit.financeID)
    //                                   }
    //                                   value={thisUnit.bY1BonusAmount}
    //                                   name="bY1BonusAmount"
    //                               ></Input>
    //                               <div className="input-group-append">
    //                                   <span className="input-group-text">%</span>
    //                               </div>
    //                           </div>
    //                       </td>
    //                       <td className="restricted-width-input">
    //                           <div className="input-group">
    //                               <Input
    //                                   type="text"
    //                                   onChange={e =>
    //                                       handleChangeBonus(e, thisUnit.financeID)
    //                                   }
    //                                   value={thisUnit.bY2BonusAmount}
    //                                   name="bY2BonusAmount"
    //                               ></Input>
    //                               <div className="input-group-append">
    //                                   <span className="input-group-text">%</span>
    //                               </div>
    //                           </div>
    //                       </td>
    //                   </tr>
    //               );
    //           })
    //         : '';
    // };

    // const handleRadioButtonChange = evt => {
    //     const name = evt.target.name;
    //     const newValue = evt.target.value === 'true' ? true : false;
    //     budgetRun[name] = newValue;
    //     setUserInput({ [name]: newValue });
    // };

    // const handleNumberChange = evt => {
    //     const name = evt.target.name;
    //     const newValue = +evt.target.value ?? 0;

    //     setUserInput({ [name]: newValue });
    // };

    // const handlePercentChange = evt => {
    //     const name = evt.target.name;
    //     const newValue = +evt.target.value ?? 0;

    //     setUserInput({ [name]: (newValue / 100) });
    // };

    const [percentRowClassName, setPercentRowClassName] = useState('');

    const percentRadioChanged = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value === 'true' ? true : false;

        if (percentRowClassName === '') {
            setPercentRowClassName('d-none');
        } else {
            setPercentRowClassName('');
        }

        budgetRun[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">Percents</h2>
            <div className="card no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">
                    Performance Funded Entities: PFF Calculation Percentages
                </h4>
                <div className="d-md-flex align-items-center">
                    <label className="mb-0 mr-3">Calculation:</label>
                    <GenericRadioButtons
                        onChange={percentRadioChanged}
                        name="isPFFFullyFunded"
                        button1="By PFF %"
                        button2="Fully Funded PFF"
                        value={userInput.isPFFFullyFunded}
                    ></GenericRadioButtons>
                </div>
                <hr />
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover align-middle table-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="text-center">{userInput.budgetYearOne}</th>
                                <th className="text-center">{userInput.budgetYearTwo}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={percentRowClassName}>
                                <td>
                                    <label className="m-0">
                                        PFF % Of Total Operating
                                    </label>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={
                                                userInput.bY1_PFFPercentOfTotalOpsInput
                                            }
                                            name="bY1_PFFPercentOfTotalOpsInput"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={
                                                userInput.bY2_PFFPercentOfTotalOps_Input
                                            }
                                            name="bY2_PFFPercentOfTotalOps_Input"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="m-0">
                                        Reallocation % Of Base Operating
                                    </label>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={
                                                userInput.bY1_ReallocPercentOfBaseInput
                                            }
                                            name="bY1_ReallocPercentOfBaseInput"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={
                                                userInput.bY2_ReallocPercentOfBase_Input
                                            }
                                            name="bY2_ReallocPercentOfBase_Input"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="m-0">
                                        New Funding % Of Base Operating
                                    </label>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={
                                                userInput.bY1_NewFundingPercentOfBaseInput
                                            }
                                            name="bY1_NewFundingPercentOfBaseInput"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={
                                                userInput.bY2_NewFundingPercentOfBase_Input
                                            }
                                            name="bY2_NewFundingPercentOfBase_Input"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="m-0">
                                        Bonus Six-Year Completion Percentage Floor
                                    </label>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={userInput.byBonusPercentFloor}
                                            name="byBonusPercentFloor"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {/* {addBonusInputRows()} */}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">
                    Non-Performance Funded Entities: Flat Rate Calculation Percentages
                </h4>
                <p className="text-italic">
                    Please enter the flat adjustment percentage to be used for
                    non-peformance funded entites (e.g. IU School of Medicine and
                    Dentistry). If left blank for a year, the system will automatically
                    calculate the % by taking the average of all the performance funded
                    institutions percent increase for that year.
                </p>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover align-middle table-sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="text-center">{userInput.budgetYearOne}</th>
                                <th className="text-center">{userInput.budgetYearTwo}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <label className="m-0">
                                        Flat % Of Base Operating
                                    </label>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={userInput.bY1_FlatAdjPercent_Input}
                                            name="bY1_FlatAdjPercent_Input"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="restricted-width-input">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            value={userInput.bY2_FlatAdjPercent_Input}
                                            name="bY2_FlatAdjPercent_Input"
                                        ></Input>
                                        <div className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={false}
                setBudgetRun={setBudgetRun}
                sanitizeBudgetRun={sanitizeBudgetRun}
            />
        </Form>
    );
};
