import React from 'react';
import { BudgetRunSingleNavTab } from './budget-run-single-nav-tab';

export const BudgetRunWizardNav = ({ step, setStep, budgetRun }) => {
    const toggleClassName = step => {
        setStep(step);
    };

    return (
        <div className="wizard-nav">
            <ul className="nav flex-column sticky-top">
                <BudgetRunSingleNavTab
                    name="General Info"
                    activeStepEnum={'GENERALINFO'}
                    tabIdName="general"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="Percents"
                    activeStepEnum={'PERCENTS'}
                    tabIdName="percents"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="Base"
                    activeStepEnum={'BASE'}
                    tabIdName="base"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="PFF Output"
                    activeStepEnum={'PFFOUTPUT'}
                    tabIdName="need"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="PFF $ Per Unit"
                    activeStepEnum={'PFFPERUNIT'}
                    tabIdName="other"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="Capital Projects"
                    activeStepEnum={'CAPITALPROJECTS'}
                    tabIdName="space"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="Line Items"
                    activeStepEnum={'LINEITEMS'}
                    tabIdName="cost"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="Dual Credit"
                    activeStepEnum={'DUALCREDIT'}
                    tabIdName="annual"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="R&R"
                    activeStepEnum={'RANDR'}
                    tabIdName="annual"
                    toggleClassName={toggleClassName}
                    step={step}
                />
                <BudgetRunSingleNavTab
                    name="Output"
                    toggleClassName={toggleClassName}
                    step={step}
                    activeStepEnum={'OUTPUT'}
                    tabIdName="submit"
                />
            </ul>
        </div>
    );
};
