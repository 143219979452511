import React from 'react';
import { Input } from 'reactstrap';

export const RadioCheckGroup = React.memo(props => {
    const { onChange, name, options, value } = props;
    return options.map((o, i) => {
        const { label, ...rest } = o;
        const id = name + i;
        const checked = value === o.value;
        return (
            <div key={id} className="form-check form-check-inline">
                <Input
                    type="checkbox"
                    checked={checked}
                    onClick={() => {
                        onChange({
                            [name]: checked ? '' : o.value,
                        });
                    }}
                    id={id}
                    name={name}
                    {...rest}
                />
                <label className="form-check-label" for={id}>
                    {label}
                </label>
            </div>
        );
    });
});
