export const ROLES = {
    ADMIN: 1,
    CheAdmin: 1,
    CheDataSubmissionAdmin: 2,
    CheDataUser: 3,
    CheApiAdmin: 4,
    CheApiUser: 5,
    CheFinanceAdmin: 6,
    CheFinanceUser: 7,
    ChedssInstitutionAdmin: 51,
    ChedssInstitutionDataSubmissionApprover: 52,
    ChedssInstitutionDataSubmissionReporter: 53,
    ChedssInstitutionDataSubmissionReviewer: 54,
    ChedssInstitutionApiApprover: 55,
    ChedssInstitutionApiReporter: 56,
    ChedssInstitutionApiReviewer: 57,
    ChedssInstitutionFinanceApprover: 58,
    ChedssInstitutionFinanceReporter: 59,
    ChedssInstitutionFinanceReviewer: 60,
    ChedssInstitutionCapitalProjectApprover: 61,
    ChedssInstitutionCapitalProjectReporter: 62,
    ChedssInstitutionCapitalProjectReviewer: 63,
};
