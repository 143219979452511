import React, { useState } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitModal } from './submit-modal';
import { LineItemService } from 'services';
import { Button } from 'components/blocks';

export const Footer = ({
    sanitizedInput,
    lineItemValues,
    biennium,
    mapOfTotals,
    isDataAudit,
    setIsDataAudit,
    scheduleId,
    fetchBudgetSchedule,
}) => {
    const [saving, setSaving] = useState(false);
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const onSave = async () => {
        setSaving(true);
        try {
            const input = {
                ...sanitizedInput(),
                scheduleStatusId: 1,
            };
            await LineItemService.saveLineItem(scheduleId, input);
            await fetchBudgetSchedule();
        } catch (error) {}
        setSaving(false);
    };
    return (
        <React.Fragment>
            <div className="sticky-bottom-bar">
                <div className="d-flex align-items-center justify-content-end">
                    <Button
                        className="btn btn-outline-primary btn-lg mr-2"
                        onClick={onSave}
                        loading={saving}
                    >
                        <i className="far fa-save"></i> Save
                    </Button>
                    <Button
                        className="btn btn-success btn-lg px-3"
                        onClick={() => {
                            setIsSubmitModalOpen(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faCheck} /> Submit
                    </Button>
                </div>
            </div>
            <SubmitModal
                isOpen={isSubmitModalOpen}
                toggle={() => setIsSubmitModalOpen(!isSubmitModalOpen)}
                lineItemValues={lineItemValues}
                biennium={biennium}
                mapOfTotals={mapOfTotals}
                sanitizedInput={sanitizedInput}
                isDataAudit={isDataAudit}
                setIsDataAudit={setIsDataAudit}
                scheduleId={scheduleId}
                fetchBudgetSchedule={fetchBudgetSchedule}
            />
        </React.Fragment>
    );
};
