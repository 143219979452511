import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';
import {
	faInfoCircle,
	faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Form, Row, Table, Card } from 'reactstrap';

export const SpaceDetail = ({ capitalProject, prevStep, nextStep }) => {
	const [spaceDetailRowHeaders, setSpaceDetailRowHeaders] = useState([]);
	useEffect(() => {
		const transformArray = (spaceDetailRowHeaderArray) => {
			return spaceDetailRowHeaderArray.map((spaceDetialRow) => {
				const { id } = spaceDetialRow;
				const savedSpaceDetailValue = capitalProject.spaceDetails.filter(
					(spaceDetail) => +spaceDetail.spaceTypeId === +id
				)[0];
				return savedSpaceDetailValue
					? savedSpaceDetailValue
					: {
							spaceTypeId: +id,
							currentUse: '0',
							construction: '0',
							plannedAndFunded: '0',
							terminated: '0',
							newRequest: '0',
					  };
			});
		};
		const fetchColumns = async () => {
			const res = await financeService.getSpaceDetailTableColumns();
			setSpaceDetailRowHeaders(res);
			capitalProject.spaceDetails = transformArray(res);
			return;
		};

		fetchColumns();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getRowHeaderTextFromSpaceTypeId = (spaceTypeId) => {
		const header = spaceDetailRowHeaders.find(
			(header) => +header.id === +spaceTypeId
		);
		return header ? header.text : 'loading';
	};

	const getTotalOfColumn = (key) => {
		let sum = 0;
		capitalProject.spaceDetails.forEach((spaceDetail) => {
			spaceDetail[key] ? (sum += +spaceDetail[key]) : (sum += 0);
		});
		return sum;
	};
	const [totals] = useState([
		getTotalOfColumn('currentUse'),
		getTotalOfColumn('construction'),
		getTotalOfColumn('plannedAndFunded'),
		getTotalOfColumn('currentUse') +
			getTotalOfColumn('construction') +
			getTotalOfColumn('plannedAndFunded'),
		getTotalOfColumn('terminated'),
		getTotalOfColumn('newRequest'),
		getTotalOfColumn('terminated') + getTotalOfColumn('newRequest'),
	]);

	const renderTotals = () => {
		return (
			<tr>
				<td>
					<label className='m-0'>Total Space</label>
				</td>
				<td className='text-strong text-right'>{totals[0]}</td>
				<td className='text-strong text-right'>{totals[1]}</td>
				<td className='text-strong text-right'>{totals[2]}</td>
				<td className='text-strong text-right'>{totals[3]}</td>
				<td className='text-strong text-right'>{totals[4]}</td>
				<td className='text-strong text-right'>{totals[5]}</td>
				<td className='text-strong text-right'>{totals[6]}</td>
			</tr>
		);
	};

	const renderTableRows = () => {
		return capitalProject.spaceDetails.map((row, index) => {
			return (
				<React.Fragment key={index}>
					<tr key={index}>
						<td className='nowrap'>
							<a
								href='/#'
								data-toggle='popover'
								data-position='top'
								data-html='true'
								data-content='(110) Classroom<br />(115) Classroom Service'>
								<FontAwesomeIcon icon={faInfoCircle} />
							</a>{' '}
							{getRowHeaderTextFromSpaceTypeId(row.spaceTypeId)}
						</td>
						<td>${capitalProject.spaceDetails[index].currentUse}</td>
						<td>${capitalProject.spaceDetails[index].construction}</td>
						<td>${capitalProject.spaceDetails[index].plannedAndFunded}</td>
						<td className='text-strong text-right highlight-cell'>
							{+row.currentUse + +row.construction + +row.plannedAndFunded}
						</td>
						<td>${capitalProject.spaceDetails[index].terminated}</td>
						<td>${capitalProject.spaceDetails[index].newRequest}</td>
						<td className='text-strong text-right highlight-cell'>
							{+row.terminated + +row.newRequest}
						</td>
					</tr>
				</React.Fragment>
			);
		});
	};
	return (
		<Form>
			<h2 className='text-strong text-primary border-bottom pb-2 mb-4'>
				Space Detail
			</h2>
			<Row>
				<Table
					responsive
					className='table table-striped table-bordered table-hover align-middle table-sm'>
					<thead className='text-center thead-light'>
						<tr>
							<th className='min-tablet-l no-sort'></th>
							<th className='min-tablet-l no-sort' colSpan='4'>
								Current Campus Total
							</th>
							<th className='min-tablet-l' colSpan='3'>
								Capital Request
							</th>
						</tr>
					</thead>
					<thead className='text-center'>
						<tr>
							<th className='min-tablet-l no-sort'>
								<span className='nowrap'>Space Classification</span>{' '}
								<a
									href='/#'
									data-toggle='popover'
									data-position='top'
									data-content='Space/Room codes based on Postsecondary Education Facilities Inventory and Classification Manual (2006)'>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className='fa-lg ml-1'
									/>
								</a>
							</th>
							<th className='min-tablet-l no-sort'>Current Space in Use</th>
							<th className='min-tablet-l no-sort'>
								Space Under Construction{' '}
								<a
									href='/#'
									data-toggle='popover'
									data-position='top'
									data-content='Identify in Space Detail Notes the specific facilities that are included in these data columns. Do not include pending approval, non-submitted projects or non-funded projects.'>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className='fa-lg ml-1'
									/>
								</a>
							</th>
							<th className='min-tablet-l no-sort'>
								Space Planned and Funded{' '}
								<a
									href='/#'
									data-toggle='popover'
									data-position='top'
									data-content='Identify in Space Detail Notes the specific facilities that are included in these data columns. Do not include pending approval, non-submitted projects or non-funded projects.'>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className='fa-lg ml-1'
									/>
								</a>
							</th>
							<th className='min-tablet-l no-sort'>
								Subtotal Current and Future Space
							</th>
							<th className='min-tablet-l no-sort'>
								Space to be Terminated{' '}
								<a
									href='/#'
									data-toggle='popover'
									data-position='top'
									data-content='Identify in Space Detail Notes the specific facilities that are included in these data columns. Do not include pending approval, non-submitted projects or non-funded projects.'>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className='fa-lg ml-1'
									/>
								</a>
							</th>
							<th className='min-tablet-l no-sort'>
								New Space in Capital Request
							</th>
							<th className='min-tablet-l no-sort'>Net Future Space</th>
						</tr>
					</thead>
					<tbody>
						{renderTableRows()}
						{renderTotals()}
					</tbody>
				</Table>
			</Row>
			<Row>
				<Card className=' no-shadow border p-3 mt-3'>
					<h4 className='mb-3 text-primary'>Space Detail Notes</h4>
					<p className='text-italic'>
						Please state specific facilities that are under construction,
						planned and funded and to be terminated. Do not include pending
						approval, non-submitted projects or non-funded projects
					</p>

					<hr />
					<label>Response:</label>
					<p>{capitalProject.spaceDetailNotes}</p>
				</Card>
			</Row>

			<WizardFooter
				prevStep={prevStep}
				nextStep={nextStep}
				capitalProject={capitalProject}
			/>
		</Form>
	);
};
