import React from 'react';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from 'prop-types';
import { UrlEnum } from 'shared';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

export const SearchCapitalProjectsReviewTable = ({ capitalProjects }) => {
    const history = useHistory();

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            const projectId = row.projectID;

            const location = {
                pathname: UrlEnum.PROJECTREVIEW,
                state: [{ projectReviewProjectId: projectId }],
            };

            history.push(location);
        },
    };
    const columns = [
        {
            dataField: 'bienniumDesc',
            text: 'Biennium',
            sort: true,
            headerStyle: getWidthStyle(140),
        },
        {
            dataField: 'financeUnitName',
            text: 'Institution',
            sort: true,
            headerStyle: getWidthStyle(300),
        },
        {
            dataField: 'projectName',
            text: 'Project Name',
            sort: true,
            headerStyle: { minWidth: 250 },
        },
        {
            dataField: 'budgetAgencyProjectNumber',
            text: 'SBA Project Number',
            sort: true,
            headerStyle: getWidthStyle(140),
        },
        {
            dataField: 'institutionalPriority',
            text: 'Pri.',
            sort: true,
            headerStyle: getWidthStyle(50),
        },
        {
            dataField: 'isSubmitted',
            text: 'Sub?',
            sort: true,
            headerStyle: getWidthStyle(80),
        },
        {
            dataField: 'lastUpdatedDate',
            text: 'Last Updated',
            sort: true,
            headerStyle: getWidthStyle(140),
        },
        {
            dataField: 'lastUpdateByUser',
            text: 'Updated By',
            sort: true,
            headerStyle: getWidthStyle(200),
        },
        // {
        // 	dataField: 'action',
        // 	text: <FontAwesomeIcon icon={faSearchPlus} />,
        // 	isDummyField: true,
        // },
    ];
    const options = {
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        sizePerPage: 10,
        hideSizePerPage: true,
    };
    return (
        <Card>
            <div className="p-3 d-md-flex justify-content-between align-items-center text-center text-md-left">
                <div className="mb-3 mb-md-0">
                    <h2>{capitalProjects?.length} Capital Project Reviews</h2>
                    <a href="/#">
                        <FontAwesomeIcon icon={faFilePdf} /> Capital Projects User Guide
                    </a>
                </div>
                {/* <div className="dropdown">
                    <button
                        className="btn btn-sm btn-outline-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i className="far fa-file-export"></i> Export As...
                    </button>
                    <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <a className="dropdown-item" href="/#">
                            PDF
                        </a>
                        <a className="dropdown-item" href="/#">
                            Excel
                        </a>
                        <a className="dropdown-item" href="/#">
                            Word
                        </a>
                    </div>
                </div> */}
            </div>
            <div className="table-responsive">
                <BootstrapTable
                    keyField="projectID"
                    data={capitalProjects.map(capitalProject => {
                        capitalProject.isSubmitted = capitalProject.isSubmitted
                            ? 'yes'
                            : 'no';
                        const newLastUpdateDate = new Date(
                            capitalProject.lastUpdatedDate
                        );
                        capitalProject.lastUpdatedDate = newLastUpdateDate.toLocaleDateString();
                        return capitalProject;
                    })}
                    columns={columns}
                    striped
                    hover
                    pagination={paginationFactory(options)}
                    rowEvents={rowEvents}
                    rowStyle={{ cursor: 'pointer' }}
                    // defaultSorted={defaultSorted}
                />
            </div>
        </Card>
    );
};

SearchCapitalProjectsReviewTable.propTypes = {
    capitalProjects: PropTypes.array.isRequired,
};
