import React, { useState, useEffect } from 'react';
import { Card, Button, UncontrolledPopover, PopoverBody, Input } from 'reactstrap';
import { academicInstitutionsService } from 'services';
import { useHistory } from 'react-router-dom';
import { faEdit, faSearchPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UrlEnum, usePermissions } from 'shared';
import { Confirm } from 'components/blocks';
import { AcademicAffairAddInstitutionModal } from './academic-affair-add-institution-modal';


const rowStyles = {
    container: provided => ({
        ...provided,
        width: 120,
    }),
};

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });


export const AcademicAffairSystem = ({
    academicSystemsData,
    setAcademicSystemsData,
}) => {
    const [institutionsToAdd, setInstitutionsToAdd] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [institutionsToRemove, setInstitutionsToRemove] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const history = useHistory();
    const getOptionValue = d => d.value;
    const [sectorSelectOptions, setSectorSelectOptions] = useState([]);
    const [reportingGroupOptions, setReportingGroupOptions] = useState([]);
    const [institutionResearchClassificationCode, setInstitutionResearchClassificationCode] = useState([]);

    
    const fetchData = async () => {
        try {
            const sectors = await academicInstitutionsService.getInstitutionSectorOptions();
            setSectorSelectOptions(sectors);

            const reportingGroups = await academicInstitutionsService.getInstitutionReportingGroups();
            setReportingGroupOptions(reportingGroups);

            const researchClassificationCode = await academicInstitutionsService.getInstitutionClassificationReportingOptions();
            setInstitutionResearchClassificationCode(researchClassificationCode);

        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    const tableMaxHeightStyle = { maxHeight: '400px', overflowY: 'scroll' };

    const handleSystemDetailsChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setAcademicSystemsData(prev => ({
            ...prev,
            [name]: newValue,
        }));
    };
 
    const {
        systemName,
        systemID,
        institutions
    } = academicSystemsData


    const addNewInstitution = institution => {
        var newInstitution = {
            InstitutionName: institution.InstitutionName,
            CHEInstitutionUnitId: institution.CHEInstitutionUnitId,
            CHESystemUnitId: institution.CHESystemUnitId,
            SectorId: institution.SectorId,
            DegreeGrantingStatusId: true,
            Active: true,
            CHEInstitutionStateCode: true,
            ReportingGroupCode: institution.ReportingGroupCode,
            InstitutionResearchClassificationCode: institution.InstitutionResearchClassificationCode,
            InstitutionAbbreviation: institution.InstitutionAbbreviation,
        }
        institutionsToAdd.push(newInstitution);
    };


    const ToggleDeletion = unitId => {
        const alreadyRemoved = institutionsToRemove.find(x => x == unitId);

        if(alreadyRemoved != null) { // needs to be readded to the institution
            const newList = institutionsToRemove.filter((x) => x !== unitId);

            setInstitutionsToRemove(newList);
        } else { // needs to be removed from institution
            institutionsToRemove.push(unitId);
            setInstitutionsToRemove(institutionsToRemove);
        }

        setRerender(!rerender);
    }

    const checkForRemoval = id => {
        const check = institutionsToRemove.find(x => x == id);
        return check != null;
    }


    const RemoveFromAddList = id => {
        const newList = institutionsToAdd.filter((x) => x.CheInstitutionUnitId !== id);

        setInstitutionsToAdd(newList);
    }




    const renderApprovedInstitutionRows = () => {
        let rows = [];
        if(institutions == null) {
            return rows;
        }

        institutionsToAdd.map(institution => {
            rows.push(
                <tr key={institution.CheInstitutionUnitId}
                    onClick={() => RemoveFromAddList(institution.CheInstitutionUnitId)}>

                    <td>{institution.InstitutionName}</td>
                    <td className="table-actions">
                        <div className="text-success">Pending Addition</div>
                    </td>
                </tr>
            );
        });

        institutions.map(institution => {
            rows.push(
                <tr key={institution.institutionId}>
                    <div>
                        <td>{institution.institutionName}</td>

                        <td className="table-actions">
                                <div className="text-danger">{checkForRemoval(institution.institutionId) ? "Pending Unit Deactivation" : ""}</div>
                        
                        
                            <Button
                                className="action-circle"
                                onClick={() => {
                                    const institutionId = institution.institutionId;

                                    const location = {
                                        pathname: UrlEnum.ACADEMIC_AFFAIRS_INSTITUTION,
                                        state: [{ institutionId: institutionId }],
                                    };
                                    history.push(location);
                                }}
                                >
                                <FontAwesomeIcon id={institution.institutionId + '-edit'} icon={faEdit} />
                            </Button>
                            {institution.institutionId != systemID && (
                                <Button
                                    className="action-circle"
                                    onClick={() => {
                                        ToggleDeletion(institution.institutionId)
                                    }}
                                    >
                                    <FontAwesomeIcon id={institution.institutionId + '-delete'} icon={faTrash} />
                                </Button>
                            )}     
                        </td>
                    </div>
                </tr>
            );
        });

        return rows;
    }

    const submitForm = async () => {
        setConfirm(false);
        institutionsToAdd.forEach(inst => {
            inst.SectorId=inst.SectorId.value
            inst.ReportingGroupCode=inst.ReportingGroupCode.value
            inst.CHESystemUnitId = systemID
        });
        const submitData = {
            SystemId: '' + systemID,
            SystemName: systemName,
            InstitutionsToAdd: institutionsToAdd,
            InstitutionsToRemove: institutionsToRemove
        };

        console.log(submitData)
        await academicInstitutionsService.editAcademicSystem(submitData, systemID);
        setInstitutionsToAdd([]);
    };
    return (
        <React.Fragment>
            <div className="sticky-footer">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_INSTITUTIONS}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back to search
                        </a>
                    </div>
                    <div className="row sticky-bar-spacer">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <h2 className="text-strong text-primary">
                                    {systemName} || ID: {systemID}
                                </h2>
                                <div className="row gutter-60">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            System Name{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="System Name"
                                            value={systemName}
                                            onChange={handleSystemDetailsChange}
                                            name="systemName"
                                        />
                                        
                                    </div>
                                
                                    <div className="col-md-7">
                                    <div className="d-md-flex align-items-center justify-content-between text-md-left text-center mb-3">
                                            <h3 className="text-primary mb-3 mb-md-0">
                                                Approved Institutions
                                            </h3>
                                            <button
                                                type="button"
                                                onClick={() => setIsModalOpen(true)}
                                                className="btn btn-sm btn-primary"
                                            >
                                                <i className="far fa-plus"></i> Add
                                                Institution
                                            </button>
                                        </div>
                                        <div className="input-group input-group-btn input-group-sm mb-3 d-none">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="search approved institutions..."
                                            />
                                            <div className="input-group-append white hide">
                                                <span className="input-group-text px-3">
                                                    <div className="body-text-link text-primary">
                                                        <i className="fal fa-times"></i>{' '}
                                                        clear
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="button"
                                                >
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <table className="table table-striped">
                                            <thead>
                                                    <tr>
                                                        <th>Institution</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderApprovedInstitutionRows()}
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(
                        <div className="sticky-bottom-bar">
                            <div className="d-flex align-items-center justify-content-end">
                                <a
                                    className="btn btn-outline-primary btn-lg mr-2"
                                    href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}
                                >
                                    Cancel
                                </a>
                                <Button
                                    type="button"
                                    className="btn btn-success btn-lg px-3"
                                    onClick={() => {
                                        setConfirm(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} /> Save Changes
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <AcademicAffairAddInstitutionModal
                isOpen={isModalOpen}
                toggle={toggle}
                addNewInstitution={addNewInstitution}
                reportingGroupCodeOptions={reportingGroupOptions}
                sectorSelectOptions={sectorSelectOptions}
                InstitutionResearchClassificationCode={institutionResearchClassificationCode}
            />

            <Confirm
                isOpen={confirm}
                toggle={() => setConfirm(false)}
                onConfirm={submitForm}
                title="Confirm Changes"
                message="Are you sure you want to submit these changes?"
            />
        </React.Fragment>
    );
};
