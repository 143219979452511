import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLockOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { budgetRunService } from 'services';
import { BudgetRunWizardForm } from 'components';
import { BudgetRunTabEnum, BudgetRunFormEnum, UrlEnum, ConfirmActionModal } from 'shared';

export const BudgetRunPage = props => {
    const [stepNumber, setStepNumber] = useState(1);
    const [budgetRun, setBudgetRun] = useState(null);
    Object.filter = (obj, predicate) =>
        Object.fromEntries(Object.entries(obj).filter(predicate));
    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedBudgetRunId = sessionStorage.getItem('budgetRunId');

        if (props.location.hash) {
            const filtered = Object.filter(
                BudgetRunTabEnum,
                ([name, tab]) => tab === props.location.hash
            );
            if (filtered) {
                const NameOfTab = Object.keys(filtered)[0];
                const newStepNumber = BudgetRunFormEnum[NameOfTab];
                if (newStepNumber !== stepNumber) setStepNumber(newStepNumber);
            }
        }
        if (query) {
            for (let param of query) {
                if (
                    param.budgetRunId &&
                    (!persistedBudgetRunId || param.budgetRunId !== persistedBudgetRunId)
                ) {
                    window.sessionStorage.setItem('budgetRunId', param.budgetRunId);
                    return param.budgetRunId;
                } else {
                    return null;
                }
            }
        }
    };
    parseQueryParams();
    const persistedBudgetRunId = sessionStorage.getItem('budgetRunId');

    return (
        <Container fluid>
            <BudgetRunHeader
                budgetRunId={persistedBudgetRunId}
                budgetRun={budgetRun}
                setBudgetRun={setBudgetRun}
            ></BudgetRunHeader>
            <BudgetRunWizardForm
                budgetRun={budgetRun}
                setBudgetRun={setBudgetRun}
                budgetRunId={persistedBudgetRunId}
                stepNumber={stepNumber}
            />
        </Container>
    );
};

const BudgetRunHeader = ({ budgetRunId, budgetRun, setBudgetRun }) => {
    const history = useHistory();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false);
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <a href={UrlEnum.BUDGETRUNSEARCH}>
                    <FontAwesomeIcon icon={faArrowLeft} /> back to search
                </a>
                <div className="d-flex align-items-center">
                    <div
                        className={`switch ${
                            budgetRun
                                ? budgetRun.budgetRunStatusId !== 4
                                    ? 'd-none'
                                    : ''
                                : ''
                        }`}
                    >
                        <button
                            className="btn btn-xs btn-outline-primary mr-2"
                            onClick={() => setIsUnlockModalOpen(true)}
                        >
                            <FontAwesomeIcon icon={faLockOpen} /> Unlock
                        </button>
                    </div>
                    <button
                        className="btn btn-danger btn-xs"
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                    </button>
                </div>
            </div>
            <ConfirmActionModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                header={`Confirm Delete`}
                message={'Are you sure you want to delete this budget run?'}
                acceptButtonText={'Delete'}
                acceptFunction={() =>
                    budgetRunService.deleteBudgetRun(budgetRunId, history)
                }
                acceptButtonCss="btn btn-danger"
            />
            <ConfirmActionModal
                isOpen={isUnlockModalOpen}
                toggle={() => setIsUnlockModalOpen(!isUnlockModalOpen)}
                header={`Confirm Unlock`}
                message={'Are you sure you want to Unlock this budget run?'}
                acceptButtonText={'Unlock'}
                acceptFunction={async () => {
                    budgetRun.budgetRunStatusId = 3;
                    setBudgetRun(await budgetRunService.saveBudgetRun(budgetRun));
                }}
                acceptButtonCss="btn btn-danger"
            />
        </React.Fragment>
    );
};
