import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const InfoTooltip = ({ id, text, blue, placement = 'top', ...rest }) => {
    return (
        <>
            <FontAwesomeIcon
                className="fas fa-question-circle fa-lg ml-1"
                icon={faQuestionCircle}
                color={blue ? '#2a4d87' : 'inherit'}
                id={id}
            />
            <UncontrolledTooltip placement={placement} target={id} {...rest}>
                <div style={{ maxWidth: 300, whiteSpace: 'normal' }}>{text}</div>
            </UncontrolledTooltip>
        </>
    );
};

export default InfoTooltip;
