export const CapitalProjectErrorEnum = {
    GENERALINFO: [
        'Project Name',
        'Institutional Priority',
        'Institutional Priority',
        'Budget Agency Project No.',
        'Project Type',
        'Previously Approved by General Assembly',
        'Previously Recommended by CHE',
        'Long Term Capital Plan',
    ],
    COSTSIZEFUNDING: [
        'Project size (GSF)',
        'Project size (ASF)',
        'Net Change in Overall Campus Space (GSF)',
        'Net Change in Overall Campus Space (ASF)',
        'Total Project Cost',
        'Funding source',
        'Funding Amount',
        'Funding Type',
        'Funding Description',
        'Funding',
        'Estimated annual change in cost',
        'Estimated annual repair and rehabilitation investment',
        'Project Total Cost',
        'Funding',
        '',
        '',
        '',
        'Justification Of Cost',
    ],
    DETAILDESCRIPTION: ['Detail Description'],
    NEEDSANDPURPOSE: ['Need and Purpose'],
    OTHERPROJECTINFO: [],
    SPACEDETAIL: [
        'Current Space In Use',
        'Current Space Under Construction',
        'Space Planned and Funded',
        'Space to be Terminated',
        'New Space in Capital Request',
    ],
    COSTDETAIL: [
        'Construction Bid Date',
        'Construction Start Date',
        'Construction End Date',
        'Cost Basis',
        'Escalation Factors',
        'Other Cost',
        'Cost Detail Total',
        '',
    ],
    ANNUALCOSTDETAIL: [
        'Personnel Services',
        'Supplies and Expenses',
        'Annual cost detail total',
    ],
    SUBMIT: [],
};
