import React, { useState, useEffect } from 'react';
import { AcademicAffairInstitution } from 'components';
import { academicInstitutionsService } from 'services';

export const AcademicInstitutionPage = props => {
    const [academicInstitutionData, setacademicInstitutionData] = useState([]);
    const [sectorSelectOptions, setSectorSelectOptions] = useState([]);
    const [reportingGroupOptions, setReportingGroupOptions] = useState([]);
    const [campuses, setCampuses] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedInstitutionID = sessionStorage.getItem('institutionID');

        if (query) {
            for (let param of query) {
                if (
                    param.UnitId &&
                    (!persistedInstitutionID || param.UnitId !== persistedInstitutionID)
                ) {
                    window.sessionStorage.setItem('institutionID', param.UnitId);
                    return param.UnitId;
                } else {
                    return null;
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            parseQueryParams();
            const persistedInstitutionID = sessionStorage.getItem('institutionID');

            const data = await academicInstitutionsService.getAcademicInstitution(
                persistedInstitutionID
            );
            setacademicInstitutionData(data || []);
            // default them all with an active status
            const sectors = await academicInstitutionsService.getInstitutionSectorOptions();
            setSectorSelectOptions(sectors);

            const reportingGroups = await academicInstitutionsService.getInstitutionReportingGroups();
            setReportingGroupOptions(reportingGroups);

            const campuses = await academicInstitutionsService.getInstitutionCampuses(persistedInstitutionID);
            setCampuses(campuses);
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <AcademicAffairInstitution
            //locations={locations}
            //setLocations={setLocations}
            academicInstitutionData={academicInstitutionData}
            setAcademicInstitutionData={setacademicInstitutionData}
            sectorSelectOptions={sectorSelectOptions}
            reportingGroupCodeOptions={reportingGroupOptions}
            fetchData={fetchData}
            campuses={campuses}
            setCampuses={setCampuses}
        />
    );
};
