import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { generateReportService } from 'services';
import { Button, FormInput } from '../../blocks';

export const SaveSearchModal = props => {
    const { open, close, getQueryString } = props;
    const user = useSelector(state => state.user);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const onSubmit = async () => {
        setLoading(true);
        try {
            const query = getQueryString();
            const payload = {
                userId: user?.id,
                name,
                searchString: query,
            };
            await generateReportService.saveSearch(payload);
            setName('');
            close();
        } catch (error) {}
        setLoading(false);
    };
    return (
        <Modal isOpen={open} className="modal-dialog">
            <ModalHeader toggle={close}>Fill with Saved Search</ModalHeader>
            <ModalBody>
                <FormInput
                    label="Save Form As"
                    onChange={e => setName(e.name)}
                    placeholder="Saved search name here"
                    value={name}
                    name="name"
                />
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" onClick={close}>
                    Cancel
                </Button>
                <Button loading={loading} onClick={onSubmit} disabled={!name}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};
