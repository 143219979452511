import React, { useState, useEffect } from 'react';
import { AcademicAffairSystemView } from 'components';
import { academicInstitutionsService } from 'services';

export const AcademicSystemPageView = props => {
    const [academicSystemsData, setAcademicSystemsData] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedSystemID = sessionStorage.getItem('systemID');

        if (query) {
            for (let param of query) {
                if (
                    param.systemID &&
                    (!persistedSystemID || param.systemID !== persistedSystemID)
                ) {
                    window.sessionStorage.setItem('systemID', param.systemID);
                    return param.systemID;
                } else {
                    return null;
                }
            }
        }
    };
    parseQueryParams();
    const persistedSystemID = sessionStorage.getItem('systemID');

    useEffect(() => {
        const res = academicInstitutionsService.getSystemInformation(persistedSystemID);
        Promise.resolve(res).then(data => {
            setAcademicSystemsData(data ?? []);
        });
    }, [persistedSystemID]);

    return (
        <AcademicAffairSystemView
            systemID={persistedSystemID}
            academicSystemsData={academicSystemsData}
            setAcademicSystemsData={setAcademicSystemsData}
        />
    );
};
