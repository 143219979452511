import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';

export const ConfirmActionModal = ({
    isOpen,
    toggle,
    header,
    message,
    rejectButtonText,
    acceptButtonText,
    cancelFunction,
    acceptFunction,
    acceptButtonCss,
}) => {
    const cancelClicked = () => {
        if (cancelFunction) {
            cancelFunction();
        }
        toggle();
    };

    const acceptClicked = () => {
        if (acceptFunction) {
            acceptFunction();
        }
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{header}</ModalHeader>
            <ModalBody>{message}</ModalBody>
            <ModalFooter>
                <Button className="btn-outline-primary" onClick={() => cancelClicked()}>
                    {rejectButtonText ?? 'Cancel'}
                </Button>
                <Button
                    color={acceptButtonCss ?? 'primary'}
                    onClick={() => acceptClicked()}
                >
                    {acceptButtonText ?? 'Accept'}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

ConfirmActionModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    rejectButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    cancelFunction: PropTypes.func,
    acceptFunction: PropTypes.func,
    acceptButtonCss: PropTypes.string,
};
