import React, { useState, useEffect, useReducer } from 'react';
import { Table, Button, Input } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import { CapitalProjectsBulkUpdateModal } from './capital-projects-bulk-update-modal';

export const CapitalProjects = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    const [capitalProjectBySchoolMap, setCapitalProjectBySchoolMap] = useState(new Map());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 1,
            capitalProjects: budgetRun.capitalProjects ? budgetRun.capitalProjects : [],
            newCapProj_InterestRate: budgetRun.newCapProj_InterestRate * 100,
            newCapProj_NumberOfPaymentPeriods:
                budgetRun.newCapProj_NumberOfPaymentPeriods,
        }
    );
    // const currentBiennium = budgetRun.bienniumId;
    const bienniumYearOne = budgetRun.budgetYearOne;
    const bienniumYearTwo = budgetRun.budgetYearTwo;
    // const bienniumRange = budgetRun.bienniumDesc;

    const sanitizeBudgetRun = () => {
        budgetRun.capitalProjects = userInput.capitalProjects;
        budgetRun.newCapProj_NumberOfPaymentPeriods =
            userInput.newCapProj_NumberOfPaymentPeriods;
        if (!isNaN(userInput.newCapProj_InterestRate))
            budgetRun.newCapProj_InterestRate = userInput.newCapProj_InterestRate / 100;
        return budgetRun;
    };

    const handleTableChange = (evt, capitalProjectID, field) => {
        const headerValue = userInput.capitalProjects.find(
            me => capitalProjectID === me.capitalProjectID
        );
        const noCommas = evt.target.value.replaceAll(',', '');
        if (field !== 'startDebtServiceBY1') headerValue[field] = +noCommas ?? 0;
        else {
            headerValue[field] = !!evt.target.checked;
        }

        setUserInput({ capitalProjects: [...userInput.capitalProjects] });
        recalculate();
    };

    const numberValueChange = (evt, field) => {
        userInput[field] = evt.target.value ?? 0;
        setUserInput(userInput);
    };

    const recalculate = () => {};

    const undoCheckboxStyle = {
        marginLeft: '0rem',
        position: 'relative',
        marginTop: '0rem',
    };

    useEffect(() => {
        const newCapitalProjectBySchoolMap = new Map();
        userInput.capitalProjects.forEach(capitalProject => {
            if (newCapitalProjectBySchoolMap.has(capitalProject.displayName)) {
                const newValue = newCapitalProjectBySchoolMap.get(
                    capitalProject.displayName
                );
                newValue.push(capitalProject);
                newCapitalProjectBySchoolMap.set(capitalProject.displayName, newValue);
            } else {
                newCapitalProjectBySchoolMap.set(capitalProject.displayName, [
                    capitalProject,
                ]);
            }
        });
        setCapitalProjectBySchoolMap(newCapitalProjectBySchoolMap);
    }, [budgetRun, userInput.capitalProjects]);

    const CapitalProjectTable = () => {
        return capitalProjectBySchoolMap ? (
            [...capitalProjectBySchoolMap.keys()].map(schoolNameKey => {
                const arrayOfCapitalProjects = capitalProjectBySchoolMap.get(
                    schoolNameKey
                );
                return (
                    <React.Fragment key={schoolNameKey}>
                        <tr key={schoolNameKey}>
                            <td colSpan="10" className="text-strong cell-light">
                                <h4 className="my-2">{schoolNameKey}</h4>
                            </td>
                        </tr>
                        {arrayOfCapitalProjects.map(capitalProject => {
                            return (
                                <tr key={capitalProject.capitalProjectID}>
                                    <td>
                                        <a href="project-review.html" target="_blank">
                                            <i className="far fa-external-link"></i>{' '}
                                            {capitalProject.capitalProjectName}
                                        </a>
                                    </td>
                                    <td className="text-right nowrap">
                                        {capitalProject.sbaNumber}
                                    </td>
                                    <td className="text-right">
                                        {capitalProject.priorityNumber}
                                    </td>
                                    <td className="text-right">
                                        {capitalProject.preApprovedByGenAssembly
                                            ? 'True'
                                            : 'False'}
                                    </td>
                                    <td className="text-right">
                                        {capitalProject.displayName}
                                    </td>
                                    <td className="text-right">
                                        $
                                        {capitalProject.requestedStateFunding == null
                                            ? 0
                                            : capitalProject.requestedStateFunding.toLocaleString()}
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    $
                                                </span>
                                            </div>
                                            {/* value={capitalProject.recommendedFunding} */}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    capitalProject.recommendedFunding ==
                                                    null
                                                        ? 0
                                                        : capitalProject.recommendedFunding.toLocaleString()
                                                }
                                                onChange={evt => {
                                                    handleTableChange(
                                                        evt,
                                                        capitalProject.capitalProjectID,
                                                        'recommendedFunding'
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    $
                                                </span>
                                            </div>
                                            {/* value={capitalProject.bY1Cash} */}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    capitalProject.bY1Cash == null
                                                        ? 0
                                                        : capitalProject.bY1Cash.toLocaleString()
                                                }
                                                onChange={evt => {
                                                    handleTableChange(
                                                        evt,
                                                        capitalProject.capitalProjectID,
                                                        'bY1Cash'
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    $
                                                </span>
                                            </div>
                                            {/* value={capitalProject.bY2Cash} */}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    capitalProject.bY2Cash == null
                                                        ? 0
                                                        : capitalProject.bY2Cash.toLocaleString()
                                                }
                                                onChange={evt => {
                                                    handleTableChange(
                                                        evt,
                                                        capitalProject.capitalProjectID,
                                                        'bY2Cash'
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    {/* value={capitalProject.startDebtServiceBY1} */}
                                    <td>
                                        <Input
                                            type="checkbox"
                                            className="form-control"
                                            style={undoCheckboxStyle}
                                            value={capitalProject.startDebtServiceBY1}
                                            checked={capitalProject.startDebtServiceBY1}
                                            onChange={evt => {
                                                handleTableChange(
                                                    evt,
                                                    capitalProject.capitalProjectID,
                                                    'startDebtServiceBY1'
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                        {SchoolCapitalProjectTotal({ schoolNameKey })}
                        {/* <SchoolCapitalProjectTotal schoolNameKey={schoolNameKey} /> */}
                    </React.Fragment>
                );
            })
        ) : (
            <tr key={'null'}></tr>
        );
    };

    const SchoolCapitalProjectTotal = ({ schoolNameKey }) => {
        const arrayOfCapitalProjects = capitalProjectBySchoolMap.get(schoolNameKey);
        const totalArray = arrayOfCapitalProjects.reduce(
            (acc, curr) => {
                acc[0] += curr.requestedStateFunding;
                acc[1] += curr.recommendedFunding;
                acc[2] += curr.bY1Cash;
                acc[3] += curr.bY2Cash;
                return acc;
            },
            [0, 0, 0, 0]
        );
        return (
            <tr key={'total' + schoolNameKey}>
                <td>
                    <label className="m-0">{schoolNameKey} Total</label>
                </td>
                <td className="text-right nowrap"></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
                <td className="text-right">
                    <label className="m-0">${totalArray[0].toLocaleString()}</label>
                </td>
                <td className="text-right">
                    <label className="m-0">${totalArray[1].toLocaleString()}</label>
                </td>
                <td className="text-right">
                    <label className="m-0">${totalArray[2].toLocaleString()}</label>
                </td>
                <td className="text-right">
                    <label className="m-0">${totalArray[3].toLocaleString()}</label>
                </td>
                <td></td>
            </tr>
        );
    };

    const LoanInformation = () => {
        return (
            <div className="card no-shadow border p-3">
                <h4 className="mb-3 text-primary">
                    Loan Information for New Projects Funded through Debt Service
                </h4>
                <div className="row align-items-end">
                    <div className="col-md-auto mb-3 mb-md-0">
                        <label>Interest Rate</label>
                        <div className="input-group">
                            <Input
                                type="text"
                                value={userInput.newCapProj_InterestRate}
                                onChange={e => {
                                    numberValueChange(e, 'newCapProj_InterestRate');
                                }}
                                className="form-control"
                            />
                            <div className="input-group-prepend">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <label>Number of Payments</label>
                        <Input
                            value={userInput.newCapProj_NumberOfPaymentPeriods}
                            onChange={e => {
                                numberValueChange(e, 'newCapProj_NumberOfPaymentPeriods');
                            }}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Capital Project Requests
            </h2>
            <Button className="btn btn-outline-primary" onClick={toggle}>
                Bulk Update
            </Button>
            <div className="table-responsive">
                <Table className="table table-striped table-bordered table-hover align-middle table-sm my-4">
                    <thead>
                        <tr key={'head'}>
                            <th></th>
                            <th className="text-center">SBA #</th>
                            <th className="text-center">Priority</th>
                            <th className="text-center">Prev Apprvd by GA</th>
                            <th className="text-center">Campus</th>
                            <th className="text-center">Requested State Funds</th>
                            <th className="text-center">Recommended Funding</th>
                            <th className="text-center">{bienniumYearOne} Cash</th>
                            <th className="text-center">{bienniumYearTwo} Cash</th>
                            <th className="text-center">Yr One DS</th>
                        </tr>
                    </thead>
                    <tbody>{CapitalProjectTable()}</tbody>
                </Table>
                {LoanInformation()}
            </div>
            <CapitalProjectsBulkUpdateModal
                isOpen={isModalOpen}
                toggle={toggle}
                capitalProjects={budgetRun.capitalProjects}
                setCapitalProjects={setUserInput}
            />
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                isGeneralInfo={false}
                sanitizeBudgetRun={sanitizeBudgetRun}
                setBudgetRun={setBudgetRun}
            />
        </React.Fragment>
    );
};
