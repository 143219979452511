import React, { useEffect, useState, useCallback, useReducer } from 'react';
import { Card, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormInput } from '../blocks';
import { useToastState } from 'shared';
import { userManagementService } from 'services';

const reducer = (state, newState) => ({ ...state, ...newState });

export const Profile = () => {
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { setToast } = useToastState();
    const [data, setData] = useState({ ...user });
    const [form, setForm] = useReducer(reducer, {
        firstName: user?.firstName,
        lastName: user?.lastName,
        emailAddress: user?.emailAddress,
    });
    const { firstName, lastName, emailAddress } = form;
    const fetchUser = async () => {
        try {
            const userRes = await userManagementService.getUser(user.id);
            setData(userRes);
        } catch (error) {}
        setLoading(false);
    };
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            await userManagementService.updateUser(user.id, {
                ...data,
                ...form,
                roles: data?.roleNames?.map(d => d.roleID),
                institutions: data?.institutionNames?.map(d => d.institutionID),
            });
            dispatch({
                type: 'SET_USER',
                user: { ...user, ...form },
            });
            fetchUser();
            setToast({
                title: 'Successfully Updated Your Profile!',
                message: 'Your profile settings have been updated.',
            });
        } catch (error) {
            console.error(error);
        }
    }, [form, data, user]);
    useEffect(() => {
        fetchUser();
    }, []);
    return (
        <Container fluid>
            <Card className="p-3">
                <div className="mb-4 d-sm-flex text-center text-sm-left align-items-center justify-content-between">
                    <h3 className="mb-3 mb-sm-0">My Profile</h3>
                    <Button
                        color="success"
                        className="btn-shadow px-4"
                        onClick={onSubmit}
                        disabled={loading}
                    >
                        Save Changes
                    </Button>
                </div>
                <div className="form-row">
                    <FormInput
                        label="First Name"
                        placeholder="First Name"
                        name="firstName"
                        value={firstName}
                        className="col-md mb-3 mb-md-0"
                        inputClassName="white"
                        onChange={setForm}
                    />
                    <FormInput
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        value={lastName}
                        className="col-md mb-3 mb-md-0"
                        inputClassName="white"
                        onChange={setForm}
                    />
                </div>
                <div className="form-row">
                    <FormInput
                        label="Email Address"
                        placeholder="Email Address"
                        name="emailAddress"
                        value={emailAddress}
                        className="col-md-12"
                        inputClassName="white"
                        onChange={setForm}
                        readOnly
                        disabled
                    />
                </div>
            </Card>
        </Container>
    );
};
