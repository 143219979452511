import React, { useState, useEffect } from 'react';

import {
    Row,
    Col,
    FormGroup,
    Input,
    InputGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const isObjectEmpty = obj => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const EditPrepopulatedValueModal = ({
    isOpen,
    toggle,
    modalObject,
    setLineItemValues,
    setIsDataAudit,
}) => {
    const [newValue, setNewValue] = useState(0);
    const [reasonForChange, setReasonForChange] = useState('');
    const [prepopulatedValue, setPrepopulatedValue] = useState(0);
    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    useEffect(() => {
        setReasonForChange(
            !isObjectEmpty(modalObject)
                ? modalObject.value[modalObject.dataAuditName] ?? ''
                : ''
        );
        setPrepopulatedValue(
            !isObjectEmpty(modalObject)
                ? modalObject.value[modalObject.prepopName] ?? 0
                : 0
        );
        const oldValue = !isObjectEmpty(modalObject)
            ? modalObject.value[modalObject.valueName] ?? 0
            : 0;
        setNewValue(oldValue);
    }, [modalObject]);

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader close={closeBtn}>Edit Prepopulated Value</ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="col-md-auto mb-3 mb-md-0">
                        <label>Data Item:</label>
                        <p>{modalObject.dataItem}</p>
                    </Col>
                    <Col className="col-md-auto mb-3 mb-md-0">
                        <label>Prepopulated Value:</label>
                        <p>${prepopulatedValue}</p>
                    </Col>
                </Row>
                <FormGroup>
                    <label>New Value</label>
                    <InputGroup>
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={faDollarSign} />
                            </span>
                        </div>
                        <Input
                            type="text"
                            value={newValue}
                            onChange={e => {
                                setNewValue(+e.target.value);
                            }}
                        />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <label>Reason for change</label>
                    <Input
                        type="textarea"
                        rows="3"
                        placeholder="enter reason..."
                        onChange={evt => {
                            setReasonForChange(evt.target.value);
                        }}
                        value={reasonForChange}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        const newLineItemMap = modalObject.newLineItemMap;
                        const key = modalObject.key;
                        const valueName = modalObject.valueName;
                        const dataAuditName = modalObject.dataAuditName;
                        newLineItemMap.get(key)[valueName] = newValue;
                        newLineItemMap.get(key)[dataAuditName] = reasonForChange;
                        setLineItemValues(newLineItemMap);
                        setIsDataAudit(true);
                        toggle();
                    }}
                >
                    Save
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
