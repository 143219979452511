import React, { useReducer, useEffect, useCallback, useMemo } from 'react';

import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { academicProgramsService } from 'services';
import { FormSelect } from './components/FormSelect';
import { FormInput } from 'components/blocks';

export const AcademicAffairAddCredentialModal = ({
    isOpen,
    toggle,
    addCredential,
    programId,
    institutionUnitID,
}) => {
    const reducer = (state, newState) => ({ ...state, ...newState });

    const [credential, setCredential] = useReducer(reducer, {
        
        programId: programId,
        credentialId: "",
        programURL: "",
        keywords: "",
        ctid: "",
        programDescription: "",
        institutionUnitId: institutionUnitID,
        credentialType: {},
    });

    const credentialTypeOptions = [
        { label: "Apprenticeship Certificate", value: "ceterms:ApprenticeshipCertificate" },
        { label: "Associate Degree", value: "ceterms:AssociateDegree" },
        { label: "Associate of Applied Arts Degree", value: "ceterms:AssociateOfAppliedArtsDegree" },
        { label: "Associate of Applied Science Degree", value: "ceterms:AssociateOfAppliedScienceDegree" },
        { label: "Associate of Arts Degree", value: "ceterms:AssociateOfArtsDegree" },
        { label: "Associate of Science Degree", value: "ceterms:AssociateOfScienceDegree" },
        { label: "Bachelor Degree", value: "ceterms:BachelorDegree" },
        { label: "Bachelor of Arts Degree", value: "ceterms:BachelorOfArtsDegree" },
        { label: "Bachelor of Science Degree", value: "ceterms:BachelorOfScienceDegree" },
        { label: "Badge", value: "ceterms:Badge" },
        { label: "Certificate", value: "ceterms:Certificate" },
        { label: "Certificate of Completion", value: "ceterms:CertificateOfCompletion" },
        { label: "Certification", value: "ceterms:Certification" },
        { label: "Credential", value: "ceterms:Credential" },
        { label: "Degree", value: "ceterms:Degree" },
        { label: "Digital Badge", value: "ceterms:DigitalBadge" },
        { label: "Diploma", value: "ceterms:Diploma" },
        { label: "Doctoral Degree", value: "ceterms:DoctoralDegree" },
        { label: "General Education Development", value: "ceterms:GeneralEducationDevelopment" },
        { label: "Journeyman Certificate", value: "ceterms:JourneymanCertificate" },
        { label: "License", value: "ceterms:License" },
        { label: "Master Certificate", value: "ceterms:MasterCertificate" },
        { label: "Master Degree", value: "ceterms:MasterDegree" },
        { label: "Master of Arts Degree", value: "ceterms:MasterOfArtsDegree" },
        { label: "Master of Science Degree", value: "ceterms:MasterOfScienceDegree" },
        { label: "Micro-Credential", value: "ceterms:MicroCredential" },
        { label: "Open Badge", value: "ceterms:OpenBadge" },
        { label: "Professional Doctorate", value: "ceterms:ProfessionalDoctorate" },
        { label: "Quality Assurance Credential", value: "ceterms:QualityAssuranceCredential" },
        { label: "Research Doctorate", value: "ceterms:ResearchDoctorate" },
        { label: "Secondary School Diploma", value: "ceterms:SecondarySchoolDiploma" },
        { label: "Specialist Degree", value: "ceterms:SpecialistDegree" }
    ]
    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    const CreateCredential = async () => {
        credential.credentialType = credential.credentialType.value;
        console.log(credential)
        credential.institutionUnitId = institutionUnitID;
        const cred = await academicProgramsService.createCredential(credential);
        credential.credentialId = cred.credentialId;
        addCredential(credential);
        toggle();
    }

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>Add Credential</ModalHeader>
            <ModalBody>
                <Form>
                    <FormInput
                        label="Url"
                        onChange={setCredential}
                        name="programURL"
                        value={credential.programURL}
                        className="col-md-8"
                    />
                    <FormInput
                        label="Keywords"
                        onChange={setCredential}
                        name="keywords"
                        value={credential.keywords}
                        className="col-md-8"
                    />
                    <FormInput
                        label="Description"
                        onChange={setCredential}
                        name="programDescription"
                        value={credential.programDescription}
                        className="col-md-8"
                    />
                    <FormSelect
                        label="Credential Type"
                        onChange={setCredential}
                        options={credentialTypeOptions ? credentialTypeOptions.map(x => {return {label: x.label, value: x.value}}): []}
                        name="credentialType"
                        value={credential.credentialType}
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => CreateCredential()}>
                    Create New Credential
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
