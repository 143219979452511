import { handleServiceRequest } from '../handle-service-request';
import { UrlEnum } from 'shared';
import {
    convertApiResponseToOppositeSearchOptions,
    convertApiResponseToSearchOptions,
} from '../shared-service-functions';

const baseUrl = `/api/AcademicInstitutions`;

const searchAcademicSystems = params => {
    const getObject = {
        url: `${baseUrl}/systems/search`,
        params: params,
    };
    return handleServiceRequest.getWithParams(getObject);
};

const searchAcademicUnits = params => {
    const getObject = {
        url: `${baseUrl}/units/search`,
        params: params,
    };
    return handleServiceRequest.getWithParams(getObject);
};

export const editAcademicSystem = (system, id) => {
    const postObject = {
        url: `${baseUrl}/system/${id}`,
        postBody: system
    }

    return handleServiceRequest.post(postObject)
}

export const createAcademicSystem = system => {
    const postObject = {
        url: `${baseUrl}/systems/create`,
        postBody: system
    }

    return handleServiceRequest.post(postObject)
};

export const validateCheUnitId = cheUnitId => {
    const postObject = {
        url: `${baseUrl}/systems/validateCheUnitId`,
        postBody: {
            'cheUnitId': cheUnitId
        }
    }

    return handleServiceRequest.post(postObject)
}

export const validateCheIpedsId = cheId => {
    const postObject = {
        url: `${baseUrl}/institutions/validateCheIpedsId`,
        postBody: {
            'cheUnitId': cheId
        }
    }

    return handleServiceRequest.post(postObject)
}

export const validateCheOpeId = cheId => {
    const postObject = {
        url: `${baseUrl}/institutions/validateCheOpeId`,
        postBody: {
            'cheUnitId': cheId
        }
    }

    return handleServiceRequest.post(postObject)
}

export const getCreateAcademicSystemOptions = _ => {
    const getObject = {
        url: `${baseUrl}/systems/create/options`
    }

    return handleServiceRequest.get(getObject)
};

const getAcademicInstitution = id => {
    const getObject = {
        url: `${baseUrl}/institutions/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const getSystemInformation = id => {
    const getObject = {
        url: `${baseUrl}/systems/${id}`
    }
    return handleServiceRequest.get(getObject);
}

const getInstitutionSectorOptions = () => {
    const getObject = {
        url: `${baseUrl}/institutions/sectors`
    }
    return handleServiceRequest.get(getObject);
}

const getInstitutionReportingGroups = () => {
    const getObject = {
        url: `${baseUrl}/institutions/reportingGroups`
    }
    return handleServiceRequest.get(getObject);
}

const getInstitutionClassificationReportingOptions = () => {
    const getObject = {
        url: `${baseUrl}/institutions/ResearchClassificationsOptions`
    }
    return handleServiceRequest.get(getObject);
}

const getInstitutionCampuses = id => {
    const getObject = {
        url: `${baseUrl}/institutions/campus/${id}`
    }
    return handleServiceRequest.get(getObject);
}

const editAcademicInstitution = (institution, id) => {

    const postObject = {
        url: `${baseUrl}/institution/${id}`,
        postBody: institution
    }

    return handleServiceRequest.post(postObject)
}

const getAcademicCampus = id => {
    
    const getObject = {
        url: `${baseUrl}/campus/${id}`
    }
    return handleServiceRequest.get(getObject);
}

const editAcademicCampus = (data, id) => {
    const postObject = {
        url: `${baseUrl}/campus/${id}`,
        postBody: data
    }

    return handleServiceRequest.post(postObject)
}

const getCampusLocations = campusId => {
    const getObject = {
        url: `${baseUrl}/campus/locations/${campusId}`
    }
    return handleServiceRequest.get(getObject);
}



const getLocation = id => {
    const getObject = {
        url: `${baseUrl}/campus/location/${id}`
    }
    return handleServiceRequest.get(getObject);
}

const editAcademicLocation = (data, id) => {
    const postObject = {
        url: `${baseUrl}/campus/location/${id}`,
        postBody: data
    }

    return handleServiceRequest.post(postObject)
}



/* Sample Response 
[
    {
        "programID: 3,
        "institutionName: "Purdue University-West Lafayette",
        "degreeLevel: null,
        "cipCode: null,
        "cipTitle: "None",
        "programName: "Associate in Agriculture in General Agriculture",
        "status: "Eliminated",
        "approvalDate: "1971-08-04T00:00:00",
        "hasPendingActionRequests: false,
        "monthYearIdentifier: null,
        "monthYearText: "None Set"
    }, 
    ...
]*/

const getOptions = () => {
    const getObject = {
        url: `${baseUrl}/options`,
    };
    return handleServiceRequest.get(getObject);
};

export const academicInstitutionsService = {
    searchAcademicSystems,
    searchAcademicUnits,
    getOptions,
    getSystemInformation,
    getAcademicInstitution,
    getInstitutionSectorOptions,
    getInstitutionCampuses,
    editAcademicInstitution,
    validateCheOpeId,
    validateCheIpedsId,
    getInstitutionReportingGroups,
    editAcademicSystem,
    getAcademicCampus,
    getCampusLocations,
    getLocation,
    editAcademicCampus,
    editAcademicLocation,
    getInstitutionClassificationReportingOptions,
};
