import { handleServiceRequest } from '../handle-service-request';

const getSections = () => {
    const obj = {
        url: '/api/Help/Section',
    };
    return handleServiceRequest.get(obj);
};
const addSection = body => {
    const obj = {
        url: '/api/Help/Section',
        postBody: body,
    };
    return handleServiceRequest.post(obj);
};
const updateSection = body => {
    const obj = {
        url: '/api/Help/Section',
        postBody: body,
    };
    return handleServiceRequest.put(obj);
};
const deleteSection = id => {
    const obj = {
        url: `/api/Help/Section/Delete/${id}`,
    };
    return handleServiceRequest.get(obj);
};
const getFiles = () => {
    const obj = {
        url: '/api/Help/File',
    };
    return handleServiceRequest.get(obj);
};
const addFile = formData => {
    const obj = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        url: '/api/Help/File',
        postBody: formData,
    };
    return handleServiceRequest.post(obj);
};
const updateFile = body => {
    const obj = {
        url: '/api/Help/File',
        postBody: body,
    };
    return handleServiceRequest.put(obj);
};
const deleteFile = id => {
    const obj = {
        url: `/api/Help/File/Delete/${id}`,
    };
    return handleServiceRequest.get(obj);
};
const getFile = (id, filename, fileExt) => {
    const obj = {
        url: `/api/Help/File/${id}`,
        filename: filename,
        ext: fileExt,
    };
    return handleServiceRequest.download(obj);
};

export const helpService = {
    getSections,
    addSection,
    updateSection,
    deleteSection,
    getFiles,
    addFile,
    updateFile,
    deleteFile,
    getFile,
};
