import _get from 'lodash/get';
import store from 'redux/store';

export const handleError = error => {
    let message = _get(error, 'message');
    let status = _get(error, 'status');
    console.group('Error Handling logs');
    // Error
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        message = _get(error, 'response.data.message') || _get(error, 'response.data');
        if (!message) {
            message = _get(error, 'response.data.title');
        }
        status = _get(error, 'response.status');
        // console.log(error.response.status);
        // console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    // console.log(error.config);
    console.groupEnd();
    store.dispatch({ type: 'SET_ERROR', message, status });
    // throw error;
};
