import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userService } from 'services';
import { useErrorState, useToastState } from 'shared';
import { LoginForm, ResetForm } from 'components/login';

export const LoginPage = props => {
    const { handleError, setError } = useErrorState();
    const locationState = props?.location?.state;
    const { setToast } = useToastState();
    const dispatch = useDispatch();
    const upcomingMaitenance = false;
    const history = useHistory();
    const [reset, setReset] = useState(locationState?.reset || false);
    const [loading, setLoading] = useState(false);
    const login = async payload => {
        setLoading(true);
        try {
            const res = await userService.login(payload.email, payload.password);
            // if the users password has expired then they will be routed to the set password page to set a new password
            dispatch({
                type: 'SET_USER',
                user: { token: res?.token },
            });
            if (res.isExpired) {
                await userService.forgotPassword(payload.email);
                setError({
                    message:
                        'Your password has expired. You must create a new password before you can log back in. Please follow the email we just sent you to set your new password.',
                });
            } else if (res?.twoFactorMethodVerified === true) {
                history.push('/verify', { ...res, ...payload });
            } else if (res?.twoFactorMethodVerified === false) {
                // if the user is not verifed then we need to link them to the phone number input page
                history.push('/phone', { ...res, ...payload });
            }
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };
    const resetPassword = async payload => {
        setLoading(true);
        try {
            const res = await userService.forgotPassword(payload.email);
            setToast({
                title: 'Successly Sent Email!',
                message: res.message,
            });
            setReset(false);
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };
    return (
        <div className="login-wrap">
            <div className="login-card">
                <div className="logo">
                    <img src="/logo-chedss.png" alt="CHEDSS" />
                    {!upcomingMaitenance && <h2 className="text-center my-5">Data Submission System (CHEDSS)</h2>}
                    {upcomingMaitenance && (
                      <div style={{ color: '#FF0000', textAlign: 'center', fontFamily: 'arial', fontSize: '12px' }}>
                          <b>NOTE</b>
                          <div style={{ color: '#000000', textAlign: 'center', fontFamily: 'arial', fontSize: '12px' }}>
                              Maintenance & enhancement work will start 7/11/24 at 11AM - 11PM 7/14/24.
                              <br />
                              Refrain from using the system during this time.
                              <div style={{ color: '#000000', textAlign: 'center', fontFamily: 'arial', fontSize: '12px' }}>
                                  Last updated 7/9/24.
                              </div>
                          </div>
                      </div>
                    )}

                    {reset ? (
                        <ResetForm
                            loading={loading}
                            setReset={setReset}
                            onSubmit={resetPassword}
                        />
                    ) : (
                        <LoginForm
                            loading={loading}
                            setReset={setReset}
                            onSubmit={login}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
