import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

export const TooltipWrapper = ({
    id,
    children,
    text,
    className,
    containerStyle = {},
    ...rest
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <div
            id={id}
            className={className}
            style={{ cursor: 'pointer', ...containerStyle }}
        >
            {children}
            <Tooltip isOpen={tooltipOpen} target={id} toggle={toggle} {...rest}>
                {text}
            </Tooltip>
        </div>
    );
};

export default TooltipWrapper;
