import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

export const useUserInstitutions = () => {
    const user = useSelector(state => state.user);
    return useMemo(() => {
        let inst = user.isImpersonation
            ? _get(user, 'impersonatedUser.institutionNames', [])
            : _get(user, 'institutionNames', []);
        return inst.map(i => i.institutionID);
    }, [user]);
};
