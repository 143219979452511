import React from 'react';

export const OutputItem = props => {
    const { className, label, text, children } = props;
    return (
        <div className={className}>
            <label className="mb-0">{label}</label>
            <p className="text-muted small mb-2">{text}</p>
            {children}
        </div>
    );
};
