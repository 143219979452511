import React, { useState, useEffect, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Label, Input, Row, Col, Card, Table, InputGroup } from 'reactstrap';
import Select from 'react-select';
import { ValidationErrorBox } from './validation-error-box';
import { FormEnum } from 'shared';
import { InfoTooltip } from 'components/blocks';

export const CostDetail = ({ capitalProject, prevStep, nextStep }) => {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(20), (val, index) => {
        return { val: index, label: year - index };
    });
    const months = [
        { label: 'Month', val: 0 },
        { label: 'January', val: 1 },
        { label: 'February', val: 2 },
        { label: 'March', val: 3 },
        { label: 'April', val: 4 },
        { label: 'May', val: 5 },
        { label: 'June', val: 6 },
        { label: 'July', val: 7 },
        { label: 'August', val: 8 },
        { label: 'September', val: 9 },
        { label: 'October', val: 10 },
        { label: 'November', val: 11 },
        { label: 'December', val: 12 },
    ];
    const getSelectValueFromYear = yearLabel => {
        return years.find(yearSelectVal => yearSelectVal.label === yearLabel);
    };
    const getSelectValueFromMonth = monthValue => {
        return months.find(monthSelectVal => monthSelectVal.val === monthValue);
    };
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 7,
            bidYear: capitalProject.bidYear
                ? getSelectValueFromYear(capitalProject.bidYear)
                : null,
            bidMonth: capitalProject.bidMonth
                ? getSelectValueFromMonth(capitalProject.bidMonth)
                : null,
            constructionYear: capitalProject.constructionYear
                ? getSelectValueFromYear(capitalProject.constructionYear)
                : null,
            constructionMonth: capitalProject.constructionMonth
                ? getSelectValueFromMonth(capitalProject.constructionMonth)
                : null,
            occupancyYear: capitalProject.occupancyYear
                ? getSelectValueFromYear(capitalProject.occupancyYear)
                : null,
            occupancyMonth: capitalProject.occupancyMonth
                ? getSelectValueFromMonth(capitalProject.occupancyMonth)
                : null,
            costDetailNotes: capitalProject.costDetailNotes
                ? capitalProject.costDetailNotes
                : '',
            costDetails: capitalProject.costDetails ? capitalProject.costDetails : [],
        }
    );
    const [costDetailTableCategories, setCostDetailTableCategories] = useState([]);
    const [costDetailTableRows, setCostDetailTableRows] = useState([]);

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    const handleSelectChange = (valueObj, options) => {
        const name = options.name;
        capitalProject[name] = valueObj;
        setUserInput({ [name]: valueObj });
    };

    const handleTableChange = (event, index, inputTypeChanged) => {
        const headerValue = userInput.costDetails[index];
        headerValue[inputTypeChanged] = event.target.value ?? 0;
        capitalProject.costDetails = [...userInput.costDetails];
        setUserInput({ costDetails: [...userInput.costDetails] });
    };

    useEffect(() => {
        const transformArray = costDetailRowCategories => {
            return costDetailRowCategories.map(costDetialCategory => {
                const { id } = costDetialCategory;
                const savedCostDetailValue = userInput.costDetails.filter(
                    costDetail => +costDetail.costTypeId === +id
                )[0];
                return savedCostDetailValue
                    ? savedCostDetailValue
                    : {
                          costTypeId: +id,
                          costBasis: '0',
                          escalationFactors: '0',
                          descriptionIfOther: '',
                      };
            });
        };

        const fetchColumns = async () => {
            const costDetailTableRows = await financeService.getCostDetailTableRows();
            const getCostDetailTableUniqueCategories = costDetailTableRows.reduce(
                (accumulator, currentValue) => {
                    const accumulatorHasValue = accumulator.includes(
                        currentValue.category
                    );
                    if (!accumulatorHasValue) {
                        accumulator.push(currentValue.category);
                    }
                    return accumulator;
                },
                [costDetailTableRows[0].category]
            );
            setCostDetailTableCategories(getCostDetailTableUniqueCategories);
            setCostDetailTableRows(costDetailTableRows);
            setUserInput({ costDetails: transformArray(costDetailTableRows) });
            return;
        };
        fetchColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sanitizeCapitalProject = () => {
        userInput.costDetails.forEach(costDetail => {
            costDetail.costBasis = +costDetail.costBasis;
            costDetail.escalationFactors = +costDetail.escalationFactors;
        });
        userInput.bidYear = userInput.bidYear?.label ?? 'Year';
        userInput.bidMonth = userInput.bidMonth?.val ?? 'Month';
        userInput.constructionYear = userInput.constructionYear?.label ?? 'Year';
        userInput.constructionMonth = userInput.constructionMonth?.val ?? 'Month';
        userInput.occupancyYear = userInput.occupancyYear?.label ?? 'Year';
        userInput.occupancyMonth = userInput.occupancyMonth?.val ?? 'Month';

        return userInput;
    };

    const renderIndividualCostRow = categoryObject => {
        const getCostDetailArray = userInput.costDetails.filter(
            costDetail => +costDetail.costTypeId === +categoryObject.id
        );
        return !getCostDetailArray
            ? null
            : getCostDetailArray.map(costObject => {
                  const index = userInput.costDetails.findIndex(
                      costDetail => costDetail === costObject
                  );
                  const costDetailToChange = userInput.costDetails[index];
                  return (
                      <tr key={categoryObject.id}>
                          <td>
                              <label className="m-0">{categoryObject.text}</label>
                          </td>

                          <td>
                              <InputGroup>
                                  <div className="input-group-prepend">
                                      <span className="input-group-text">
                                          <FontAwesomeIcon icon={faDollarSign} />
                                      </span>
                                  </div>
                                  <Input
                                      type="text"
                                      value={costDetailToChange.costBasis.toString()}
                                      onChange={e => {
                                          handleTableChange(e, index, 'costBasis');
                                      }}
                                  />
                              </InputGroup>
                          </td>
                          <td>
                              <InputGroup>
                                  <div className="input-group-prepend">
                                      <span className="input-group-text">
                                          <FontAwesomeIcon icon={faDollarSign} />
                                      </span>
                                  </div>
                                  <Input
                                      type="text"
                                      value={costDetailToChange.escalationFactors.toString()}
                                      onChange={e => {
                                          handleTableChange(
                                              e,
                                              index,
                                              'escalationFactors'
                                          );
                                      }}
                                  />
                              </InputGroup>
                          </td>
                          <td className="text-strong text-right">
                              $
                              {+costDetailToChange.costBasis +
                                  +costDetailToChange.escalationFactors}
                          </td>
                      </tr>
                  );
              });
    };

    const renderCostsTable = () => {
        return costDetailTableCategories === [] ? (
            <div></div>
        ) : (
            costDetailTableCategories.map((category, index) => {
                const filteredTableRow = costDetailTableRows.filter(
                    row => row.category === category
                );
                const rows = filteredTableRow.map(categoryObject => {
                    return renderIndividualCostRow(categoryObject, index);
                });
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td
                                className="text-strong text-primary cell-light"
                                colSpan="4"
                            >
                                {category}
                            </td>
                        </tr>
                        {rows}
                    </React.Fragment>
                );
            })
        );
    };
    return (
        <React.Fragment>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Cost Detail
            </h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.COSTDETAIL}
            />
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Anticipated Construction Schedule</h4>
                <Row>
                    <Col className="col-md mb-3 mb-md-0">
                        <label>
                            Bid Date <sup className="text-danger">*</sup>
                        </label>
                        <div className="d-flex">
                            <Select
                                className="form-control mr-3"
                                onChange={handleSelectChange}
                                options={years}
                                placeholder="Year"
                                name="bidYear"
                                value={userInput.bidYear}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                            <Select
                                className="form-control"
                                onChange={handleSelectChange}
                                options={months}
                                placeholder="Month"
                                name="bidMonth"
                                value={userInput.bidMonth}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </Col>
                    <Col className="col-md mb-3 mb-md-0">
                        <Label tag="h4" id="formTitle">
                            Start Construction <sup className="text-danger">*</sup>
                        </Label>
                        <div className="d-flex">
                            <Select
                                className="form-control mr-3"
                                onChange={handleSelectChange}
                                options={years}
                                placeholder="Year"
                                name="constructionYear"
                                value={userInput.constructionYear}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />

                            <Select
                                className="form-control"
                                onChange={handleSelectChange}
                                options={months}
                                placeholder="Month"
                                name="constructionMonth"
                                value={userInput.constructionMonth}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </Col>
                    <Col className="col-md mb-3 mb-md-0">
                        <Label tag="h4" id="formTitle">
                            Occupancy (End Date) <sup className="text-danger">*</sup>
                        </Label>
                        <div className="d-flex">
                            <Select
                                className="form-control mr-3"
                                onChange={handleSelectChange}
                                options={years}
                                placeholder="Year"
                                name="occupancyYear"
                                value={userInput.occupancyYear}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                            <Select
                                className="form-control"
                                onChange={handleSelectChange}
                                options={months}
                                placeholder="Month"
                                name="occupancyMonth"
                                value={userInput.occupancyMonth}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Estimated Cost for Project</h4>
                <Table
                    key="largeCostDetailTable"
                    className="table table-striped table-bordered table-hover align-middle table-sm"
                    responsive
                    borderless
                >
                    <thead>
                        <tr>
                            <th className="min-tablet-l no-sort"></th>
                            <th className="min-tablet-l no-sort">
                                Cost Basis{' '}
                                <InfoTooltip
                                    id="cost-basis"
                                    text="State the month and year the current Cost Basis was calculated in the Cost Detail Notes."
                                />
                            </th>
                            <th className="min-tablet-l no-sort">
                                Escalation Factors{' '}
                                <InfoTooltip
                                    id="escalation-factors"
                                    text="Explain in the Cost Detail Notes the reasoning for estimated escalation factors."
                                />
                            </th>
                            <th className="min-tablet-l no-sort text-right">
                                Project Cost
                            </th>
                        </tr>
                    </thead>
                    <tbody>{renderCostsTable()}</tbody>
                </Table>
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Cost Detail Notes</h4>
                <p className="text-italic">
                    Please state the month and year the current Cost Basis was calculated.
                    Additionally, explain the reasoning for the estimated escalation
                    factors.
                </p>
                <Input
                    type="textarea"
                    rows="8"
                    placeholder="Cost Detial Notes"
                    onChange={handleChange}
                    name="costDetailNotes"
                    value={userInput.costDetailNotes}
                />
            </Card>

            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProject={capitalProject}
            />
        </React.Fragment>
    );
};
