import React from 'react';
import { Input } from 'reactstrap';

export const FormRadio = React.memo(props => {
    const { className, label, onChange, options = [], required, name, value } = props;
    return (
        <div className={className}>
            <fieldset className="form-group">
                <label className="d-block">
                    {label} {required && <sup className="text-danger">*</sup>}
                </label>
                {options.map((o, i) => {
                    const id = name + i;
                    return (
                        <div key={id} className="form-check form-check-inline">
                            <Input
                                type="radio"
                                onChange={() => {
                                    onChange({
                                        [name]: o.value,
                                    });
                                }}
                                id={id}
                                name={name}
                                value={value === o.value}
                                checked={value === o.value}
                                {...o.rest}
                            />
                            <label className="form-check-label" for={id}>
                                {o.label}
                            </label>
                        </div>
                    );
                })}
            </fieldset>
        </div>
    );
});

export default FormRadio;
