import React, { Fragment } from 'react';
// import { FormGroup, Label } from 'reactstrap';
export const GenericRadioButtons = ({
    name,
    value,
    onChange,
    button1 = 'No',
    button2 = 'Yes',
}) => {
    const option1 = name + 'one';
    const option2 = name + 'two';
    return (
        <Fragment>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={option1}
                    value={false}
                    checked={value === false}
                    onChange={onChange}
                />
                <label className="form-check-label" htmlFor={option1}>
                    {button1}
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={option2}
                    value={true}
                    checked={value === true}
                    onChange={onChange}
                />
                <label className="form-check-label" htmlFor={option2}>
                    {button2}
                </label>
            </div>
        </Fragment>
    );
};
