import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import {
    SearchAcademicInstitutionsForm,
    AcademicAffairsSearchInstitutionsTable,
} from 'components';

export const SearchAcademicInstitutionInventoryPage = props => {
    const academicAffairsDataFromSession = window.sessionStorage.getItem('academicSystemsData') ? JSON.parse(window.sessionStorage.getItem('academicSystemsData')) : null;

    const [academicSystemsData, setAcademicSystemsData] = useState(academicAffairsDataFromSession ? academicAffairsDataFromSession : []);
    const [searchObject, setSearchObject] = useState({
        bienniums: null,
        title: null,
        status: null,
        includeAll: true,
        // orderBy: 'LastUpdate',
        // sortDirection: 'desc',
    });

    // Before this component is unmounted, commit whatever is in academic affairs data to session storage
    useEffect(() => {
        return () => {
            window.sessionStorage.setItem('academicSystemsData', JSON.stringify(academicSystemsData));
        }
    });

    return (
        <Row>
            <Col className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                <SearchAcademicInstitutionsForm
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    setAcademicSystemsData={setAcademicSystemsData}
                ></SearchAcademicInstitutionsForm>
            </Col>
            <Col className="col-xl-9 col-lg-12">
                <AcademicAffairsSearchInstitutionsTable
                    searchObject={searchObject}
                    academicSystemsData={academicSystemsData}
                ></AcademicAffairsSearchInstitutionsTable>
            </Col>
        </Row>
    );
};
