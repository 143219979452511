import https from 'https';
import http from 'http';
import axios from 'axios';

export const api_base_url = process.env.REACT_APP_API_BASEURL || '';

export const axiosInstance = axios.create({
    httpsAgent: new https.Agent({
        rejectUnauthorized: false,
    }),
    httpAgent: new http.Agent(),
});

export const handleAxiosResponse = res => {
    const text = res.request.response || res.data;
    const type = res.headers['content-type'];
    if (
        typeof text == 'object' ||
        [
            'application/octet-stream',
            'application/text',
            'application/xlsx',
            'text/csv',
        ].includes(type)
    ) {
        return text;
    } else if (!!text) {
        return JSON.parse(text);
    } else {
        return Promise.reject('No response');
    }
};
