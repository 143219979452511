import React from 'react';

const record = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div
            className={`nav-link text-left text-primary ${
                formatExtraData?.active?.id === row.id ? 'active' : ''
            }`}
        >
            <h5 className="mb-1">
                {row.firstName} {row.lastName}
            </h5>
            <ul className="d-md-flex align-items-center p-0 m-0 no-bullets text-muted">
                <li className="mr-3">
                    <label className="m-0">ID:</label> {row.id}
                </li>
                <li>
                    {row.reviewed === 'Link' && (
                        <>
                            <i className="fas fa-solid fa-link" /> Link
                        </>
                    )}
                    {row.reviewed === 'Non-Link' && (
                        <>
                            <i className="fas fa-solid fa-link-slash" /> Non-Link
                        </>
                    )}
                    {row.reviewed === 'Needs Review' && (
                        <>
                            <i className="fas fa-solid fa-search" /> Needs Review
                        </>
                    )}
                </li>
            </ul>
        </div>
    );
};

export const Records = props => [
    {
        dataField: 'id',
        text: '',
        formatter: record,
        formatExtraData: props,
        classes: 'nav-item',
    },
];
