import React, { useState } from 'react';
import { Collapse, Card, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { TooltipWrapper } from '../../blocks';

export const Section = props => {
    const [open, setOpen] = useState(true);
    const { name, children, id, onEdit, onDelete, data, admin } = props;
    return (
        <Card>
            <CardHeader>
                <div className="mb-0 d-md-flex text-center text-md-left justify-content-between align-items-center">
                    <button
                        onClick={() => setOpen(!open)}
                        className="btn btn-link btn-block text-left text-strong text-body px-1"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                        {name}
                    </button>
                    {admin && (
                        <div className="d-flex align-items-center">
                            <TooltipWrapper
                                id={`edit-${id}`}
                                text="Edit"
                                placement="top"
                                containerStyle={{ marginRight: 8 }}
                            >
                                <div
                                    onClick={() => onEdit(data)}
                                    className="action-circle"
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </div>
                            </TooltipWrapper>
                            <TooltipWrapper
                                id={`delete-${id}`}
                                text="Delete"
                                placement="top"
                            >
                                <div
                                    onClick={() => onDelete(data)}
                                    className="action-circle"
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                            </TooltipWrapper>
                        </div>
                    )}
                </div>
            </CardHeader>
            <Collapse isOpen={open}>{children}</Collapse>
        </Card>
    );
};

export default Section;
