import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { userService } from 'services';
import { ErrorBoundary, ROLES } from 'shared';
import Spinner from 'components/blocks/Spinner';

// const perms = {
//     NOT_LOGGED_IN: 0,
//     NEED_TFA: 1,
//     PAGE_NOT_AUTHORIZED: 2,
//     AUTHORIZED: 3,
//     ADMIN: 10,
// };

// TODO: validate auth at endpoint
// const getAuth = () => {
// 	const user = JSON.parse(localStorage.getItem('user'));
// 	const userRoles = JSON.parse(localStorage.getItem('userRoles'));
// 	const authToken = JSON.parse(localStorage.getItem('authToken'));

// 	if (authToken && userRoles) {
// 	} else if (user && user.userID && authToken) {
// 		return perms.AUTHORIZED;
// 	} else if (user && user.userID && !authToken) {
// 		return perms.NEED_TFA;
// 	} else if (!user || !user.userID) {
// 		return perms.NOT_LOGGED_IN;
// 	}
// };

export const PrivateRoute = ({ component: Component, admin, ...rest }) => {
    const user = useSelector(state => state.user);
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (location.pathname === rest.path) {
            // if the user is no longer signed in we need to reroute them to login
            if (!user.token) {
                history.replace('/login');
            }
        }
    }, [user, location, rest.path, history]);
    return (
        <Route
            {...rest}
            render={props => (
                <ErrorBoundary>
                    {user.token ? (
                        <Component {...props} admin={admin} />
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                position: 'relative',
                                height: 200,
                            }}
                        >
                            <Spinner color="primary" size={48} />
                        </div>
                    )}
                </ErrorBoundary>
            )}
        />
    );
};

export const AdminRoute = ({ component: Component, ...rest }) => {
    if (userService.userCan(ROLES.ADMIN)) {
        return (
            <Route
                {...rest}
                render={props => (
                    <ErrorBoundary>
                        <Component {...props} />
                    </ErrorBoundary>
                )}
            />
        );
    } else {
        // TODO: FLASH error message
        return <Route {...rest} render={props => <Redirect to="/" {...props} />} />;
    }
};
