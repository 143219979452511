import React from 'react';
import { getWidthStyle } from 'components/blocks';

const yearStyle = getWidthStyle(80);
const colStyle = getWidthStyle(100);

export const format = number =>
    number.toLocaleString('en-US', {
        style: 'currency',
        maximumFractionDigits: 0,
        currency: 'USD',
    });

export const Title = React.memo(() => {
    return (
        <thead className="no-border">
            <tr>
                <th colSpan={1}></th>
                <th colSpan={4}>
                    <h5 className="mb-0 text-center text-strong">
                        Fee Replaced Debt Service
                    </h5>
                </th>
                <th colSpan={4} className="thick-border">
                    <h5 className="mb-0 text-center text-strong">
                        Non Fee Replaced Debt Service
                    </h5>
                </th>
            </tr>
        </thead>
    );
});

export const SubTitle = React.memo(() => {
    return (
        <thead className="thead-gray large">
            <tr>
                <th className="text-center text-strong" style={yearStyle}>
                    Fiscal Year
                </th>
                <th className="text-center text-strong" style={colStyle}>
                    Principal
                </th>
                <th className="text-center text-strong" style={colStyle}>
                    Interest
                </th>
                <th className="text-center text-strong" style={colStyle}>
                    Total Debt Service
                </th>
                <th className="text-center text-strong" style={colStyle}>
                    Outstanding Debt
                </th>
                <th className="text-center text-strong thick-border" style={colStyle}>
                    Principal
                </th>
                <th className="text-center text-strong" style={colStyle}>
                    Interest
                </th>
                <th className="text-center text-strong" style={colStyle}>
                    Total Debt Service
                </th>
                <th className="text-center text-strong" style={colStyle}>
                    Outstanding Debt
                </th>
            </tr>
        </thead>
    );
});

export const TableRow = React.memo(props => {
    const {
        feeInterest,
        feeOutstandingDebt,
        feePrincipal,
        feeTotalDebtService,
        fiscalYear,
        nonFeeInterest,
        nonFeeOutstandingDebt,
        nonFeePrincipal,
        nonFeeTotalDebtService,
    } = props;
    return (
        <tr>
            <td className="text-center" style={yearStyle}>
                {fiscalYear}
            </td>
            <td className="text-right" style={colStyle}>
                {format(feePrincipal)}
            </td>
            <td className="text-right" style={colStyle}>
                {format(feeInterest)}
            </td>
            <td className="text-right" style={colStyle}>
                {format(feeTotalDebtService)}
            </td>
            <td className="text-right" style={colStyle}>
                {format(feeOutstandingDebt)}
            </td>
            <td className="text-right thick-border" style={colStyle}>
                {format(nonFeePrincipal)}
            </td>
            <td className="text-right" style={colStyle}>
                {format(nonFeeInterest)}
            </td>
            <td className="text-right" style={colStyle}>
                {format(nonFeeTotalDebtService)}
            </td>
            <td className="text-right" style={colStyle}>
                {format(nonFeeOutstandingDebt)}
            </td>
        </tr>
    );
});

export const TableBody = React.memo(props => {
    return <tbody>{props.children}</tbody>;
});
