import React, { useReducer, useEffect, useCallback, useMemo, useState } from 'react';

import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createAcademicSystem, validateCheUnitId, validateCheOpeId, validateCheIpedsId } from 'services/admin-services/academic-institutions.service';
import { FormInput } from 'components/blocks';
import { academicInstitutionsService } from 'services';
import { FormSelect } from './components/FormSelect';

export const AcademicAffairAddLocationModal = ({
    isOpen,
    toggle,
    addNewLocation,
}) => {
    
    const reducer = (state, newState) => ({ ...state, ...newState });
    const [loading, setLoading] = useState(false);
    const [invalidId, setInvalidId] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [missingName, setMissingName] = useState(false);
    const [missingCHEId, setMissingCHEId] = useState(false);

 


    function empty(object) {
        return Object.keys(object).length == 0
    }


    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    const [location, setLocation] = useReducer(reducer, {
        LocationunitId: "",
        LocationName: "",
        ShortName: "",
    });

    function clearErrors() {
        setMissingCHEId(false);
        setMissingName(false);
    }


    function prepareCampus(location) {
        let newLocation = Object.assign({}, location)

        for(const key in newLocation) {
            
            if(newLocation[key].hasOwnProperty('value')) {
                newLocation[key] = newLocation[key]['value']
            } else if (empty(newLocation[key])) {
                newLocation[key] = ""
            }
        }

        return newLocation 
    }

    function validateRequiredFields(location) {
        var varMissingName = location.cheUnitName == "";
        setMissingName(varMissingName)
        var varMissingId = location.campusId == "";
        setMissingCHEId(varMissingId)

        let missingSomething = (varMissingName || varMissingId);

        return !missingSomething;
    }


    // Makes post to create the system to the backend
    const create = async () => {
        let locationToSend = prepareCampus(location)
        console.log(locationToSend)

        if (!validateRequiredFields(locationToSend)) {
            return
        }

        setLoading(true);

        const validUnitId = await validateCheUnitId(locationToSend.LocationunitId)

        if (!validUnitId) {
            setLoading(false);
            setInvalidId(true);
            return
        }

        const locationAdded = addNewLocation(locationToSend)
        setLoading(false);
        toggle();
        
    }

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-md">
            <ModalHeader>Create a New Location</ModalHeader>
            <ModalBody>
                <Form>
                    <div className="form-row">

                        <div className="col-md-8">
                            <FormInput 
                                label="Name"
                                required={true}
                                value={location.LocationName}
                                name="LocationName"
                                onChange={setLocation}
                                onClick={clearErrors}
                            ></FormInput>
                            {missingName && <p className="text-danger">
                                Location Name is required
                            </p>}
                        </div>

                        <div className="col-md-3">
                            <FormInput 
                                label="Short Name"
                                required={false}
                                value={location.ShortName}
                                name="ShortName"
                                onChange={setLocation}
                                onClick={clearErrors}
                            ></FormInput>
                        </div>
                    </div>
                    <div className="form-row">
                        <div>
                            <FormInput 
                                label="CHEUnitId"
                                required={true}
                                value={location.LocationunitId}
                                name="LocationunitId"
                                onChange={setLocation}
                                className="col-md-8"
                            ></FormInput>
                            {invalidId && <p className="text-danger">
                                    ID taken, please choose another
                                </p>}
                                {missingCHEId && <p className="text-danger">
                                    ID is required
                                </p>}
                        </div>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button 
                    color="secondary" 
                    onClick={toggle}
                >
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    disabled={disabled}
                    loading={loading}
                    onClick={create}
                >
                    Add Location 
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
};