import React, { useState } from 'react';
import {
    Container,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFileExport, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { capitalProjectService } from 'services';

import { CapitalProjectRequestWizardForm } from 'components';

import { TabEnum, FormEnum, UrlEnum, ConfirmActionModal } from 'shared';

export const CapitalProjectRequest = props => {
    const [stepNumber, setStepNumber] = useState(1);
    Object.filter = (obj, predicate) =>
        Object.fromEntries(Object.entries(obj).filter(predicate));
    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedProjectId = sessionStorage.getItem('projectId');

        if (props.location.hash) {
            const filtered = Object.filter(
                TabEnum,
                ([name, tab]) => tab === props.location.hash
            );
            if (filtered) {
                const NameOfTab = Object.keys(filtered)[0];
                const newStepNumber = FormEnum[NameOfTab];
                if (newStepNumber !== stepNumber) setStepNumber(newStepNumber);
            }
        }
        if (query) {
            for (let param of query) {
                if (
                    param.projectId &&
                    (!persistedProjectId || param.projectId !== persistedProjectId)
                ) {
                    window.sessionStorage.setItem('projectId', param.projectId);
                    return param.projectId;
                } else {
                    return null;
                }
            }
        }
    };
    parseQueryParams();
    const persistedProjectId = sessionStorage.getItem('projectId');

    return (
        <Container fluid>
            <CapitalProjectRequestHeader
                projectId={persistedProjectId}
            ></CapitalProjectRequestHeader>
            <CapitalProjectRequestWizardForm
                projectId={persistedProjectId}
                stepNumber={stepNumber}
            />
        </Container>
    );
};

const CapitalProjectRequestHeader = ({ projectId }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <a href={UrlEnum.CAPITALPROJECTSEARCH}>
                    <FontAwesomeIcon icon={faArrowLeft} /> back to search
                </a>
                <div className="d-flex align-items-center">
                    <ExportDropdown projectId={projectId} />
                    <button
                        className="btn btn-danger btn-xs"
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                    </button>
                </div>
            </div>
            <ConfirmActionModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                header={`Confirm Delete`}
                message={'Are you sure you want to delete this capital project?'}
                acceptButtonText={'Delete'}
                acceptFunction={() =>
                    capitalProjectService
                        .deleteCapitalProject(projectId)
                        .then((window.location.href = UrlEnum.CAPITALPROJECTSEARCH))
                }
                acceptButtonCss="btn btn-danger"
            />
        </React.Fragment>
    );
};

const ExportDropdown = ({ projectId }) => {
    const [isOpen, updateIsOpen] = useState(false);

    return (
        <React.Fragment>
            <Dropdown
                className="dropdown mr-2"
                onMouseOver={() => updateIsOpen(true)}
                onFocus={() => updateIsOpen(true)}
                // onMouseLeave={() => updateIsOpen(false)}
                onBlur={() => updateIsOpen(false)}
                toggle={() => updateIsOpen(!isOpen)}
                isOpen={isOpen}
                direction="down"
            >
                <DropdownToggle className="btn btn-xs btn-outline-primary dropdown-toggle">
                    <FontAwesomeIcon icon={faFileExport} />
                    Export as...
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        onClick={() =>
                            capitalProjectService.downloadCapitalProject('xls', projectId)
                        }
                    >
                        XLS
                    </DropdownItem>
                    <DropdownItem
                        onClick={() =>
                            capitalProjectService.downloadCapitalProject('pdf', projectId)
                        }
                    >
                        PDF
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};
