import React, { useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import {
    Card,
    Container,
    Row,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import queryString from 'query-string';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { useDispatch } from 'react-redux';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { UserColumns, UserModal, Alert } from './components';
import { Button, FormSelect, FormRadio } from '../blocks';
import { onChangeFunction, useToastState } from 'shared';
import { userManagementService } from 'services';

const getPermissionLabel = d => d.roleName;
const getPermissionValue = d => d.roleID;
const getInstitutionLabel = d => d.institutionName;
const getInstitutionValue = d => d.institutionID;
const reducer = (state, newState) => ({ ...state, ...newState });

const paginationOptions = {
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    sizePerPage: 10,
    hideSizePerPage: true,
};

const Filter = React.memo(props => {
    const clickHandler = e => {
        e.preventDefault();
        props.onClick(props.id);
    };
    return (
        <div className="dropdown-item" onClick={clickHandler}>
            {props.label}
        </div>
    );
});

const FILTERS = [
    {
        id: 'name',
        label: 'All',
    },
    {
        id: 'firstName',
        label: 'First Name',
    },
    {
        id: 'lastName',
        label: 'Last Name',
    },
    {
        id: 'emailAddress',
        label: 'Email',
    },
];

const deriveKey = (array = [], key = 'key') => {
    if(array == null) return [];
    return array.map(d => d[key]);
};

const cleanUser = user => {
    const {
        createdDate,
        id,
        tfaOverride,
        lastLoginDate,
        roleNames = [],
        institutionNames = [],
        ...rest
    } = user;
    return {
        ...rest,
        roles: deriveKey(roleNames, 'roleID'),
        institutions: deriveKey(institutionNames, 'institutionID'),
    };
};

export const SearchUsersForm = props => {
    const dispatch = useDispatch();
    const { admin } = props;
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchFilter, setSearchFilter] = useState('name');
    const { setToast } = useToastState();
    const [form, setForm] = useReducer(reducer, {
        search: '',
        status: 'active',
        roles: [],
        institutions: [],
    });
    const [options, setOptions] = useReducer(reducer, {});
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prev => !prev);
    const setFilter = id => {
        setSearchFilter(id);
        setDropdownOpen(false);
    };
    const fetchOptions = async () => {
        try {
            const res = await Promise.all([
                userManagementService.getRoles(),
                userManagementService.getInstitutions(),
            ]);
            const cheRoles = [];
            const institutionRoles = [];
            res[0].forEach(d => {
                if (d.roleName.includes('CHEDSS ')) {
                    institutionRoles.push(d);
                } else {
                    cheRoles.push(d);
                }
            });
            setOptions({
                cheRoles,
                institutionRoles,
                institutions: res[1],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onSubmit = useCallback(
        async e => {
            setLoading(true);
            console.log(form)
            const getFormPayload = () => {
                let payload = {
                    [searchFilter]: form.search,
                    roles: deriveKey(form.roles, 'value'),
                    institutions: deriveKey(form.institutions, 'value'),
                };
                if (form.status === 'active') {
                    payload.isActive = true;
                } else if (form.status === 'inactive') {
                    payload.isActive = false;
                }
                return queryString.stringify(payload, {
                    arrayFormat: 'separator',
                    skipEmptyString: true,
                });
            };
            try {
                if (e) e.preventDefault();
                const query = getFormPayload();
                const res = await userManagementService.getUsers(query);
                if (res) {
                    setUsers(res);
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        },
        [form, searchFilter]
    );
    const onAddUser = async body => {
        const res = await userManagementService.createUser(body);
        if (res) {
            onSubmit();
        }
    };
    const onEditUser = async (id, body) => {
        const res = await userManagementService.updateUser(id, body);
        if (res) {
            onSubmit();
        }
    };
    const onEdit = async user => {
        setUser(user);
        setOpen(true);
    };
    const onImpersonate = async user => {
        const res = await userManagementService.impersonateUser(user.id);
        if (res?.token) {
            const updatedFields = _pick(res, ['token', 'isImpersonation']);
            dispatch({
                type: 'SET_USER',
                user: { ...updatedFields, roles: user.roleNames, impersonatedUser: user },
                merge: true,
                setOriginal: true,
            });
            setToast({
                title: 'Success!',
                message: `You are now impersonating ${user.firstName} ${user.lastName}`,
            });
        }
        fetchOptions();
    };
    const onSendEmail = async user => {
        const res = await userManagementService.sendPasswordReset(user.id);
        if (res?.message) {
            setToast({
                title: 'Successly Sent Email!',
                message: res.message,
            });
        }
    };
    const onDeactivate = user => {
        setAlertOpen(user);
    };
    const onDelete = async () => {
        const res = await userManagementService.updateUser(alertOpen.id, {
            ...cleanUser(alertOpen),
            isDeleted: true,
        });
        setAlertOpen(false);
        if (res) onSubmit();
    };
    const onActivate = async user => {
        const res = await userManagementService.updateUser(user.id, {
            ...cleanUser(user),
            isDeleted: false,
        });
        if (res) onSubmit();
    };
    useEffect(() => {
        fetchOptions();
        onSubmit();
    }, []);
    const filterLabel = useMemo(() => {
        return FILTERS.find(f => f.id === searchFilter).label;
    }, [searchFilter]);
    return (
        <Container fluid>
            <Row>
                <div className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                    <Button
                        color="success"
                        className="btn-block new-btn mb-3"
                        onClick={() => setOpen(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} /> New user
                    </Button>
                    <Card className="mb-3 p-3">
                        <form onSubmit={loading ? null : onSubmit}>
                            <h4 className="text-muted mb-4">Search Users</h4>
                            <div className="form-row">
                                <div className="col-xl-12">
                                    <fieldset className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <Dropdown
                                                    isOpen={dropdownOpen}
                                                    toggle={toggle}
                                                >
                                                    <DropdownToggle
                                                        style={{ height: '100%' }}
                                                        className="btn btn-white btn-sm dropdown-toggle"
                                                    >
                                                        <span className="selected-text">
                                                            {filterLabel}
                                                        </span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {FILTERS.filter(
                                                            d => d.id !== searchFilter
                                                        ).map(d => (
                                                            <Filter
                                                                key={d.id}
                                                                label={d.label}
                                                                id={d.id}
                                                                onClick={setFilter}
                                                            />
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                            <Input
                                                type="text"
                                                placeholder="search..."
                                                onChange={evt =>
                                                    onChangeFunction.handleChange(
                                                        evt,
                                                        setForm
                                                    )
                                                }
                                                name="search"
                                                value={form.search}
                                                className="white"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="col-xl-12">
                                    <FormRadio
                                        label="Status"
                                        name="status"
                                        onChange={setForm}
                                        value={form.status}
                                        options={[
                                            {
                                                value: 'active',
                                                label: 'Active',
                                            },
                                            {
                                                value: 'inactive',
                                                label: 'Inactive',
                                            },
                                            {
                                                value: 'all',
                                                label: 'All',
                                            },
                                        ]}
                                    />
                                </div>
                                <FormSelect
                                    label="Roles"
                                    onChange={setForm}
                                    placeholder="All"
                                    name="roles"
                                    value={form.roles}
                                    className="col-xl-12"
                                    getOptionLabel={getPermissionLabel}
                                    getOptionValue={getPermissionValue}
                                    isMulti
                                    showLoading
                                    options={[
                                        {
                                            label: 'CHE',
                                            options: options.cheRoles ? options.cheRoles.map(x => {return {label: x.roleName, value: x.roleID};}) : []
                                        },
                                        {
                                            label: 'Institution',
                                            options: options.institutionRoles ? options.institutionRoles.map(x => {return {label: x.roleName, value: x.roleID};}) : []
                                        },
                                    ]}
                                />
                                <FormSelect
                                    label="Institution"
                                    onChange={setForm}
                                    options={options.institutions ? options.institutions.map(x => {return {label: x.institutionName, value: x.institutionID};}) : []}
                                    placeholder="All"
                                    name="institutions"
                                    isMulti={true}
                                    value={form.institutions}
                                    className="col-xl-12"
                                    getOptionLabel={getInstitutionLabel}
                                    getOptionValue={getInstitutionValue}
                                    showLoading
                                />
                                <div className="col-12">
                                    <Button
                                        color="primary"
                                        className="btn-primary btn-block"
                                        type="search"
                                        loading={loading}
                                    >
                                        <FontAwesomeIcon icon={faSearch} /> Search
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
                <div className="col-xl-9 col-lg-12">
                    <h2 className="mb-3">
                        {users.length} {users.length === 1 ? 'User' : 'Users'} Found
                    </h2>
                    <Card className="mb-4">
                        <div className="table-responsive">
                            <BootstrapTable
                                classes="data-table table table-striped table-bordered table-hover align-middle dataTable no-footer dtr-inline"
                                keyField="id"
                                data={users}
                                columns={UserColumns({
                                    onEdit,
                                    onImpersonate,
                                    onSendEmail,
                                    onDeactivate,
                                    onActivate,
                                    admin,
                                })}
                                striped
                                hover
                                pagination={paginationFactory(paginationOptions)}
                            />
                        </div>
                    </Card>
                </div>
            </Row>
            <UserModal
                open={open}
                close={() => {
                    setOpen(false);
                    setUser(null);
                }}
                user={user}
                options={options.institutions}
                cheRoles={options.cheRoles}
                institutionRoles={options.institutionRoles}
                onAddUser={onAddUser}
                onEditUser={onEditUser}
            />
            <Alert
                open={!_isEmpty(alertOpen)}
                close={() => {
                    setAlertOpen(false);
                }}
                onDelete={onDelete}
            />
        </Container>
    );
};
