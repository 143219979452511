import React, { useState, useEffect, useReducer } from 'react';
import { Table, Input, Checkbox } from 'reactstrap';
import { WizardFooter } from './wizard-footer';
import Select from 'react-select';

export const PffOutput = ({ budgetRun, setBudgetRun, prevStep, nextStep }) => {
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [showAll, setShowAll] = useState(false);
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: budgetRun.budgetRunID,
            tabNumber: 5,
            pffPerUnits: budgetRun.pffPerUnits ?? [],
        }
    );
    const handleSelectChange = valueObj => {
        setSelectedOption(valueObj);
    };

    const sanitizeBudgetRun = () => {
        budgetRun.pffPerUnits = userInput.pffPerUnits;
        return budgetRun;
    };

    const handleTableChange = (evt, index, otherIndex) => {
        const headerValue = userInput.pffPerUnits[otherIndex].perUnits[index];
        headerValue['totalUnits'] = +evt.target.value ?? 0;
        setUserInput({ pffPerUnits: [...userInput.pffPerUnits] });
        recalculatePercents(otherIndex);
    };

    const recalculatePercents = index => {
        const pffUnit = userInput.pffPerUnits[index];
        let thisSum = 0;
        if (null != pffUnit) {
            thisSum = pffUnit.perUnits.reduce(function(a, b) {
                return a + b['totalUnits'];
            }, 0);

            // eslint-disable-next-line array-callback-return
            pffUnit.perUnits.map((v, i) => {
                v.percentOfTotal = +((v.totalUnits ?? 0) / thisSum).toFixed(2);
            });
            userInput.pffPerUnits[index] = pffUnit;
            setUserInput({ pffPerUnits: [...userInput.pffPerUnits] });
        }
    };

    useEffect(() => {
        const getOptions = () => {
            return userInput.pffPerUnits.map(pffUnit => {
                return {
                    label: `${pffUnit.pffTypeName}: ${pffUnit.pffDisplayName}`,
                    value: pffUnit.pffItemID,
                };
            });
        };
        const options = getOptions();
        setSelectOptions(options);
        setSelectedOption(options[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetRun]);

    const PffOutputTableBody = show => {
        const pffUnit = userInput.pffPerUnits.find(
            pffUnit => selectedOption.value === pffUnit.pffItemID
        );
        const otherIndex = userInput.pffPerUnits.findIndex(
            pffUnit => selectedOption.value === pffUnit.pffItemID
        );
        return pffUnit
            ? pffUnit.perUnits.map((financeUnit, index) => {
                  if (financeUnit.totalUnits === null && !show) return null;
                  return (
                      <tr key={otherIndex + '_' + index}>
                          <td>
                              <label className="mb-0">{financeUnit.displayName}</label>
                          </td>
                          <td
                              className="restricted-width-input"
                              key={otherIndex + '_' + index}
                          >
                              <Input
                                  key={otherIndex + '_' + index}
                                  type="number"
                                  onChange={evt => {
                                      handleTableChange(evt, index, otherIndex);
                                  }}
                                  //   onChange={evt => {
                                  //     const headerValue = userInput.pffPerUnits[otherIndex].perUnits[index];
                                  //     headerValue["totalUnits"] = +evt.target.value ?? 0;
                                  //     setUserInput({pffPerUnits: [...userInput.pffPerUnits]});
                                  //   }}
                                  //onBlur={() => recalculatePercents(otherIndex)}
                                  value={financeUnit.totalUnits ?? 0}
                              />
                          </td>
                          <td className="text-right">
                              <label className="m-0">
                                  {(financeUnit.percentOfTotal * 100).toFixed(2)}%
                              </label>
                          </td>
                      </tr>
                  );
              })
            : null;
    };

    return (
        <React.Fragment>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                PFF Per Unit Input
            </h2>
            <Select
                onChange={handleSelectChange}
                options={selectOptions}
                value={selectedOption}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />
            <div className="mt-2 mb-1">
                <input
                    type="checkbox"
                    id="showall"
                    value={showAll}
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                />
                <label className="ml-2" for="showall">
                    Show All
                </label>
            </div>
            <div className="table-responsive">
                <Table className="table table-striped table-bordered table-hover align-middle table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="text-right">Total Units</th>
                            <th className="text-right">% of Total</th>
                        </tr>
                    </thead>
                    <tbody>{PffOutputTableBody(showAll)}</tbody>
                </Table>
            </div>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                budgetRun={budgetRun}
                setBudgetRun={setBudgetRun}
                isGeneralInfo={false}
                sanitizeBudgetRun={sanitizeBudgetRun}
            />
        </React.Fragment>
    );
};
