import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { LineItemService } from 'services';
import { Container, Card, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import _groupBy from 'lodash/groupBy';
import {
    faHistory,
    faArrowLeft,
    faDownload,
    faFileExport,
    faFilePdf,
    faPencilAlt,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
    Footer,
    HeaderCard,
    SummaryBudgetRequestTable,
    EditPrepopulatedValueModal,
    DataAuditModal,
    UpdateDescriptionModal,
} from './components';
import { DropdownButton } from 'components/blocks';
import { downloadExcel, downloadCsv } from 'helpers';
import { UrlEnum, ConfirmActionModal } from 'shared';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_FORM = {
    bienniums: [],
    institutions: [],
    schedules: [],
};

export const LineItemsBudget = () => {
    const location = useLocation();
    const { row } = location?.state;
    const { scheduleId, scheduleDescription, lastUpdated, lastUpdatedByUser } = row;
    const [options, setOptions] = useReducer(reducer, {
        fileTypes: [],
        bienniums: [],
        institutions: [],
        statuses: [],
        schedules: [],
    });
    const [budgetSchedule, setBudgetSchedule] = useState(null);
    const [lineItemValues, setLineItemValues] = useState([]);
    const [lineItemCategories, setLineItemCategories] = useState([]);
    const [isEditPrePopulatedModalOpen, setIsEditPrePopulatedModalOpen] = useState(false);
    const [isDataAuditModalOpen, setIsDataAuditModalOpen] = useState(false);
    const [modalObject, setModalObject] = useState({});
    const [mapOfTotals, setMapOfTotals] = useState([]);
    const [isDataAudit, setIsDataAudit] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const fetchBudgetSchedule = async () => {
        if (scheduleId) {
            const res = await LineItemService.getBudgetScheduleWithId(scheduleId);
            setBudgetSchedule(res);
            const lineItemValueMap = new Map();
            res.scheduleData.forEach(detail => {
                lineItemValueMap.set(detail.lineItemName, detail);
            });
            const groups = _groupBy(res.scheduleData, 'lineItemCategoryName');
            const categories = Object.keys(groups).map(prop => {
                return {
                    lineItemCategoryName: prop,
                    lineItems: groups[prop],
                };
            });
            setLineItemValues(lineItemValueMap);
            setLineItemCategories(categories);
        }
    };
    useEffect(() => {
        const fetch = async () => {
            const res = await Promise.all([
                LineItemService.getExportTypes(),
                LineItemService.getBienniums(),
                LineItemService.getInstitutions(),
                LineItemService.getStatuses(),
                LineItemService.getTypes(),
            ]);
            setOptions({
                fileTypes: res[0]?.fileTypes,
                bienniums: res[1],
                institutions: res[2],
                statuses: res[3],
                schedules: res[4],
            });
        };
        fetch();
    }, []);
    useEffect(() => {
        fetchBudgetSchedule();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleId]);
    const exportAs = async type => {
        try {
            const filename = `LineItem - ${scheduleId}`;
            const res = await LineItemService.downloadLineItem(type, scheduleId);
            if (type === 'Excel') {
                downloadExcel(res, filename);
            } else if (type === 'Csv') {
                downloadCsv(res, filename);
            }
        } catch (error) {}
    };
    const sanitizeInput = () => {
        const scheduleData = [];
        // eslint-disable-next-line array-callback-return
        budgetSchedule.scheduleData.forEach(detailList => {
            scheduleData.push(lineItemValues.get(detailList.lineItemName));
        });
        return {
            ...budgetSchedule,
            scheduleData,
        };
    };
    const labels = useMemo(() => {
        if (budgetSchedule)
            return {
                biennium:
                    options?.bienniums?.find(d => d.id === budgetSchedule.biennium)
                        ?.name || '---',
                institution:
                    options?.institutions?.find(
                        d => d.value === budgetSchedule.cheFinanceUnitId
                    )?.label || '---',
                schedule:
                    options?.schedules?.find(d => d.id === budgetSchedule.scheduleTypeId)
                        ?.name || '---',
                status:
                    options?.statuses?.find(d => d.id === budgetSchedule.scheduleStatusId)
                        ?.name || '---',
            };
        return {
            biennium: '---',
            institution: '---',
            schedule: '---',
            status: '---',
        };
    }, [budgetSchedule, options]);
    const disabled = budgetSchedule?.scheduleEditStatusId === 0;
    return (
        <Container fluid>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <a href={UrlEnum.LINEITEMSEARCH}>
                    <FontAwesomeIcon icon={faArrowLeft} /> back to search
                </a>
                <div className="d-flex align-items-center">
                    <DropdownButton
                        className="mr-2"
                        icon={faFilePdf}
                        text="Description"
                        options={[
                            {
                                label: (
                                    <>
                                        <FontAwesomeIcon icon={faDownload} /> Download
                                    </>
                                ),
                                onClick: () =>
                                    LineItemService.getDescription(scheduleId, true),
                            },
                            {
                                label: (
                                    <>
                                        <FontAwesomeIcon icon={faPencilAlt} /> Update
                                    </>
                                ),
                                onClick: () => setIsUpdateModalOpen(true),
                            },
                        ]}
                    />
                    <DropdownButton
                        className="mr-2"
                        icon={faFileExport}
                        text="Export As..."
                        options={options?.fileTypes?.map(type => {
                            return { label: type, onClick: () => exportAs(type) };
                        })}
                    />
                    <button
                        className="btn btn-danger btn-xs"
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete Schedule
                    </button>
                </div>
            </div>

            <Row className="sticky-bar-spacer">
                <div className="col-12">
                    <HeaderCard
                        biennium={labels?.biennium}
                        institution={labels?.institution}
                        schedule={labels?.schedule}
                        status={labels?.status}
                        description={scheduleDescription}
                        lastUpdated={lastUpdated}
                        lastUpdatedByUser={lastUpdatedByUser}
                    />
                    {isDataAudit ? (
                        <button
                            type="button"
                            onClick={() => setIsDataAuditModalOpen(true)}
                            className="btn btn-sm btn-outline-primary mb-3"
                        >
                            <FontAwesomeIcon icon={faHistory} /> View Data Audits
                        </button>
                    ) : null}

                    <Card>
                        {budgetSchedule ? (
                            <React.Fragment>
                                <SummaryBudgetRequestTable
                                    biennium={labels?.biennium}
                                    lineItemCategories={lineItemCategories}
                                    lineItemValues={lineItemValues}
                                    setLineItemValues={setLineItemValues}
                                    setModalObject={setModalObject}
                                    setIsEditPrePopulatedModalOpen={
                                        setIsEditPrePopulatedModalOpen
                                    }
                                    mapOfTotals={mapOfTotals}
                                    setMapOfTotals={setMapOfTotals}
                                    isDataAudit={isDataAudit}
                                    setIsDataAudit={setIsDataAudit}
                                    disabled={disabled}
                                />

                                {!disabled && (
                                    <Footer
                                        sanitizedInput={sanitizeInput}
                                        lineItemValues={lineItemValues}
                                        biennium={labels?.biennium}
                                        mapOfTotals={mapOfTotals}
                                        isDataAudit={isDataAudit}
                                        setIsDataAudit={setIsDataAudit}
                                        scheduleId={scheduleId}
                                        fetchBudgetSchedule={fetchBudgetSchedule}
                                    />
                                )}
                            </React.Fragment>
                        ) : null}
                    </Card>
                </div>
            </Row>
            <EditPrepopulatedValueModal
                modalObject={modalObject}
                isOpen={isEditPrePopulatedModalOpen}
                toggle={() =>
                    setIsEditPrePopulatedModalOpen(!isEditPrePopulatedModalOpen)
                }
                setLineItemValues={setLineItemValues}
                setIsDataAudit={setIsDataAudit}
            />
            <DataAuditModal
                isOpen={isDataAuditModalOpen}
                toggle={() => setIsDataAuditModalOpen(!isDataAuditModalOpen)}
                biennium={labels?.biennium}
                lineItemValues={lineItemValues}
                isDataAudit={isDataAudit}
                setIsDataAudit={setIsDataAudit}
            />
            <ConfirmActionModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                header={`Confirm Delete`}
                message={'Are you sure you want to delete this line item?'}
                acceptButtonText={'Delete'}
                acceptFunction={() => {
                    LineItemService.deleteLineItemWithId(scheduleId).then(
                        (window.location.href = UrlEnum.LINEITEMSEARCH)
                    );
                }}
                acceptButtonCss="btn btn-danger"
            />
            <UpdateDescriptionModal
                isOpen={isUpdateModalOpen}
                toggle={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
                scheduleId={scheduleId}
            />
        </Container>
    );
};
