import _get from 'lodash/get';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export const useErrorState = () => {
    const error = useSelector(state => state.error);
    const dispatch = useDispatch();
    const setError = payload => {
        dispatch({ type: 'SET_ERROR', ...payload });
    };
    const clearError = () => {
        dispatch({ type: 'CLEAR_ERROR' });
    };
    const handleError = error => {
        console.log(error)
        const resMsg = _get(error, 'response.data.message');
        const resStatus = _get(error, 'response.status');
        const errMsg = _get(error, 'message');
        
        if (resMsg) {
            setError({ message: resMsg, status: resStatus });
        } else {
            setError({ message: errMsg, status: resStatus });
        }
    };
    useEffect(() => {
        if (error?.status === 401) {
            dispatch({ type: 'FAILED_AUTH' });
        }
    }, [error]);
    return { error, setError, clearError, handleError };
};
