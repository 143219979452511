import React, { useState, useEffect } from 'react';

import { AcademicAffairProgram } from 'components';
import { academicProgramsService } from 'services';
import { academicInstitutionsService } from 'services';

export const AcademicProgramPage = props => {
    const [academicAffairsData, setAcademicAffairsData] = useState([]);
    const [degreeSelectOptions, setDegreeSelectOptions] = useState([]);
    const [distanceEdOnlineOptions, setDistanceEdOnlineOptions] = useState([]);
    const [distanceEdOptions, setDistanceEdOptions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [institutionCTID, setInstitutionCTID] = useState([]);

    const parseQueryParams = () => {
        const query = props.location.state;
        const persistedProgramID = sessionStorage.getItem('programID');

        if (query) {
            for (let param of query) {
                if (
                    param.programID &&
                    (!persistedProgramID || param.programID !== persistedProgramID)
                ) {
                    window.sessionStorage.setItem('programID', param.programID);
                    return param.programID;
                } else {
                    return null;
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            parseQueryParams();
            const persistedProgramID = sessionStorage.getItem('programID');
            const data = await academicProgramsService.getAcademicProgram(
                persistedProgramID
            );
            setAcademicAffairsData(data || []);
            // default them all with an active status
            setLocations(data?.locations || []);
            const degrees = await academicProgramsService.getDegreeLevels();
            setDegreeSelectOptions(degrees);

            const distanceEds = await academicProgramsService.getDistanceEds();
            setDistanceEdOptions(distanceEds);

            const distanceEdOnlineOptions = await academicProgramsService.getDistanceEdsOnline();
            setDistanceEdOnlineOptions(distanceEdOnlineOptions);
        } catch (error) {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <AcademicAffairProgram
            locations={locations}
            setLocations={setLocations}
            academicAffairsData={academicAffairsData}
            setAcademicAffairsData={setAcademicAffairsData}
            degreeSelectOptions={degreeSelectOptions}
            distanceEdOptions={distanceEdOptions}
            fetchData={fetchData}
            distanceEdOnlineOptions={distanceEdOnlineOptions}
        />
    );
};
