import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Form, Card, Table } from 'reactstrap';

export const AnnualCostDetail = ({ capitalProject, prevStep, nextStep }) => {
    const [annualDetailRowHeaders, setAnnualDetailRowHeaders] = useState([]);

    useEffect(() => {
        const transformArray = annualDetailRowHeaderArray => {
            return !annualDetailRowHeaderArray
                ? null
                : annualDetailRowHeaderArray.map(annualDetialRow => {
                      const { id } = annualDetialRow;
                      const savedAnnualDetailValue = capitalProject.annualDetails.filter(
                          annualDetail => +annualDetail.costTypeId === +id
                      )[0];
                      return savedAnnualDetailValue
                          ? savedAnnualDetailValue
                          : {
                                costTypeId: +id,
                                personnelServices: '0',
                                suppAndExp: '0',
                            };
                  });
        };

        const fetchColumns = async () => {
            const res = await financeService.getAnnualCostDetailTableColumns();
            setAnnualDetailRowHeaders(res);
            capitalProject.annualDetails = transformArray(res);
            return;
        };
        fetchColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRowHeaderTextFromCostTypeId = costTypeId => {
        if (!annualDetailRowHeaders) {
            return 'loading';
        }
        const header = annualDetailRowHeaders.find(header => +header.id === +costTypeId);
        return header ? header.text : 'loading';
    };
    const getTotalOfColumn = key => {
        let sum = 0;
        if (!capitalProject.annualDetails || capitalProject.annualDetails === []) {
            return 0;
        }
        capitalProject.annualDetails.forEach(annualDetail => {
            annualDetail[key] ? (sum += +annualDetail[key]) : (sum += 0);
        });
        return sum;
    };

    const [totals] = useState([
        getTotalOfColumn('personnelServices'),
        getTotalOfColumn('suppAndExp'),
        getTotalOfColumn('personnelServices') + getTotalOfColumn('suppAndExp'),
        getTotalOfColumn('personnelServices') + getTotalOfColumn('suppAndExp'),
    ]);

    const renderAnnualCostsTable = () => {
        return !capitalProject.annualDetails || capitalProject.annualDetails === [] ? (
            <div></div>
        ) : (
            capitalProject.annualDetails.map((row, index) => {
                const annualDetailToChange = capitalProject.annualDetails[index];
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td className="text-strong text-primary cell-light">
                                {getRowHeaderTextFromCostTypeId(row.costTypeId)}
                            </td>
                            <td>${annualDetailToChange.personnelServices}</td>
                            <td>${annualDetailToChange.suppAndExp}</td>
                            <td className="text-strong text-right">
                                $
                                {+annualDetailToChange.personnelServices +
                                    +annualDetailToChange.suppAndExp}
                            </td>
                            <td className="text-strong text-right">
                                $
                                {+annualDetailToChange.personnelServices +
                                    +annualDetailToChange.suppAndExp}
                            </td>
                        </tr>
                    </React.Fragment>
                );
            })
        );
    };
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Annual Operating Cost/Savings{' '}
                <a
                    href="/#"
                    data-toggle="popover"
                    data-position="top"
                    data-content="Based on figures from Cost, Size & Funding"
                >
                    <FontAwesomeIcon className="fa-lg ml-1" icon={faQuestionCircle} />
                </a>
            </h2>
            <Card className="no-shadow border p-3 mb-3">
                <Table
                    key="largeCostDetailTable"
                    className="table-striped table-bordered table-hover align-middle table-sm"
                    responsive
                >
                    <thead>
                        <tr>
                            <th className="min-tablet-l no-sort"></th>
                            <th className="min-tablet-l no-sort">Personnel Services</th>
                            <th className="min-tablet-l no-sort">
                                Supplies and Expenses
                            </th>
                            <th className="min-tablet-l no-sort text-right">
                                Total Operating Cost
                            </th>
                            <th className="min-tablet-l no-sort text-right">
                                Cost per GSF
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderAnnualCostsTable()}
                        <tr>
                            <td>
                                <label className="m-0">
                                    Total Estimated Operational Cost/Savings
                                </label>
                            </td>
                            <td className="text-strong text-right">${totals[0]}</td>
                            <td className="text-strong text-right">${totals[1]}</td>
                            <td className="text-strong text-right">${totals[2]}</td>
                            <td className="text-strong text-right">${totals[3]}</td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Annual Cost Detail Notes</h4>
                <p className="text-italic">
                    If annual operating costs are increasing or decreasing due to a
                    renovation, please provide specific on what is causing the increase or
                    decrease.
                </p>
                <hr />
                <label>Response:</label>
                <p>{capitalProject.annualDetailNote}</p>
            </Card>
            <WizardFooter prevStep={prevStep} nextStep={nextStep} />
        </Form>
    );
};
