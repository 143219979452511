import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

export const PopOver = props => {
    const { id, icon, text } = props;

    const displayListOrText = () => {
        return Array.isArray(text) ? (
            <ul>
                {text.map((text, index) => {
                    return <li key={id + index}>{text}</li>;
                })}
            </ul>
        ) : (
            text
        );
    };

    return (
        <React.Fragment>
            {icon ? <FontAwesomeIcon id={'Popover-' + id} icon={icon} /> : null}
            <UncontrolledPopover target={'Popover-' + id} trigger="hover">
                <PopoverBody>{displayListOrText()}</PopoverBody>
            </UncontrolledPopover>
        </React.Fragment>
    );
};
