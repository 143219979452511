import React, { useState, useEffect, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { financeService } from 'services';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Form, Input, Card, Table, InputGroup } from 'reactstrap';
import { ValidationErrorBox } from './validation-error-box';
import { FormEnum } from 'shared';
import { InfoTooltip } from 'components/blocks';

export const AnnualCostDetail = ({ capitalProject, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 8,
            annualDetailNote: capitalProject.annualDetailNote
                ? capitalProject.annualDetailNote
                : '',
            annualDetails: capitalProject.annualDetails
                ? capitalProject.annualDetails
                : [],
        }
    );

    const [annualDetailRowHeaders, setAnnualDetailRowHeaders] = useState([]);
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };

    const handleTableChange = (event, index, inputTypeChanged) => {
        const headerValue = userInput.annualDetails[index];
        headerValue[inputTypeChanged] = event.target.value ?? '';
        setChangedTotals();
        capitalProject.annualDetails = [...userInput.annualDetails];
        setUserInput({ annualDetails: [...userInput.annualDetails] });
    };

    const sanitizeCapitalProject = () => {
        userInput.annualDetails.forEach(annualDetail => {
            annualDetail.personnelServices = +annualDetail.personnelServices;
            annualDetail.suppAndExp = +annualDetail.suppAndExp;
        });
        return userInput;
    };

    useEffect(() => {
        const transformArray = annualDetailRowHeaderArray => {
            return !annualDetailRowHeaderArray
                ? null
                : annualDetailRowHeaderArray.map(annualDetialRow => {
                      const { id } = annualDetialRow;
                      const savedAnnualDetailValue = userInput.annualDetails.filter(
                          annualDetail => +annualDetail.costTypeId === +id
                      )[0];
                      return savedAnnualDetailValue
                          ? savedAnnualDetailValue
                          : {
                                costTypeId: +id,
                                personnelServices: '0',
                                suppAndExp: '0',
                            };
                  });
        };

        const fetchColumns = async () => {
            const res = await financeService.getAnnualCostDetailTableColumns();
            setAnnualDetailRowHeaders(res);
            setUserInput({ annualDetails: transformArray(res) });
            return;
        };
        fetchColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRowHeaderTextFromCostTypeId = costTypeId => {
        if (!annualDetailRowHeaders) {
            return 'loading';
        }
        const header = annualDetailRowHeaders.find(header => +header.id === +costTypeId);
        return header ? header.text : 'loading';
    };
    const getTotalOfColumn = key => {
        let sum = 0;
        if (!userInput.annualDetails || userInput.annualDetails === []) {
            return 0;
        }
        userInput.annualDetails.forEach(annualDetail => {
            annualDetail[key] ? (sum += +annualDetail[key]) : (sum += 0);
        });
        return sum;
    };

    const [totals, setTotals] = useState([
        getTotalOfColumn('personnelServices'),
        getTotalOfColumn('suppAndExp'),
        getTotalOfColumn('personnelServices') + getTotalOfColumn('suppAndExp'),
        getTotalOfColumn('personnelServices') + getTotalOfColumn('suppAndExp'),
    ]);

    const setChangedTotals = () => {
        setTotals(prevTotal => {
            prevTotal[0] = getTotalOfColumn('personnelServices');
            prevTotal[1] = getTotalOfColumn('suppAndExp');
            prevTotal[2] = prevTotal[0] + prevTotal[1];
            prevTotal[3] = prevTotal[0] + prevTotal[1];
            return prevTotal;
        });
    };

    const renderAnnualCostsTable = () => {
        return !userInput.annualDetails || userInput.annualDetails === [] ? (
            <div></div>
        ) : (
            userInput.annualDetails.map((row, index) => {
                const annualDetailToChange = userInput.annualDetails[index];
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td className="text-strong text-primary cell-light">
                                {getRowHeaderTextFromCostTypeId(row.costTypeId)}
                            </td>
                            <td>
                                <InputGroup>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <FontAwesomeIcon icon={faDollarSign} />
                                        </span>
                                    </div>
                                    <Input
                                        type="text"
                                        value={annualDetailToChange.personnelServices}
                                        onChange={e => {
                                            handleTableChange(
                                                e,
                                                index,
                                                'personnelServices'
                                            );
                                        }}
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <FontAwesomeIcon icon={faDollarSign} />
                                        </span>
                                    </div>
                                    <Input
                                        type="text"
                                        value={annualDetailToChange.suppAndExp}
                                        onChange={e => {
                                            handleTableChange(e, index, 'suppAndExp');
                                        }}
                                    />
                                </InputGroup>
                            </td>
                            <td className="text-strong text-right">
                                $
                                {+annualDetailToChange.personnelServices +
                                    +annualDetailToChange.suppAndExp}
                            </td>
                            <td className="text-strong text-right">
                                $
                                {+annualDetailToChange.personnelServices +
                                    +annualDetailToChange.suppAndExp}
                            </td>
                        </tr>
                    </React.Fragment>
                );
            })
        );
    };
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Annual Operating Cost/Savings{' '}
                <InfoTooltip
                    id="operating-cost"
                    text="Based on figures from Cost, Size & Funding"
                />
            </h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.ANNUALCOSTDETAIL}
            />
            <Card className="no-shadow border p-3 mb-3">
                <Table
                    key="largeCostDetailTable"
                    className="table-striped table-bordered table-hover align-middle table-sm"
                    responsive
                >
                    <thead>
                        <tr>
                            <th className="min-tablet-l no-sort"></th>
                            <th className="min-tablet-l no-sort">Personnel Services</th>
                            <th className="min-tablet-l no-sort">
                                Supplies and Expenses
                            </th>
                            <th className="min-tablet-l no-sort text-right">
                                Total Operating Cost
                            </th>
                            <th className="min-tablet-l no-sort text-right">
                                Cost per GSF
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderAnnualCostsTable()}
                        <tr>
                            <td>
                                <label className="m-0">
                                    Total Estimated Operational Cost/Savings
                                </label>
                            </td>
                            <td className="text-strong text-right">${totals[0]}</td>
                            <td className="text-strong text-right">${totals[1]}</td>
                            <td className="text-strong text-right">${totals[2]}</td>
                            <td className="text-strong text-right">${totals[3]}</td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Annual Cost Detail Notes</h4>
                <p className="text-italic">
                    If annual operating costs are increasing or decreasing due to a
                    renovation, please provide specific on what is causing the increase or
                    decrease.
                </p>
                <Input
                    type="textarea"
                    rows="8"
                    placeholder="Annual Detial Notes"
                    onChange={handleChange}
                    name="annualDetailNote"
                    value={userInput.annualDetailNote}
                />
            </Card>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProject={capitalProject}
            />
        </Form>
    );
};
