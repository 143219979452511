import React from 'react';
import { ProjectReviewSingleNavTab } from './project-review-single-nav-tab';

export const ProjectReviewWizardNav = ({ step, setStep, capitalProject }) => {
    const toggleClassName = step => {
        setStep(step);
    };
    return (
        <div className="wizard-nav">
            <ul className="nav flex-column sticky-top">
                <ProjectReviewSingleNavTab
                    name="General Info"
                    activeStepEnum={'GENERALINFO'}
                    tabIdName="general"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Cost, Size, and Funding"
                    activeStepEnum={'COSTSIZEFUNDING'}
                    tabIdName="cost-size"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Detail Description"
                    activeStepEnum={'DETAILDESCRIPTION'}
                    tabIdName="description"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Need and Purpose"
                    activeStepEnum={'NEEDSANDPURPOSE'}
                    tabIdName="need"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Other Project Info"
                    activeStepEnum={'OTHERPROJECTINFO'}
                    tabIdName="other"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Space Details"
                    activeStepEnum={'SPACEDETAIL'}
                    tabIdName="space"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Cost Details"
                    activeStepEnum={'COSTDETAIL'}
                    tabIdName="cost"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Annual Cost Details"
                    activeStepEnum={'ANNUALCOSTDETAIL'}
                    tabIdName="annual"
                    toggleClassName={toggleClassName}
                    step={step}
                    validationErrors={capitalProject.validationErrors}
                />
                <ProjectReviewSingleNavTab
                    name="Complete Review"
                    toggleClassName={toggleClassName}
                    step={step}
                    activeStepEnum={'SUBMIT'}
                    tabIdName="submit"
                    validationErrors={capitalProject.validationErrors}
                />
            </ul>
        </div>
    );
};
