import React, { useState, useReducer } from 'react';

import {
    FormGroup,
    Label,
    Input,
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { budgetRunService, financeService } from 'services';
import { onChangeFunction } from 'shared';

export const CreateBudgetRunModal = ({ isOpen, toggle }) => {
    const history = useHistory();
    const [error, setError] = useState(false);
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            bienniumModalValue: [],
            budgetRunTitle: '',
            copyFrom: [],
        }
    );

    const [bienniums] = useState(financeService.getAllBienniums);

    const loadModalBienniums = () =>
        Promise.resolve(bienniums).then(allBienniums => {
            return allBienniums;
        });

    const loadCopyFrom = async () => {
        const biennium = +userInput.bienniumModalValue.value;
        if (biennium) {
            const singleStatusSearchObject = {
                bienniums: +userInput.bienniumModalValue.value,
                title: null,
                status: null,
                includeAll: true,
            };
            const searchResults = await budgetRunService.searchBudgetRuns(
                singleStatusSearchObject
            );
            const budgetRuns = searchResults.budgetRuns;
            const budgetRunTitleArray = budgetRuns.map(budgetrun => {
                return { value: budgetrun.budgetRunID, label: budgetrun.budgetRunTitle };
            });
            return new Promise(resolve => {
                resolve(budgetRunTitleArray);
            });
        } else {
            return new Promise(resolve => {
                resolve([]);
            });
        }
    };

    const createBudgetRun = () => {
        const postData = {
            Biennium: +userInput.bienniumModalValue.value,
            BudgetRunTitle: userInput.budgetRunTitle,
            CreateFromRunID: +userInput.copyFrom.value,
        };
        if (userInput.budgetRunTitle && userInput.budgetRunTitle !== '') {
            budgetRunService.createNewBudgetRun(postData, history);
            toggle();
        } else {
            setError(true);
        }
    };

    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader close={closeBtn}>Create New Budget Run</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <label>Biennium</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            onChange={(valueObj, options) => {
                                onChangeFunction.handleAsyncSelectChange(
                                    valueObj,
                                    options,
                                    setUserInput
                                );
                            }}
                            name="bienniumModalValue"
                            value={userInput.bienniumModalValue}
                            loadOptions={loadModalBienniums}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Budget Run Title</Label>
                        <Input
                            type="text"
                            placeholder="Budget Run Title"
                            onChange={evt =>
                                onChangeFunction.handleChange(evt, setUserInput)
                            }
                            name="budgetRunTitle"
                            value={userInput.budgetRunTitle}
                            required
                        />
                    </FormGroup>
                    {error ? <p>Budget run title can not be empty</p> : null}
                    <FormGroup>
                        <Label>Copy From</Label>
                        <AsyncSelect
                            key={userInput.bienniumModalValue}
                            cacheOptions
                            defaultOptions
                            onChange={(valueObj, options) =>
                                onChangeFunction.handleAsyncSelectChange(
                                    valueObj,
                                    options,
                                    setUserInput
                                )
                            }
                            name="copyFrom"
                            value={userInput.copyFrom}
                            loadOptions={loadCopyFrom}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={createBudgetRun}>
                    Begin
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
