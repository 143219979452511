import React, { useState } from 'react';
import { DataAuditTable } from './data-audit-table';
import { faCheck, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LineItemService } from 'services';

import { Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { format } from './summary-budget-request-table';
import { Button } from 'components/blocks';

export const SubmitModal = ({
    isOpen,
    toggle,
    lineItemValues,
    biennium,
    mapOfTotals,
    sanitizedInput,
    isDataAudit,
    setIsDataAudit,
    scheduleId,
    fetchBudgetSchedule,
}) => {
    const getBienniumHeaders = biennium => {
        if (biennium) {
            const bMinusZeroBienniumEndYear = biennium.split('-')[0];
            const firstYear = +bMinusZeroBienniumEndYear - 7;
            const bienniumHeaders = [];
            for (let i = 0; i < 9; i += 1) {
                const firstYearString = (firstYear + i).toString();
                const secondYearString = (firstYear + i + 1).toString();
                const secondYearShortened = secondYearString[2] + secondYearString[3];
                const bienniumHeader = firstYearString + '-' + secondYearShortened;
                bienniumHeaders.push(bienniumHeader);
            }
            return bienniumHeaders;
        } else {
            return [];
        }
    };
    const bienniumHeaders = getBienniumHeaders(biennium);
    const closeBtn = (
        <button
            className="close"
            onClick={() => {
                toggle();
                setStep(1);
            }}
        >
            &times;
        </button>
    );
    const valueNameArray = [
        'bminus6Val',
        'bminus5Val',
        'bminus4Val',
        'bminus3Val',
        'bminus2Val',
        'bminus1Val',
        'bminus0Val',
        'bplus1Val',
        'bplus2Val',
    ];
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const onSubmit = async () => {
        setLoading(true);
        try {
            const input = {
                ...sanitizedInput(),
                scheduleStatusId: 2,
            };
            await LineItemService.saveLineItem(scheduleId, input);
            fetchBudgetSchedule();
            toggle();
            setStep(1);
        } catch (error) {}
        setLoading(false);
    };
    const ModalSwitcher = ({ step }) => {
        switch (step) {
            case 1:
                return (
                    <React.Fragment>
                        <div className="alert alert-info">
                            <p className="m-0 text-strong">
                                The data entries listed below differ from pre-populated
                                data. Before submission of schedule can continue please
                                review your edits.
                            </p>
                        </div>
                        <DataAuditTable
                            lineItemValues={lineItemValues}
                            biennium={biennium}
                            isDataAudit={isDataAudit}
                            setIsDataAudit={setIsDataAudit}
                        />
                    </React.Fragment>
                );
            case 2:
                return (
                    <React.Fragment>
                        <h2 className="text-primary mb-3">
                            Review Total Funding Amounts
                        </h2>
                        <Table className="table table-striped table-bordered table-hover line-item-table align-middle">
                            <thead>
                                <tr>
                                    {bienniumHeaders.map((bienniumHeader, index) => {
                                        return (
                                            <th
                                                key={bienniumHeader}
                                                className="text-center"
                                            >
                                                {bienniumHeader}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <DisplayTotalFunding />
                            </tbody>
                        </Table>
                    </React.Fragment>
                );
            default:
                return (
                    <DataAuditTable lineItemValues={lineItemValues} biennium={biennium} />
                );
        }
    };
    const DisplayTotalFunding = () => {
        return (
            <tr>
                {valueNameArray.map((valueName, index) => {
                    const mapOfOneCategory = mapOfTotals.has('Funding')
                        ? mapOfTotals.get('Funding')
                        : null;
                    const valueToAdd =
                        mapOfOneCategory && mapOfOneCategory.has(valueName)
                            ? mapOfOneCategory.get(valueName)
                            : 0;
                    return (
                        <td
                            key={'funding' + valueName}
                            className={
                                index > 6
                                    ? 'text-right text-strong highlight-cell'
                                    : 'text-right text-strong'
                            }
                        >
                            <h3 className="m-0">{format(valueToAdd)}</h3>
                        </td>
                    );
                })}
            </tr>
        );
    };
    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>Before you submit...</ModalHeader>
            <ModalBody className="modal-body wizard-steps">
                {isDataAudit ? (
                    <ModalSwitcher step={step} setStep={setStep} />
                ) : (
                    <React.Fragment>
                        <h2 className="text-primary mb-3">
                            Review Total Funding Amounts
                        </h2>
                        <Table className="table table-striped table-bordered table-hover line-item-table align-middle">
                            <thead>
                                <tr>
                                    {bienniumHeaders.map((bienniumHeader, index) => {
                                        return (
                                            <th
                                                key={bienniumHeader}
                                                className="text-center"
                                            >
                                                {bienniumHeader}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <DisplayTotalFunding />
                            </tbody>
                        </Table>
                    </React.Fragment>
                )}
            </ModalBody>
            <ModalFooter className="wizard-steps-footer">
                {step === 2 && isDataAudit ? (
                    <div
                        style={{
                            float: 'left',
                            width: '40rem',
                        }}
                        className="back-link text-muted d-block mb-2"
                        onClick={() => {
                            setStep(1);
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} /> back
                    </div>
                ) : (
                    <div
                        style={{
                            float: 'left',
                            width: '40rem',
                        }}
                    ></div>
                )}

                {/* <ul
                    className="step-indicators"
                    style={{
                        float: 'left',
                        width: '30rem',
                    }}
                >
                    <li className={step === 1 ? 'active' : ''}>1</li>
                    <li className={step === 2 ? 'active' : ''}>2</li>
                </ul> */}
                <div>
                    {step === 1 && isDataAudit ? (
                        <Button
                            color="secondary"
                            onClick={() => {
                                setStep(2);
                            }}
                        >
                            Continue <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    ) : (
                        <Button
                            className="btn btn-success btn-lg px-3"
                            onClick={onSubmit}
                            loading={loading}
                        >
                            <FontAwesomeIcon icon={faCheck} /> Submit
                        </Button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};
