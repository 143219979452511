import React from 'react';
import * as ReactStrap from 'reactstrap';

import Spinner from './Spinner';

export const Button = props => {
    const { disabled, loading, children, className, ...rest } = props;
    return (
        <ReactStrap.Button
            {...rest}
            disabled={disabled || loading}
            style={{ overflow: 'hidden' }}
            className={`${className} ${loading ? 'btn-loading' : ''}`}
        >
            {loading && <Spinner fill color={rest.outline ? rest.color : ''} />}
            <span style={{ opacity: loading ? 0 : 1 }}>{children}</span>
        </ReactStrap.Button>
    );
};

export default Button;
