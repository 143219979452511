export const UrlEnum = {
    CAPITALPROJECTSEARCH: '/finance/capital-project-search',
    CAPITALPROJECTREQUEST: '/finance/capital-project-request',
    PROJECTREVIEWSEARCH: '/finance/project-review-search',
    PROJECTREVIEW: '/finance/project-review',
    LINEITEMSEARCH: '/finance/budget-schedules',
    LINEITEMBUDGET: '/finance/line-item-budget',
    BUDGETRUNSEARCH: '/finance/budget-runs-search',
    PERFORMANCESCHEDULES: '/finance/performance-schedules',
    LINEITEMPERFORMANCE: '/finance/line-item-performance',
    BUDGETRUN: '/finance/budget-run',
    DEBTSERVICE: '/finance/debt-service-outstanding-debt',
    RECORD_LINKING: '/record-linking',
    LINK_REVIEW: '/record-linking/link-review',
    SEARCH_ACADEMIC_AFFAIRS_PROGRAMS: '/academic-affairs/search-programs',
    ACADEMIC_AFFAIRS_PROGRAMS: '/academic-affairs/edit-program',
    ACADEMIC_AFFAIRS_CREDENTIAL: '/academic-affairs/edit-credential',
    ACADEMIC_AFFAIRS_SYSTEM_VIEW: '/che-admin/systems',
    ACADEMIC_AFFAIRS_SYSTEM: '/che-admin/edit-systems',
    ACADEMIC_AFFAIRS_INSTITUTION: '/che-admin/edit-institutions',
    ACADEMIC_AFFAIRS_CAMPUS: '/che-admin/edit-campus',
    ACADEMIC_AFFAIRS_LOCATION: '/che-admin/edit-location',
    ACADEMIC_AFFAIRS_INSTITUTION_VIEW: '/che-admin/institution',
    SEARCH_ACADEMIC_AFFAIRS_INSTITUTIONS: '/che-admin/search-institutions',
    ACADEMIC_AFFAIRS_PROGRAMS_VIEW: '/academic-affairs/program',
    ACADEMIC_AFFAIRS_GENERATE_REPORT: '/academic-affairs/generate-report',
    ACADEMIC_AFFAIRS_REPORT: '/academic-affairs/report',
    DATA_SUBMISSION: '/data-submission',
    PARTITION_TOGGLE: '/che-admin/partition-toggle',
    COLLECTION_MANAGEMENT: '/che-admin/collection-management',
    SET_PASSWORD: '/set-password',
    HELP: '/help',
    MANAGE_USERS: '/user-management/search-users',
    PROFILE: '/profile',
};
