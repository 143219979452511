import React from 'react';
import { WizardFooter } from './wizard-footer';
import { Form } from 'reactstrap';

export const DetailDescription = ({ capitalProject, prevStep, nextStep }) => {
	return (
		<Form>
			<h2 className='text-strong text-primary border-bottom pb-2 mb-4'>
				Detail Description of Project
			</h2>
			<p className='text-italic'>
				Describe the general nature of the project. Include the relationship of
				the project to the long-term capital plan for the institution, any
				historical significance the project might have to the campus,
				alternatives considered by the institution, the impact the project has
				on future capital projects identified by the institution, etc.
				Institutions may include how the project is being financed or other
				financial related items. If there is a change in operational costs to
				the institution, please include how the institution will cover the costs
				(increase) or reallocate savings (decrease). Note if funds have been
				identified to support future R&R needs for the project. Include unique
				building characteristics, design features, construction materials, site
				development factors and other considerations affecting the cost
				estimates.
			</p>
			<p className='text-italic'>
				Provide additional narrative for the funding sources listed. If all
				funds are not secured, outline cash flow for the project. If debt is to
				be issued for the project, what is the current outstanding debt of the
				institution? What is the repayment method for the debt? What is the
				structure of the gift funds? What is the structure of the grant?
			</p>
			<hr />
			<label>Response:</label>
			<p>{capitalProject.detailDescription}</p>

			<WizardFooter
				prevStep={prevStep}
				nextStep={nextStep}
				capitalProject={capitalProject}
			/>
		</Form>
	);
};
