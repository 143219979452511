import React from 'react';

import {
    GeneralInfo,
    Percents,
    Base,
    PffOutput,
    PffPerUnit,
    CapitalProjects,
    LineItems,
    DualCredit,
    RandR,
    Output,
} from './budget-run-tabs';

import { BudgetRunFormEnum } from 'shared';

export const BudgetRunTabSwitch = ({
    budgetRun,
    setBudgetRun,
    step,
    setStep,
    nextStep,
    prevStep,
    running,
    finalizing,
    setRunning,
    setFinalizing,
    fetchData,
}) => {
    switch (step) {
        case BudgetRunFormEnum.GENERALINFO:
            return (
                <GeneralInfo
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></GeneralInfo>
            );
        case BudgetRunFormEnum.PERCENTS:
            return (
                <Percents
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></Percents>
            );
        case BudgetRunFormEnum.BASE:
            return (
                <Base
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></Base>
            );
        case BudgetRunFormEnum.PFFOUTPUT:
            return (
                <PffOutput
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></PffOutput>
            );
        case BudgetRunFormEnum.PFFPERUNIT:
            return (
                <PffPerUnit
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></PffPerUnit>
            );
        case BudgetRunFormEnum.CAPITALPROJECTS:
            return (
                <CapitalProjects
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></CapitalProjects>
            );
        case BudgetRunFormEnum.LINEITEMS:
            return (
                <LineItems
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></LineItems>
            );
        case BudgetRunFormEnum.DUALCREDIT:
            return (
                <DualCredit
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></DualCredit>
            );
        case BudgetRunFormEnum.RANDR:
            return (
                <RandR
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></RandR>
            );
        case BudgetRunFormEnum.OUTPUT:
            return (
                <Output
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                    running={running}
                    finalizing={finalizing}
                    setRunning={setRunning}
                    setFinalizing={setFinalizing}
                    fetchData={fetchData}
                ></Output>
            );
        default: {
            return (
                <GeneralInfo
                    nextStep={nextStep}
                    prevStep={prevStep}
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                ></GeneralInfo>
            );
        }
    }
};
