import React, { useReducer } from 'react';
import { WizardFooter } from './wizard-footer';
import { Form, Input } from 'reactstrap';
import { ValidationErrorBox } from './validation-error-box';
import { FormEnum } from 'shared';

export const NeedAndPurpose = ({ capitalProject, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 4,
            needAndPurpose: capitalProject.needAndPurpose
                ? capitalProject.needAndPurpose
                : '',
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };
    const sanitizeCapitalProject = () => userInput;
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Need and Purpose of the Project
            </h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.NEEDSANDPURPOSE}
            />
            <p className="text-italic">
                Describe how the project relates to the mission and long-term strategic
                plan of the institution. Include the impact of the project on the academic
                and educational attainment of students, programmatic changes that the
                project would have to the campus, how the project might improve the
                education quality of students at the campus, etc. Identify the impact if
                the project is not approved or recommended by the state. Describe, if
                necessary, any health and safety issues that result in the need for the
                project. Describe, if applicable, any financial impact the project might
                have regarding the cost of attendance to students.
            </p>
            <Input
                type="textarea"
                rows="15"
                placeholder="Need & Purpose"
                onChange={handleChange}
                name="needAndPurpose"
                value={userInput.needAndPurpose}
            />
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProject={capitalProject}
            />
        </Form>
    );
};
