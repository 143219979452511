import React, { useState, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { useOnClickOutside } from 'shared';

export const PopoverWrapper = ({ id, children, render, className, ...rest }) => {
    const ref = useRef();
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    useOnClickOutside(ref, () => {
        if (open) setOpen(false);
    });
    return (
        <>
            <span id={id} onClick={toggle} className={className}>
                {children}
            </span>
            <Popover
                placement="bottom"
                isOpen={open}
                target={id}
                toggle={toggle}
                {...rest}
            >
                <div ref={ref}>
                    <PopoverBody>{render}</PopoverBody>
                </div>
            </Popover>
        </>
    );
};

export default PopoverWrapper;
