import React, { useState, useReducer } from 'react';

import {
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { financeService } from 'services';
import { onChangeFunction } from 'shared';

export const LineItemsBulkUpdateModal = ({
    isOpen,
    toggle,
    lineItems,
    setLineItems,
    bienniumYearOne,
    bienniumYearTwo,
    isGeneralFundDisplayed,
}) => {
    const allValue = { value: 'all', label: 'All' };
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            institutionModalValue: [allValue],
            budgetYearOne: true,
            budgetYearTwo: true,
            NewLineItemYes: true,
            NewLineItemNo: true,
            percentValueChecked: true,
            percentValue: 0,
        }
    );

    const handleCheckButtonChange = name => {
        const newValue = !userInput[name];
        setUserInput({ [name]: newValue });
    };

    const handleRadioButtonChange = evt => {
        setUserInput({ percentValueChecked: evt });
    };

    const [institutions] = useState(financeService.getAllInstitutions);

    const loadModalInstitutions = () =>
        Promise.resolve(institutions).then(allInstitutions => {
            if (!allInstitutions.includes(allValue)) {
                allInstitutions.unshift(allValue);
            }
            return allInstitutions;
        });

    const extractLabelsFromResponse = unextractedResponseValues => {
        return unextractedResponseValues.reduce((acc, currentValue) => {
            return acc.concat(currentValue.label);
        }, []);
    };

    const applyBulkUpdate = () => {
        const institutionsSelected = userInput.institutionModalValue
            ? extractLabelsFromResponse(userInput.institutionModalValue)
            : [];
        const hasAllValue = institutionsSelected.includes(allValue.label);
        lineItems.map(lineItem => {
            if (
                (hasAllValue || institutionsSelected.includes(lineItem.displayName)) &&
                (userInput.NewLineItemYes === lineItem.isNewLineItem ||
                    userInput.NewLineItemNo === !lineItem.isNewLineItem)
            ) {
                if (userInput.percentValueChecked) {
                    //Maximum percent increase from previous Biennium
                    if (userInput.budgetYearOne) {
                        if (isGeneralFundDisplayed) {
                            const maxFunding =
                                lineItem.baseFunding * (1 - userInput.percentValue / 100);
                            console.log(lineItem);
                            console.log(maxFunding);
                            console.log(lineItem.bY1RequestedStateFunding);
                            if (maxFunding < lineItem.bY1RequestedStateFunding) {
                                console.log('got here');
                                lineItem.bY1ReccomendedStateFunding = maxFunding;
                                setLineItems({ lineItems: [...lineItems] });
                            }
                        } else {
                            const maxFunding =
                                lineItem.baseFunding * (1 - userInput.percentValue / 100);
                            if (maxFunding < lineItem.dedicatedBY1RequestedStateFunding) {
                                lineItem.dedicatedBY1ReccomendedStateFunding = maxFunding;
                                setLineItems({ lineItems: [...lineItems] });
                            }
                        }
                    }
                    if (userInput.budgetYearTwo) {
                        if (isGeneralFundDisplayed) {
                            const maxFunding =
                                lineItem.baseFunding * (1 - userInput.percentValue / 100);
                            if (maxFunding < lineItem.bY2RequestedStateFunding) {
                                lineItem.bY2ReccomnededStateFunding = maxFunding;
                                setLineItems({ lineItems: [...lineItems] });
                            }
                        } else {
                            //dedicatedBY2ReccomnededStateFunding is spelled wrong hopefully this changes in API
                            const maxFunding =
                                lineItem.baseFunding * (1 - userInput.percentValue / 100);
                            if (maxFunding < lineItem.dedicatedBY2RequestedStateFunding) {
                                lineItem.dedicatedBY2ReccomnededStateFunding = maxFunding;
                                setLineItems({ lineItems: [...lineItems] });
                            }
                        }
                    }
                } else {
                    //% of requested funding to recommend
                    if (userInput.budgetYearOne) {
                        if (isGeneralFundDisplayed) {
                            lineItem.bY1ReccomendedStateFunding =
                                lineItem.bY1RequestedStateFunding *
                                (userInput.percentValue / 100);
                            setLineItems({ lineItems: [...lineItems] });
                        } else {
                            lineItem.dedicatedBY1ReccomendedStateFunding =
                                lineItem.dedicatedBY1RequestedStateFunding *
                                (userInput.percentValue / 100);
                            setLineItems({ lineItems: [...lineItems] });
                        }
                    }
                    if (userInput.budgetYearTwo) {
                        if (isGeneralFundDisplayed) {
                            lineItem.bY2ReccomnededStateFunding =
                                lineItem.bY2RequestedStateFunding *
                                (userInput.percentValue / 100);
                            setLineItems({ lineItems: [...lineItems] });
                        } else {
                            //dedicatedBY2ReccomnededStateFunding is spelled wrong hopefully this changes in API
                            lineItem.dedicatedBY2ReccomnededStateFunding =
                                lineItem.dedicatedBY2RequestedStateFunding *
                                (userInput.percentValue / 100);
                            setLineItems({ lineItems: [...lineItems] });
                        }
                    }
                }
            }
            return null;
        });

        toggle();
    };

    const closeBtn = (
        <button className="close" onClick={toggle}>
            &times;
        </button>
    );

    return (
        <Modal isOpen={isOpen} className="modal-dialog modal-lg">
            <ModalHeader close={closeBtn}>Bulk Udpate</ModalHeader>
            <ModalBody>
                <h3 className="text-strong text-primary mb-3">
                    Select criteria that will determine which projects are affected by
                    bulk update
                </h3>

                <FormGroup>
                    <Label>Select Institution(s)</Label>
                    <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        onChange={(valueObj, options) =>
                            onChangeFunction.handleAsyncSelectChange(
                                valueObj,
                                options,
                                setUserInput
                            )
                        }
                        name="institutionModalValue"
                        value={userInput.institutionModalValue}
                        loadOptions={loadModalInstitutions}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </FormGroup>
                <div className="row align-items-end">
                    <div className="col-md mb-3">
                        <label className="d-block">Budget Year</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value="option1"
                                checked={userInput.budgetYearOne}
                                onChange={() => {
                                    handleCheckButtonChange('budgetYearOne');
                                }}
                            />
                            <label className="form-check-label">{bienniumYearOne}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox2"
                                value="option2"
                                checked={userInput.budgetYearTwo}
                                onChange={() => {
                                    handleCheckButtonChange('budgetYearTwo');
                                }}
                            />
                            <label className="form-check-label">{bienniumYearTwo}</label>
                        </div>
                    </div>

                    <div className="col-md mb-3">
                        <label className="d-block">New Line Item</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckboxyes"
                                value="yes"
                                checked={userInput.NewLineItemYes}
                                onChange={() => {
                                    handleCheckButtonChange('NewLineItemYes');
                                }}
                            />
                            <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckboxno"
                                value="optionno"
                                checked={userInput.NewLineItemNo}
                                onChange={() => {
                                    handleCheckButtonChange('NewLineItemNo');
                                }}
                            />
                            <label className="form-check-label">No</label>
                        </div>
                    </div>
                </div>
                <hr />
                <h3 className="text-strong text-primary mb-3">
                    Select bulk updates to make
                </h3>

                <div className="col-md mb-3">
                    <label className="d-block">New Line Item</label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckboxyes"
                            value="yes"
                            checked={userInput.percentValueChecked}
                            onChange={() => {
                                handleRadioButtonChange(true);
                            }}
                        />
                        <label className="form-check-label">
                            Maximum % increase from previous biennium
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckboxno"
                            value="optionno"
                            checked={!userInput.percentValueChecked}
                            onChange={() => {
                                handleRadioButtonChange(false);
                            }}
                        />
                        <label className="form-check-label">
                            % of requested funding to recommend
                        </label>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md mb-3">
                        <div className="input-group">
                            <input
                                type="number"
                                name="percentValue"
                                className="form-control"
                                value={userInput.percentValue}
                                onChange={evt =>
                                    onChangeFunction.handleChange(evt, setUserInput)
                                }
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                        {userInput.percentValueChecked ? (
                            <p className="m-0 mt-2">Enter value from -100 to 9999</p>
                        ) : (
                            <p className="m-0 mt-2">Enter value from 0 to 100</p>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={applyBulkUpdate}>
                    Apply
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};
