import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'components/blocks';

export const Alert = props => {
    const { open, close, onDelete } = props;
    const [loading, setLoading] = useState(false);
    const handleDelete = async () => {
        setLoading(true);
        await onDelete();
        setLoading(false);
    };
    return (
        <div>
            <Modal isOpen={open} toggle={close}>
                <ModalHeader toggle={close}>Deactivate User?</ModalHeader>
                <ModalBody>
                    Are you sure you want to deactivate this user's account? They will no
                    longer be able to sign into the CHEDSS' system.
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={close}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={handleDelete} loading={loading}>
                        Deactivate
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
