import React, { useState } from 'react';

import { Row, Col } from 'reactstrap';
import { SearchCapitalProjectsForm, CapitalProjectsSearchTable } from 'components';

export const CapitalProjectSearchPage = props => {
    const [capitalProjects, setCapitalProjects] = useState([]);
    return (
        <Row>
            <Col className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                <SearchCapitalProjectsForm
                    capitalProjects={capitalProjects}
                    setCapitalProjects={setCapitalProjects}
                ></SearchCapitalProjectsForm>
            </Col>
            <Col className="col-xl-9 col-lg-12">
                <CapitalProjectsSearchTable
                    capitalProjects={capitalProjects}
                ></CapitalProjectsSearchTable>
            </Col>
        </Row>
    );
};
