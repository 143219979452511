import React, { useState, useEffect } from 'react';
import { Input, Button } from 'reactstrap';
import { academicInstitutionsService } from 'services';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faCheck, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import { UrlEnum, usePermissions } from 'shared';
import { AcademicAffairAddCampusModal } from './academic-affair-add-campus-modal';
import { Confirm } from 'components/blocks';
import {validateCheOpeId, validateCheIpedsId } from 'services/admin-services/academic-institutions.service';
import { Institution } from '../academic-affair-report/components';
import { AcademicAffairAddLocationModal } from './academic-affair-add-location-modal';


const rowStyles = {
    container: provided => ({
        ...provided,
        width: 120,
    }),
};

export const AcademicAffairCampus = ({
    academicCampusData,
    setAcademicCampusData,
    locations,
    setLocations,
}) => {


    const [locationsToAdd, setLocationsToAdd] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [locationsToRemove, setLocationsToRemove] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [invalidIpedsId, setInvalidIpedsId] = useState(false);
    const [invalidOpeId, setInvalidOpeId] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const history = useHistory();
    const getOptionValue = d => d.value;

    const tableMaxHeightStyle = { maxHeight: '400px', overflowY: 'scroll' };
    


    const addNewLocation = location => {
        var newLocation = {
            LocationUnitId: location.LocationunitId,
            LocationName: location.LocationName, 
            CampusUnitId: cheCampusUnitId,
            SystemUnitId: cheSystemUnitId,
            InstitutionUnitId: cheInstitutionUnitId,
            StatusId: 1,
            ShortName: location.ShortName,
        }
        console.log(newLocation)
        locationsToAdd.push(newLocation);
    };

    const handleCampusDetailsChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setAcademicCampusData(prev => ({
            ...prev,
            [name]: newValue,
            
            
        }));
    };

    const RemoveFromAddList = id => {
        const newList = locationsToAdd.filter((x) => x.LocationUnitId !== id);

        setLocationsToAdd(newList);
    }

    
    const {
        cheInstitutionUnitId,
        cheCampusUnitId,
        cheSystemUnitId,
        cheCampusName,
        CampusShortName,
        cheCampusAddress,
        cheCampusZipCode,
        cheCampusCity,
        ipedsCampusUnitId,
        opeCampusId,
    } = academicCampusData

    

    const parentIds = [cheInstitutionUnitId, cheCampusUnitId, cheSystemUnitId];

    const toggleRemoval = id => {
        const alreadyRemoved = locationsToRemove.find(x => x == id);

        if(alreadyRemoved != null) { // needs to be readded to the campus
            const newList = locationsToRemove.filter((x) => x !== id);

            setLocationsToRemove(newList);
        } else { // needs to be removed from institution
            locationsToRemove.push(id);
            setLocationsToRemove(locationsToRemove);
        }

        setRerender(!rerender);
    }



    const renderApprovedLocationRows = () => {
        let rows = [];
        
        if(locationsToAdd != null) {
            locationsToAdd.map(location => {
                rows.push(
                    <tr key={location.LocationUnitId}
                        onClick={() => RemoveFromAddList(location.LocationUnitId)}>
    
                        <td>{location.LocationName}</td>
                        <td className="table-actions">
                            <div className="text-success">Pending Addition</div>
                        </td>
                    </tr>
                );
            });
        }
        


        if(locations != null) {
            locations.map(location => {
                rows.push(
                    <tr key={location.cheLocationUnitId}>
                        <td>{location.cheLocationName}</td>

                        <td className="table-actions">
                                <div className="text-danger">{checkForRemoval(location.cheLocationUnitId) ? "Pending Unit Deactivation" : ""}</div>
                    
                            <Button
                                className="action-circle"
                                onClick={() => {
                                    const locationId = location.cheLocationUnitId;

                                    const locationPage = {
                                        pathname: UrlEnum.ACADEMIC_AFFAIRS_LOCATION,
                                        state: [{ locationId: locationId }],
                                    };
                                    history.push(locationPage);
                                }}
                                >
                                <FontAwesomeIcon id={location.cheLocationUnitId + '-edit'} icon={faEdit} />
                            </Button>
                            {!parentIds.some(x => x == location.cheLocationUnitId) && (
                                <Button
                                    className="action-circle"
                                    onClick={() => {
                                        toggleRemoval(location.cheLocationUnitId)
                                    }}
                                    >
                                    <FontAwesomeIcon id={location.cheLocationUnitId + '-delete'} icon={faTrash} />
                                </Button>
                            )}     
                        </td>
                    </tr>
                );
            });
        }
        return rows;
    };


    const checkForRemoval = id => {
        const check = locationsToRemove.find(x => x == id);
        return check != null;
    }

    const checkShortName = shortName => {
        return (shortName != null && shortName != "" && shortName != cheCampusName)
    }

  
    

    const submitForm = async () => {
        setConfirm(false);

        const validIpedsId = await validateCheIpedsId(ipedsCampusUnitId)
        const validOpeId = await validateCheOpeId(opeCampusId)

        if (!validIpedsId) {
            setInvalidIpedsId(true);
            return
        }

        if (!validOpeId) {
            setInvalidOpeId(true);
            return
        }


        const submitData = {
            LocationsToAdd: locationsToAdd,
            LocationsToRemove: locationsToRemove,
            InstitutionUnitId: cheInstitutionUnitId,
            CampusUnitId: cheCampusUnitId,
            SystemUnitId: cheSystemUnitId,
            CampusName: cheCampusName !== null && cheCampusName !== undefined ? cheCampusName : "",
            CampusShortName: CampusShortName !== null && CampusShortName !== undefined ? CampusShortName : "",
            CampusAddress: cheCampusAddress !== null && cheCampusAddress !== undefined  ? cheCampusAddress : "",
            CampusZipCode: cheCampusZipCode !== null && cheCampusZipCode !== undefined  ? cheCampusZipCode : "",
            CampusCity: cheCampusCity !== null && cheCampusCity !== undefined  ? cheCampusCity : "",
            IPEDSId: ipedsCampusUnitId,
            OPECampusId: opeCampusId,
        };
        await academicInstitutionsService.editAcademicCampus(submitData, cheCampusUnitId);
        setLocationsToAdd([]);
    };
    return (
        <React.Fragment>
            <div className="sticky-footer">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.ACADEMIC_AFFAIRS_INSTITUTION}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back
                        </a>
                    </div>
                    <div className="row sticky-bar-spacer">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <h2 className="text-strong text-primary">
                                    {cheCampusName} {(checkShortName(CampusShortName)) ? "("+CampusShortName+")" : ""}
                                </h2>
                                <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                                    <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                                        <li className="mr-3">
                                            <label className="m-0">Campus ID:</label>{' '}
                                            {cheCampusUnitId} {rerender ? "" : ""}
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="card p-3">
                                <div className="row mb-4">
                                    <div className="col-md-8 mb-3 mb-md-0">
                                        <label>
                                            Campus Name{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campus Name"
                                            value={cheCampusName}
                                            onChange={handleCampusDetailsChange}
                                            name="cheCampusName"
                                        />
                                        
                                    </div>
                                    <div className="col-md-4 mb-3 mb-md-0">
                                        <label>
                                            Campus Short Name{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campus Short Name"
                                            value={CampusShortName}
                                            onChange={handleCampusDetailsChange}
                                            name="CampusShortName"
                                        />
                                        
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Address{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campus Address"
                                            value={cheCampusAddress}
                                            onChange={handleCampusDetailsChange}
                                            name="cheCampusAddress"
                                        />
                                    </div>
                                    
                                </div>

                                <div className="row mb-4">
                                    
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            City{' '}
                                            
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campus City"
                                            value={cheCampusCity}
                                            onChange={handleCampusDetailsChange}
                                            name="cheCampusCity"
                                        />
                                    </div>

                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Zip Code{' '}
                                            
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campus Zip"
                                            value={cheCampusZipCode}
                                            onChange={handleCampusDetailsChange}
                                            name="cheCampusZipCode"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            IPEDS Id{' '}
                                            
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campus IPEDS"
                                            value={ipedsCampusUnitId}
                                            onChange={handleCampusDetailsChange}
                                            name="ipedsCampusUnitId"
                                        />
                                        {invalidIpedsId && <p className="text-danger">
                                            IPEDS Id does not exist
                                        </p>}
                                    </div>

                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            OPE Id{' '}
                                            
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Campus Ope Id"
                                            value={opeCampusId}
                                            onChange={handleCampusDetailsChange}
                                            name="opeCampusId"
                                        />
                                        {invalidOpeId && <p className="text-danger">
                                            OPE Id does not exist
                                        </p>}
                                    </div>
                                </div>

                                <hr />
                                <div className="row gutter-60">
                                    <div className="col-md-7">
                                        <div className="d-md-flex align-items-center justify-content-between text-md-left text-center mb-3">
                                            <h3 className="text-primary mb-3 mb-md-0">
                                                Approved Locations
                                            </h3>
                                            <button
                                                type="button"
                                                onClick={() => setIsModalOpen(true)}
                                                className="btn btn-sm btn-primary"
                                            >
                                                <i className="far fa-plus"></i> Add
                                                Location
                                            </button>
                                        </div>
                                        <div className="input-group input-group-btn input-group-sm mb-3 d-none">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="search approved campuss..."
                                            />
                                            <div className="input-group-append white hide">
                                                <span className="input-group-text px-3">
                                                    <div className="body-text-link text-primary">
                                                        <i className="fal fa-times"></i>{' '}
                                                        clear
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="button"
                                                >
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Location</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderApprovedLocationRows()}
                                                    {/*                                                 
                                            <tr>
                                                <td>
                                                    Ivy Tech Community College-Terre
                                                    Haute
                                                </td>
                                                <td className="table-actions">
                                                    <a
                                                        href="#"
                                                        className="text-danger"
                                                    >
                                                        Remove
                                                    </a>
                                                </td>
                                            </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {(
                        <div className="sticky-bottom-bar">
                            <div className="d-flex align-items-center justify-content-end">
                                <a
                                    className="btn btn-outline-primary btn-lg mr-2"
                                    href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}
                                >
                                    Cancel
                                </a>
                                <Button
                                    type="button"
                                    className="btn btn-success btn-lg px-3"
                                    onClick={() => {
                                        setConfirm(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} /> Save Changes
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <AcademicAffairAddLocationModal
                isOpen={isModalOpen}
                toggle={toggle}
                addNewLocation={addNewLocation}
            />
            
            <Confirm
                isOpen={confirm}
                toggle={() => setConfirm(false)}
                onConfirm={submitForm}
                title="Confirm Changes"
                message="Are you sure you want to submit these changes?"
            />
        </React.Fragment>
    );
};
