import React, { useState } from 'react';

import { Row, Col } from 'reactstrap';
import {
	SearchCapitalProjectReviewForm,
	SearchCapitalProjectsReviewTable,
} from 'components';

export const ProjectReviewSearchPage = (props) => {
	const [capitalProjects, setCapitalProjects] = useState([]);
	return (
		<Row>
			<Col className='col-xl-3 col-lg-12 mb-3 mb-lg-0'>
				<SearchCapitalProjectReviewForm
					capitalProjects={capitalProjects}
					setCapitalProjects={setCapitalProjects}
				/>
			</Col>
			<Col className='col-xl-9 col-lg-12'>
				<SearchCapitalProjectsReviewTable
					capitalProjects={capitalProjects}></SearchCapitalProjectsReviewTable>
			</Col>
		</Row>
	);
};
