import React, { useState, useEffect, useReducer, useCallback, useMemo } from 'react';
import { FormGroup, Input, Button, Card, InputGroup, Label } from 'reactstrap';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { generateReportService } from 'services';
import { academicInstitutionsService } from '../../services';
import { onChangeFunction, usePermissions } from 'shared';
import { FormSelect } from '../blocks';
import { InstitutionAddSystemModal } from 'components/che-admin/components';

const initialValues = {
    systems: [],
    types: [],
};

export const getOptionLabel = d => d.value;

const convertToValue = d => ({ ...d, value: d.name });

export const SearchAcademicInstitutionsForm = ({ setAcademicSystemsData }) => {
    const submitPermission = usePermissions({
        permissions: ['submit-new-institution'],
    });
    const [fetchingInstitutions, setFetchingInstitutions] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const reducer = (state, newState) => ({ ...state, ...newState });

    const [userInput, setUserInput] = useReducer(reducer, initialValues);

    const [options, setOptions] = useReducer(reducer, []);

    const typeOptions = [
        {label: "System", value: "System"},
        {label: "Institution", value: "Institution"},
        {label: "Campus", value: "Campus"},
        {label: "Location", value: "Location"},
    ]

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await generateReportService.getSystems();
                
                setOptions({
                    
                    systems: res.systems.map(convertToValue),
                    types: typeOptions,
                });
            } catch (error) {}
        };
        fetchOptions();
    }, []);


    const extractValuesFromResponseCSV = unextractedResponseValues => {
        return unextractedResponseValues
            .map(currentValue => {
                return currentValue.value
            })
            .join(',');
    }

    const submitForm = () => {
        const searchPostData = JSON.parse(
            JSON.stringify({
                pendingReview: false,
                
                systems:
                    userInput.systems && userInput.systems.length > 0
                        ? extractValuesFromResponseCSV(userInput.systems)
                        : undefined,
                filterTypes:
                    userInput.types && userInput.types.length > 0
                        ? extractValuesFromResponseCSV(userInput.types)
                        : undefined,
               
            })
        );

        console.log(searchPostData)
        Promise.resolve(
           academicInstitutionsService.searchAcademicUnits(searchPostData)

        ).then(postRes => {
            setAcademicSystemsData(postRes.results || []);
            console.log(postRes)
            return postRes;
        });
    };

    return (
        <React.Fragment>
            {/*submitPermission*/ true && (
                <Button
                    className="btn btn-success btn-block new-btn mb-3"
                    onClick={() => setIsModalOpen(true)}
                >
                    <FontAwesomeIcon icon={faPlus} /> New System
                </Button>
            )}
            <Card className="card mb-3 p-3">
                <h4 className="text-muted mb-4">Search Academic System Inventory</h4>
                
                <FormSelect
                    label="Systems"
                    onChange={setUserInput}
                    options={options.systems ? options.systems.map(x => {return {label: x.value, value: x.id}}): []}
                    placeholder="All"
                    name="systems"
                    value={userInput.systems}
                    //isDisabled={userInput.institutionTypes.length === 0}
                    isMulti
                />
                <FormSelect
                    label="Types"
                    onChange={setUserInput}
                    options={options.types ? options.types : []}
                    placeholder="All"
                    name="types"
                    value={userInput.types}
                    //isDisabled={userInput.institutionTypes.length === 0}
                    isMulti
                />
                <button
                    className="btn btn-block btn-primary"
                    color="success"
                    onClick={() => {
                        submitForm();
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} />
                    &nbsp;Search
                </button>
            </Card>

            <InstitutionAddSystemModal 
                isOpen={isModalOpen}
                toggle={toggle}
            >
            </InstitutionAddSystemModal>
        </React.Fragment>
    );
};
