import React from 'react';

import { Table } from 'reactstrap';

export const DataAuditTable = ({ lineItemValues, biennium, setIsDataAudit }) => {
    return (
        <Table
            className="table-striped table-bordered table-hover align-middle data-table"
            responsive
        >
            <thead>
                <tr>
                    <th className="min-tablet-l nowrap">Data Item</th>
                    <th className="min-tablet-l text-right">Prepopulated Value</th>
                    <th className="min-tablet-l text-right">Submitted Value</th>
                    <th className="min-tablet-l text-center">Reason for Change</th>
                </tr>
            </thead>
            <tbody>
                {[...lineItemValues.keys()].map(key => {
                    const row = lineItemValues.get(key);
                    // eslint-disable-next-line array-callback-return
                    return Object.entries(row).map(valueNameArr => {
                        const valueName = valueNameArr[0];
                        const value = valueNameArr[1];
                        if (valueName === 'bminus0Val' || valueName === 'bminus1Val') {
                            const dataAuditName =
                                valueName === 'bminus0Val'
                                    ? 'bminus0DataAudit'
                                    : 'bminus1DataAudit';
                            const prepopName =
                                valueName === 'bminus0Val'
                                    ? 'bminus0Val_PrePop'
                                    : 'bminus1Val_PrePop';

                            if (value !== row[prepopName]) {
                                setIsDataAudit(true);
                                const lastYear = +biennium.split('-')[0];
                                const year =
                                    valueName === 'bminus0Val'
                                        ? `${lastYear - 1} - ${lastYear}`
                                        : `${lastYear - 2} - ${lastYear - 1}`;
                                const dataItem = `${year} ${row.lineItemCategoryName} ${row.lineItemName}`;
                                return (
                                    <tr key={dataItem}>
                                        <td>{dataItem}</td>
                                        <td className="text-right">
                                            ${row[prepopName] ?? 0}
                                        </td>
                                        <td className="text-right">${row[valueName]}</td>
                                        <td>{row[dataAuditName]}</td>
                                    </tr>
                                );
                            }
                        }
                    });
                })}
            </tbody>
        </Table>
    );
};
