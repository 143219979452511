import React, { useRef, ErrorBoundary } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronCircleLeft,
    faChevronCircleRight,
    faHome,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { UrlEnum, useOnScreen } from 'shared';
import { useSelector, useDispatch } from 'react-redux';

const StyledLink = props => {
    const { children, to, pathname } = props;
    const active = pathname === to;
    return (
        <Link {...props} className={active ? 'active' : ''}>
            {children}
        </Link>
    );
};

export const SideNav = props => {
    const ref = useRef();
    const open = useSelector(state => state.menuOpen);
    const expanded = useSelector(state => state.menuExpanded);
    const onScreen = useOnScreen(ref, { threshold: 1 });
    const style = open ? { display: 'block' } : {};
    return (
        <div
            id="site-menu"
            className={props.impersonating ? 'site-menu-impersonation' : ''}
            style={
                onScreen
                    ? style
                    : { overflowY: expanded ? 'scroll' : 'visible', ...style }
            }
        >
            <div to="/" className="site-logo">
                <img
                    // className='site-logo'
                    src="/CHE_Logo.png"
                    alt="Indiana Commission for Higher Education Logo"
                />
            </div>
            <div ref={ref}>
                <NavLinks admin={props.admin} dynamicViews={props.dynamicViews} />
            </div>
        </div>
    );
};

const NavLinks = props => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { admin, dynamicViews } = props;
    return (
        <nav id="site-nav">
            <div
                className="mobile-menu-header"
                onClick={() => dispatch({ type: 'TOGGLE_MENU' })}
            >
                <h3 className="m-0">Menu</h3>
                <FontAwesomeIcon icon={faTimes} color="#FFF" size="lg" />
            </div>
            <ul>
                <li>
                    <Link to="/" className={pathname === '/' ? 'active' : ''}>
                        <FontAwesomeIcon icon={faHome} /> Home
                    </Link>
                </li>
                {dynamicViews.includes('Manage Users') && (
                    <li>
                        <StyledLink to={UrlEnum.MANAGE_USERS} pathname={pathname}>
                            Manage Users
                        </StyledLink>
                    </li>
                )}
                {/*dynamicViews.includes('Data Submission') && (
                    <li>
                        <StyledLink to={UrlEnum.DATA_SUBMISSION} pathname={pathname}>
                            Data Submission
                        </StyledLink>
                    </li>
                )*/}
                {dynamicViews.includes('Data Submission') && (
                    <li>
                        <a
                            href="/#"
                            className={`pointer-events-none ${
                                pathname.includes('/data-submission') ||
                                pathname.includes('/record-linking')
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            Data Submission{' '}
                            <span className="sub-menu-arrow">
                                <FontAwesomeIcon icon={faChevronCircleRight} />
                            </span>
                        </a>
                        <ul className="sub-menu">
                            {dynamicViews.includes('Data Submission') && (
                                <li>
                                    <a href={UrlEnum.DATA_SUBMISSION}>
                                        Search & Transmit
                                    </a>
                                </li>
                            )}
                            {dynamicViews.includes('Record Linking') && (
                                <li>
                                    <a href="/record-linking">Record Linking</a>
                                </li>
                            )}
                        </ul>
                    </li>
                )}
                {/* <li className="nav-link-disabled">
                    <StyledLink to="/data-retrieval" pathname={pathname}>
                        Data Retrieval
                    </StyledLink>
                </li> */}
                {/* <li className="nav-link-disabled">
                    <StyledLink to="/reports" pathname={pathname}>
                        Reports
                    </StyledLink>
                </li> */}
                <HoverControlledDropdown
                    dynamicViews={dynamicViews}
                    pathname={pathname}
                />
                {/* <li className="nav-link-disabled">
                    <StyledLink to="/tools" pathname={pathname}>
                        Tools
                    </StyledLink>
                </li>
                <li className="nav-link-disabled">
                    <StyledLink to="/security" pathname={pathname}>
                        Security
                    </StyledLink>
                </li> */}
                {admin && <CheAdminDropdown pathname={pathname} />}
                <li>
                    <StyledLink to="/help" pathname={pathname}>
                        Help
                    </StyledLink>
                </li>
            </ul>
        </nav>
    );
};

const HoverControlledDropdown = props => {
    //const [isOpen, updateIsOpen] = useState(false);
    const { dynamicViews, pathname } = props;
    return (
        <React.Fragment>
            {dynamicViews.includes('Academic Affairs') && (
                <li>
                    <a
                        href="/#"
                        className={`pointer-events-none ${
                            pathname.includes('academic-affairs/') ? 'active' : ''
                        }`}
                    >
                        Academic Affairs{' '}
                        <span className="sub-menu-arrow">
                            <FontAwesomeIcon icon={faChevronCircleRight} />
                        </span>
                    </a>
                    <ul className="sub-menu">
                        {dynamicViews.includes('Academic Affairs/Programs') && (
                            <li>
                                <a href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}>
                                    Programs
                                </a>
                            </li>
                        )}
                        <li>
                            <a href={UrlEnum.ACADEMIC_AFFAIRS_GENERATE_REPORT}>
                                Generate Reports
                            </a>
                        </li>
                    </ul>
                </li>
            )}
            {/* {dynamicViews.includes('Finance') && (
                <li>
                    <a
                        href="/#"
                        className={`pointer-events-none ${
                            pathname.includes('finance/') ? 'active' : ''
                        }`}
                    >
                        Finance{' '}
                        <span className="sub-menu-arrow">
                            <FontAwesomeIcon icon={faChevronCircleRight} />
                        </span>
                    </a>
                    <ul className="sub-menu">
                        {dynamicViews.includes('Finance/Capital Projects') && (
                            <li>
                                <a href={UrlEnum.CAPITALPROJECTSEARCH}>
                                    Capital Projects
                                </a>
                            </li>
                        )}
                        <li>
                            <a href={UrlEnum.PROJECTREVIEWSEARCH}>Project Reviews</a>
                        </li>
                        {dynamicViews.includes('Finance/Budget Schedules') && (
                            <React.Fragment>
                                <li>
                                    <a href={UrlEnum.LINEITEMSEARCH}>BRS XI Schedules</a>
                                </li>
                                <li>
                                    <a href="/finance/budget-schedules">
                                        Budget Schedules
                                    </a>
                                </li>
                                <li>
                                    <a href="/finance/performance-schedules">
                                        Performance Schedules
                                    </a>
                                </li>
                            </React.Fragment>
                        )}
                        {dynamicViews.includes('Finance/Budget Runs') && (
                            <li>
                                <a href={UrlEnum.BUDGETRUNSEARCH}>Budget Runs</a>
                            </li>
                        )}
                        <li>
                            <a href={UrlEnum.DEBTSERVICE}>
                                Debt Service & Outstanding Debt
                            </a>
                        </li>
                    </ul>
                </li>
            )} */}
        </React.Fragment>
    );
};

const CheAdminDropdown = ({ pathname }) => {
    return (
        <li>
            <a
                href="/#"
                className={`pointer-events-none ${
                    pathname.includes('che-admin/') ? 'active' : ''
                }`}
            >
                CHE Admin{' '}
                <span className="sub-menu-arrow">
                    <FontAwesomeIcon icon={faChevronCircleRight} />
                </span>
            </a>
            <ul className="sub-menu">
                <li>
                    <a href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_INSTITUTIONS}>
                        Institutions
                    </a>
                </li>
                <li>
                    <a href={UrlEnum.PARTITION_TOGGLE}>Partition Toggle</a>
                </li>
                <li>
                    <a href={UrlEnum.COLLECTION_MANAGEMENT}>Collection Processing</a>
                </li>
            </ul>
        </li>
    );
};
