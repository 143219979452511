import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DropdownButton = props => {
    const {
        icon,
        text,
        options,
        buttonClassName = '',
        dropdownProps,
        dropdownMenuProps,
        ...rest
    } = props;
    const [open, setOpen] = useState(false);
    return (
        <Dropdown isOpen={open} toggle={() => setOpen(!open)} {...rest}>
            <DropdownToggle
                style={{ height: '100%' }}
                className={`btn btn-outline-primary dropdown-toggle ${buttonClassName}`}
                {...dropdownProps}
            >
                <FontAwesomeIcon icon={icon} /> {text}
            </DropdownToggle>
            <DropdownMenu {...dropdownMenuProps}>
                {options.map((option, i) => {
                    return (
                        <DropdownItem key={i} onClick={option.onClick}>
                            {option.label}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

DropdownButton.defaultProps = {
    dropdownProps: {
        size: 'xs',
    },
    dropdownMenuProps: {},
};

export default DropdownButton;
