import React, { useReducer } from 'react';
import { Form, Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { onChangeFunction } from 'shared';
import { Button } from 'components/blocks';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_STATE = {
    phoneNumber: '',
};

export const PhoneNumberForm = props => {
    const { loading, onSubmit, linkToLogin } = props;
    const [form, setForm] = useReducer(reducer, INITIAL_STATE);
    const { phoneNumber } = form;
    const submitHandler = async e => {
        e.preventDefault();
        onSubmit({ ...form, phoneNumber: form.phoneNumber.replace(/\D+/g, '') });
    };
    return (
        <Form onSubmit={loading ? null : submitHandler}>
            <h2 className="text-uppercase text-darkgreen mb-1">Verify Phone Number</h2>
            <p>
                In order to login in you will need to add a valid phone number. We will
                send you a text message with a verification code that you'll need to enter
                on the next screen.
            </p>
            <Input
                type="tel"
                name="phoneNumber"
                className="form-control form-control-lg mb-4"
                style={{ background: '#FFF' }}
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={evt => onChangeFunction.handleChange(evt, setForm)}
                mask="(999) 999-9999"
                maskChar=" "
                tag={InputMask}
            />
            <Button
                type="submit"
                color="primary"
                className="btn-lg btn-block btn-round"
                loading={loading}
                disabled={phoneNumber.length === 0}
            >
                Submit <FontAwesomeIcon icon={faArrowRight} />
            </Button>
            <div className="mt-5 text-center">
                <div onClick={linkToLogin} className="body-text-link cursor-pointer">
                    <FontAwesomeIcon icon={faArrowLeft} /> back to login
                </div>
            </div>
        </Form>
    );
};
