import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Button, FormInput } from '../../blocks';

export const AddSection = props => {
    const { open, close, onSubmit, editing } = props;
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const isEdit = Boolean(editing.name);
    const clickHandler = async e => {
        e.preventDefault();
        setLoading(true);
        await onSubmit(name, editing);
        setLoading(false);
        close();
    };
    useEffect(() => {
        if (open) {
            setName(editing.name || '');
        }
    }, [editing.name, open]);
    return (
        <Modal isOpen={open} className="modal-dialog">
            <ModalHeader close={close}>
                {isEdit ? 'Edit Section' : 'New Section'}
            </ModalHeader>
            <form onSubmit={loading ? null : clickHandler}>
                <ModalBody>
                    <FormInput
                        required
                        label="Name"
                        placeholder="Section Name..."
                        name="name"
                        value={name}
                        onChange={e => setName(e.name)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button outline onClick={close}>
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        disabled={name.length === 0}
                        loading={loading}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default AddSection;
