import React, { useState } from 'react';
import { convertToHumanReadableDate } from 'shared';

export const BudgetRunHeaderCard = HeaderCardModel => {
    const [cardTitle] = useState(HeaderCardModel.cardTitle);
    const [biennium] = useState(HeaderCardModel.biennium);
    // const [budgetAgencyProjectNo] = useState(HeaderCardModel.budgetAgencyProjectNo);
    const [lastUpdated] = useState(HeaderCardModel.lastUpdated);
    const [statusName] = useState(HeaderCardModel.statusName);
    return (
        <div className="card p-3 mb-3">
            <h2 className="text-strong text-primary">{cardTitle}</h2>
            <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                    <li className="mr-3">
                        <label className="m-0">Biennium:</label> {biennium}
                    </li>
                    <li className="mr-3">
                        <label className="m-0">Status:</label> {statusName}
                    </li>
                    <li>
                        <label className="m-0">Last Updated:</label>{' '}
                        {convertToHumanReadableDate(lastUpdated)}
                    </li>
                </ul>
            </div>
        </div>
    );
};
