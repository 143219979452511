import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { onChangeFunction } from 'shared';
import { Button, FormInput, FormSelect } from '../../blocks';

const getInstitutionLabel = d => d.label;
const getInstitutionValue = d => d.value;
const reducer = (state, newState) => ({ ...state, ...newState });

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    isActive: true,
    institutions: [],
    roles: [],
};

const deriveKey = (array = [], key = 'key') => {
    return array.map(d => d[key]);
};

const Checkbox = props => {
    const { label, active, data, onChange, ...rest } = props;
    return (
        <div key={label} className="form-check mb-1">
            <input
                className="form-check-input"
                type="checkbox"
                id={label}
                value={active}
                checked={active}
                onChange={() => {
                    onChange(prev => {
                        return active
                            ? prev.filter(d => d.roleID !== data.roleID)
                            : prev.concat(data);
                    });
                }}
                {...rest}
            />
            <label className="form-check-label" for={label}>
                {label}
            </label>
        </div>
    );
};

export const UserModal = props => {
    const {
        open,
        close,
        options,
        onAddUser,
        onEditUser,
        cheRoles = [],
        institutionRoles = [],
        user,
    } = props;
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState('che');
    const [roles, setRoles] = useState([]);
    const [form, setForm] = useReducer(reducer, INITIAL_STATE);
    const { firstName, lastName, emailAddress, isActive, institutions } = form;
    const getPayload = () => {
        return {
            firstName,
            lastName,
            emailAddress,
            roles: deriveKey(roles, 'roleID'),
            institutions: deriveKey(institutions, 'value'),
            isDeleted: !isActive,
        };
    };
    const clearForm = () => {
        setTab(cheRoles.length > 0 ? 'che' : 'institution');
        setRoles([]);
        setForm(INITIAL_STATE);
    };
    const addUser = async () => {
        setLoading(true);
        try {
            const payload = getPayload();
            await onAddUser(payload);
            close();
        } catch (error) {}
        setLoading(false);
    };
    const updateUser = async () => {
        setLoading(true);
        try {
            const payload = getPayload();
            await onEditUser(user.id, payload);
            close();
        } catch (error) {}
        setLoading(false);
    };
    useEffect(() => {
        if (open && user) {
            setForm({
                firstName: user.firstName,
                lastName: user.lastName,
                emailAddress: user.emailAddress,
                isActive: !user.isDeleted,
                institutions: user.institutionNames ? user.institutionNames.map(x => {return {label: x.institutionName, value: x.institutionID};}) : [],
            });
            setRoles(user.roleNames);
        }
        return () => clearForm();
    }, [open, user]);

    const renderItem = item => {
        const active = roles.find(p => p.roleID === item.roleID);
        return (
            <Checkbox
                key={item.roleID}
                label={item.roleName}
                active={Boolean(active)}
                onChange={setRoles}
                data={item}
            />
        );
    };
    const disabled = useMemo(() => {
        return !firstName || !lastName || !emailAddress;
    }, [firstName, lastName, emailAddress]);
    const error = useMemo(() => {
        if(institutions == null) return false;
        const cheRoles = roles.filter(r => r.roleName.includes('CHE '));
        return cheRoles.length > 0 && institutions.length > 0;
    }, [roles, institutions]);
    return (
        <Modal isOpen={open} className="modal-dialog">
            <ModalHeader toggle={close}>{user ? 'Edit User' : 'New User'}</ModalHeader>
            <ModalBody>
                <div className="form-row">
                    <FormInput
                        label="First Name"
                        placeholder="First Name"
                        name="firstName"
                        value={firstName}
                        className="col-md mb-3 mb-md-0"
                        inputClassName="white"
                        onChange={setForm}
                    />
                    <FormInput
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        value={lastName}
                        className="col-md mb-3 mb-md-0"
                        inputClassName="white"
                        onChange={setForm}
                    />
                </div>
                <div className="form-row">
                    <FormInput
                        label="Email Address"
                        placeholder="Email Address"
                        name="emailAddress"
                        value={emailAddress}
                        className="col-md-8 mb-3 mb-md-0"
                        inputClassName="white"
                        onChange={setForm}
                    />
                    <div className="col-md-4">
                        <fieldset className="form-group">
                            <label>Status</label>
                            <div className="form-check checkbox-slider--b-flat checkbox-slider-md">
                                <label className="m-0">
                                    <Input
                                        type="checkbox"
                                        placeholder="Program ID"
                                        onChange={evt =>
                                            onChangeFunction.handleCheckChange(
                                                evt,
                                                setForm
                                            )
                                        }
                                        name="isActive"
                                        value={isActive}
                                        checked={isActive}
                                    />
                                    {isActive ? (
                                        <span className="text-success user-toggle">
                                            Active
                                        </span>
                                    ) : (
                                        <span className="text-danger user-toggle">
                                            Inactive
                                        </span>
                                    )}
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <FormSelect
                    label="Institution"
                    onChange={setForm}
                    options={options  ? options.map(x => {return {label: x.institutionName, value: x.institutionID};}) : []}
                    placeholder="All"
                    name="institutions"
                    value={institutions}
                    isMulti
                    getOptionLabel={getInstitutionLabel}
                    getOptionValue={getInstitutionValue}
                    showLoading
                />
                {error && (
                    <div className="text-danger mb-3" style={{ marginTop: '-0.5rem' }}>
                        CHE roles cannot be associated with institutions
                    </div>
                )}
                <fieldset className="form-group">
                    <label className="d-block">Roles</label>
                    <div
                        className="btn-group btn-group-sm mb-2"
                        role="group"
                        style={{ zIndex: 0 }}
                    >
                        {cheRoles.length > 0 && <button
                            type="button"
                            onClick={() => setTab('che')}
                            className={`btn btn-outline-primary ${tab === 'che' &&
                                'active'}`}
                        >
                            CHE Roles
                        </button>}
                        <button
                            type="button"
                            onClick={() => setTab('institution')}
                            className={`btn btn-outline-primary ${tab === 'institution' &&
                                'active'}`}
                        >
                            Institution Roles
                        </button>
                    </div>
                    {tab === 'che'
                        ? cheRoles.map(renderItem)
                        : institutionRoles.map(renderItem)}
                </fieldset>
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" onClick={close}>
                    Cancel
                </Button>
                {user ? (
                    <Button
                        color="primary"
                        onClick={updateUser}
                        disabled={disabled}
                        loading={loading}
                    >
                        Update User
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        onClick={addUser}
                        disabled={disabled}
                        loading={loading}
                    >
                        Add User
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};
