import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { Card, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { helpService } from 'services';
import {
    Contact,
    Section,
    Header,
    Document,
    Documents,
    AddSection,
    Upload,
} from './components';
import { Button } from '../blocks';

export const Help = props => {
    const { admin } = props;
    const [modal, setModal] = useState(false);
    const [sections, setSections] = useState([]);
    const [files, setFiles] = useState([]);
    const [editing, setEditing] = useState({});
    const fetchSections = useCallback(async () => {
        try {
            const res = await helpService.getSections();
            const files = await helpService.getFiles();
            setSections(res || []);
            setFiles(files || []);
        } catch (error) {
            console.error(error);
        }
    }, []);
    const addNewSection = useCallback(async (name, edit) => {
        try {
            let res;
            if (edit.id) {
                res = await helpService.updateSection({ name, id: edit.id });
            } else {
                res = await helpService.addSection({ name });
            }
            if (res) setSections(res);
        } catch (error) {
            console.error(error);
        }
    }, []);
    const deleteSection = useCallback(async section => {
        helpService.deleteSection(section.id).then(res => {
            if (res) {
                console.log(res);
                setSections(res);
            }
        });
    }, []);
    const deleteFile = useCallback(async file => {
        helpService.deleteFile(file.id).then(res => setFiles(res));
    }, []);
    const onDownload = useCallback(async file => {
        helpService.getFile(file.id, file.friendlyName, file.fileExtension);
    }, []);
    const addNewFile = useCallback(async (data, edit = {}) => {
        try {
            let res;
            if (edit.id) {
                res = await helpService.updateFile(data);
            } else {
                res = await helpService.addFile(data);
            }
            if (res) setFiles(res);
        } catch (error) {
            console.error(error);
        }
    }, []);
    const renderLink = useCallback(item => {
        const id = '#' + _.kebabCase(item.name + '-' + item.id);
        return (
            <li key={id} className="mb-2">
                <h5 className="m-0">
                    <a href={id} className="scrollLink">
                        {item.name}
                    </a>
                </h5>
            </li>
        );
    }, []);
    const renderDocument = useCallback(
        item => {
            return (
                <Document
                    id={item.id}
                    key={item.id}
                    title={item.friendlyName}
                    description={item.fileDescription}
                    data={item}
                    onEdit={d => {
                        setEditing(d);
                        setModal('upload');
                    }}
                    onDelete={deleteFile}
                    onDownload={onDownload}
                    admin={admin}
                />
            );
        },
        [admin, deleteFile, onDownload]
    );
    const renderOption = useCallback(
        item => {
            const id = _.kebabCase(item.name + '-' + item.id);
            const sectionFiles = files.filter(file => file.categoryId === item.id);
            const [documentation, dataLayouts] = _.partition(
                sectionFiles,
                'isDocumentation'
            );
            return (
                <div key={id} className="accordion mb-4" id={id}>
                    <Section
                        name={item.name}
                        id={id}
                        data={item}
                        onEdit={d => {
                            setEditing(d);
                            setModal('section');
                        }}
                        onDelete={deleteSection}
                        admin={admin}
                    >
                        {documentation.length > 0 && (
                            <Fragment>
                                <Header title="Documentation:" />
                                <Documents>{documentation.map(renderDocument)}</Documents>
                            </Fragment>
                        )}
                        {dataLayouts.length > 0 && (
                            <Fragment>
                                <Header title="Data Layouts:" />
                                <Documents>{dataLayouts.map(renderDocument)}</Documents>
                            </Fragment>
                        )}
                    </Section>
                </div>
            );
        },
        [files, deleteSection, admin, renderDocument]
    );
    useEffect(() => {
        fetchSections();
    }, [fetchSections]);
    return (
        <Container>
            <h2 className="mb-4 text-strong">Help Documentation &amp; Data Layouts</h2>
            <Row className="help-documents">
                <div className="col-md-12">
                    <Card className="mb-4 p-3">
                        <Row>
                            <div className="col-md-8 border-right">
                                <p className="mb-2">Jump to section:</p>
                                <ul className="pl-3 m-0">{sections?.map(renderLink)}</ul>
                            </div>
                            <div className="col-md-4 pl-4">
                                <h2 className="mb-3 text-strong">Contact Information</h2>
                                <Contact />
                            </div>
                        </Row>
                    </Card>
                    {admin && (
                        <div className="d-flex mb-4">
                            <Button
                                color="success"
                                className="mr-2"
                                onClick={() => setModal('section')}
                            >
                                <FontAwesomeIcon icon={faPlus} /> New Section
                            </Button>
                            <Button color="success" onClick={() => setModal('upload')}>
                                <FontAwesomeIcon icon={faUpload} /> Upload Document
                            </Button>
                        </div>
                    )}
                    {sections?.map(renderOption)}
                    <Card className="p-3">
                        <h2 className="mb-3 text-strong">Contact Information</h2>
                        <Contact />
                    </Card>
                </div>
            </Row>
            <AddSection
                open={modal === 'section'}
                close={() => {
                    setModal(false);
                    setEditing({});
                }}
                onSubmit={addNewSection}
                editing={editing}
            />
            <Upload
                open={modal === 'upload'}
                close={() => {
                    setModal(false);
                    setEditing({});
                }}
                onSubmit={addNewFile}
                editing={editing}
                sections={sections}
            />
        </Container>
    );
};
