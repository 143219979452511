import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faSignOutAlt,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faUser,
    faLock,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

export const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState();
    const user = useSelector(state => state.user);
    const open = useSelector(state => state.menuOpen);
    const expanded = useSelector(state => state.menuExpanded);
    const openRef = useRef(open);
    const toggle = () => setMenuOpen(!menuOpen);
    const handleLogout = e => {
        e.preventDefault();
        dispatch({ type: 'LOGOUT' });
        history.replace('/login');
    };
    const handleReset = e => {
        e.preventDefault();
        history.push('/login', { reset: true });
    };
    const linkToProfile = e => {
        e.preventDefault();
        toggle();
        history.push('/profile');
    };
    useEffect(() => {
        openRef.current = open;
    }, [open]);
    useEffect(() => {
        // when the route changes we want to close the mobile menu if its open
        if (openRef.current) {
            dispatch({ type: 'TOGGLE_MENU', open: false });
        }
    }, [dispatch, location.pathname]);
    return (
        <React.Fragment>
            <div className="d-flex align-items-center">
                <div
                    id="mobile-menu-btn"
                    className="btn btn-primary mr-2"
                    onClick={() => dispatch({ type: 'TOGGLE_MENU' })}
                >
                    <FontAwesomeIcon icon={faBars} />
                </div>
                <div
                    className="nav-links-expand"
                    onClick={() => dispatch({ type: 'TOGGLE_EXPAND' })}
                >
                    <FontAwesomeIcon
                        icon={expanded ? faChevronLeft : faChevronRight}
                        color="#2a4d87"
                        className="site-menu-expand"
                    />
                </div>
                <h5 className="m-0 header-title">Data Submission System (CHEDSS)</h5>
            </div>
            <Dropdown isOpen={menuOpen} toggle={toggle} className="header-links">
                <DropdownToggle
                    tag="div"
                    onClick={toggle}
                    data-toggle="dropdown"
                    aria-expanded={menuOpen}
                    className="header-links-target"
                >
                    <div className="header-links-name-mobile btn">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div className="header-links-name">
                        {user?.firstName} {user?.lastName}{' '}
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <div className="header-links-item" onClick={linkToProfile}>
                        <FontAwesomeIcon icon={faUser} /> My Profile
                    </div>
                    <div className="header-links-item" onClick={handleReset}>
                        <FontAwesomeIcon icon={faLock} /> Reset Password
                    </div>
                    <div className="header-links-item" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};
