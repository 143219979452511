import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    PrivateRoute,
    AdminRoute,
    ROLES,
    UrlEnum,
    ErrorBoundary,
    useRole,
    useDynamicViews,
} from 'shared';
import {
    UserAdmin,
    Header,
    SideNav,
    ErrorAlert,
    ToastAlert,
    Impersonation,
} from 'components';
import {
    ProjectReviewPage,
    ProjectReviewSearchPage,
    CapitalProjectRequest,
    CapitalProjectSearchPage,
    LineItemSearchPage,
    LineItemBudgetPage,
    LoginPage,
    VerifyCodePage,
    PhoneNumberPage,
    SetPasswordPage,
    BudgetRunPage,
    BudgetRunSearchPage,
    AcademicProgramPage,
    AcademicProgramPageView,
    SearchAcademicProgramInventoryPage,
    GenerateProgramReviewReportPage,
    ProgramReviewReportPage,
    DataSubmissionPage,
    PartitionTogglePage,
    HelpPage,
    HomePage,
    SearchUsersPage,
    PerformanceSchedulesPage,
    LineItemPerformancePage,
    RecordLinkingPage,
    LinkReviewPage,
    ProfilePage,
    DebtServicePage,
    SearchAcademicInstitutionInventoryPage,
    AcademicInstitutionPage,
    AcademicInstitutionPageView,
    AcademicCredentialPage,
    AcademicSystemPage,
    AcademicSystemPageView,
    AcademicCampusPage,
    AcademicLocationPage,
    CollectionProcessingPage,
} from 'pages';

import './app.scss';

function App() {
    const admin = useRole(ROLES.ADMIN);
    const views = useDynamicViews();
    const expanded = useSelector(state => state.menuExpanded);
    const user = useSelector(state => state.user);
    return (
        <div>
            <ErrorAlert />
            <ToastAlert />
            <Router>
                <link
                    rel="stylesheet"
                    // href='/bootstrap.min.css'
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossOrigin="anonymous"
                />
                <Switch>
                    {/* THE FOLLOWING PAGES LIVE OUTSIDE OF THE NAVIGATION SIDEBAR AND HEADER */}
                    <PrivateRoute
                        exact
                        path={UrlEnum.ACADEMIC_AFFAIRS_REPORT}
                        component={ProgramReviewReportPage}
                    />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/verify" component={VerifyCodePage} />
                    <Route exact path="/phone" component={PhoneNumberPage} />
                    {/* password reset links structure like so: /account/ForgotPassword/31/2c1fd8c3-4bdd-4da6-9aab-7131cc392e7f */}
                    <Route exact path="/account/ForgotPassword/:id/:uid">
                        {props => {
                            const { id, uid } = props.match.params;
                            return (
                                <Redirect
                                    to={{
                                        pathname: UrlEnum.SET_PASSWORD,
                                        search: `?uid=${uid}&id=${id}`,
                                    }}
                                />
                            );
                        }}
                    </Route>
                    <Route exact path="/account/SetPassword/:id/:uid">
                        {props => {
                            const { id, uid } = props.match.params;
                            return (
                                <Redirect
                                    to={{
                                        pathname: UrlEnum.SET_PASSWORD,
                                        search: `?uid=${uid}&id=${id}`,
                                    }}
                                />
                            );
                        }}
                    </Route>
                    <Route
                        exact
                        path={UrlEnum.SET_PASSWORD}
                        component={SetPasswordPage}
                    />
                    <Route
                        render={() => {
                            return (
                                <div
                                    className={`page-wrap ${
                                        !expanded ? 'page-wrap-closed' : ''
                                    }`}
                                >
                                    <SideNav
                                        admin={admin}
                                        dynamicViews={views}
                                        impersonating={user?.isImpersonation}
                                    />
                                    {user?.isImpersonation && <Impersonation />}
                                    <header
                                        id="site-header"
                                        className={
                                            user?.isImpersonation
                                                ? 'site-header-impersonation'
                                                : ''
                                        }
                                    >
                                        <ErrorBoundary>
                                            <Header />
                                        </ErrorBoundary>
                                    </header>
                                    <div
                                        className={`page-container ${
                                            user?.isImpersonation
                                                ? 'page-container-impersonation'
                                                : ''
                                        }`}
                                    >
                                        <PrivateRoute
                                            exact
                                            path="/"
                                            component={HomePage}
                                        />
                                        {views.includes('Manage Users') && (
                                            <PrivateRoute
                                                exact
                                                path={UrlEnum.MANAGE_USERS}
                                                component={SearchUsersPage}
                                                admin={admin}
                                            />
                                        )}
                                        {views.includes('Data Submission') && (
                                            <PrivateRoute
                                                exact
                                                path={UrlEnum.DATA_SUBMISSION}
                                                component={DataSubmissionPage}
                                            />
                                        )}
                                        {views.includes('Finance') && (
                                            <Fragment>
                                                {views.includes(
                                                    'Finance/Budget Runs'
                                                ) && (
                                                    <Fragment>
                                                        <PrivateRoute
                                                            exact
                                                            path={UrlEnum.BUDGETRUNSEARCH}
                                                            component={
                                                                BudgetRunSearchPage
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path={UrlEnum.BUDGETRUN}
                                                            component={BudgetRunPage}
                                                        />
                                                    </Fragment>
                                                )}
                                                {views.includes(
                                                    'Finance/Budget Schedules'
                                                ) && (
                                                    <Fragment>
                                                        <PrivateRoute
                                                            exact
                                                            path={UrlEnum.LINEITEMSEARCH}
                                                            component={LineItemSearchPage}
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path={UrlEnum.LINEITEMBUDGET}
                                                            component={LineItemBudgetPage}
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path={
                                                                UrlEnum.PERFORMANCESCHEDULES
                                                            }
                                                            component={
                                                                PerformanceSchedulesPage
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path={
                                                                UrlEnum.LINEITEMPERFORMANCE
                                                            }
                                                            component={
                                                                LineItemPerformancePage
                                                            }
                                                        />
                                                    </Fragment>
                                                )}
                                                {views.includes(
                                                    'Finance/Capital Projects'
                                                ) && (
                                                    <Fragment>
                                                        <PrivateRoute
                                                            exact
                                                            path={
                                                                UrlEnum.CAPITALPROJECTSEARCH
                                                            }
                                                            component={
                                                                CapitalProjectSearchPage
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path={
                                                                UrlEnum.CAPITALPROJECTREQUEST
                                                            }
                                                            component={
                                                                CapitalProjectRequest
                                                            }
                                                        />
                                                    </Fragment>
                                                )}
                                                <PrivateRoute
                                                    exact
                                                    path={UrlEnum.PROJECTREVIEWSEARCH}
                                                    component={ProjectReviewSearchPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={UrlEnum.PROJECTREVIEW}
                                                    component={ProjectReviewPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={UrlEnum.DEBTSERVICE}
                                                    component={DebtServicePage}
                                                />
                                            </Fragment>
                                        )}
                                        {views.includes('Academic Affairs') && (
                                            <Fragment>
                                                {views.includes(
                                                    'Academic Affairs/Programs'
                                                ) && (
                                                    <PrivateRoute
                                                        exact
                                                        path={
                                                            UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS
                                                        }
                                                        component={
                                                            SearchAcademicProgramInventoryPage
                                                        }
                                                    />
                                                )}
                                                <PrivateRoute
                                                    exact
                                                    path={
                                                        UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS
                                                    }
                                                    component={AcademicProgramPage}
                                                />

                                                <PrivateRoute
                                                    exact
                                                    path={
                                                        UrlEnum.ACADEMIC_AFFAIRS_CREDENTIAL
                                                    }
                                                    component={AcademicCredentialPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={
                                                        UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS_VIEW
                                                    }
                                                    component={AcademicProgramPageView}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={
                                                        UrlEnum.ACADEMIC_AFFAIRS_GENERATE_REPORT
                                                    }
                                                    component={
                                                        GenerateProgramReviewReportPage
                                                    }
                                                />
                                            </Fragment>
                                        )}
                                        {views.includes('Record Linking') && (
                                            <Fragment>
                                                <PrivateRoute
                                                    exact
                                                    path={UrlEnum.RECORD_LINKING}
                                                    component={RecordLinkingPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={UrlEnum.LINK_REVIEW}
                                                    component={LinkReviewPage}
                                                />
                                            </Fragment>
                                        )}
                                        <PrivateRoute
                                            exact
                                            path={UrlEnum.HELP}
                                            component={HelpPage}
                                            admin={admin}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={UrlEnum.PROFILE}
                                            component={ProfilePage}
                                        />
                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={UrlEnum.PARTITION_TOGGLE}
                                                component={PartitionTogglePage}
                                            />
                                        )}

                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={UrlEnum.COLLECTION_MANAGEMENT}
                                                component={CollectionProcessingPage}
                                            />
                                        )}

                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={
                                                    UrlEnum.SEARCH_ACADEMIC_AFFAIRS_INSTITUTIONS
                                                }
                                                component={
                                                    SearchAcademicInstitutionInventoryPage
                                                }
                                            />
                                        )}

                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={
                                                    UrlEnum.ACADEMIC_AFFAIRS_SYSTEM_VIEW
                                                }
                                                component={AcademicSystemPageView}
                                            />
                                        )}

                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={
                                                    UrlEnum.ACADEMIC_AFFAIRS_INSTITUTION
                                                }
                                                component={AcademicInstitutionPage}
                                            />
                                        )}

                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={UrlEnum.ACADEMIC_AFFAIRS_SYSTEM}
                                                component={AcademicSystemPage}
                                            />
                                        )}

                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={UrlEnum.ACADEMIC_AFFAIRS_CAMPUS}
                                                component={AcademicCampusPage}
                                            />
                                        )}

                                        {admin && (
                                            <PrivateRoute
                                                exact
                                                path={UrlEnum.ACADEMIC_AFFAIRS_LOCATION}
                                                component={AcademicLocationPage}
                                            />
                                        )}

                                        <AdminRoute
                                            exact
                                            path="/admin/users"
                                            component={UserAdmin}
                                        />
                                    </div>
                                </div>
                            );
                        }}
                    />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
