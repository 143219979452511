import React, { useState } from 'react';
import { Input, Button } from 'reactstrap';
import { academicProgramsService } from 'services';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { faEdit, faSearchPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UrlEnum, usePermissions } from 'shared';
import { AcademicAffairAddLocationModal } from './academic-affair-add-location-modal';
import { Confirm } from 'components/blocks';

export const AcademicCredential = ({
    credentialData,
    setCredentialData,
    fetchData,
}) => {
    const updatePermission = usePermissions({
        permissions: ['submit-changes-to-programs'],
    });
    const history = useHistory();
    const [confirm, setConfirm] = useState(false);


    const handleCredentialDataChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setCredentialData(prev => ({
            ...prev,
            [name]: newValue,
        }));
    };


    const {
        credentialId,
        programURL,
        keywords,
        ctid,
        programDescription,
    } = credentialData;

    const submitForm = async () => {
        setConfirm(false);
        const submitData = {
            CredentialId: credentialId,
            URL: programURL,
            Keywords: keywords,
            Description: programDescription,
        };
        await academicProgramsService.editCredential(submitData);
        fetchData();
    };
    return (
        <React.Fragment>
            <div className="sticky-footer">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back to program
                        </a>
                    </div>
                    <div className="row sticky-bar-spacer">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <h2 className="text-strong text-primary">
                                    Credential Edit
                                </h2>
                                <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                                    <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                                        <li className="mr-3">
                                            <label className="m-0">CHE Credential ID:</label>{' '}
                                            {credentialId}
                                        </li>
                                        <li className="mr-3">
                                            <label className="m-0">CTID:</label>{' '}
                                            {ctid}
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="card p-3">
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            URL{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Program URL"
                                            value={programURL}
                                            onChange={handleCredentialDataChange}
                                            name="programURL"
                                        />
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Keywords{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Keywords"
                                            value={keywords}
                                            onChange={handleCredentialDataChange}
                                            name="keywords"
                                        />
                                    </div>
                                </div>
                                
                                <div className="row gutter-60">
                                    <div className="col-md">
                                        <label>
                                            Description{' '}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            placeholder="description..."
                                            rows="10"
                                            value={programDescription}
                                            onChange={handleCredentialDataChange}
                                            name="programDescription"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {updatePermission && (
                        <div className="sticky-bottom-bar">
                            <div className="d-flex align-items-center justify-content-end">
                                <a
                                    className="btn btn-outline-primary btn-lg mr-2"
                                    href={UrlEnum.ACADEMIC_AFFAIRS_PROGRAMS}
                                >
                                    Cancel
                                </a>
                                <Button
                                    type="button"
                                    className="btn btn-success btn-lg px-3"
                                    onClick={() => {
                                        setConfirm(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} /> Save Changes
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Confirm
                isOpen={confirm}
                toggle={() => setConfirm(false)}
                onConfirm={submitForm}
                title="Confirm Changes"
                message="Are you sure you want to submit these changes?"
            />
        </React.Fragment>
    );
};
