import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import {
    SearchAcademicProgramsForm,
    AcademicAffairsSearchProgramsTable,
} from '../../../components';

export const SearchAcademicProgramInventoryPage = props => {
    const academicAffairsDataFromSession = window.sessionStorage.getItem('academicAffairsData') ? JSON.parse(window.sessionStorage.getItem('academicAffairsData')) : null;

    const [academicAffairsData, setAcademicAffairsData] = useState(academicAffairsDataFromSession ? academicAffairsDataFromSession : []);
    const [searchObject, setSearchObject] = useState({
        bienniums: null,
        title: null,
        status: null,
        includeAll: true,
        // orderBy: 'LastUpdate',
        // sortDirection: 'desc',
    });

    // Before this component is unmounted, commit whatever is in academic affairs data to session storage
    useEffect(() => {
        return () => {
            window.sessionStorage.setItem('academicAffairsData', JSON.stringify(academicAffairsData));
        }
    });

    return (
        <Row>
            <Col className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                <SearchAcademicProgramsForm
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    setAcademicAffairs={setAcademicAffairsData}
                ></SearchAcademicProgramsForm>
            </Col>
            <Col className="col-xl-9 col-lg-12">
                <AcademicAffairsSearchProgramsTable
                    searchObject={searchObject}
                    academicAffairsData={academicAffairsData}
                ></AcademicAffairsSearchProgramsTable>
            </Col>
        </Row>
    );
};
