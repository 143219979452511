import { authHeader } from 'helpers/auth-header';
import { handleAxiosResponse, api_base_url, axiosInstance } from '../common.service.js';
import { handleError } from 'shared';

const convertApiResponseToSearchoptions = responses => {
    const toReturn = responses.map(response => ({
        value: response.id,
        label: response.text,
    }));
    return toReturn;
};

const getFundingType = biennium => {
    const firstYearOfBiennium = biennium.split('-')[0];
    return axiosInstance
        .get(
            `${api_base_url}/api/finance/CapitalProjectType/fundings?biennium=${firstYearOfBiennium}`,
            {
                headers: authHeader(),
            }
        )
        .then(handleAxiosResponse)
        .then(res => {
            return convertApiResponseToSearchoptions(res);
        })
        .catch(error => {
            handleError(error);
        });
};

export const capitalProjectTypeService = {
    getFundingType,
};
