import React, { useState, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WizardFooter } from './wizard-footer';
import { capitalProjectService } from 'services';
import { faUpload, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Form, Input, Card, Table } from 'reactstrap';
import { ConfirmActionModal, FormEnum } from 'shared';
import { ValidationErrorBox } from './validation-error-box';

const DownloadTable = ({ files }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const downloadClicked = documentId => {
        capitalProjectService.downloadCapitalProjectSupportingDocument(documentId);
    };

    return (
        <Table
            responsive
            className="table table-striped table-bordered table-hover align-middle data-table"
        >
            <thead>
                <tr>
                    <th className="min-tablet-l">Document ID</th>
                    <th className="min-tablet-l">Document Name</th>
                    <th className="min-tablet-l">Upload Date</th>
                    <th className="min-tablet-l no-sort"></th>
                </tr>
            </thead>
            <tbody>
                {!files
                    ? null
                    : files.map(supportingDocument => {
                          const newLastUpdateDate = new Date(
                              supportingDocument.uploadDate
                          );
                          const correctlyFormattedDateString = newLastUpdateDate.toLocaleDateString();
                          return (
                              <tr key={supportingDocument.documentId}>
                                  <td>{supportingDocument.documentId}</td>
                                  <td>{supportingDocument.documentName}</td>
                                  <td>{correctlyFormattedDateString}</td>
                                  <td className="table-actions">
                                      <div
                                          data-toggle="tooltip"
                                          title="Download"
                                          onClick={() =>
                                              downloadClicked(
                                                  supportingDocument.documentId
                                              )
                                          }
                                          className="action-circle"
                                      >
                                          <FontAwesomeIcon
                                              icon={faDownload}
                                              className="fa-fw"
                                          />
                                      </div>
                                      <div
                                          data-toggle="tooltip"
                                          title="Delete"
                                          onClick={() => setIsModalOpen(true)}
                                          className="action-circle"
                                      >
                                          <FontAwesomeIcon
                                              icon={faTrash}
                                              className="fa-fw"
                                          />
                                      </div>
                                      <ConfirmActionModal
                                          isOpen={isModalOpen}
                                          toggle={() => setIsModalOpen(!isModalOpen)}
                                          header={`Confirm Delete of ${supportingDocument.documentName}`}
                                          message={
                                              'Are you sure you want to delete this supporting document?'
                                          }
                                          acceptButtonText={'Delete'}
                                          acceptFunction={() =>
                                              capitalProjectService
                                                  .deleteCapitalProjectSupportingDocument(
                                                      supportingDocument.documentId
                                                  )
                                                  .then(window.location.reload())
                                          }
                                          acceptButtonCss="btn btn-danger"
                                      />
                                  </td>
                              </tr>
                          );
                      })}
            </tbody>
        </Table>
    );
};

export const OtherProjectInfo = ({ capitalProject, prevStep, nextStep }) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: capitalProject.id,
            tabNumber: 5,
            spaceUtilization: capitalProject.spaceUtilization
                ? capitalProject.spaceUtilization
                : '',
            comparableProjects: capitalProject.comparableProjects
                ? capitalProject.comparableProjects
                : '',
            backgroundMaterials: capitalProject.backgroundMaterials
                ? capitalProject.backgroundMaterials
                : '',
        }
    );
    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        capitalProject[name] = newValue;
        setUserInput({ [name]: newValue });
    };
    const [supportingDocuments] = useState(capitalProject.files);

    const onFileChange = event => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('myFile', file, file.name);
        capitalProjectService
            .uploadCapitalProjectSupportingDocument(capitalProject.id, formData)
            .then(window.location.reload());
    };
    const sanitizeCapitalProject = () => userInput;
    return (
        <Form>
            <h2 className="text-strong text-primary border-bottom pb-2 mb-4">
                Other Project Info
            </h2>
            <ValidationErrorBox
                capitalProject={capitalProject}
                tabNumber={FormEnum.OTHERPROJECTINFO}
            />
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Space Utilization</h4>
                <p className="text-italic">
                    Describe how the project will impact the overall space of the campus.
                    Include current utilization of space on campus, e.g. if a new building
                    is requested, what is the utilization of similar space on campus
                    (academic, auxiliary, administration, etc.), how will this new
                    building impact utilization. If a new building is being requested,
                    what space will be removed as a result of the project. If the project
                    is a renovation of current space, explain how the new space will be
                    used and note any change in space utilization. If a project is moving
                    operations (vacating space) note if the vacated space will be
                    terminated or used for other purposes (identify purposes).
                </p>
                <Input
                    type="textarea"
                    rows="8"
                    placeholder="Space Utilization"
                    onChange={handleChange}
                    name="spaceUtilization"
                    value={userInput.spaceUtilization}
                />
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-3 text-primary">Background Materials</h4>
                <p className="text-italic">
                    If necessary, please include maps, floor plans, or other project
                    details. Also, if the Board of Trustees have reviewed the project,
                    please include the minutes from the meeting that reference the
                    project. If non-state funds are being used for the project
                    (institutional funds, private funds, etc.) please describe the nature
                    of the funds, sources of revenue and allowable uses of the fund. Also,
                    please include the balance of the fund as of 6/30/12. Institutions may
                    include additional information as addendums to the project summary
                    form.{' '}
                </p>
                <Input
                    type="textarea"
                    rows="8"
                    placeholder="Background Materials"
                    onChange={handleChange}
                    name="backgroundMaterials"
                    value={userInput.backgroundMaterials}
                />
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <h4 className="mb-0 text-primary">Comparable Projects</h4>
                <p className="text-italic">
                    List previous projects (can be your own institution or other
                    institutions) that are similar to the project request. Note the size
                    of the project, cost, and cost metrics (cost per GSF/ASF, cost per bed
                    or classroom, etc). If similar projects are not available from other
                    state public institutions, providing peer institutions outside of IN
                    is recommended (mostly for auxiliary projects for example).
                </p>
                <Input
                    type="textarea"
                    rows="8"
                    placeholder="Comparable Projects"
                    onChange={handleChange}
                    name="comparableProjects"
                    value={userInput.comparableProjects}
                />
            </Card>
            <Card className="no-shadow border p-3 mb-3">
                <div className="d-md-flex justify-content-between align-items-center text-center text-md-left mb-3">
                    <h4 className="mb-0 text-primary">Supporting Documents</h4>
                    <div className="hidden-file-input">
                        <input type="file" onChange={onFileChange} />
                        <button type="button" className="btn btn-sm btn-outline-primary">
                            <FontAwesomeIcon icon={faUpload} /> Upload Files
                        </button>
                    </div>
                </div>

                <DownloadTable files={supportingDocuments} />
            </Card>
            <WizardFooter
                prevStep={prevStep}
                nextStep={nextStep}
                sanitizeCapitalProject={sanitizeCapitalProject}
                capitalProjectObject={capitalProject}
            />
        </Form>
    );
};
