import { handleServiceRequest } from '../handle-service-request';
// import { fakeResponse } from './fake-response-budget-run';
import { handleAxiosResponse, api_base_url, axiosInstance } from '../common.service';
import { authHeader } from 'helpers/auth-header';
import { UrlEnum, handleError } from 'shared';

const baseUrl = `/api/finance/budgetruns`;

const createNewBudgetRun = (postBody, history) => {
    const convertFunction = res => {
        const location = {
            pathname: UrlEnum.BUDGETRUN,
            state: [{ budgetRunId: res.newBudgetRunID }],
        };

        history.push(location);
    };
    const postObject = {
        url: `${baseUrl}`,
        postBody: postBody,
        convertFunction: convertFunction,
    };
    return handleServiceRequest.post(postObject);
};

const getBudgetRunWithId = id => {
    const getObject = {
        url: `${baseUrl}/${id}`,
        // customHandleError: () => {
        //     return fakeResponse.getBudgetRunById();
        // },
    };
    return handleServiceRequest.get(getObject);
};

const searchBudgetRuns = params => {
    const getObject = {
        url: `${baseUrl}`,
        params: params,
        // customHandleError: error => {
        //     const returned = fakeResponse.SearchBudgetRun();
        //     return returned;
        // },
    };
    return handleServiceRequest.getWithParams(getObject);
};

const saveBudgetRun = postBody => {
    return axiosInstance
        .patch(
            `${api_base_url}/api/finance/BudgetRuns/${postBody.budgetRunID}`,
            postBody,
            {
                headers: authHeader(),
            }
        )
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const runBudgetRun = id => {
    const postObject = {
        url: `${baseUrl}/run/${id}`,
    };
    return handleServiceRequest.post(postObject);
};

const saveNewBudgetRun = postBody => {
    const postObject = {
        url: `${baseUrl}/new`,
        postBody: postBody,
    };
    return handleServiceRequest.post(postObject);
};

const budgetRunErrorCheck = id => {
    const getObject = {
        url: `${baseUrl}/ErrorCheck/${id}`,
    };
    return handleServiceRequest.get(getObject);
};

const debtServiceReport = search => {
    const getObject = {
        url: `${baseUrl}/DebtServiceReport?${search}`,
    };
    return handleServiceRequest.get(getObject);
};

const deleteBudgetRun = (budgetRunId, history) => {
    const onCompleteFunction = res => {
        const location = {
            pathname: UrlEnum.BUDGETRUNSEARCH,
        };

        history.push(location);
    };
    const deleteObject = {
        url: `${baseUrl}/${budgetRunId}`,
        onCompleteFunction: onCompleteFunction,
    };
    return handleServiceRequest.deleteItem(deleteObject);
};

const downloadBudgetRun = (fileType, projectId) => {
    const url = `${api_base_url}${baseUrl}/download/${fileType}/${projectId}`;
    const fileEnding = fileType === 'excel' ? 'xlsx' : fileType;
    return axiosInstance
        .get(url, {
            headers: authHeader(),
            responseType: 'blob',
        })
        .then(res => {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `BudgetRun-${projectId}.${fileEnding}`;
            link.click();
        })
        .catch(error => {
            handleError(error);
        });
};

export const budgetRunService = {
    getBudgetRunWithId,
    saveNewBudgetRun,
    searchBudgetRuns,
    saveBudgetRun,
    createNewBudgetRun,
    deleteBudgetRun,
    downloadBudgetRun,
    runBudgetRun,
    budgetRunErrorCheck,
    debtServiceReport,
};
