import React, { useReducer } from 'react';
import { Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { onChangeFunction } from 'shared';
import { Button } from 'components/blocks';

const reducer = (state, newState) => ({ ...state, ...newState });
const INITIAL_STATE = {
    code: '',
};

export const VerifyCodeForm = props => {
    const { loading, onSubmit, resendCode } = props;
    const [form, setForm] = useReducer(reducer, INITIAL_STATE);
    const { code } = form;
    const submitHandler = async e => {
        e.preventDefault();
        onSubmit(form);
    };
    return (
        <Form onSubmit={loading ? null : submitHandler}>
            <h2 className="text-uppercase text-darkgreen mb-1">
                Two-Factor Authentication
            </h2>
            <p>
                An sms verification code has been sent to your mobile phone.
                <br />
                Please enter the code below.
            </p>
            <Input
                type="text"
                name="code"
                className="form-control form-control-lg mb-4"
                style={{ background: '#FFF' }}
                placeholder="Verification Code"
                value={code}
                onChange={evt => onChangeFunction.handleChange(evt, setForm)}
            />
            <Button
                type="submit"
                color="primary"
                className="btn-lg btn-block btn-round"
                loading={loading}
                disabled={code.length === 0}
            >
                Submit <FontAwesomeIcon icon={faArrowRight} />
            </Button>
            <div className="mt-5 text-center">
                <div className="body-text-link cursor-pointer" onClick={resendCode}>
                    Didn't get a code? Resend it.
                </div>
            </div>
        </Form>
    );
};
