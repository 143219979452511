import React, { useState } from 'react';

import { Row, Col } from 'reactstrap';
import { SearchBudgetRunForm, BudgetRunSearchTable } from 'components';

export const BudgetRunSearchPage = props => {
    const [budgetRun, setBudgetRun] = useState([]);
    const [searchObject, setSearchObject] = useState({
        bienniums: null,
        title: null,
        status: null,
        includeAll: true,
        // orderBy: 'LastUpdate',
        // sortDirection: 'desc',
    });
    return (
        <Row>
            <Col className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                <SearchBudgetRunForm
                    budgetRun={budgetRun}
                    setBudgetRun={setBudgetRun}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                ></SearchBudgetRunForm>
            </Col>
            <Col className="col-xl-9 col-lg-12">
                <BudgetRunSearchTable
                    budgetRun={budgetRun}
                    searchObject={searchObject}
                ></BudgetRunSearchTable>
            </Col>
        </Row>
    );
};
