import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Card, Container, Row, Table } from 'reactstrap';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { budgetRunService, LineItemService, generateReportService } from 'services';
import { Title, SubTitle, TableBody, TableRow } from './components';
import { FormSelect, Button } from '../blocks';

const reducer = (state, newState) => ({ ...state, ...newState });
const levelOptions = [
    { label: 'Institution', value: 'institution' },
    { label: 'System', value: 'system' },
    { label: 'State', value: 'state' },
];
const defaultStateOption = { value: 'state', label: 'State' };
const INITIAL_FORM = {
    biennium: '',
    unitType: levelOptions[0],
    units: [],
};
const getOptionLabel = d => d.label;
const convertToValue = d => ({ ...d, value: d.id, label: d.name });
const deriveKey = (array = [], key = 'key') => {
    return array.map(d => d[key]);
};
const paginationOptions = {
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    sizePerPage: 20,
    hideSizePerPage: true,
};
export const DebtService = () => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useReducer(reducer, INITIAL_FORM);
    const [options, setOptions] = useReducer(reducer, {});
    const [data, setData] = useState([]);
    const [active, setActive] = useState(0);
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await Promise.all([
                    LineItemService.getBienniums(),
                    generateReportService.getInstitutions('capitalProjectUnitsOnly=true'),
                    generateReportService.getSystems('capitalProjectUnitsOnly=true'),
                ]);
                const opts = {
                    biennium: res[0].map(convertToValue),
                    institutions: res[1]?.institutions?.map(convertToValue),
                    systems: res[2]?.systems?.map(convertToValue),
                };
                const today = new Date();
                const notFutureDates = opts.biennium.filter(
                    b => b.id < today.getFullYear()
                );
                const lastBiennium = notFutureDates.length > 0 ? notFutureDates[0] : '';
                setForm({ biennium: lastBiennium });
                setOptions(opts);
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        };
        fetchOptions();
    }, []);
    useEffect(() => {
        if (form.unitType?.value === 'state') {
            setForm({ units: defaultStateOption });
        } else {
            setForm({ units: [] });
        }
    }, [form.unitType]);
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const getFormPayload = () => {
            const payload = {
                biennium: form?.biennium?.value,
                unitType: form?.unitType?.value,
                units: Array.isArray(form.units)
                    ? deriveKey(form.units, 'value')
                    : [form.units.value],
            };
            return queryString.stringify(payload, {
                arrayFormat: 'separator',
                skipEmptyString: true,
            });
        };
        try {
            const query = getFormPayload();
            const res = await budgetRunService.debtServiceReport(query);
            if (res) {
                setData(res);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }, [form]);
    const filterOptions = useMemo(() => {
        if (form.unitType?.value === 'institution') {
            return options.institutions;
        }
        if (form.unitType?.value === 'system') {
            return options.systems;
        }
        return [defaultStateOption];
    }, [form.unitType, options.institutions, options.systems]);
    const activeReport = _get(data, `[${active}]`, null);
    return (
        <Container fluid>
            <Row>
                <div className="col-xl-3 col-lg-12 mb-3 mb-lg-0">
                    <Card className="mb-3 p-3">
                        <h4 className="text-muted mb-4">Search Budget Schedules</h4>
                        <div className="row align-items-end">
                            <FormSelect
                                label="Biennium"
                                onChange={setForm}
                                options={options.biennium}
                                placeholder="All"
                                name="biennium"
                                value={form.biennium}
                                className="col-12"
                                getOptionLabel={getOptionLabel}
                                isMulti={false}
                            />
                        </div>
                        <div className="row align-items-end">
                            <FormSelect
                                label="Report Level"
                                onChange={setForm}
                                options={levelOptions}
                                name="unitType"
                                value={form.unitType}
                                className="col-12"
                                getOptionLabel={getOptionLabel}
                                isMulti={false}
                            />
                        </div>
                        <div className="row align-items-end">
                            <FormSelect
                                label="Filter"
                                onChange={setForm}
                                options={filterOptions}
                                placeholder="All"
                                name="units"
                                value={form.units}
                                className="col-12"
                                getOptionLabel={getOptionLabel}
                                isMulti={form.unitType?.value !== 'state'}
                            />
                        </div>
                        <div className="row align-items-end">
                            <div className="col-12">
                                <Button
                                    color="primary"
                                    className="btn-primary btn-block"
                                    type="search"
                                    loading={loading}
                                    onClick={onSubmit}
                                    disabled={
                                        _isEmpty(form.units) || _isEmpty(form.biennium)
                                    }
                                >
                                    View Report
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-xl-9 col-lg-12">
                    {activeReport && (
                        <Card className="mb-3 p-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <Button
                                    disabled={active === 0}
                                    className="btn-xs action-circle mr-0"
                                    onClick={() => setActive(prev => prev - 1)}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </Button>
                                <div className="ml-2 mr-2">
                                    {active + 1} of {data.length}
                                </div>
                                <Button
                                    disabled={active === data.length - 1}
                                    className="btn-xs action-circle"
                                    onClick={() => setActive(prev => prev + 1)}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                            </div>
                            <div className="d-md-flex flex-column align-items-center justify-content-between text-center text-md-left mb-3">
                                <h4 className="mb-1">Debt Service on Capital Projects</h4>
                                <h4 className="mb-1">{activeReport.unitName}</h4>
                                <h4 className="mb-1">
                                    Summary of Debt Service for Capital Projects through
                                    Retirement of Debt
                                </h4>
                            </div>
                            <div className="table-responsive">
                                <Table className="table table-striped table-bordered table-hover align-middle">
                                    <Title />
                                    <SubTitle />
                                    <TableBody>
                                        {activeReport.rows.map(row => {
                                            return (
                                                <TableRow key={row.fiscalYear} {...row} />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </Card>
                    )}
                </div>
            </Row>
        </Container>
    );
};
