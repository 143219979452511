import { authHeader } from 'helpers/auth-header';
import { handleAxiosResponse, api_base_url, axiosInstance } from './common.service';
import { handleServiceRequest } from './handle-service-request';

export const userService = {
    login,
    forgotPassword,
    setPassword,
    verifyPhone,
    submitPhone,
    twoFactorAuthenticate,
    tfaAuth,
    logout,
    getAll,
    userCan,
    getRole,
};

function login(Email, Password) {
    console.log(Email + " " + Password)
    const url = `${api_base_url}/api/account/login`;
    const data = {
        EmailAddress: Email,
        Password: Password,
    };
    return axiosInstance
        .post(url, data)
        .then(handleAxiosResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(Email + ':' + Password);
            }

            return user;
        });
}

function forgotPassword(Email) {
    const url = `${api_base_url}/api/account/forgotPassword`;
    const data = {
        emailAddress: Email,
    };
    return axiosInstance
        .post(url, data)
        .then(handleAxiosResponse)
        .then(user => {
            return user;
        });
}

function setPassword(id, uid, password) {
    const url = `${api_base_url}/api/account/setPassword/${id}/${uid}`;
    const data = {
        password,
    };
    return axiosInstance
        .post(url, data)
        .then(handleAxiosResponse)
        .then(user => {
            return user;
        });
}

function submitPhone(data) {
    const url = `${api_base_url}/api/Account/TwoFactor/PhoneNumber`;
    return axiosInstance
        .post(url, data, {
            headers: {
                ...authHeader(),
            },
        })
        .then(handleAxiosResponse);
}

function verifyPhone(data) {
    const url = `${api_base_url}/api/Account/TwoFactor/ValidatePhoneNumber`;
    return axiosInstance
        .post(url, data, {
            headers: {
                ...authHeader(),
            },
        })
        .then(handleAxiosResponse);
}

function twoFactorAuthenticate(data) {
    const url = `${api_base_url}/api/Account/TwoFactor/Authenticate`;
    return axiosInstance
        .post(url, data, {
            headers: {
                ...authHeader(),
            },
        })
        .then(handleAxiosResponse);
}

function tfaAuth(tfaCode) {
    const tfaAPIPath = `${api_base_url}/api/account/verifyTFA`;
    const tfaCredentials = JSON.parse(localStorage.getItem('user')).tfaCredentials[0];
    const postBody = {
        credentialID: tfaCredentials.credentialID,
        securityCode: tfaCode,
        userID: tfaCredentials.userID,
        credentialName: tfaCredentials.credentialName,
    };

    return axiosInstance
        .post(tfaAPIPath, postBody)
        .then(handleAxiosResponse)
        .then(res => {
            if (res) {
                localStorage.setItem('userRoles', JSON.stringify(res.userRoles));
                localStorage.setItem('authToken', JSON.stringify(res.token));
            }

            return res;
        });
}

function getAll() {
    return axiosInstance
        .get(
            `${api_base_url}/users/authenticate/users`,
            JSON.stringify({ headers: authHeader() })
        )
        .then(handleAxiosResponse);
}

function userCan(roleId) {
    const roles = JSON.parse(localStorage.getItem('userRoles')) || [];
    return Boolean(roles.find(role => role.roleID === roleId));
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('authToken');
}

function getRole() {
    const getObject = {
        url: '/api/Account/WhoAmI',
    };
    return handleServiceRequest.get(getObject).then(res => {
        if (res) {
            localStorage.setItem('userRoles', JSON.stringify(res));
            let event = document.createEvent('Event');
            event.initEvent('storage', true, true);
            window.dispatchEvent(event);
        }
        return res;
    });
}
