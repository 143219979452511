import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { userManagementService } from 'services';

export const Impersonation = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const clearImpersonation = async () => {
        const res = await userManagementService.clearImpersonation();
        if (res?.token) {
            dispatch({
                type: 'SET_USER',
                user: {
                    ...res,
                    ...user.original,
                },
                merge: false,
            });
        }
    };
    return (
        <div className="user-impersonation">
            <div className="user-impersonation-title">
                <FontAwesomeIcon icon={faUser} style={{ marginRight: 8 }} />
                {user?.impersonatedUser?.firstName} {user?.impersonatedUser?.lastName}
            </div>
            <span className="truncate">
                (
                {user?.impersonatedUser?.roleNames?.map(role => role.roleName).join(', ')}
                )
            </span>
            <Button outline color="white" size="sm" onClick={clearImpersonation}>
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        </div>
    );
};
