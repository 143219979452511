import { authHeader } from 'helpers/auth-header';
import { handleAxiosResponse, api_base_url, axiosInstance } from '../common.service';

// import { fakeResponses } from '../fakeresponses';
import { UrlEnum, handleError } from 'shared';

const getCapitalProjectWithId = id => {
    return axiosInstance
        .get(`${api_base_url}/api/finance/CapitalProjects/${id}`, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const createCapitalProject = (postBody, history) => {
    return axiosInstance
        .post(`${api_base_url}/api/finance/CapitalProjects/create`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            const location = {
                pathname: UrlEnum.CAPITALPROJECTREQUEST,
                state: [{ projectId: res }],
            };

            history.push(location);
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const searchCapitalProjects = postBody => {
    if (postBody.biennium.includes('all')) postBody.biennium = [];
    if (postBody.institutions.includes('all')) postBody.institutions = [];
    return axiosInstance
        .post(`${api_base_url}/api/finance/CapitalProjects/search`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res.runList;
        })
        .catch(error => {
            handleError(error);
        });
};

const saveCapitalProjects = postBody => {
    return axiosInstance
        .post(`${api_base_url}/api/finance/CapitalProjects/save`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const uploadCapitalProjectSupportingDocument = (capitalProjectId, formData) => {
    const url = `${api_base_url}/api/finance/CapitalProjects/upload/${capitalProjectId}`;

    return axiosInstance
        .post(url, formData, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const downloadCapitalProjectSupportingDocument = fileid => {
    const url = `${api_base_url}/api/finance/CapitalProjects/download/${fileid}`;

    return axiosInstance
        .get(url, {
            headers: authHeader(),
            responseType: 'blob',
        })
        .then(res => {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.click();
        })
        .catch(error => {
            handleError(error);
        });
};

const deleteCapitalProjectSupportingDocument = fileid => {
    const url = `${api_base_url}/api/finance/CapitalProjects/file/${fileid}`;

    return axiosInstance
        .delete(url, {
            headers: authHeader(),
        })
        .then(res => {})
        .catch(error => {
            handleError(error);
        });
};

const validateCapitalProjects = capitalProjectId => {
    return axiosInstance
        .post(
            `${api_base_url}/api/finance/CapitalProjects/validate?id=${capitalProjectId}`,
            {},
            {
                headers: authHeader(),
            }
        )
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const submitCapitalProject = postBody => {
    return axiosInstance
        .post(`${api_base_url}/api/finance/CapitalProjects/submit`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};
const approveCapitalProject = postBody => {
    return axiosInstance
        .post(`${api_base_url}/api/finance/CapitalProjects/approve`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};
const rejectCapitalProject = postBody => {
    return axiosInstance
        .post(`${api_base_url}/api/finance/CapitalProjects/reject`, postBody, {
            headers: authHeader(),
        })
        .then(handleAxiosResponse)
        .then(res => {
            return res;
        })
        .catch(error => {
            handleError(error);
        });
};

const downloadCapitalProject = (fileType, projectId) => {
    const url = `${api_base_url}/api/finance/CapitalProjects/report/${fileType}/${projectId}`;

    return axiosInstance
        .get(url, {
            headers: authHeader(),
            responseType: 'blob',
        })
        .then(res => {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `CapitalProject${projectId}.${fileType}`;
            link.click();
        })
        .catch(error => {
            handleError(error);
        });
};

const deleteCapitalProject = capitalProjectId => {
    const url = `${api_base_url}/api/finance/CapitalProjects/${capitalProjectId}`;

    return axiosInstance
        .delete(url, {
            headers: authHeader(),
        })
        .then(res => {})
        .catch(error => {
            handleError(error);
        });
};

export const capitalProjectService = {
    getCapitalProjectWithId,
    createCapitalProject,
    searchCapitalProjects,
    saveCapitalProjects,
    validateCapitalProjects,
    submitCapitalProject,
    approveCapitalProject,
    rejectCapitalProject,
    deleteCapitalProject,
    downloadCapitalProject,
    uploadCapitalProjectSupportingDocument,
    downloadCapitalProjectSupportingDocument,
    deleteCapitalProjectSupportingDocument,
};
